
import { DogEvent, DogEventType } from "@app/common";
import { DogClass, DogClassType } from "@app/common/classes";
import { RuleSet } from "@app/common/class_rules";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Loading from "./Loading.vue";
import DogRule from "./DogRule.vue";
import NewShowStore from "../store/modules/newshow";
import fuzzySearch from "../services/fuzzySearch";
@Component({ components: { Loading, DogRule } })
export default class DogClasses extends Vue {
  @Prop({
    default: () => {
      return new DogEvent(-1, new Date(), new Date(), null, DogEventType.OTHER);
    },
  })
  readonly dogEvent!: DogEvent;
  @Prop({default : () => {return []}}) readonly existingClasses! : Array<DogClass>
  showRules: boolean = false;
  showCustomClass: boolean = false;
  search: string = "";
  rulesAndClasses: {
    rules: RuleSet;
    prerequisiteClasses: Array<DogClassType>;
  } = { rules: {}, prerequisiteClasses: [] };

  get dogClasses(): Array<DogClass> {
    return NewShowStore.fetchedClasses;
  }
  async beforeMount() {
    await NewShowStore.fetchClasses();
    let eventType = this.eventTypes.find((y) => {
      return y.type === this.dogEvent.type;
    });
    if (eventType) {
      this.eventType = eventType;
      this.selectedClassTypes = eventType.classTypes.map((y) => {
        return y;
      });
    }
  }

  selectedClassTypes: Array<DogClassType> = [];

  eventTypes: Array<{ type: DogEventType; classTypes: Array<DogClassType> }> = [
    {
      type: DogEventType.CONFORMATION,
      classTypes: [
        DogClassType.CONFORMATION_REGULAR,
        DogClassType.CONFORMATION_NON_REGULAR,
        DogClassType.CONFORMATION_JUNIOR,
      ],
    },

    { type: DogEventType.SWEEPSTAKES, classTypes: [DogClassType.SWEEPSTAKE] },
    {
      type: DogEventType.OBEDIENCE,
      classTypes: [
        DogClassType.OBEDIENCE_REGULAR,
        DogClassType.OBEDIENCE_NON_REGULAR,
        DogClassType.OBEDIENCE_PREFERRED,
      ],
    },
    { type: DogEventType.RALLY, classTypes: [DogClassType.RALLY_REGULAR] },
    {
      type: DogEventType.FUTURITY_MATURITY,
      classTypes: [DogClassType.FUTURITY_MATURITY],
    },
    { type: DogEventType.OTHER, classTypes: [DogClassType.OTHER] },
  ];

  eventType: { type: DogEventType; classTypes: Array<DogClassType> } = {
    type: DogEventType.OTHER,
    classTypes: [DogClassType.OTHER],
  };

  get classesFilteredByTypes(): Array<DogClass> {
    let out: Array<DogClass> = [];
    for (let dogClass of this.dogClasses) {
      if (
        this.selectedClassTypes.find((x) => {
          return x === dogClass.type;
        }) && !this.existingClasses.find((x)=>{return x.id == dogClass.id})
      ) {
        out.push(dogClass);
      }
    }
    console.log("cftn")
    console.log(this.dogEvent)
    console.log(this.dogClasses)
    console.log(out)
    if (this.search.trim().length > 2) {
      let fuzzSearch = new fuzzySearch((x: DogClass) => {
        return x.name;
      }, []);
      fuzzSearch.input = out;
      fuzzSearch.search = this.search.trim();
      return fuzzSearch.output;
    }
    return out;
  }

  selected: Array<boolean> = [];

  @Watch("classesFilteredByTypes")
  onClassesFilteredByTypesChange(
    newVal: Array<DogClass>,
    oldVal: Array<DogClass>
  ) {
    
    let selectedClasses: Array<DogClass> = [];
    for (let k = 0; k < oldVal.length; k++) {
      if (this.selected[k]) {
        selectedClasses.push(oldVal[k]);
      }
    }
    this.selected = new Array(newVal.length).fill(false);
    for (let k = 0; k < newVal.length; k++) {
      if (
        selectedClasses.find((x) => {
          return newVal[k].id == x.id;
        })
      ) {
        this.selected[k] = true;
      }
    }
  }

  @Watch("dogEvent")
  onDogEventChange(x: DogEvent) {
    let eventType = this.eventTypes.find((y) => {
      return y.type === x.type;
    });
    if (eventType) {
      this.eventType = eventType;
      this.selectedClassTypes = eventType.classTypes.map((y) => {
        return y;
      });
    }
  }

  openRules(idx: number) {
    let dogClass: DogClass = this.classesFilteredByTypes[idx];
    this.rulesAndClasses = {
      rules: dogClass.rules.ruleSet,
      prerequisiteClasses: dogClass.prerequisiteClasses,
    };
    this.showRules = true;
  }

  get selectedStyle(): { "background-color": string; color: string } {
    return {
      "background-color": this.$vuetify.theme.themes.light.primary as string,
      color: "white",
    };
  }

  selectedStyleByIdx(idx: number) {
    return this.selected[idx] ? this.selectedStyle : "";
  }

  lastIdx: number | null = null;
  shiftDown: boolean = false;
  startIdx: number | null = null;

  mounted() {
    document.addEventListener("mousedown", () => {
      this.mouseDown = true;
    });

    document.addEventListener("mouseup", () => {
      this.mouseDown = false;
      this.startIdx = null;
    });

    let element = this.$refs.topDogClassPanel;
    if (element && (element as any).clientHeight) {
      this.topPanelHeight = (element as any).clientHeight;
      console.log(this.topPanelHeight);
    }
  }



  state: boolean = true;
  mouseDown: boolean = false;
  setState(idx: number) {
    this.state = !this.selected[idx];
    this.$set(this.selected, idx, this.state);
    this.startIdx = idx;
  }

  setSelected(idx: number) {
    if (this.mouseDown && this.startIdx !== null) {
      let min = Math.min(this.startIdx, idx);
      let max = Math.max(this.startIdx, idx);
      for (let k = min; k <= max; k++) {
        this.$set(this.selected, k, this.state);
      }
    }
  }

  unselect() {
    this.selected = new Array(this.selected.length).fill(false)
  }

  selectAll() {
    this.selected = new Array(this.selected.length).fill(true)
  }

  get atLeastOneSelected(): boolean {
    for (let item of this.selected) {
      if (item) {
        return true;
      }
    }
    return false;
  }

  get selectedClasses(): Array<DogClass> {
    let out: Array<DogClass> = [];
    for (let k = 0; k < this.selected.length; k++) {
      if (this.selected[k]) {
        out.push(this.classesFilteredByTypes[k]);
      }
    }
    return out;
  }

  @Watch("selectedClasses")
  onSelectedClassesChange(x :Array<DogClass>){
    this.$emit("change",x)
  }

  topPanelHeight: number = 0;

  //   get topPanelHeight(): number {
  //     let element = this.$refs['togDogClassPanel']
  //     //let element = document.getElementById("top-dog-class-panel");
  //     console.log("eklemen")
  //     console.log(element)
  //     console.log(this.$refs)
  //     if (element) {
  //       return (element as any).clientHeight;
  //     }
  //     return 0;
  //   }
}
