var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('b',_vm._l((_vm.events),function(event,idx){return _c('div',{key:idx},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"10%"}},[_vm._v(_vm._s(event.getDayShort()))]),_vm._m(0,true)]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"10%"}},[_vm._v(_vm._s(event.getDayShort()))]),_vm._m(1,true),_vm._m(2,true)]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"10%"}},[_vm._v(_vm._s(event.getDayShort()))]),_vm._m(3,true),_vm._m(4,true)]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"10%"}},[_vm._v(_vm._s(event.getDayShort()))]),_vm._m(5,true),_vm._m(6,true)])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"width":"45%"}},[_vm._v(" Best of Breed "),_c('span',{staticClass:"underline"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"width":"45%"}},[_vm._v(" Best of Winners "),_c('span',{staticClass:"underline"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"width":"45%"}},[_vm._v(" Best of Opposite Sex "),_c('span',{staticClass:"underline"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"width":"45%"}},[_vm._v(" Select Dog "),_c('span',{staticClass:"underline"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"width":"45%"}},[_vm._v(" Select Bitch "),_c('span',{staticClass:"underline"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"width":"45%"}},[_vm._v(" Best Puppy "),_c('span',{staticClass:"underline"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"width":"45%"}},[_vm._v(" Best Puppy Opposite Sex "),_c('span',{staticClass:"underline"})])
}]

export { render, staticRenderFns }