
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class PhoneNumber extends Vue {
  @Prop() readonly value!: string;
  @Prop({ default: "Phone number" }) readonly label!: string;
  @Prop({ default: []}) readonly messages!: Array<string>;

  phoneNumber: string = "";

  update(){
      this.$nextTick(()=>{
        this.phoneNumber = this.phoneNumber.replace(/[^0-9]/g,'')
        let areaCode = this.phoneNumber.substring(0,3)
        let phonePrefix = this.phoneNumber.substring(3,6)
        let lineNumber = this.phoneNumber.substring(6,10)
        let phoneNumber = ''
        if(areaCode.length > 0){
            phoneNumber = "("+areaCode
        }
        if(phonePrefix.length > 0){
            phoneNumber += ") "+phonePrefix
        }
        if(lineNumber.length > 0){
            phoneNumber += "-"+lineNumber
        }
        this.phoneNumber = phoneNumber
      })
  }

  @Watch("phoneNumber")
  onPhoneNumberChange(){
    this.$emit("input",this.phoneNumber)
  }

  @Watch("value")
  onValueChange() {
    this.phoneNumber = this.value;
    this.update();
  }
}
