
import {
  DogEvent,
  Entry,
  EventClass,
  SecretaryPaymentDataRequest,
  SecretaryShowDataRequest,
  SecretaryShowInterfaceRequest,
  SecretaryShowsRequest,
  Show,
} from "@app/common";
import { Component, Vue, Watch } from "vue-property-decorator";
import Loading from "../components/Loading.vue";
import SecretaryStore from "../store/modules/secretary";
import DogFooter from "../components/DogFooter.vue";
import CsvDataExporter from "../components/CSVData/CsvDataExporter.vue";
import ShowManagerEntriesSummary from "../components/ShowManager/ShowManagerEntriesSummary.vue";
import ShowManagerEntriesPanel from "../components/ShowManager/ShowManagerEntriesPanel.vue";
import ShowManagerClassTotals from "../components/ShowManager/ShowManagerClassTotals.vue";
import ShowManagerPaymentsSummary from "../components/ShowManager/ShowManagerPaymentsSummary.vue";
import ShowManagerPaymentsPanel from "../components/ShowManager/ShowManagerPaymentsPanel.vue";
import ShowManagerCatalogPanel from "../components/ShowManager/ShowManagerCatalogPanel.vue";
import ShowManagerShowInfo from "../components/ShowManager/ShowManagerShowInfo.vue";
import SafetyButton from "../components/SafetyButton.vue";
@Component({
  components: {
    Loading,
    DogFooter,
    CsvDataExporter,
    ShowManagerEntriesSummary,
    ShowManagerEntriesPanel,
    ShowManagerClassTotals,
    ShowManagerPaymentsSummary,
    ShowManagerPaymentsPanel,
    ShowManagerCatalogPanel,
    ShowManagerShowInfo,
    SafetyButton,
  },
})
export default class ShowManager extends Vue {
  loading: boolean = false;
  abbr: string = "";
  dataModal: boolean = false;
  window: number = 0;

  showDataLoaded: boolean = false;

  async mounted() {
    this.loading = true;
    this.abbr = this.$route.params.abbr;
    if (!SecretaryStore.selectedShow) {
      let payload: SecretaryShowInterfaceRequest = { abbr: this.abbr };
      await SecretaryStore.fetchShow(payload);
    }
    if (SecretaryStore.selectedShow && SecretaryStore.selectedShow.id) {
      let showDataPaylod: SecretaryShowDataRequest = {
        show: SecretaryStore.selectedShow.id,
      };
      await SecretaryStore.fetchShowData(showDataPaylod);
    }
    this.loading = false;
    this.showDataLoaded = true;
  }

  get show(): Show | undefined {
    return SecretaryStore.selectedShow;
  }

  get loadingEntries(): boolean {
    return SecretaryStore.fetchingEntries;
  }

  entryModal: boolean = false;
  paymentModal: boolean = false;
  catalogModal: boolean = false;

  tab: number = 0;

  @Watch("window")
  async onWindowChange(x: number) {
    if (this.show && this.show.id) {
      if (x == 1) {
        let payload: SecretaryShowDataRequest = {
          show: this.show.id,
        };
        await SecretaryStore.fetchEntries(payload);
      } else if (x == 2) {
        let payload: SecretaryPaymentDataRequest = {
          show: this.show.id,
        };
        await SecretaryStore.fetchPaymentData(payload);
      } else if (x == 3) {
        //
      }
    }
  }

  linkNewTab: boolean = false;
  urlText: string = "";

  get urlLink(): string {
    if (SecretaryStore.selectedShow) {
      return (
        process.env.VUE_APP_DOG_SITE +
        "/show/" +
        SecretaryStore.selectedShow.abbr
      );
    } else {
      return "";
    }
  }

  copyLink() {
    navigator.clipboard.writeText(this.urlLink);
  }
  copyCustomLink() {
    let text =
      '<a href="' +
      this.urlLink +
      (this.linkNewTab ? '" target="_blank" rel="noopener noreferrer"' : '"') +
      ">" +
      this.urlText +
      "</a>";
    navigator.clipboard.writeText(text);
  }
}
