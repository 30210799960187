
import { Component, Vue, Prop } from "vue-property-decorator";
import Loading from "./Loading.vue";
import ExpansionList from "./ExpansionList.vue";
import DogEntry from "./DogEntry.vue";
import DogInfo from "./DogInfo.vue";
import SafetyButton from "./SafetyButton.vue";
import DogEntriesAlert from "./DogEntriesList/DogEntriesAlert.vue";
import ShowDogInfo from "./DogEntriesList/ShowDogInfo.vue";
import DogStore from "../store/modules/dog";
import UserStore from "../store/modules/user";
import SecretaryStore from "../store/modules/secretary";
import SecretaryEntryStore from "../store/modules/secretary_entries";
import ShowStore from "../store/modules/show";
import fuzzySearch from "../services/fuzzySearch";
import {
  Dog,
  DogEvent,
  Entry,
  PaymentStatus,
  RemoveEntryRequest,
  Show,
  ShowDog,
  ShowEntry,
} from "@app/common";

class ShowEntryItem extends ShowEntry {
  private _selected: boolean;
  get selected(): boolean {
    return this._selected;
  }
  set selected(x: boolean) {
    console.log(this.showDog);
    this._selected = x;
  }
  // constructor(selected : boolean, showDog : ShowDog,entries : Array<Entry>,show: Show,armband: string,showDogEdit? : {showDog: ShowDog,time: Date}){
  //   super(showDog,entries,show,armband,showDogEdit)
  //   this._selected = selected
  // }
  constructor(showEntry: ShowEntry, selected: boolean) {
    super(
      showEntry.showDog,
      showEntry.entries,
      showEntry.show,
      showEntry.armband,
      showEntry.showDogEdit
    );
    this._selected = selected;
  }
  public exportShowEntry() {
    return new ShowEntry(
      this.showDog,
      this.entries,
      this.show,
      this.armband,
      this.showDogEdit
    );
  }
}

@Component({
  components: {
    ExpansionList,
    Loading,
    DogInfo,
    DogEntry,
    SafetyButton,
    DogEntriesAlert,
    ShowDogInfo,
  },
})
export default class DogEntriesSecretary extends Vue {
  @Prop({ default: false }) readonly paymentMode!: boolean;
  @Prop({ default: "" }) readonly search!: string;
  @Prop({
    default: () => {
      return ["Paid", "Unpaid", "Refunded"];
    },
  })
  readonly paymentStatus!: Array<"Paid" | "Unpaid" | "Refunded">;
  @Prop({
    default: () => {
      return ["Online", "Manual"];
    },
  })
  readonly entryMethod!: Array<"Online" | "Manual">;
  @Prop({
    default: () => {
      return [];
    },
  })
  readonly excludeEvents!: Array<DogEvent>;

  showDogInfo = false;
  infoDog: ShowEntry | null = null;
  payEntry: Array<boolean> = [];
  removeShowEntry: ShowEntry | null = null;
  removeEntryModal: boolean = false;

  get removeEntryDogName(): string {
    if (this.removeShowEntry && this.removeEntryModal) {
      return this.removeShowEntry.showDog.showDog.dog.printName();
    } else {
      return "";
    }
  }

  get showPaid(): boolean {
    return this.paymentStatus.includes("Paid");
  }
  get showUnpaid(): boolean {
    return this.paymentStatus.includes("Unpaid");
  }
  get showRefunded(): boolean {
    return this.paymentStatus.includes("Refunded");
  }

  get showEntries(): Array<ShowEntry> {
    return SecretaryStore.showEntries;
  }

  entryPaymentCheck(entry: Entry): boolean {
    if (this.paymentStatus.includes("Paid") && entry.paid && !entry.refunded) {
      return true;
    }
    if (this.paymentStatus.includes("Unpaid") && !entry.paid) {
      return true;
    }
    if (this.paymentStatus.includes("Refunded") && entry.refunded) {
      return true;
    }
    return false;
  }
  showEntryPaymentCheck(showEntry: ShowEntry): boolean {
    for (let entry of showEntry.entries) {
      if (this.entryPaymentCheck(entry)) {
        return true;
      }
    }
    return false;
  }

  get show(): Show | undefined {
    return SecretaryStore.selectedShow;
  }

  get filteredShowEntries(): Array<ShowEntryItem> {
    if (this.show) {
      let out: Array<ShowEntryItem> = this.showEntryItems.filter((x) => {
        return this.showEntryPaymentCheck(x);
      });
      let includedEvents: Array<DogEvent> = this.show.events.filter((x) => {
        return !this.excludeEvents.find((y) => {
          return x.id == y.id;
        });
      });
      out = out.filter((x) => {
        return includedEvents.find((y) => {
          return x.entries.find((z) => {
            return z.event.id == y.id;
          });
        });
      });
      if (this.paymentMode) {
        let pendingPaymentEntries: Array<Entry> = [];
        for (let payment of SecretaryStore.payments) {
          if (payment.status == PaymentStatus.PENDING) {
            pendingPaymentEntries = pendingPaymentEntries.concat(
              payment.entries.map((z) => {
                return z.entry;
              })
            );
          }
        }
        out = out.filter((x) => {
          return !x.entries.find((y) => {
            return pendingPaymentEntries.find((z) => {
              return z.id == y.id;
            });
          });
        });
        if (this.selectedEntries.length > 0) {
          let userId: number | null = this.selectedEntries[0].user
            ? this.selectedEntries[0].user.id
            : null;
          if (userId) {
            out = out.filter((x) => {
              return x.user ? x.user.id == userId : false;
            });
          } else {
            out = out.filter((x) => {
              return !x.user;
            });
          }
        }
      }

      if (this.search.trim().length > 0) {
        let fuzzy = new fuzzySearch((x: ShowEntryItem) => {
          return (
            x.showDog.showDog.dog.printName() +
            (x.showDog.user
              ? " " + x.showDog.user.fullName + " " + x.showDog.user.email
              : "")
          );
        }, []);
        fuzzy.input = out;
        fuzzy.search = this.search;
        out = fuzzy.output;
      }
      if (this.paymentMode && this.selectedEntries.length > 0) {
        for (let showEntry of this.selectedEntries) {
          if (
            !out.find((x) => {
              return x.showDog.showDog.id == showEntry.showDog.showDog.id;
            })
          ) {
            out.push(showEntry);
          }
        }
      }
      return out;
    }
    return [];
  }

  entriesOpen(show: Show): boolean {
    return new Date().getTime() <= show.closingTime.getTime();
  }

  get selectedEntries(): Array<ShowEntryItem> {
    // let out : Array<ShowEntryItem> = []
    // for(let k = 0; k<this.showEntryItems.length; k++){
    //   if(){
    //     out.push(this.showEntries[k])
    //   }
    // }
    return this.showEntryItems.filter((x) => {
      return x.selected;
    });
  }

  //showDogsSelected : Record<number,ShowEntry> = {}
  showEntryItems: Array<ShowEntryItem> = [];

  setShowEntryItems() {
    this.showEntryItems = this.showEntries.map((x) => {
      return new ShowEntryItem(x, false);
    });
  }

  async mounted() {
    this.payEntry = [];
    this.showEntryItems = [];
    this.setShowEntryItems();
    this.updatePaymentEntries();
  }

  updatePaymentEntries() {
    let paymentEntries: Array<ShowEntry> = this.selectedEntries.map((x) => {
      return x.exportShowEntry();
    });
    this.$emit("payment", paymentEntries);
  }

  searchShows() {
    this.$router.push("/shows");
  }

  dogInfo(showEntry: ShowEntry) {
    //this.showDogInfo = true;
    this.infoDog = showEntry;
  }

  editDog(showEntry: ShowEntry) {
    if (showEntry.showDogEdit) {
      let newShowDog: ShowDog = new ShowDog(
        showEntry.showDog.showDog.id,
        showEntry.showDogEdit.showDog.showDog.dog
      );
      SecretaryEntryStore.setSelectedShowDog(newShowDog);
    } else {
      SecretaryEntryStore.setSelectedShowDog(showEntry.showDog);
    }
    this.$router.push(
      "/dog-form/edit/show/" + showEntry.show.abbr + "/secretary"
    );
  }

  removeEntry(showEntry: ShowEntry) {
    this.removeShowEntry = showEntry;

    this.removeEntryModal = true;
  }

  get removingEntries(): boolean {
    return DogStore.removingEntry;
  }

  async removeEntries() {
    if (
      this.removeShowEntry &&
      this.removeShowEntry.showDog.dog.id &&
      this.removeShowEntry.show.id
    ) {
      let payload: RemoveEntryRequest = {
        dog: this.removeShowEntry.showDog.dog.id,
        show: this.removeShowEntry.show.id,
        entries: this.removeShowEntry.entries
          .filter((x) => {
            return !x.paid;
          })
          .map((x) => {
            return x.exportInterface();
          }),
        csrfSecurityToken: UserStore.csrfSecurityToken,
      };
      await DogStore.removeEntry(payload);
      if (!DogStore.removeEntryError) {
        await DogStore.fetchDogData();
        this.removeEntryModal = false;
      }
    }
  }

  changeClasses(showEntry: ShowEntry) {
    SecretaryEntryStore.setSelectedShowDog(showEntry.showDog);
    SecretaryEntryStore.setSelectedShowEntry(showEntry);
    this.$router.push("/enter-dog/edit/secretary");
  }

  createPayment() {
    // for(let k = 0; k<this.en)
  }

  test() {
    let test: ShowEntry = this.showEntries[0];
    this.showEntries[0];
    this.showEntries[0].entries[0].classes[0].dogClass.name;
    this.showEntries[0].entries[0].event.printDateRange;
    this.showEntries[0].showDog.showDog.dog.printName();
  }
}
