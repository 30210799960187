
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class ComponentName extends Vue {
  @Prop({default: 1000}) readonly disableTime!: number;
  @Prop() readonly opened!: boolean;
  @Prop({default: false}) readonly disabled!: boolean;
  @Prop({default: false}) readonly loading!: boolean;

  emitClick() {
    this.$emit("click");
  }
  disableButton = true;
  timeout = 0;

  @Watch("opened")
  onOpenedChange(val: boolean) {
    if (val) {
      this.timeout = setTimeout(() => {
        this.disableButton = false;
      }, this.disableTime);
    } else {
      clearTimeout(this.timeout);
      this.disableButton = true;
    }
  }

  mounted(){
    if(this.opened){
        this.timeout = setTimeout(() => {
        this.disableButton = false;
      }, this.disableTime);
    }
  }

}
