import { SecretaryCSVDataRequest } from "@app/common";

export default async function fetchCSVData(payload : SecretaryCSVDataRequest){
        const response = await fetch(
            process.env.VUE_APP_DOG_API + "/secretary/csv-data/export",
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(payload),
                credentials: "include",
            }
        );
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        document.body.appendChild(link);
        link.setAttribute("download", "csv_data.zip");
        link.click();
}