
import { Component, Vue } from "vue-property-decorator";
import fuzzySearch from "../services/fuzzySearch";
import TopPage from "../components/TopPage.vue";
import DogList from "../components/DogList.vue";
import DogEntries from "../components/DogEntries.vue";
import DogPayments from "../components/DogPayments.vue";
import DogRefunds from "../components/DogRefunds.vue"
import Loading from "../components/Loading.vue";
import DogToggle from "../components/DogForm/DogToggle.vue"
import DogStore from "../store/modules/dog";
import PaymentStore from "../store/modules/payment";
import { Dog, Payment } from "@app/common";
@Component({
  components: {
    TopPage,
    DogList,
    DogEntries,
    DogPayments,
    DogRefunds,
    Loading,
    DogToggle
  },
})
export default class ExhibitorDashboard extends Vue {
  tab = 0;
  dogSearch = "";
  paymentOrRefund = "payment"

  async mounted() {
    if (DogStore.dogDataNeedsUpdating) {
      DogStore.fetchDogData();
    }
    if (PaymentStore.paymentDataNeedsUpdating) {
      PaymentStore.fetchPayments();
    }
  }

  get dogDataLoading(): boolean {
    return DogStore.fetchingDogData;
  }

  get paymentsLoading(): boolean {
    return PaymentStore.fetchingPaymentData;
  }

  get dogs(): Array<Dog> {
    if (this.dogSearch && this.dogSearch.trim().length > 2) {
      let search = new fuzzySearch((x: Dog) => {
        return x.name.name;
      }, []);
      search.input = DogStore.dogs;
      search.search = this.dogSearch;
      return search.output;
    } else {
      return DogStore.dogs;
    }
  }
}
