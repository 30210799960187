
import { DogClass, DogClassType } from "@app/common/classes";
import { Rule } from "@app/common/class_rules";
import { Component, Vue, Watch } from "vue-property-decorator";
import DogSplit from "../components/DogSplit.vue";
import ClassFromText from "../components/NewShow/ClassFromText.vue";
@Component({ components: { DogSplit, ClassFromText } })
export default class Test2 extends Vue {
  dogClass: DogClass = new DogClass(
    -1,
    "silly",
    new Rule({}),
    [],
    DogClassType.CONFORMATION_REGULAR,
    true
  );
}
