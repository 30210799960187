
import { Component, Vue, Watch } from "vue-property-decorator";
import EmailWindow from "./EmailWindow.vue";
import LoginWindow from "./LoginWindow.vue";
import ForgotWindow from "./ForgotWindow.vue";
import UserStore from "../../store/modules/user";

@Component({
  components: {
    EmailWindow,
    LoginWindow,
    ForgotWindow,
  },
})
export default class Login extends Vue {
  topText = "Login";
  step = 0;
  private _windowTransition: undefined | null = undefined;
  private _windowReverseTransition: undefined | null = undefined;
  loginReady = false;
  forgotReady = false;
  email = "";

  get nextText() {
    if (this.step == -1) {
      return "Submit";
    } else if (this.step == 0) {
      return "Login";
    } else {
      return "";
    }
  }

  get windowTransition(): undefined | null {
    return this._windowTransition;
  }

  get windowReverseTransition(): undefined | null {
    return this._windowReverseTransition;
  }

  get loading(): boolean {
    if (this.step == 0) {
      return UserStore.loggingIn;
    } else if (this.step == -1) {
      return UserStore.requestingForgotRequest;
    }
    return false;
  }

  get nextDisabled(): boolean {
    if (this.step == -1) {
      return !this.forgotReady;
    } else if (this.step == 0) {
      return !this.loginReady;
    } else {
      return true;
    }
  }

  next() {
    this.step++;
  }

  back() {
    this.step--;
  }

  close() {
    this.$emit("close");
  }

  reset() {
    console.log("abc");
  }

  async action() {
    console.log("action");
    if (this.step == 0 && this.loginReady) {
      const child = this.$refs.login as LoginWindow;
      await child.login();
    } else if (this.step == -1 && this.forgotReady) {
      const child = this.$refs.forgot as ForgotWindow;
      await child.requestReset();
    }
  }
}
