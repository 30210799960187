
import { Component, Vue } from "vue-property-decorator";
import DogFee from "../DogFee.vue";
import NewShowStore from "../../store/modules/newshow";
@Component({ components: { DogFee } })
export default class NewShowAdditionalFees extends Vue {
  catalog: { canPurchase: boolean; fee: number } = {
    canPurchase: false,
    fee: 0,
  };
  surcharge: boolean = false;
  haveSecretaryFee: boolean = false;
  secretaryFee: number = 0;
  update() {
    let catalogOut: { canPurchase: boolean; fee: number } = {
      canPurchase: this.catalog.canPurchase,
      fee: this.catalog.canPurchase ? this.catalog.fee : 0,
    };
    let secretaryFeeOut: number = this.haveSecretaryFee ? this.secretaryFee : 0;
    NewShowStore.setAdditionalFees({
      catalog: catalogOut,
      surcharge: this.surcharge,
      secretaryFee: secretaryFeeOut,
    });
  }

  mounted() {
    if(NewShowStore.loadFromLocalStorage){
      this.surcharge = NewShowStore.show.surcharge
      this.catalog = NewShowStore.show.catalog
      this.secretaryFee = NewShowStore.show.secretaryFee
      this.haveSecretaryFee = this.secretaryFee > 0
    }   
  }

  validate(): Array<string> {
    let out : Array<string> = []
    if(this.catalog.canPurchase && this.catalog.fee == 0){
      out.push("You must provide a non-zero fee for catalogs.")
    }
    if(this.haveSecretaryFee && this.secretaryFee == 0){
      out.push("If you choose to include a secretary service fee, then that fee must be a non-zero fee.")
    }
    return out;
  }
}
