import { User, UserInterface } from "./user"
import { DogClassInterface } from "./classes"
import { CSVPreset, CatalogOrder, CatalogOrderInterface, EventClassInterface, NewEventClassInterface, ShowEntryInterface } from "./index"
import { PaymentInterface, PaymentType, RefundInterface, SquareTokenInterface } from "./payment"
import { AddressInterface, JudgeInterface } from "./people"
import { GeneralErrors, ActionResponse } from "./response_interface"
import { ShowDogBaseInterface, ShowDogInterface, ShowInterface } from "./show"

export enum SecretaryErrors {
    NOT_AUTHORIZED = 'NOT_AUTHORIZED',
    READ_ENTRIES_NOT_AUTHORIZED = 'READ_ENTRIES_NOT_AUTHORIZED',
    WRITE_ENTRIES_NOT_AUTHORIZED = 'WRITE_ENTRIES_NOT_AUTHORIZED',
    READ_PAYMENTS_NOT_AUTHORIZED = 'READ_PAYMENTS_NOT_AUTHORIZED',
    WRITE_PAYMENTS_NOT_AUTHORIZED = 'WRITE_PAYMENTS_NOT_AUTHORIZED',
    INVALID_ENTRY_DATA = 'INVALID_ENTRY_DATA',
    ENTRIES_OPEN = 'ENTRIES_OPEN',
    DOG_OWNERSHIP = 'DOG_OWNERSHIP',
    ENTRY_PAID = 'ENTRY_PAID',
    SHOW_MISMATCH = 'SHOW_MISMATCH',
    NOT_ROOT = 'NOT_ROOT'
}

export const SecretaryIntializeOutput: SecretaryPermissionsResponse = {
    authenticated: false,
    authorized: false,
    root: false,
    permissions: { readEntries: false, writeEntries: false, readPayments: false, writePayments: false },
}

export const SecretaryIntializeActionOutput: SecretaryActionResponse = {
    authenticated: false,
    authorized: false,
    root: false,
    permissions: { readEntries: false, writeEntries: false, readPayments: false, writePayments: false },
    csrfSecurityToken : ""
}

export interface SecretaryPermissionsResponse {
    authenticated: boolean,
    authorized: boolean,
    root: boolean,
    permissions: {
        readEntries: boolean,
        writeEntries: boolean,
        readPayments: boolean,
        writePayments: boolean
    }

}

export interface SecretaryActionResponse extends ActionResponse, SecretaryPermissionsResponse {
}

export interface SecretaryShowsResponse {
    success: boolean,
    authenticated: boolean,
    shows: Array<ShowInterface>
}

export interface SecretaryShowInterfaceResponse{
    success :boolean,
    show? : ShowInterface
}


export interface SecretaryShowDataResponse extends SecretaryPermissionsResponse {
    success: boolean,
    stats: {
        dogs: Array<{ event: number, total: number }>
        entries: Array<{ event: number, total: number }>
        classes: Array<{ event: number, total: number }>
        catalogs: number
    }
    eventClasses: Array<EventClassInterface>
    classes: Array<DogClassInterface>
    judges: Array<JudgeInterface>
    users: Array<UserInterface>
}

export interface SecretaryEntryDataResponse extends SecretaryPermissionsResponse {
    success: boolean,
    authenticated: boolean,
    errors: Array<GeneralErrors | SecretaryErrors>
    entries: Array<ShowEntryInterface>
    showDogs: Array<ShowDogInterface>
    showDogEdits: Array<ShowDogInterface>
    juniorAddresses : Array<AddressInterface>
    users : Array<UserInterface>
}

export interface SecretaryPaymentDataResponse extends SecretaryPermissionsResponse {
    success: boolean,
    authenticated: boolean,
    errors: Array<GeneralErrors | SecretaryErrors>
    payments: Array<PaymentInterface>
    refunds: Array<RefundInterface>
    users : Array<UserInterface>
}

export interface SecretaryEnterDogResponse extends SecretaryActionResponse {
    success: boolean,
    errors: Array<GeneralErrors | SecretaryErrors>
}

export interface SecretaryEditDogResponse extends SecretaryActionResponse {
    success: boolean,
    authenticated: boolean,
    errors: Array<GeneralErrors | SecretaryErrors>
}

export interface SecretaryRemoveDogResponse extends SecretaryActionResponse {
    success: boolean,
    authenticated: boolean,
    errors: Array<GeneralErrors | SecretaryErrors>
}

export interface SecretaryEditEntryResponse extends SecretaryActionResponse {
    success: boolean,
    authenticated: boolean,
    errors: Array<GeneralErrors | SecretaryErrors>
}

export interface SecretaryRemoveEntryResponse extends SecretaryActionResponse {
    success: boolean,
    authenticated: boolean,
    errors: Array<GeneralErrors | SecretaryErrors>
}

export interface SecretaryAcceptCheckPaymentResponse extends SecretaryActionResponse{
    success: boolean,
    authenticated : boolean,
    errors: Array<GeneralErrors | SecretaryErrors>
}

export interface SecretaryFetchClassesResponse {
    success: boolean,
    classes: Array<DogClassInterface>
}

export interface SecretaryFetchJudgesResponse {
    success: boolean,
    judges: Array<JudgeInterface>
}

export interface SecretaryFetchSquareTokensResponse {
    success: boolean,
    authenticated: boolean,
    squareTokens: Array<SquareTokenInterface>,
    errors: Array<GeneralErrors>
}

export interface SecretaryNewShowResponse extends SecretaryActionResponse {
    success: boolean,
}

export interface SecretaryCheckAbbrResponse {
    success: boolean
    available: boolean
}

export interface SecretaryGenerateAbbrResponse{
    success:boolean
    abbr: string
}

export interface SecretaryFetchCSVPresetsResponse {
    success: boolean,
    authenticated: boolean,
    presets: Array<CSVPreset>,
    errors: Array<GeneralErrors>
}

export interface SecretarySaveCSVPresetResponse extends ActionResponse {
    success: boolean
    authenticated: boolean
    errors: Array<GeneralErrors>
}

export interface SecretaryDeleteCSVPresetResponse extends ActionResponse {
    success: boolean
    authenticated: boolean
    errors: Array<GeneralErrors>
}

export interface SecretarySubmitShowResponse extends ActionResponse{
    success : boolean
    authenticated : boolean
    errors : Array<GeneralErrors>
    trusted : boolean
}


export interface SecretarySubmitInfoResponse extends ActionResponse{
    success : boolean
    authenticated : boolean
    errors : Array<GeneralErrors>
}

export interface SecretarySuspendShowResponse extends SecretaryActionResponse{
    success : boolean
    authenticated  :boolean
    errors : Array<GeneralErrors | SecretaryErrors>
}

export interface SecretaryDeleteShowResponse extends SecretaryActionResponse{
    success : boolean
    authenticated : boolean
    errors : Array<GeneralErrors | SecretaryErrors>
}

export interface SecretaryPaymentResponse extends SecretaryActionResponse{
    success : boolean
    authenticated : boolean
    payment: number
    errors : Array<GeneralErrors | SecretaryErrors>
}

export interface SecretaryClassAndJudgeResponse{
    success : boolean
    authenticated : boolean
    classes : Array<DogClassInterface>
    judges : Array<JudgeInterface>
    errors : Array<GeneralErrors>
}

export interface SecretaryLoadShowResponse{
    success : boolean
    show : ShowInterface,
    customClasses : Array<DogClassInterface>
    newJudges : Array<JudgeInterface>
    squareToken : number
    eventClasses : Array<NewEventClassInterface>
}

export interface SecretarySaveCatalogSettingsResponse extends ActionResponse, SecretaryPermissionsResponse {
    success : boolean,
    authenticated : boolean,
}

export interface SecretaryLoadCatalogSettingsResponse extends SecretaryPermissionsResponse{
    success : boolean,
    authenticated : boolean,
    ordering : CatalogOrderInterface | null
}