import { User } from './user'
import { Person, Address } from './people'
import helpers from "./dl"
import { AddressInterface, PersonInterface } from '.'
import { PrefixTitles, SuffixTitles, DogTitle, DogName, DogNameInterface } from './dog_titles'

export interface Breed {
    breed: string
    id: number
}


export interface Color {
    color: string
    id: number
}

export interface Variety {
    variety: string
    id: number
}

export interface Group {
    group: string
    id: number
}

let nullBreed: Breed = { breed: "", id: -1 }
let nullColor: Color = { color: "", id: -1 }
let nullVariety: Variety = { variety: "", id: -1 }
let nullGroup: Group = { group: "", id: -1 }

export { nullBreed, nullColor, nullVariety, nullGroup }

export enum RegistrationTypes {
    AKC = 'AKC',
    AKCLIT = 'AKCLIT',
    ILP = 'ILP',
    FOREIGN = 'FOREIGN'
}

export enum Sex {
    MALE = 'M',
    FEMALE = 'F'
}


// export enum PrefixDogTitles {
//     CH = 'CH',
//     GCH = 'GCH',
//     GCHB = 'GCHB',
//     GCHS = 'GCHS',
//     GCHP = 'GCHP'
// }

// export enum SuffixDogTitles {
//     A = 'A'
// }

// export interface DogTitle {
//     title: PrefixTitles | SuffixTitles,
//     order: number
// }



export class RegistrationNumber {
    static registrationNumberFromJSON(x: RegistrationNumberInterface): RegistrationNumber {
        return new RegistrationNumber(x.type, x.number, x.country)
    }

    type: RegistrationTypes
    number: string
    country: string

    constructor()
    constructor(type: RegistrationTypes, number: string, country: string)
    constructor(type?: RegistrationTypes, number?: string, country?: string) {
        if (typeof type !== "undefined") {
            this.type = type
        } else {
            this.type = RegistrationTypes.AKC
        }

        if (typeof number !== "undefined") {
            this.number = number
        } else {
            this.number = ""
        }

        if (typeof country !== "undefined") {
            this.country = country
        } else {
            this.country = ""
        }
    }

    printRegistrationNumber(): string {
        if (this.type === RegistrationTypes.FOREIGN) {
            return this.number + " (" + this.country + ")"
        } else {
            return this.number
        }
    }

    public clone(): RegistrationNumber {
        return new RegistrationNumber(this.type, this.number, this.country)
    }

    public exportInterface(): RegistrationNumberInterface {
        let out: RegistrationNumberInterface = {
            number: this.number,
            type: this.type,
            country: this.country
        }
        return out
    }
}

export interface RegistrationNumberInterface {
    number: string,
    type: RegistrationTypes,
    country: string,
}

// export class DogName {
//     public static dogNameFromJSON(x: DogNameInterface): DogName {
//         return new DogName(x.baseName, x.prefixTitles, x.suffixTitles)
//     }

//     private _name: string
//     private _baseName: string
//     private _prefixTitles: Array<DogTitle>
//     private _suffixTitles: Array<DogTitle>
//     private titlesCollected = false
//     public get name(): string {
//         return this._name
//     }
//     public set name(x: string) {
//         this._name = x.trim()
//         if (!this.titlesCollected) {
//             let { baseName, prefixTitles, suffixTitles } = this.extractTitlesAndBaseName(x)
//             this._baseName = baseName
//             this._prefixTitles = prefixTitles
//             this._suffixTitles = suffixTitles
//         }
//     }
//     public get prefixTitles(): Array<DogTitle> {
//         return this._prefixTitles
//     }
//     //Do not set prefixTitles
//     public get suffixTitles(): Array<DogTitle> {
//         return this._suffixTitles
//     }
//     //Do not set suffixTitles
//     public get baseName(): string {
//         return this._baseName
//     }

//     constructor()
//     constructor(baseName: string, prefixTitles: Array<DogTitle>, suffixTitles: Array<DogTitle>)
//     constructor(baseName?: string, prefixTitles?: Array<DogTitle>, suffixTitles?: Array<DogTitle>) {
//         this._baseName = baseName || ''
//         this._prefixTitles = prefixTitles || []
//         this._suffixTitles = suffixTitles || []
//         this._name = this._prefixTitles.join(' ') + ' ' + this._baseName + (this._suffixTitles.length > 0 ? ' ' : '') + this._suffixTitles.join(' ')
//     }

//     public correctName(): void {
//         if (!/[a-z]/.test(this._baseName) || !/[A-Z]/.test(this._baseName)) {
//             this._baseName = this._baseName.split(" ")
//                 .map(function (x) {
//                     return x.charAt(0).toUpperCase() + x.slice(1).toLowerCase();
//                 })
//                 .join(" ");
//         }
//         let prefix: string = this.prefixTitles.map((x) => { return x.title + x.order.toString() }).join(' ')
//         let suffix: string = this.suffixTitles.map((x) => { return x.title + x.order.toString() }).join(' ')
//         this.name = prefix + ' ' + this._baseName + (suffix.length ? ' ' : '') + suffix
//     }

//     private extractTitlesAndBaseName(x: string): { baseName: string, prefixTitles: Array<DogTitle>, suffixTitles: Array<DogTitle> } {
//         let words = x.trim().split(" ").filter((s) => { return s.length > 0 })
//         let { baseStrings: remainingWords, titles: pTitles } = this.extractTitlesAndBaseStringsFromWords(words, true)
//         remainingWords.reverse()
//         let { baseStrings: finalWords, titles: sTitles } = this.extractTitlesAndBaseStringsFromWords(remainingWords, false)
//         finalWords.reverse()
//         let bName = finalWords.join(' ')
//         return { baseName: bName, prefixTitles: pTitles, suffixTitles: sTitles }
//     }

//     private extractTitlesAndBaseStringsFromWords(words: Array<string>, isPrefix: boolean): { baseStrings: Array<string>, titles: Array<DogTitle> } {
//         let titles: Array<DogTitle> = []
//         let remainingWords: Array<string> = []
//         let finishedTitles = false
//         let titleValues: PrefixTitles[] | SuffixTitles[]
//         if (isPrefix) {
//             titleValues = Object.values(PrefixTitles)
//         } else {
//             titleValues = Object.values(SuffixTitles)
//         }
//         for (let word of words) {
//             if (!finishedTitles) {
//                 let w = word.split(/(\d+)/)
//                 if (w.length > 2) {
//                     finishedTitles = true
//                 }
//                 let foundTitle = false

//                 for (const title of titleValues) {
//                     if (title == w[0].toUpperCase()) {
//                         if (w.length == 1) {
//                             titles.push({ title: title, order: 0 })
//                         } else {
//                             titles.push({ title: title, order: parseInt(w[1]) })
//                         }
//                         foundTitle = true
//                         break
//                     }
//                 }
//                 if (foundTitle) {
//                     finishedTitles = false
//                 }
//             } else {
//                 remainingWords.push(word)
//             }
//         }
//         return { baseStrings: remainingWords, titles: titles }
//     }

//     public clone(): DogName {
//         let prefixTitles: Array<DogTitle> = []
//         for (let title of this._prefixTitles) {
//             prefixTitles.push(Object.assign({}, title))
//         }
//         let suffixTitles: Array<DogTitle> = []
//         for (let title of this._prefixTitles) {
//             suffixTitles.push(Object.assign({}, title))
//         }
//         return new DogName(this._baseName, prefixTitles, suffixTitles)
//     }
// }

// export interface DogNameInterface {
//     baseName: string,
//     prefixTitles: Array<DogTitle>
//     suffixTitles: Array<DogTitle>
// }



export class Dog {
    static breeds: Array<Breed>
    static varieties: Array<Variety>
    static colors: Array<Color>
    static groups: Array<Group>
    static breedData: Array<{ breed: Breed, groups: Array<{ group: Group | null, varieties: Array<{ variety: Variety | null, colors: Array<Color> }> }> }>

    public static initializeBreedData(breeds: Array<Breed>, groups: Array<Group>, varieties: Array<Variety>, colors: Array<Color>, dogBreedData: Array<BreedDataInterface>) {
        Dog.breeds = breeds
        Dog.groups = groups
        Dog.varieties = varieties
        Dog.colors = colors
        let breedData: Array<{ breed: Breed, groups: Array<{ group: Group | null, varieties: Array<{ variety: Variety | null, colors: Array<Color> }> }> }> = []
        for (let thisBreedData of dogBreedData) {
            let breed: Breed | undefined = Dog.breeds.find((x) => { return x.id == thisBreedData.breed })
            if (!breed) {
                console.error("Breed data: Breed not found")
                throw "Breed data: Breed not found"
            }
            let groups: Array<{ group: Group | null, varieties: Array<{ variety: Variety | null, colors: Array<Color> }> }> = []
            for (let groupData of thisBreedData.groups) {
                let group: Group | null = null
                if (groupData.group !== null) {
                    let groupSearch: Group | undefined = Dog.groups.find((x) => { return x.id == groupData.group })
                    if (!groupSearch) {
                        console.error("Breed data: Group not found")
                        throw "Breed data: Group not found"
                    }
                    group = groupSearch
                }

                let varieties: Array<{ variety: Variety | null, colors: Array<Color> }> = []
                for (let varietyData of groupData.varieties) {
                    let variety: Variety | null = null
                    if (varietyData.variety !== null) {
                        let varietySearch: Variety | undefined = Dog.varieties.find((x) => { return x.id == varietyData.variety })
                        if (!varietySearch) {
                            console.error("Breed data: Variety not found")
                            throw "Breed data: Variety not found"
                        }
                        variety = varietySearch
                    }

                    let colors: Array<Color> = []
                    for (let colorData of varietyData.colors) {
                        let color: Color | undefined = Dog.colors.find((x) => { return x.id == colorData })
                        if (!color) {
                            console.error("Breed data: Color not found")
                            throw "Breed data: Color not found"
                        }
                        colors.push(color)
                    }
                    varieties.push({ variety: variety, colors: colors })
                }
                groups.push({ group: group, varieties: varieties })
            }
            breedData.push({ breed: breed, groups: groups })
        }
        Dog.breedData = breedData
    }

    public static findGroup(breed: Breed, variety: Variety | null): Group | null {
        let thisBreedData = Dog.breedData.find((u) => { return u.breed.id == breed.id })
        if (!thisBreedData) {
            // console.error("Dog: Breed not found searching for group.")
            // throw "Group not found."
            return null
        }
        let groupData = thisBreedData.groups.find((x) => { return x.varieties.find((y) => { return (y.variety ? y.variety.id : null) == (variety !== null ? variety.id : null) }) })
        // if (!groupData) {
        //     console.error("Dog: Group not found")
        //     throw "Group not found."
        // }
        return (groupData ? groupData.group : null)
    }

    public static findBreed(x: number): Breed {
        let out: Breed | undefined = Dog.breeds.find((u) => { return u.id == x })
        if (!out) {
            console.error("Dog: Variety not found.")
            throw "Breed not found."
        }
        return out
    }

    public static findVariety(x: number | null): Variety | null {
        if (x) {
            let out: Variety | undefined = Dog.varieties.find((u) => { return u.id == x })
            if (!out) {
                console.error("Dog: Variety not found.")
                throw "Variety not found."
            }
            return out
        } {
            return null
        }
    }

    public static findColor(x: number): Color {
        let out: Color | undefined = Dog.colors.find((u) => { return u.id == x })
        if (!out) {
            console.error("Dog: Color not found.")
            throw "Color not found."
        }
        return out
    }

    public static getVaritiesByBreed(x: number): Array<Variety> {
        let varieties: Array<Variety> = [];
        let breedData = this.breedData.find((y) => {
            return y.breed.id == x;
        });
        if (breedData) {
            for (let group of breedData.groups) {
                for (let variety of group.varieties) {
                    if (variety.variety) {
                        varieties.push(variety.variety);
                    }
                }
            }
        }
        return varieties
    }

    public static getColorsByBreedAndVariety(x: number, y: number | null): Array<Color> {
        let colors: Array<Color> = [];
        let breedData = this.breedData.find((z) => {
            return z.breed.id == x;
        });
        if (breedData && y !== null) {
            for (let group of breedData.groups) {
                for (let variety of group.varieties) {
                    if (variety.variety && variety.variety.id == y) {
                        return variety.colors;
                    }
                }
            }
        }else if(breedData){
            return breedData.groups[0].varieties[0].colors
        }
        return colors;
    }

    public static dogFromJSON(dog: DogInterface, owners: Array<Person>, breeders: Array<Person>, addresses: Array<Address>, users?: Array<User>): Dog {
        let breed: Breed = this.breeds.find((x) => { return x.id == dog.breed }) || nullBreed
        let variety: Variety | null = this.varieties.find((x) => { return x.id == dog.variety }) || null
        let color: Color = this.colors.find((x) => { return x.id == dog.color }) || nullColor

        // return new Dog(dog.id, (user ? user : null), dog.name, dog.sire, dog.dam, breed, variety, color, dog.sex, new Date(dog.birthDate), dog.birthPlace, new RegistrationNumber())
        let regNumber: RegistrationNumber = RegistrationNumber.registrationNumberFromJSON(dog.registrationNumber)
        let dogOwners: Array<Person> = owners.filter((x) => { return dog.owners.find((y) => { return y == x.id }) })
        let dogBreeders: Array<Person> = breeders.filter((x) => { return dog.breeders.find((y) => { return y == x.id }) })
        let address: Address | undefined = addresses.find((x) => { return x.id == dog.address })
        if (!address) {
            console.error("Dog: No address found.")
            throw "Dog: No address found."
        }
        let user: User | null | undefined = null
        if (users) {
            user = users.find((x) => { return x.id == dog.user })
            if (!user) {
                console.error("Dog: User not found.")
                throw "Dog: User not found."
            }
        }
        let name = DogName.dogNameFromJSON(dog.name)
        let sire = DogName.dogNameFromJSON(dog.sire)
        let dam = DogName.dogNameFromJSON(dog.dam)
        return new Dog(dog.id, user || null, name, sire, dam, breed, variety, color, dog.sex, new Date(dog.birthDate), dog.birthPlace, regNumber, dogBreeders, dogOwners, address)
    }

    public static dogArrayFromJSON(dogs: Array<DogInterface>, owners: Array<Person>, breeders: Array<Person>, addresses: Array<Address>, users?: Array<User>): Array<Dog> {
        return dogs.map((x) => { return Dog.dogFromJSON(x, owners, breeders, addresses) })
    }

    public static dogFromAddDogInterface(x: AddDogInterface): Dog {
        let name: DogName = DogName.dogNameFromJSON(x.name)
        let sire: DogName = DogName.dogNameFromJSON(x.sire)
        let dam: DogName = DogName.dogNameFromJSON(x.dam)
        let breed: Breed = nullBreed
        if (x.breed > -1) {
            breed = Dog.findBreed(x.breed)
        }
        let variety: Variety | null = Dog.findVariety(x.variety)
        let color: Color = nullColor
        if (x.color > -1) {
            color = Dog.findColor(x.color)
        }
        let birthDate = new Date(x.birthDate)
        let registrationNumber: RegistrationNumber = RegistrationNumber.registrationNumberFromJSON(x.registrationNumber)
        let breeders: Array<Person> = Person.personArrayFromJSON(x.breeders)
        let owners: Array<Person> = Person.personArrayFromJSON(x.owners)
        let address: Address = Address.addressFromJSON(x.address)
        return new Dog(x.id, null, name, sire, dam, breed, variety, color, x.sex, birthDate, x.birthPlace, registrationNumber, breeders, owners, address)
    }

    public static copyDog(dog: Dog): Dog {
        return new Dog(dog.id, dog.user, dog.name, dog.sire, dog.dam, dog.breed, dog.variety, dog.color, dog.sex, dog.birthDate, dog.birthPlace, dog.registrationNumber, dog.breeders, dog.owners, dog.address)
    }

    private _id: number | null
    private _user: User | null
    private _name: DogName
    private _sire: DogName
    private _dam: DogName
    private _breed: Breed
    private _variety: Variety | null
    private _group: Group | null
    private _color: Color
    private _sex: Sex
    private _birthDate: Date
    private _birthPlace: string
    private _registrationNumber: RegistrationNumber
    private _breeders: Array<Person>
    private _owners: Array<Person>
    private _address: Address


    public get id(): number | null {
        return this._id
    }
    //Do not set for id
    public get user(): User | null {
        return this._user
    }
    //Do not set for user
    public get name(): DogName {
        return this._name
    }
    public set name(value: DogName) {
        this._name = value
    }
    public get sire(): DogName {
        return this._sire
    }
    public set sire(value: DogName) {
        this._sire = value
    }
    public get dam(): DogName {
        return this._dam
    }
    public set dam(value: DogName) {
        this._dam = value
    }
    public get breed(): Breed {
        return this._breed
    }
    public set breed(value: Breed) {
        this._breed = value
    }
    public get variety(): Variety | null {
        return this._variety
    }
    public set variety(value: Variety | null) {
        this._variety = value
    }

    //
    //Group
    //
    public get color(): Color {
        return this._color
    }
    public set color(value: Color) {
        this._color = value
    }
    public get sex(): Sex {
        return this._sex
    }
    public set sex(value: Sex) {
        this._sex = value
    }

    public get birthDate(): Date {
        return this._birthDate
    }
    public set birthDate(value: Date) {
        this._birthDate = value
    }
    public get birthPlace(): string {
        return this._birthPlace
    }
    public set birthPlace(value: string) {
        this._birthPlace = value
    }
    public get registrationNumber(): RegistrationNumber {
        return this._registrationNumber
    }
    public set registrationNumber(value: RegistrationNumber) {
        this._registrationNumber = value
    }
    public get owners(): Array<Person> {
        return this._owners
    }
    public set owners(value: Array<Person>) {
        this._owners = value
    }
    public get breeders(): Array<Person> {
        return this._breeders
    }
    public set breeders(value: Array<Person>) {
        this._breeders = value
    }
    public get address(): Address {
        return this._address
    }
    public set address(value: Address) {
        this._address = value
    }

    constructor()
    constructor(id: number | null, user: User | null, name: DogName, sire: DogName, dam: DogName, breed: Breed, variety: Variety | null, color: Color, sex: Sex, birthDate: Date, birthPlace: string, registrationNumber: RegistrationNumber, breeders: Array<Person>, owners: Array<Person>, address: Address)
    constructor(id?: number | null, user?: User | null, name?: DogName, sire?: DogName, dam?: DogName, breed?: Breed, variety?: Variety | null, color?: Color, sex?: Sex, birthDate?: Date, birthPlace?: string, registrationNumber?: RegistrationNumber, breeders?: Array<Person>, owners?: Array<Person>, address?: Address) {
        this._id = id || null
        this._user = user || null
        this._name = name || new DogName()
        this._sire = sire || new DogName()
        this._dam = dam || new DogName()
        this._breed = breed || nullBreed
        this._variety = variety || null
        this._color = color || nullColor
        this._sex = sex || Sex.MALE
        this._birthDate = birthDate || new Date()
        this._birthPlace = birthPlace || ""
        this._registrationNumber = registrationNumber || new RegistrationNumber()
        this._breeders = breeders || []
        this._owners = owners || []
        this._address = address || new Address()
        this._group = Dog.findGroup(this._breed, this._variety)
    }

    printName(): string {
        return this._name.name
    }
    printSire(): string {
        return this._sire.name
    }
    printDam(): string {
        return this._dam.name
    }

    getBirthDate(): Date {
        return this._birthDate
    }

    private extractTitlesAndBaseName(x: string): { baseName: string, prefixTitles: Array<DogTitle>, suffixTitles: Array<DogTitle> } {
        let words = x.trim().split(" ").filter((s) => { return s.length > 0 })
        let { baseStrings: remainingWords, titles: pTitles } = this.extractTitlesAndBaseStringsFromWords(words, true)
        remainingWords.reverse()
        let { baseStrings: finalWords, titles: sTitles } = this.extractTitlesAndBaseStringsFromWords(remainingWords, false)
        finalWords.reverse()
        let bName = finalWords.join(' ')
        return { baseName: bName, prefixTitles: pTitles, suffixTitles: sTitles }
    }
    private extractTitlesAndBaseStringsFromWords(words: Array<string>, isPrefix: boolean): { baseStrings: Array<string>, titles: Array<DogTitle> } {
        let titles: Array<DogTitle> = []
        let remainingWords: Array<string> = []
        let finishedTitles = false
        let titleValues: PrefixTitles[] | SuffixTitles[]
        if (isPrefix) {
            titleValues = Object.values(PrefixTitles)
        } else {
            titleValues = Object.values(SuffixTitles)
        }
        for (let word of words) {
            if (!finishedTitles) {
                let w = word.split(/(\d+)/)
                if (w.length > 2) {
                    finishedTitles = true
                }
                let foundTitle = false

                for (const title of titleValues) {
                    if (title == w[0].toUpperCase()) {
                        if (w.length == 1) {
                            titles.push({ title: title, order: 0 })
                        } else {
                            titles.push({ title: title, order: parseInt(w[1]) })
                        }
                        foundTitle = true
                        break
                    }
                }
                if (foundTitle) {
                    finishedTitles = false
                }
            } else {
                remainingWords.push(word)
            }
        }
        return { baseStrings: remainingWords, titles: titles }
    }

    printBirthDate(): string {
        return this._birthDate.toLocaleString('en-US', { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' })
    }
    printBreeders(): string {
        let out = ""
        for (let breeder of this._breeders) {
            out += breeder.printFullName() + ", "
        }
        out = out.slice(0, -2)
        return out
    }

    printOwners(): string {
        let out = ""
        for (let owner of this._owners) {
            out += owner.printFullName() + ", "
        }
        out = out.slice(0, -2)
        return out
    }

    printSex(): string {
        if (this._sex === Sex.MALE) {
            return "Dog"
        } else if (this._sex === Sex.FEMALE) {
            return "Bitch"
        } else {
            return ""
        }
    }

    printRegistrationNumber(): string {
        let out: string = this._registrationNumber.number
        if (this._registrationNumber.type === RegistrationTypes.FOREIGN) {
            out += " (" + this._registrationNumber.country + ")"
        }
        return out
    }


    // correctDogName(): void {
    //     this._name.correctName()
    // }

    // correctSireName(): void {
    //     this._sire.correctName()
    // }

    // correctDamName(): void {
    //     this._dam.correctName()
    // }

    public clone(): Dog {
        let name = this._name.clone()
        let sire = this._sire.clone()
        let dam = this._dam.clone()
        let breed: Breed = Object.assign({}, this._breed)
        let variety: Variety = Object.assign({}, this.variety)
        let color: Color = Object.assign({}, this._color)
        let birthDate: Date = new Date(this._birthDate.getTime())
        let registrationNumber: RegistrationNumber = this._registrationNumber.clone()
        let breeders = Person.clonePersonArray(this._breeders)
        let owners = Person.clonePersonArray(this._owners)
        let address = this._address.clone()
        //Copy user because secretary may need to make changes to dog
        return new Dog(this._id, this._user, name, sire, dam, breed, variety, color, this._sex, birthDate, this._birthPlace, registrationNumber, breeders, owners, address)
    }

    // validateDog(): Array<string> {
    //     let errors = []
    //     if (!this._breed) {
    //         errors.push("Dog does not have a breed.")
    //     }
    //     if (!this._variety) {
    //         errors.push("Dog does not have a variety")
    //     }
    //     if (!this._color) {
    //         errors.push("Dog does not have a color")
    //     }
    //     if (this._name.trim().length == 0) {
    //         errors.push("Dog does not have a name")
    //     }
    //     if (this.sire.trim().length == 0) {
    //         errors.push("Dog does not have a sire")
    //     }
    //     if (this._dam.trim().length == 0) {
    //         errors.push("Dog does not have a dam")
    //     }
    //     if (this._breeders.length == 0) {
    //         errors.push("Dog has no breeders")
    //     }
    //     if (this._owners.length == 0) {
    //         errors.push("Dog has no owners")
    //     }
    //     return errors
    // }

    // exportInterface():DogInterface{
    //     let out:DogInterface =
    //     {
    //         id: this._id,
    //         name: this._name,
    //         sire: this._sire,
    //         dam: this._dam,
    //         breed: this._breed,
    //         variety: this._variety


    //     }
    // }

    // getTitles():Array<DogTitles>{

    // }

    exportAddDogInterface(): AddDogInterface {
        let variety: number | null = null
        if (this._variety) {
            variety = this._variety.id
        }
        let out: AddDogInterface = {
            id: this._id,
            name: this._name.exportInterface(),
            sire: this._sire.exportInterface(),
            dam: this._dam.exportInterface(),
            breed: this._breed.id,
            variety: variety,
            color: this._color.id,
            sex: this._sex,
            birthDate: this._birthDate.toString(),
            birthPlace: this._birthPlace,
            registrationNumber: this._registrationNumber,
            owners: this._owners.map((x) => { return x.exportInterface() }),
            breeders: this._breeders.map((x) => { return x.exportInterface() }),
            address: this._address.exportInterface()
        }
        return out
    }


}



export interface BreedDataInterface {
    breed: number
    groups: Array<{ group: number | null, varieties: Array<{ variety: number | null, colors: Array<number> }> }>
}



export interface DogInterface {
    id: number | null
    user?: number
    name: DogNameInterface
    sire: DogNameInterface
    dam: DogNameInterface
    breed: number
    variety: number | null
    color: number
    sex: Sex
    birthDate: string
    birthPlace: string
    registrationNumber: RegistrationNumberInterface
    breeders: Array<number>
    owners: Array<number>
    address: number
}

export interface AddDogInterface {
    id: number | null
    user?: number
    name: DogNameInterface
    sire: DogNameInterface
    dam: DogNameInterface
    breed: number
    variety: number | null
    color: number
    sex: Sex
    birthDate: string
    birthPlace: string
    registrationNumber: RegistrationNumberInterface
    breeders: Array<PersonInterface>
    owners: Array<PersonInterface>
    address: AddressInterface
}

export interface SecretaryDogInterface {
    id: number
    user: number
    name: DogNameInterface
    sire: DogNameInterface
    dam: DogNameInterface
    breed: number
    variety: number | null
    color: number
    sex: Sex
    birthDate: string
    birthPlace: string
    registrationNumber: RegistrationNumberInterface
    breeders: Array<string>
    owners: Array<string>
    address: AddressInterface
}






// export interface ShowDogEditInterface {
//     showDog: number
//     name?: DogNameInterface
//     sire?: DogNameInterface
//     dam?: DogNameInterface
//     breed?: number
//     variety?: number
//     color?: number
//     sex?: Sex
//     birthDate?: string
//     birthPlace?: string
//     registrationNumber?: RegistrationNumberInterface
//     breeders?: Array<number>
//     owners?: Array<number>
//     address?: number
// }

// namespace dogPost {

//     export interface AddDog {
//         dog: Dog
//     }

//     export interface AddDogResponse {
//         success: boolean,
//         dogId: number
//     }

//     interface EditDog {
//         dog: Dog
//     }

//     interface EditDogResponse {
//         success: boolean
//     }

//     interface EnterDog {
//         showEntry: Array<Array<number>>
//     }

//     interface EnterDogResponse {
//         succes: boolean
//     }

//     interface Payment {
//         showEntries: Array<Array<number>>
//         catalogs: Array<number>
//     }

//     interface PaymentResponse {
//         success: boolean

//     }

//     interface SecretaryPayment {
//         type: PaymentType
//     }

//     interface SecretaryPaymentResponse {
//         success: boolean
//     }

//     interface Refund {
//         refundShowEntries: Array<number>
//         refundCatalogs: Array<number>
//     }

//     export interface RefundResponse {
//         sucess: boolean
//         stuff?: string
//     }

// }

