
import { GeneralErrors, PaymentErrors } from "@app/common";
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class CreditCardError extends Vue {
  @Prop() readonly errors!: Array<PaymentErrors | GeneralErrors>;

  get text(): string {
    return "";
  }

  errorText(err: PaymentErrors | GeneralErrors): string {
    if (err === PaymentErrors.ADDRESS_VERIFICATION_FAILURE) {
      return "THe card issuer declined this payment because the postal code is invalid.";
    }
    if (err === PaymentErrors.BAD_EXPIRATION) {
      return "The expiration date is invalid or missing.";
    }
    if (err === PaymentErrors.PAYMENT_EMAIL_ERROR) {
      return "Invalid email address. Please contact support@dogalog.dog about this error.";
    }
    if (err === PaymentErrors.CARD_EXPIRED) {
      return "This card has expired.";
    }
    if (err === PaymentErrors.INVALID_INFO) {
      return "Some of the card information is invalid.";
    }
    if (err === PaymentErrors.CVV_FAILURE) {
      return "The card issuer declined this payment because of an invalid CVV code.";
    }
    if (err === PaymentErrors.GENERIC_DECLINE) {
      return "The card issuer declined this payment without providing us any additional information. You can contact the card issuer for additional information.";
    }
    if (err === PaymentErrors.TRANSACTION_LIMIT){
      return "The card issuer declined this payment because it was either too high or too low. "
    }

    if (err === PaymentErrors.GENERAL_PAYMENT_ERROR) {
      return "The payment has failed. Please check the card information is correct and try again. If the problem persists, then contact support@dogalog.dog.";
    }

    if (err === PaymentErrors.AUTHORIZATION_ERROR) {
      return "Please contact support@dogalog.dog about this error.";
    }
    if (err === PaymentErrors.SQUARE_TOKEN_ERROR) {
      return "The payment failed because credit cards currently cannot be used on this show. Please contact the show secretary if you believe this is in error.";
    }

    if (err === PaymentErrors.RECORDING_ERROR) {
      return "Your payment was recieved however there was an error while attempting to record it in our database. Please contact support@dogalog.dog about this issue. After you contact us, we will either cancel the payment or notify the secretary of the payment.";
    }
    if (err === PaymentErrors.RECEIPT_ERROR) {
      return "Your payment was recieved and recorded. The payment will appear in your dogalog account. However, there was an issue generating the receipt. Please contact support@dogalog.dog to notify us of this issue.";
    }
    if (err === GeneralErrors.AUTHENTICATION_ERROR){
      return "The payment failed because you're not currently logged in. If this problem persists, then please contact support@dogalog.dog"
    }
    if (err === GeneralErrors.GENERAL_ERROR){
      return "They payment failed for an unkown reason. Please report this issue to support@dogalog.dog."
    }
    return "";
  }
}
