
import { DogEvent, SecretaryShowInterfaceRequest } from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import SecretaryStore from "../../store/modules/secretary";
@Component
export default class CatalogOrdering extends Vue {
  @Prop({
    default: () => {
      return [[]];
    },
  })
  readonly value!: Array<Array<DogEvent>>;
  async mounted() {
    let payload: SecretaryShowInterfaceRequest = {
      abbr: this.$route.params.abbr,
    };
    await SecretaryStore.fetchShow(payload);
    this.eventBlocks = this.value;
  }

  get events(): Array<DogEvent> {
    if (SecretaryStore.selectedShow) {
      return SecretaryStore.selectedShow.events;
    }
    return [];
  }

  get availableEvents(): Array<DogEvent> {
    let out: Array<DogEvent> = [];
    for (let event of this.events) {
      if (
        !this.eventBlocks.find((x) => {
          return x.find((y) => {
            return y.id == event.id;
          });
        })
      ) {
        out.push(event);
      }
    }
    return out;
  }

  eventBlocks: Array<Array<DogEvent>> = [[]];
  selectedBlock: number = 0;

  addToEventBlock(idx: number) {
    this.eventBlocks[this.selectedBlock].push(this.availableEvents[idx]);
    this.update();
  }

  removeFromBlock(idx: number, jdx: number) {
    this.eventBlocks[jdx].splice(idx, 1);
    if (this.eventBlocks[jdx].length == 0 && this.eventBlocks.length > 1) {
      this.eventBlocks.splice(jdx, 1);
    }
    this.update();
  }

  addNewBlock() {
    this.eventBlocks.push([]);
    this.selectedBlock = this.eventBlocks.length - 1;
    this.update();
  }

  deleteBlock(idx: number) {
    this.eventBlocks.splice(idx, 1);
    if (this.selectedBlock >= idx) {
      this.selectedBlock = idx - 1;
    }
    this.update();
    //setTimeout(()=>{this.selectedBlock = 0},10)
  }

  get canAddNewBlock(): boolean {
    for (let eventBlock of this.eventBlocks) {
      if (eventBlock.length == 0) {
        return false;
      }
    }
    return true;
  }

  get selectedStyle(): { "background-color": string; color: string } {
    return {
      "background-color": this.$vuetify.theme.themes.light.primary as string,
      color: "white",
    };
  }

  @Watch("value")
  onValueChange(x: Array<Array<DogEvent>>) {
    this.eventBlocks = x;
  }

  update() {
    this.$emit("value", this.eventBlocks);
  }
}
