
import { Component, Vue, Watch } from "vue-property-decorator";
import Loading from "../Loading.vue";
import DogClasses from "../DogClasses.vue";
import NewShowStore from "../../store/modules/newshow";
import { DogClass } from "@app/common/classes";
import {
  Color,
  Dog,
  DogEvent,
  DogEventInterface,
  DogEventType,
  EventClass,
  Judge,
  Variety,
} from "@app/common";
import fuzzySearch from "../../services/fuzzySearch";
import DogCustomClass from "../DogCustomClass.vue";
import DogSplit from "../DogSplit.vue";
import DogClassEdit from "../DogClassEdit.vue";
@Component({
  components: { Loading, DogClasses, DogCustomClass, DogSplit, DogClassEdit },
})
export default class NewShowClasses extends Vue {
  get loading(): boolean {
    return NewShowStore.fetchingDogClasses;
  }

  get dogClasses(): Array<DogClass> {
    return NewShowStore.fetchedClasses;
  }

  async beforeMount() {
    if (NewShowStore.loadFromLocalStorage) {
      console.log(NewShowStore.eventClasses);
      this.eventClasses = NewShowStore.eventClasses;
    } else {
      this.eventClasses = [];
      for (let dogEvent of this.events) {
        this.eventClasses.push([]);
      }
    }
    await NewShowStore.fetchClasses();
  }

  selectedEventIdx: number = 0;

  get selectedEvent(): DogEvent {
    return this.events[this.selectedEventIdx];
  }

  get events(): Array<DogEvent> {
    return NewShowStore.show.events.map((x) => {
      return new DogEvent(
        x.id,
        new Date(x.startDate),
        new Date(x.endDate),
        x.AKCNumber,
        x.type
      );
    });
  }

  get selectedStyle(): { "background-color": string; color: string } {
    return {
      "background-color": this.$vuetify.theme.themes.light.primary as string,
      color: "white",
    };
  }

  selectEvent(idx: number) {
    this.selectedEventIdx = idx;
  }

  @Watch("events")
  onEventsChange(x: Array<DogEvent>) {
    this.eventClasses = [];
    for (let event of x) {
      this.eventClasses.push([]);
    }
  }

  eventClasses: Array<Array<EventClass>> = [];

  get selectedEventClasses(): Array<EventClass> {
    return this.eventClasses[this.selectedEventIdx];
  }

  // search : string = ""
  // get filteredEventClasses(): Array<EventClass>{
  //   if(this.search.trim().length > 2){
  //     let fuzzy = new fuzzySearch((x : EventClass) => {return x.dogClass.name},[])
  //     fuzzy.input = this.selectedEventClasses
  //   }
  //   return this.selectedEventClasses
  // }

  get selectedDogClasses(): Array<DogClass> {
    return this.selectedEventClasses.map((x) => {
      return x.dogClass;
    });
  }

  addClassesModal: boolean = false;
  classesToAdd: Array<DogClass> = [];

  addClasses(x: Array<DogClass>) {
    this.classesToAdd = x;
  }

  updateClasses() {
    for (let dogClass of this.classesToAdd) {
      let newEventClass = new EventClass(
        -1,
        this.selectedEvent,
        dogClass,
        0,
        new Judge()
      );
      this.eventClasses[this.selectedEventIdx].push(newEventClass);
    }
    console.log("Asdf");
    console.log(this.eventClasses);
    this.sortEventClasses();
    console.log(this.eventClasses);

    this.classesToAdd = [];
    this.addClassesModal = false;
  }

  sortEventClasses() {
    this.$set(
      this.eventClasses,
      this.selectedEventIdx,
      this.eventClasses[this.selectedEventIdx].sort((x, y) => {
        return x.dogClass.type > y.dogClass.type
          ? 1
          : x.dogClass.type < y.dogClass.type
          ? -1
          : x.dogClass.name.toLowerCase() > y.dogClass.name.toLowerCase()
          ? 1
          : -1;
      })
    );
  }

  closeAddClassesModal() {
    this.addClassesModal = false;
    this.classesToAdd = [];
  }

  removeClass(idx: number) {
    this.eventClasses[this.selectedEventIdx].splice(idx, 1);
  }

  customClassModal: boolean = false;
  customClass: DogClass | null = null;
  newCustomClass: DogClass | null = null;

  openCustomClassModal() {
    this.customClassModal = true;
    this.$nextTick(() => {
      (this.$refs.customClass as any).reset();
    });
  }

  closeCustomClassModal() {
    this.customClassModal = false;
    this.newCustomClass = null;
    this.customClass = null;
    (this.$refs.customClass as any).reset();
  }

  setCustomClass(x: DogClass | null) {
    this.newCustomClass = x;
  }

  addCustomClass() {
    if (this.newCustomClass) {
      let newEventClass: EventClass = new EventClass(
        -1,
        this.selectedEvent,
        this.newCustomClass,
        0,
        new Judge()
      );
      this.eventClasses[this.selectedEventIdx].push(newEventClass);
      this.sortEventClasses();
      this.customClassModal = false;
      this.newCustomClass = null;
      this.customClass = null;
    }
  }

  setSplitClasses() {
    let newEventClasses: Array<EventClass> = this.splitClasses.map((x) => {
      return new EventClass(-1, this.selectedEvent, x, 0, new Judge());
    });
    this.eventClasses[this.selectedEventIdx].splice(this.classToSplitIdx, 1);
    this.eventClasses[this.selectedEventIdx] =
      this.selectedEventClasses.concat(newEventClasses);
    this.sortEventClasses();
    this.splitClasses = [];
    this.splitModal = false;
  }

  updateSplitClasses(x: Array<DogClass>) {
    this.splitClasses = x;
  }

  classToSplit: DogClass | null = null;
  classToSplitIdx: number = -1;
  splitModal: boolean = false;
  splitClasses: Array<DogClass> = [];
  setClassToSplit(x: EventClass, idx: number) {
    this.classToSplit = x.dogClass;
    this.splitModal = true;
    this.classToSplitIdx = idx;
  }

  editModal: boolean = false;
  classToEdit: DogClass | null = null;
  classToEdtiIdx: number = -1;
  editedClass: DogClass | null = null;
  setClassToEdit(x: EventClass, idx: number) {
    console.log("set class to edit");
    this.classToEdit = x.dogClass;
    this.classToEdtiIdx = idx;
    this.editModal = true;
  }

  updateEditClass(x: DogClass) {
    this.editedClass = x;
  }

  editClass() {
    if (this.editedClass) {
      let newEventClass = new EventClass(
        -1,
        this.selectedEvent,
        this.editedClass,
        0,
        new Judge()
      );
      this.$set(
        this.eventClasses[this.selectedEventIdx],
        this.classToSplitIdx,
        newEventClass
      );
      this.editModal = false;
    }
  }

  copyClasses(idx: number) {
    //this.eventClasses[this.selectedEventIdx] = []
    let newEventClasses: Array<EventClass> = [];
    for (let eventClass of this.eventClasses[idx]) {
      let newEventClass: EventClass = new EventClass(
        -1,
        this.selectedEvent,
        eventClass.dogClass,
        0,
        new Judge()
      );
      newEventClasses.push(newEventClass);
    }
    this.$set(this.eventClasses, this.selectedEventIdx, newEventClasses);
  }

  @Watch("eventClasses")
  onEventClassesChange() {
    NewShowStore.setEventClasses(this.eventClasses);
  }

  validate(): Array<string> {
    let out: Array<string> = [];
    for (let eventClasses of this.eventClasses) {
      if (eventClasses.length == 0) {
        out.push("One or more events have no classes.");
        break;
      }
    }
    return out;
  }
}
