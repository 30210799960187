import { ReceiptRequest, SecretaryReceiptRequest } from "@app/common";

export async function fetchReceipt(payload : ReceiptRequest){
        const response = await fetch(
            process.env.VUE_APP_DOG_API + "/transactions/receipt",
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(payload),
                credentials: "include",
            }
        );
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        document.body.appendChild(link);
        link.setAttribute("download", "receipt.pdf");
        link.click();
}

export async function secretaryFetchReceipt(payload : SecretaryReceiptRequest){
    const response = await fetch(
        process.env.VUE_APP_DOG_API + "/secretary/transactions/fetch-receipt",
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "include",
        }
    );
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.setAttribute("download", "receipt.pdf");
    link.click();
}