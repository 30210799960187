
import { ResetPasswordRequest, User, VerifyResetTokenRequest } from "@app/common";
import { Component, Prop, Vue } from "vue-property-decorator";
import Loading from "../components/Loading.vue";
import UserStore from "../store/modules/user";
import sha512 from "../services/hash"

@Component
export default class ResetPassword extends Vue {
  step: number = 0;
  email: string = "";
  token: string | null = null;
  showNew: boolean = false;
  showConfirm: boolean = false;
  newPassword: string = "";
  newPasswordConfirm: string = "";
  loading: boolean = true;
  tokenVerified: boolean = false;

  async beforeMount() {
    this.token = (this.$route.query.token as string | undefined) || null;
    this.tokenVerified = false;
    if (this.token !== null) {
      let payload: VerifyResetTokenRequest = {
        token: this.token,
      };
      await UserStore.verifyResetToken(payload);
      if (!UserStore.verifyResetTokenErrors) {
        this.email = UserStore.resetEmail;
        this.tokenVerified = true;
      }
    }
  }

  get disabled() {
    return (
      this.newPassword === "" || this.newPassword !== this.newPasswordConfirm
    );
  }

  get submitting(): boolean {
    return UserStore.resettingPassword;
  }

  get rules() {
    return {
      passwordLength: (x: string) => {
        return !x || x.length < 6
          ? "Password must be at least 6 characters long"
          : true;
      },
      passwordMatch: (x: string) => {
        return !x || x !== this.newPassword ? "Passwords must match" : true;
      },
    };
  }

  async reset() {
    if (this.token) {
      let passwordHash : string = await sha512(this.newPassword+this.email.toLowerCase().trim())
      let payload: ResetPasswordRequest = {
        token: this.token,
        password: passwordHash,
      };
      await UserStore.resetPassword(payload)
      if(!UserStore.resetPasswordErrors){
        this.step = 1
      }else{
        this.step = 2
      }
    }
  }

  key(x : KeyboardEvent){
    if(this.step == 0 && this.tokenVerified && !this.disabled && x.key === 'Enter'){
      this.reset()
    }
  }
}
