import { Address, AddressType } from "@app/common";

const SmartyStreetsSDK = require("smartystreets-javascript-sdk");

let SmartyStreetsCore = SmartyStreetsSDK.core;
let Lookup = SmartyStreetsSDK.usAutocompletePro.Lookup;
let key = process.env.VUE_APP_SMARTY_KEY
let hostname = "";
let credentials = new SmartyStreetsCore.SharedCredentials(key);
let clientBuilder = new SmartyStreetsCore.ClientBuilder(credentials).withLicenses(["us-autocomplete-pro-cloud"]);
let client = clientBuilder.buildUsAutocompleteProClient();

const smartSearch = {
    search: async function (address : string) : Promise<Array<Address>>{
        let newLookup = new Lookup(address)
        newLookup.maxResults = 4
        try {
            let response = await client.send(newLookup)
            return response.map((x : any) => {return new Address(null, x.streetLine, x.secondary, x.city, x.state, x.zipcode, "United States", AddressType.USSMART)})
        } catch (err) {
            console.error(err)
        }
        return []
    },
    searchWithZIPCodes: async function (address : string, zip : string, selected?: string): Promise<Array<{address: Address, entries: number}>> {
        console.log(address)
        let newLookup = new Lookup(address)
        newLookup.includeOnlyZIPCodes = [zip]
        newLookup.maxResults = 4
        if(selected){
            newLookup.selected = selected
        }
        try {
            let response = await client.send(newLookup)
            console.log("response")
            console.log(response)
            
            return response.result.map((x : any) => {
                return { address: new Address(null, x.streetLine, x.secondary, x.city, x.state, x.zipcode, "United States", AddressType.USSMART), entries: x.entries}
            })

        } catch (err) {
            console.error(err)
        }
        return []
    }
}

export default smartSearch