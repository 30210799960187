import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
  } from "vuex-module-decorators";
  import store from "..";
  import { fetchErrorMessage, generalErrorMessage } from "../error";
  import { DogClass } from "@app/common/classes";
  import UserStore from "./user";
  import { Address, Dog, Entry, EventClass, GeneralErrors, Judge, Payment, Refund, SecretaryEditDogRequest, SecretaryEditDogResponse, SecretaryEditEntryRequest, SecretaryEditEntryResponse, SecretaryEnterDogRequest, SecretaryEnterDogResponse, SecretaryEntryDataRequest, SecretaryEntryDataResponse, SecretaryErrors, SecretaryFetchClassesResponse, SecretaryPaymentDataRequest, SecretaryPaymentDataResponse, SecretaryShowDataRequest, SecretaryShowDataResponse, SecretaryShowInterfaceRequest, SecretaryShowInterfaceResponse, SecretaryShowsResponse, Show, ShowDog, ShowEntry, ShowEntryInterface, User } from "@app/common";
  import { Ref } from "vue-property-decorator";
  
  @Module({ name: "SecretaryEntryStore", dynamic: true, store, namespaced: true })
  class SecretaryEntryStore extends VuexModule {
    private _selectedShowDog : ShowDog | undefined = undefined
    get selectedShowDog() : ShowDog | undefined{
        return this._selectedShowDog
    }
    @Mutation
    setSelectedShowDog(x : ShowDog | undefined){
        this._selectedShowDog = x
    }

    private _editingDog : boolean = false
    private _editDogError : GeneralErrors | SecretaryErrors | undefined = undefined
    get editingDog():boolean{
      return this._editingDog
    }
    @Mutation
    setEditingDog(x : boolean){
      this._editingDog = x
    }
    get editDogError() : GeneralErrors | SecretaryErrors | undefined{
      return this._editDogError
    }
    @Mutation
    setEditDogError(x : GeneralErrors | SecretaryErrors | undefined){
      this._editDogError = x
    }

    @Action
    async editDog(payload: SecretaryEditDogRequest) {
        this.setEditingDog(true);
        const response = await fetch(process.env.VUE_APP_DOG_API + '/secretary/entries/edit-dog', {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "include",
        });
        this.setEditingDog(false);
        if (!response.ok) {
            this.context.rootState.errorModal = true;
            this.context.rootState.errorMsg = fetchErrorMessage;
            this.context.rootState.errorCode =
                response.status.toString() + " " + response.statusText;
        } else {
            const data: SecretaryEditDogResponse = await response.json();
            if (data.success) {
                this.setEditDogError(undefined)
            } else {
                this.context.rootState.errorMsg = generalErrorMessage;
                this.context.rootState.errorModal = true;
                this.context.rootState.errorCode = data.errors[0];
                this.setEditDogError(data.errors[0]);
            }
            UserStore.authCheck(data);
        }
    }






    private _enteringDog : boolean = false
    private _enterDogError : GeneralErrors | SecretaryErrors | undefined = undefined
    get enteringDog():boolean{
      return this._enteringDog
    }
    @Mutation
    setEnteringDog(x : boolean){
      this._enteringDog = x
    }
    get enterDogError() : GeneralErrors | SecretaryErrors | undefined{
      return this._enterDogError
    }
    @Mutation
    setEnterDogError(x : GeneralErrors | SecretaryErrors | undefined){
      this._enterDogError = x
    }

    @Action
    async enterDog(payload: SecretaryEnterDogRequest) {
        this.setEnteringDog(true);
        const response = await fetch(process.env.VUE_APP_DOG_API + '/secretary/entries/enter-dog', {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "include",
        });
        this.setEnteringDog(false);
        if (!response.ok) {
            this.context.rootState.errorModal = true;
            this.context.rootState.errorMsg = fetchErrorMessage;
            this.context.rootState.errorCode =
                response.status.toString() + " " + response.statusText;
        } else {
            const data: SecretaryEnterDogResponse = await response.json();
            if (data.success) {
                this.setEnterDogError(undefined)
            } else {
                this.context.rootState.errorMsg = generalErrorMessage;
                this.context.rootState.errorModal = true;
                this.context.rootState.errorCode = data.errors[0];
                this.setEnterDogError(data.errors[0]);
            }
            UserStore.authCheck(data);
        }
    }



    private _selectedShowEntry : ShowEntry | undefined = undefined
    private _editingEntry : boolean = false
    private _editEntryError : GeneralErrors | SecretaryErrors | undefined = undefined
    
    get selectedShowEntry(): ShowEntry | undefined{
      return this._selectedShowEntry
    }
    @Mutation
    setSelectedShowEntry(x : ShowEntry | undefined){
      this._selectedShowEntry = x
    }
    get editingEntry():boolean{
      return this._editingEntry
    }
    @Mutation
    setEditingEntry(x : boolean){
      this._editingEntry = x
    }
    get editEntryError(): GeneralErrors | SecretaryErrors | undefined{
      return this._editEntryError
    }
    @Mutation
    setEditEntryError(x : GeneralErrors | SecretaryErrors | undefined){
      this._editEntryError = x
    }


    @Action
    async editEntry(payload: SecretaryEditEntryRequest) {
        this.setEditingEntry(true);
        const response = await fetch(process.env.VUE_APP_DOG_API + '/secretary/entries/edit-entry', {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "include",
        });
        this.setEditingEntry(false);
        if (!response.ok) {
            this.context.rootState.errorModal = true;
            this.context.rootState.errorMsg = fetchErrorMessage;
            this.context.rootState.errorCode =
                response.status.toString() + " " + response.statusText;
        } else {
            const data: SecretaryEditEntryResponse = await response.json();
            if (data.success) {
                this.setEditEntryError(undefined)
            } else {
                this.context.rootState.errorMsg = generalErrorMessage;
                this.context.rootState.errorModal = true;
                this.context.rootState.errorCode = data.errors[0];
                this.setEditEntryError(data.errors[0]);
            }
            UserStore.authCheck(data);
        }
    }



    //'/secretary/entries/edit-dog'
    
  }
  
  export default getModule(SecretaryEntryStore);
  