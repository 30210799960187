
import {
  DogEvent,
  EventClass,
  SecretaryShowDataRequest,
  SecretaryShowInterfaceRequest,
} from "@app/common";
import { DogClass } from "@app/common/classes";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import SecretaryStore from "../../store/modules/secretary";
import NewShowClasses from "../NewShow/NewShowClasses.vue";
const fuzz = require("fuzzball");

@Component
export default class CatalogClassOrdering extends Vue {
  @Prop({
    default: () => {
      return [];
    },
  })
  readonly value!: Array<{
    events: Array<DogEvent>;
    classOrderings: Array<DogClass>;
  }>;
  @Prop({
    default: () => {
      return [[]];
    },
  })
  readonly eventBlocks!: Array<Array<DogEvent>>;

  classOrderings: Array<Array<number>> = [];
  selectedBlock: number = 0;

  sortModal: boolean = false;
  textModal: boolean = false;

  async mounted() {
    let payload: SecretaryShowDataRequest = { show: 2 };
    await SecretaryStore.fetchShowData(payload);
  }

  get eventClasses(): Array<EventClass> {
    return SecretaryStore.eventClasses;
  }

  get eventBlockEventClasses(): Array<Array<EventClass>> {
    let out: Array<Array<EventClass>> = [];
    for (let eventBlock of this.eventBlocks) {
      out.push(
        this.eventClasses.filter((x) => {
          return eventBlock.find((y) => {
            return y.id == x.event.id;
          });
        })
      );
    }
    return out;
  }

  get eventBlockDogClasses(): Array<Array<DogClass>> {
    let out: Array<Array<DogClass>> = [];
    for (let eventBlockEventClasses of this.eventBlockEventClasses) {
      let dogClasses: Array<DogClass> = [];
      for (let eventClass of eventBlockEventClasses) {
        if (
          !dogClasses.find((x) => {
            return x.id == eventClass.dogClass.id;
          })
        ) {
          dogClasses.push(eventClass.dogClass);
        }
      }
      out.push(dogClasses);
    }
    return out;
  }

  get eventBlockDogClassesSorted(): Array<Array<DogClass>> {
    let out: Array<Array<DogClass>> = [];
    for (let eventBlockDogClasses of this.eventBlockDogClasses) {
      out.push(DogClass.sortClasses(eventBlockDogClasses));
    }
    return out;
  }

  get dogClasses(): Array<DogClass> {
    let out: Array<DogClass> = [];
    for (let eventClass of this.eventClasses) {
      if (
        !out.find((x) => {
          return x.id == eventClass.dogClass.id;
        })
      ) {
        out.push(eventClass.dogClass);
      }
    }
    return out;
  }

  get selectedStyle(): { "background-color": string; color: string } {
    return {
      "background-color": this.$vuetify.theme.themes.light.primary as string,
      color: "white",
    };
  }

  @Watch("eventBlockDogClasses")
  onEventBlockDogClassesChange(x: Array<Array<DogClass>>) {
    this.classOrderings = [];
    for (let dogClasses of x) {
      let classOrdering: Array<number> = [];
      for (let k = 0; k < dogClasses.length; k++) {
        classOrdering.push(k);
      }
      this.classOrderings.push(classOrdering);
    }
  }

  get selectedClasses(): Array<DogClass> {
    let out: Array<DogClass> = [];
    if (this.classOrderings.length > 0) {
      for (let k = 0; k < this.classOrderings[this.selectedBlock].length; k++) {
        out.push(
          this.eventBlockDogClassesSorted[this.selectedBlock][
            this.classOrderings[this.selectedBlock][k]
          ]
        );
      }
    }
    return out;
  }

  moveUp(idx: number) {
    if (idx > 0) {
      let selectedClassOrdering: Array<number> =
        this.classOrderings[this.selectedBlock];
      let n: number = selectedClassOrdering[idx - 1];
      let m: number = selectedClassOrdering[idx];
      this.$set(this.classOrderings[this.selectedBlock], idx, n);
      this.$set(this.classOrderings[this.selectedBlock], idx - 1, m);
    }
  }

  moveDown(idx: number) {
    if (idx < this.classOrderings[this.selectedBlock].length - 1) {
      let selectedClassOrdering: Array<number> =
        this.classOrderings[this.selectedBlock];
      let n: number = selectedClassOrdering[idx + 1];
      let m: number = selectedClassOrdering[idx];
      this.$set(this.classOrderings[this.selectedBlock], idx, n);
      this.$set(this.classOrderings[this.selectedBlock], idx + 1, m);
    }
  }

  movingRow: boolean = false;
  moveRow: number = -1;

  moveRowSelect(idx: number) {
    if (!this.movingRow) {
      this.movingRow = true;
      this.moveRow = idx;
    } else {
      this.movingRow = false;
      this.moveRow = -1;
    }
  }

  getClass(eventBlock: number, classIdx: number): DogClass {
    return this.eventBlockDogClasses[eventBlock][classIdx];
  }

  copyText: string = "";
  textClasses: Array<string> = [];
  sortedDogClasses: Array<DogClass> = [];
  remainingDogClasses: Array<DogClass> = [];
  remainingTextClasses: Array<string> = [];
  textWindow: number = 0;

  nextText() {
    if (this.textWindow == 0) {
      this.extractClasses();
      this.textWindow++;
    } else if (this.textWindow == 1) {
      console.log("sorted");
      let newClassOrdering = [];
      for (let dogClass of this.sortedDogClasses) {
        let idx = this.eventBlockDogClassesSorted[this.selectedBlock].findIndex(
          (x) => {
            return x.id == dogClass.id;
          }
        );
        newClassOrdering.push(idx);
      }
      this.$set(this.classOrderings, this.selectedBlock, newClassOrdering);
      this.textModal = false;
    }
  }
  extractClasses(): Array<DogClass> {
    let out: Array<DogClass> = [];
    let textClasses: Array<string> = this.copyText.split(/\r?\n/);
    textClasses = textClasses
      .map((x) => {
        return x.trim();
      })
      .filter((x) => {
        return x.length > 0;
      });
    this.textClasses = textClasses;
    this.remainingTextClasses = [];
    this.sortedDogClasses = [];
    let remainingClasses: Array<DogClass> = this.selectedClasses.map((x) => {
      return x.clone();
    });
    for (let textClass of textClasses) {
      if (remainingClasses.length > 0) {
        let remainingClassesNames: Array<string> = remainingClasses.map((x) => {
          return x.name;
        });
        let options = { scorer: fuzz.token_set_ratio };
        let output = fuzz.extract(textClass, remainingClassesNames, options);
        let idx: number = output[0][2];
        this.sortedDogClasses.push(remainingClasses[idx]);
        remainingClasses.splice(idx, 1);
      } else {
        this.remainingTextClasses.push(textClass);
      }
    }
    this.remainingDogClasses = remainingClasses;
    return out;
  }

  @Watch("value")
  onValueChange(
    x: Array<{
      events: Array<DogEvent>;
      classOrderings: Array<DogClass>;
    }>
  ) {
    let newClassOrderings: Array<Array<number>> = [];
    for (let k = 0; k < x.length; k++) {
      let newClassOrdering: Array<number> = [];
      for (let j = 0; j < x[k].classOrderings.length; j++) {
        let idx = this.eventBlockDogClassesSorted[k].findIndex((y) => {
          return y.id == x[k].classOrderings[j].id;
        });
        newClassOrdering.push(idx);
      }
      newClassOrderings.push(newClassOrdering);
    }
    this.classOrderings = newClassOrderings;
  }

  update() {
    let out: Array<{
      events: Array<DogEvent>;
      classOrderings: Array<DogClass>;
    }> = [];
    for (let k = 0; k < this.eventBlocks.length; k++) {
      console.log(this.classOrderings);
      out.push({
        events: this.eventBlocks[k],
        classOrderings: this.classOrderings[k].map((x) => {
          return this.eventBlockDogClassesSorted[k][x];
        }),
      });
    }
    console.log("update");
    console.log(out);
    this.$emit("update", out);
  }

  @Watch("classOrderings")
  onClassOrderingsChange(x: Array<Array<number>>) {
    this.update();
  }
}
