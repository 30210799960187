var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('sticky-footer',[_c('div',{staticStyle:{"padding":"10px","background-color":"#ddd","color":"#444"}},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('div',{staticStyle:{"font-weight":"bold","font-size":"1.25em"}},[_vm._v("SHOW MANAGER")])]),_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('div',{staticStyle:{"font-weight":"bold"}},[_vm._v("Dog entries")])])],1),_c('loading',{staticStyle:{"min-height":"100vh"},attrs:{"loading":_vm.loading}},[(_vm.show)?_c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" Event summary ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"disable-pagination":"","headers":[
              { text: 'Event', value: 'eventName' },
              { text: 'Date', value: 'dates' },
              { text: 'Number of dogs', value: 'dogTotal' },
              { text: 'Number of exhibitors', value: 'exhibitorTotal' },
              { text: 'Total fees', value: 'feeTotal' },
            ],"items":_vm.dataTableSummaryItems,"item-key":"subId","hide-default-footer":""}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){_vm.entryModal = true}}},[_vm._v("View Entries")])],1)],1),_c('br'),_c('br'),_c('v-card',[_c('v-card-title',[_vm._v("Class totals")]),_c('v-card-text',[_c('v-tabs',{staticStyle:{"width":"100%"},attrs:{"fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.show.events),function(event,idx){return _c('v-tab',{key:idx},[_vm._v(" "+_vm._s(event.printStartDate())+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.show.events),function(event,jdx){return _c('v-tab-item',{key:jdx,attrs:{"value":jdx}},[_c('br'),_c('b',[_vm._v(" "+_vm._s(event.eventTypeAndNumber)+" "),_c('br'),_vm._v(" "+_vm._s(event.printDateRange()))]),_c('br'),_c('v-data-table',{attrs:{"disable-pagination":"","headers":[
                  { text: 'Class', value: 'name' },
                  { text: 'Number of dogs', value: 'total' },
                ],"items":_vm.dataTableItems(jdx),"item-key":"subId","hide-default-footer":""}})],1)}),1)],1)],1)],1):_vm._e()],1),_c('template',{slot:"bar"},[_c('div',{staticClass:"pa-2",staticStyle:{"width":"100%","text-align":"right"}},[_c('v-btn',{attrs:{"text":"","dark":""},on:{"click":function($event){return _vm.$router.push('/show-manager/'+_vm.abbr)}}},[_vm._v("Back")])],1)])],2),_c('v-dialog',{attrs:{"fullscreen":"","transition":"dialog-bottom-transition"},model:{value:(_vm.entryModal),callback:function ($$v) {_vm.entryModal=$$v},expression:"entryModal"}},[_c('show-manager-entries-panel',{on:{"close":function($event){_vm.entryModal = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }