
import { Component, Vue } from "vue-property-decorator";
import DogFooter from "./DogFooter.vue";

@Component({components: {
    DogFooter
}})
export default class StickyFooter extends Vue {
    bottomHeight =  -210 + 36
    bottomPosition = "fixed"

    onScroll() {
      let remainingHeight =
        document.documentElement.scrollHeight -
        window.scrollY -
        window.innerHeight;
      if (remainingHeight < 200 - 36) {
        this.bottomPosition = "relative";
        this.bottomHeight = 0;
      } else {
        this.bottomPosition = "fixed";
        this.bottomHeight = -200 + 36;
      }
    }
}
