
import { PREFIX_TITLES, SUFFIX_TITLES } from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class DogTitleSelect extends Vue {
  @Prop({ default: true }) readonly prefix!: boolean;
  @Prop({
    default: () => {
      return [];
    },
  })
  readonly value!: Array<{ title: string; order: number }>;
  titles: Array<{ title: string; order: number; multiple: boolean }> = [];
  filteredTitles: Array<{ title: string; order: number; multiple: boolean }> =
    [];
  selectedTitles: Array<{ title: string; order: number; multiple: boolean }> =
    [];
  search: string = "";

  beforeMount() {
    if (this.prefix) {
      this.titles = PREFIX_TITLES.map((x) => {
        return { title: x.title, order: 1, multiple: x.multiple };
      });
    } else {
      this.titles = SUFFIX_TITLES.map((x) => {
        return { title: x.title, order: 1, multiple: x.multiple };
      });
    }
  }

  mounted() {
    this.updateValue(this.value);
  }

  get label(): string {
    return this.prefix ? "Prefix titles" : "Suffix titles";
  }

  changeOrder(
    x: { title: string; order: number; multiple: boolean },
    k: number
  ) {
    x.order = Math.max(x.order + k, 1);
    this.update();
  }

  // changeOrder(
  //   x: { title: string, order: number, multiple: boolean },
  //   k: number
  // ) {
  //   let item = this.titles.find((y) => {
  //     return y.title === x.title;
  //   })
  //   if (item) {
  //     item.order = Math.max(item.order + k, 1);
  //   }
  //   this.update();
  // }

  get letters(): string {
    if (this.search !== null) {
      let letterMatch = this.search.match(/[a-zA-Z]+/g);
      if (letterMatch && letterMatch.length == 0) {
        return letterMatch[0];
      }
    }
    return "";
  }

  get searchSplit(): { letters: string, numbers: number, match: boolean } {
    let out = { letters: "", numbers: 1, match: false };
    if (this.search !== null) {
      let letterMatch = this.search.trim().match(/[a-zA-Z]+/g);
      let numberMatch = this.search.trim().match(/[0-9]+/g);
      if (letterMatch && letterMatch.length == 1) {
        if (numberMatch) {
          let letters: string = letterMatch[0];
          let numbers: number = parseInt(numberMatch[0]);
          let test: string = letterMatch[0] + numberMatch[0];
          out.match = test === this.search.trim()
          out.letters = letters.toUpperCase()
          out.numbers = numbers
        }else{
          out.match = letterMatch[0] === this.search.trim()
          out.letters = letterMatch[0].toUpperCase()
        }
      }
    }
    return out;
  }

  isSelected(
    x: { title: string; order: number; multiple: boolean },
    items: Array<{ title: string; order: number; multiple: boolean }>
  ): boolean {
    return !!items.find((y) => {
      return x.title === y.title;
    });
  }

  @Watch("titles")
  onTitlesChange() {
    console.log("titles changes");
  }

  @Watch("value")
  onValueChange(x: Array<{ title: string; order: number }>) {
    this.updateValue(x);
  }

  // @Watch("search")
  // onSearchChange(x: string | null) {
  //   if (x !== null) {
  //     let out: Array<{ title: string; order: number; multiple: boolean }> = [];
  //     let letterMatch = x.match(/[a-zA-Z]+/g);
  //     console.log(letterMatch);
  //     let letters: string = "";
  //     if (letterMatch) {
  //       letters = letterMatch[0].toUpperCase();
  //     }
  //     let numberMatch = x.match(/[0-9]+/g);
  //     let numbers: number = 1;
  //     if (numberMatch) {
  //       numbers = parseInt(numberMatch[0]);
  //     }
  //     for (let title of this.titles) {
  //       if (title.title.indexOf(letters) > -1) {
  //         out.push(title);
  //       }
  //     }
  //     if (out.length == 1 && numbers > 1 && out[0].multiple) {
  //       out[0].order = numbers;
  //     }
  //     this.filteredTitles = out;
  //   } else {
  //     this.filteredTitles = this.titles;
  //   }
  // }

  queryFunction(
    item: { title: string; order: number; multiple: boolean },
    queryText: string,
    itemText: string
  ): boolean {
    if (!item.multiple) {
      return itemText.indexOf(queryText.toUpperCase()) == 0;
    } else {
      let searchSplit = this.searchSplit
      console.log(searchSplit)
      console.log(queryText)
      if (searchSplit.match) {
        if(searchSplit.numbers == 1){
          return itemText.indexOf(searchSplit.letters) == 0
        }else if(itemText.indexOf(searchSplit.letters) == 0){
          item.order = searchSplit.numbers
          return true
        }
      }
    }
    return false
  }

  updateValue(x: Array<{ title: string; order: number }>) {
    let out: Array<{ title: string; order: number; multiple: boolean }> = [];
    for (let title of x) {
      let selectableTitle:
        | { title: string; order: number; multiple: boolean }
        | undefined = this.titles.find((y) => {
        return y.title === title.title;
      });
      if (!selectableTitle) {
        selectableTitle = { ...title, multiple: false };
      }
      out.push(selectableTitle);
    }
    this.selectedTitles = out;
  }

  handleKey(x: KeyboardEvent) {
    console.log(x);
    if (x.key === "Enter") {
      this.$nextTick(() => {
        this.search = "";
      });
    }
  }

  update() {
    let out: Array<{ title: string; order: number }> = this.selectedTitles.map(
      (x) => {
        return { title: x.title, order: x.order };
      }
    );
    this.$emit("input", out);
  }
}
