import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./types";
import DogStore from "./modules/dog";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    errorModal: false,
    errorMsg: "",
    errorCode: "",
    show: null,
    shows: [],
  },
};

export default new Vuex.Store<RootState>(store);
