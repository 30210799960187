
import { Entry, EventClass, ShowEntry } from "@app/common";
import { Component, Vue } from "vue-property-decorator";
import DogStore from "../../store/modules/dog";
import ShowStore from "../../store/modules/dog"
@Component
export default class DogFormProblemEntries extends Vue {
  get problemEntries(): Array<{
    entry: Entry;
    problemEventClasses: Array<EventClass>;
  }> {
    let problemEntries: Array<{
      entry: number;
      paid: boolean;
      problemEventClasses: Array<{
        eventClass: number;
        classEntry: number;
      }>;
    }> = DogStore.checkEditDogProblemEntries;
    let out: Array<{ entry: Entry; problemEventClasses: Array<EventClass> }> =
      [];
    for (let problem of problemEntries) {
      let showEntry = DogStore.entries.find((x) => {
        return x.entries.find((y) => {
          return y.id == problem.entry;
        });
      });
      if (!showEntry) {
        throw "DogFormProblemEntries : Show entry not found.";
      }
      let entry = showEntry.entries.find((x) => {
        return x.id == problem.entry;
      });
      if (!entry) {
        throw "DogFormProblemEntries : Entry not found.";
      }
      let problemClasses: Array<EventClass> = entry.classes.filter((x) => {
        return problem.problemEventClasses.find((y) => {
          return y.eventClass == x.id;
        });
      });
      let problemItem: {
        entry: Entry;
        problemEventClasses: Array<EventClass>;
      } = {
        entry: entry,
        problemEventClasses: problemClasses,
      };
      out.push(problemItem);
    }
    return out;
  }
  mounted() {
    setTimeout(() => {
       this.$vuetify.goTo(0)
    },300)
   
  }

  get paidEntries(): Array<Entry> {
    return this.problemEntries
      .filter((x) => {
        return x.entry.paid;
      })
      .map((y) => {
        return y.entry;
      });
  }

  gotoRefund(){
    this.$router.push('/refunds/'+this.problemEntries[0].entry.show.abbr)
  }
}
