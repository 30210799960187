
import sha512 from "@/services/hash";
import { ChangePasswordRequest, GeneralErrors } from "@app/common";
import { Component, Vue } from "vue-property-decorator";
import UserStore from "../store/modules/user";
import TopPage from "../components/TopPage.vue";

@Component({
  components: {
    TopPage,
  },
})
export default class UserProfile extends Vue {
  changePassword = false;
  currentPassword = "";
  newPassword = "";
  newPasswordConfirm = "";
  passwordSubmitReady = false;
  shake = false;
  changePasswordSuccess = false;

  openPasswordMenu() {
    this.changePasswordSuccess = false;
    UserStore.setChangePasswordError(undefined);
    this.shake = false;
    const child = this.$refs.password as any;
    child.reset();
    this.changePassword = true;
  }

  get userInfo(): { firstName: string; lastName: string; email: string } {
    return UserStore.userInfo;
  }

  get rules() {
    return {
      passwordRequired: (x: string) => {
        return !x ? "Your current password is required" : true;
      },
      newPasswordRequired: (x: string) => {
        return !x ? "A new password is required" : true;
      },
      passwordLength: (x: string) => {
        return !x || x.length < 6
          ? "Password must be at least 6 characters long"
          : true;
      },
      passwordMatch: (x: string) => {
        return !x || x !== this.newPassword ? "Passwords must match" : true;
      },
      firstNameRequired: (x: string) => {
        return !x ? "Must have a first name" : true;
      },
      lasttNameRequired: (x: string) => {
        return !x ? "Must have a last name" : true;
      },
    };
  }

  get passwordError(): GeneralErrors | "INCORRECT_PASSWORD" | undefined {
    return UserStore.changePasswordError;
  }
  get incorrectPassword(): boolean {
    return UserStore.changePasswordError === "INCORRECT_PASSWORD";
  }

  get changingPassword(): boolean {
    return UserStore.changingPassword;
  }

  toggleChangePassword() {
    this.edit = false;
    this.changePassword = true;
  }

  async submitChangePassword() {
    this.shake = false;
    let email = UserStore.userInfo.email.trim().toLowerCase();
    let oldPasswordHash = await sha512(this.currentPassword + email);
    let newPasswordHash = await sha512(this.newPassword + email);
    let payload: ChangePasswordRequest = {
      oldPassword: oldPasswordHash,
      newPassword: newPasswordHash,
      csrfSecurityToken: UserStore.csrfSecurityToken,
    };
    await UserStore.changePassword(payload);
    if (this.passwordError) {
      this.shake = true;
    }
  }

  edit = false;
  firstName = "";
  lastName = "";
  userSubmitReady = false;
  changeUserSuccess = false;

  openUserMenu() {
    this.changeUserSuccess = false;
    // UserStore.setChangePasswordError(undefined);
    const child = this.$refs.user as any;
    child.reset();
    this.$nextTick(() => {
      this.firstName = UserStore.userInfo.firstName;
      this.lastName = UserStore.userInfo.lastName;
    });

    this.edit = true;
  }

  //   get passwordError(): GeneralErrors | "INCORRECT_PASSWORD" | undefined {
  //     return UserStore.changePasswordError;
  //   }
  get changingUser(): boolean {
    return UserStore.changingPassword;
  }

  async submitChangeUser() {
    console.log("ddffd");
    this.shake = false;
    let email = UserStore.userInfo.email.trim().toLowerCase();
    let oldPasswordHash = await sha512(this.currentPassword + email);
    let newPasswordHash = await sha512(this.newPassword + email);
    let payload: ChangePasswordRequest = {
      oldPassword: oldPasswordHash,
      newPassword: newPasswordHash,
      csrfSecurityToken: UserStore.csrfSecurityToken,
    };
    await UserStore.changePassword(payload);
    if (this.passwordError) {
      this.shake = true;
    }
  }
}
