
import {
  SecretaryShowInterfaceRequest,
  SecretarySubmitShowRequest,
  SecretarySuspendShowRequest,
  Show,
} from "@app/common";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import SafetyButton from "../SafetyButton.vue";
import SecretaryInfoStore from "../../store/modules/secretary_info";
import SecretaryStore from "../../store/modules/secretary";
import UserStore from "../../store/modules/user";
@Component({ components: { SafetyButton } })
export default class ShowManagerShowInfo extends Vue {
  @Prop() readonly show!: Show;
  suspendModal: boolean = false;

  get entriesOpen(): boolean {
    return !this.show || this.show.openingTime.getTime() < new Date().getTime();
  }
  get changingShow(): boolean {
    return SecretaryInfoStore.suspendingShow;
  }

  async suspendOrOpenShow() {
    if (this.show.id) {
      let payload: SecretarySuspendShowRequest = {
        show: this.show.id,
        csrfSecurityToken: UserStore.csrfSecurityToken,
      };
      await SecretaryInfoStore.suspendShow(payload);
      this.suspendModal = false;
      let showPayload: SecretaryShowInterfaceRequest = { abbr: this.show.abbr };
      await SecretaryStore.fetchShow(showPayload);
    }
  }
}
