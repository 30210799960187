import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "..";
import UserStore from "./user";
import SecretaryStore from "./secretary"
import { fetchErrorMessage, generalErrorMessage } from "../error";
import { GeneralErrors, SecretaryErrors, SecretarySuspendShowRequest, SecretarySuspendShowResponse } from "@app/common";

@Module({ name: "SecretaryInfoStore", dynamic: true, store, namespaced: true })
class SecretaryInfotStore extends VuexModule {
  private _suspendingShow: boolean = false
  private _suspendingShowError: Array<GeneralErrors | SecretaryErrors> | undefined = undefined
  get suspendingShow(): boolean {
    return this._suspendingShow
  }
  @Mutation
  setSuspendingShow(x: boolean) {
    this._suspendingShow = x
  }
  get suspendingShowError(): Array<GeneralErrors | SecretaryErrors> | undefined {
    return this._suspendingShowError
  }
  @Mutation
  setSuspendingShowError(x: Array<GeneralErrors | SecretaryErrors> | undefined) {
    this._suspendingShowError = x
  }
  @Action
  async suspendShow(payload:SecretarySuspendShowRequest) {
    this.setSuspendingShow(true);
    const response = await fetch(process.env.VUE_APP_DOG_API + "/secretary/suspend-show", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(payload),
      credentials: "include",
    });
    this.setSuspendingShow(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data : SecretarySuspendShowResponse = await response.json();
      if (data.success) {
        this.setSuspendingShowError(undefined)
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
        this.context.rootState.errorCode = data.errors[0];
        this.setSuspendingShowError([data.errors[0]]);
      }
      UserStore.authCheck(data);
    }
  }

}

export default getModule(SecretaryInfotStore);
