
import { CatalogBlock, CatalogBlockType } from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import CatalogBlockComponent from "./CatalogBlockComponent.vue";
@Component({ components: { CatalogBlockComponent } })
export default class CatalogOrderComponent extends Vue {
  @Prop({
    default: () => {
      return [];
    },
  })
  value!: Array<CatalogBlock>;
  blocks: Array<CatalogBlock> = [];
  blockTypes: Array<CatalogBlockType> = Object.assign(CatalogBlockType);

  addBlock() {
    this.blocks.push(new CatalogBlock());
  }

  remove(idx: number) {
    console.log(idx);
    this.blocks.splice(idx, 1);
    console.log(this.blocks);
  }
}
