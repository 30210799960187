
import { Show, ShowType } from "@app/common";
import { Component, Vue, Prop } from "vue-property-decorator";
import SecretaryStore from "../store/modules/secretary"
@Component
export default class DogShow extends Vue {
    @Prop({default : () => {return undefined}}) readonly show! : Show | undefined
    @Prop({default : false}) readonly secretary! : boolean


    dateExpired(x: Date): boolean {
    if (x.getTime() < new Date().getTime()) {
      return true;
    } else {
      return false;
    }
  }

  showType(x: ShowType): string {
    if (x == ShowType.SPECIALTY) {
      return "Specialty";
    } else if (x == ShowType.GROUP) {
      return "Group";
    } else if (x == ShowType.ALLBREED) {
      return "All Breed";
    } else {
      return "Other";
    }
  }

  gotoShow(abbr: string) {
    if(this.secretary && this.show){
        SecretaryStore.setSelectedShow(this.show)
        this.$router.push("/show-manager/" + this.show.abbr)
    }else if(this.show){
         this.$router.push("/show/" + this.show.abbr);
    }
  }
}
