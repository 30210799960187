import { DogClass } from './classes'
import { Entry, EventClass, ShowEntry } from './entries'
import { DogEvent, Show, ShowDog } from './show'
import {Dog, Sex} from './dog'
import { Address, Judge, Person } from './people'

export class CatalogEntry{
    private _dog: ShowDog
    private _armband: string | null
    private _dogClass : DogClass
    private _events : Array<DogEvent>
    get dog(): ShowDog{
        return this._dog
    }
    get armband(): string | null{
        return this._armband
    }
    get dogClass(): DogClass{
        return this._dogClass
    }
    constructor(dog: ShowDog, armband: string | null, dogClass : DogClass, events : Array<DogEvent>){
        this._dog = dog
        this._armband = armband
        this._dogClass = dogClass
        this._events = events
    }
}

export enum CatalogBlockType{
    TEXT_BLOCK = 'Text block',
    CLASS_BLOCK = 'Class block',
    HTML_BLOCK = 'HTML block',
    PAGE_BREAK_BLOCK = 'Page break block',
    AD_BLOCK = 'AD block'
}

export enum CatalogBlockPageBreak{
    BEFORE = 'Before',
    AVOID = 'Avoid',
    AFTER = 'After',
    NONE = 'No preference'
}

export interface CatalogTextLine{
    text : string
    bold : boolean
    size : number
    align : 'LEFT' | 'CENTER' | 'RIGHT'
}


export class CatalogBlock{
    private _type : CatalogBlockType
    private _pageBreak : CatalogBlockPageBreak
    private _html : string
    private _text : Array<CatalogTextLine>
    private _classOrdering : CatalogOrder

    get type() : CatalogBlockType{
        return this._type
    }
    set type(x : CatalogBlockType){
        this._type = x
        if(this._type !== CatalogBlockType.HTML_BLOCK){
            this._html = ""
        }
        if(this._type !== CatalogBlockType.CLASS_BLOCK){
            this._classOrdering = new CatalogOrder([])
        }
        if(this._type !== CatalogBlockType.TEXT_BLOCK){
            this._text = [{text : "", bold: false, size : 12, align : 'CENTER'}]
        }
    }

    get pageBreak() : CatalogBlockPageBreak{
        return this._pageBreak
    }
    set pageBreak(x : CatalogBlockPageBreak){
        this._pageBreak = x
    }

    get html() : string{
        return this._html
    }
    set html(x : string){
        this._html = x
        this._type = CatalogBlockType.HTML_BLOCK
    }

    get text(): Array<CatalogTextLine>{
        return this._text
    }

    set text(x : Array<CatalogTextLine>){
        this._text = x
        this._type = CatalogBlockType.TEXT_BLOCK
    }

    get classOrdering() : CatalogOrder{
        return this._classOrdering
    }
    set classOrdering(x : CatalogOrder){
        this._classOrdering = x
    }


    constructor()
    constructor(type : CatalogBlockType, pageBreak : CatalogBlockPageBreak, html : string, text : Array<CatalogTextLine>, classOrdering : CatalogOrder)
    constructor(type? : CatalogBlockType, pageBreak? : CatalogBlockPageBreak, html? : string, text? : Array<CatalogTextLine>, classOrdering? : CatalogOrder){
        this._type = type || CatalogBlockType.TEXT_BLOCK
        this._pageBreak = pageBreak || CatalogBlockPageBreak.AVOID
        this._html = html || ''
        this._text = text || [{text : "", bold: false, size : 12, align : 'CENTER'}]
        this._classOrdering = classOrdering || new CatalogOrder([])
    }
}

export class CatalogOrder{
    public static catalogOrderFromJSON(x : CatalogOrderInterface, events : Array<DogEvent>, dogClasses : Array<DogClass>):CatalogOrder{
        let ordering : Array<{events : Array<DogEvent>, classOrderings : Array<DogClass>}> = []
        for(let order of x.ordering){
            let dogEvents : Array<DogEvent> = []
            for(let dogEventId of order.events){
                let dogEvent : DogEvent | undefined = events.find((y)=>{return y.id == dogEventId})
                if(!dogEvent){
                    throw "CatalogOrder : Event not found."
                }
                dogEvents.push(dogEvent)
            }
            let classOrderings : Array<DogClass> = []
            for(let dogClassId of order.classOrderings){
                let dogClass : DogClass | undefined = dogClasses.find((y) => {return y.id == dogClassId})
                if(!dogClass){
                    throw "CatalogOrder : DogClass not found."
                }
                classOrderings.push(dogClass)
            }
            ordering.push({events : dogEvents, classOrderings : classOrderings})
        }
        return new CatalogOrder(ordering)
    }

    private _ordering : Array<{events : Array<DogEvent>, classOrderings : Array<DogClass>}>

    public get ordering(): Array<{events : Array<DogEvent>, classOrderings : Array<DogClass>}>{
        return this._ordering
    }
    constructor(ordering : Array<{events : Array<DogEvent>, classOrderings : Array<DogClass>}>){
        this._ordering = ordering
    }

    exportInterface(): CatalogOrderInterface{
        return { ordering : this._ordering.map((x) => {return {events : x.events.map((y) => {return ( y.id !== null ? y.id : -1)}), classOrderings : x.classOrderings.map((y) => {return y.id})}}) }
    }
}

export interface CatalogOrderInterface{
    ordering : Array<{events : Array<number>,classOrderings : Array<number>}>
}

export enum CatalogItemType{
    DOG_CLASS = 'DOG_CLASS',
    PAGE_BREAK = 'PAGE_BREAK',
    SUMMARY = 'SUMMARY',
}

export interface CatalogItem{
    
}

export class DogCatalog {
    private _catalogOrder : CatalogOrder
    private _showDogs: Array<ShowDog>
    private _showEntries: Array<ShowEntry>
    private _entries : Array<Entry>
    private _entriesPaidNotRefunded : Array<Entry>
    private _eventClasses: Array<EventClass>
    private _dogClasses: Array<DogClass> = []
    private _judges : Array<Judge> = []
    private _armbands: Array<{dog: ShowDog, armband: number}> = []
    private _show: Show
    private _printOrder : Array<CatalogPrintItem> = []
    private _entryClasses : Array<{eventClass : EventClass, showDogs : Array<ShowDog>}>
    private _entryBlocks : Array<{events : Array<DogEvent>, classes : Array<{dogClass : DogClass, entries : Array<{showDog: ShowDog, events : Array<DogEvent>, agent : string, hasAgent : boolean}>, events : Array<DogEvent>}>}>
    private _ownerAddresses : Array<{owners : Array<Person>, armbands : Array<number>, address : Address}>

    get armbands(): Array<{dog: ShowDog, armband: number}> {
        return this._armbands
    }

    get show(): Show{
        return this._show
    }

    get entryClasses() : Array<{eventClass : EventClass, showDogs : Array<ShowDog>}>{
        return this._entryClasses
    }

    get entryBlocks() :  Array<{events : Array<DogEvent>, classes : Array<{dogClass : DogClass, entries : Array<{showDog: ShowDog, events : Array<DogEvent>, agent : string, hasAgent : boolean}>, events : Array<DogEvent>}>}>{
        return this._entryBlocks
    }

    get ownerAddresses(): Array<{owners : Array<Person>, armbands : Array<number>, address : Address}>{
        return this._ownerAddresses
    }

    get entries():Array<Entry>{
        return this._entries
    }

    constructor(catalogOrder: CatalogOrder, showDogs: Array<ShowDog>, showEntries: Array<ShowEntry>, eventClasses: Array<EventClass>, show: Show){
        this._catalogOrder = catalogOrder
        this._showDogs = showDogs
        this._showEntries = showEntries
        this._eventClasses = eventClasses
        this._show = show
        this._entries = []
        for(let showEntry of this._showEntries){
            this._entries = this._entries.concat(showEntry.entries)
        }
        this._entriesPaidNotRefunded = this._entries.filter((x) => {return x.paid && !x.refunded})
        this._entryClasses = []
        for(let eventClass of this._eventClasses){
            let showDogs : Array<ShowDog> = []
            for(let entry of this._entriesPaidNotRefunded){
                if(entry.classes.find((x) => {return x.id == eventClass.id})){
                    showDogs.push(entry.dog)
                }
            }
            //showDogs.sort((x,y) => {return (x.showDog.dog.name.baseName > y.showDog.dog.name.baseName ? 1 : -1)})
            this._entryClasses.push({eventClass : eventClass, showDogs : showDogs})
        }
        
        this._entryBlocks = []
        for(let order of this._catalogOrder.ordering){
            let classes : Array<{dogClass : DogClass, entries : Array<{showDog: ShowDog, events : Array<DogEvent>, agent : string, hasAgent : boolean}>, events : Array<DogEvent>}> = []
            for(let dogClass of order.classOrderings){
                let newClass : {dogClass : DogClass, entries : Array<{showDog: ShowDog, events : Array<DogEvent>, agent : string, hasAgent : boolean}>, events : Array<DogEvent>} = {dogClass : dogClass, entries : [], events : []}
                for(let event of order.events){
                    if(this._eventClasses.find((x) => {return x.dogClass.id == dogClass.id && x.event.id == event.id})){
                        newClass.events.push(event)
                    }
                }
                let eventEntryClasses : Array<{eventClass : EventClass, showDogs : Array<ShowDog>}> = this._entryClasses.filter((x) =>{return x.eventClass.dogClass.id == dogClass.id && !!order.events.find((y)=>{return y.id == x.eventClass.event.id})})
                let showDogs : Array<ShowDog> = []
                for(let eventEntries of eventEntryClasses){
                    for(let showDog of eventEntries.showDogs){
                        if(!showDogs.find((x) => {return x.showDog.id == showDog.showDog.id})){
                            showDogs.push(showDog)
                        }
                    }
                }
                showDogs.sort((x,y) => {return (x.showDog.dog.name.baseName > y.showDog.dog.name.baseName ? 1 : -1)})
                //let entries : Array<{showDog: ShowDog, events : Array<DogEvent>}> = []
                for(let showDog of showDogs){
                    let events : Array<DogEvent> = []
                    for(let event of order.events){
                        if(eventEntryClasses.find((x) => {return x.eventClass.event.id == event.id && !!x.showDogs.find((y) => {return y.showDog.id == showDog.showDog.id})})){
                            events.push(event)
                        }
                    }
                    let agent : string = ""
                    let hasAgent : boolean = false
                    for(let showEntry of this._showEntries){
                        if(showEntry.showDog.showDog.id == showDog.showDog.id){
                            for(let entry of showEntry.entries){
                                if(entry.agent){
                                    agent = entry.agent
                                    hasAgent = true
                                }
                            }
                        }
                    }
                    newClass.entries.push({showDog : showDog, events : events, agent : agent, hasAgent : hasAgent})
                }
                //classes.push({dogClass : dogClass, entries : entries})
                classes.push(newClass)
            }
            this._entryBlocks.push({events : order.events, classes : classes})
        }
        this._armbands = []
        let armbandMale : number = 3
        let armbandFemale : number = 4
        for(let entryBlock of this._entryBlocks){
            for(let entryClasses of entryBlock.classes){
                for(let entry of entryClasses.entries){
                    if(!this._armbands.find((x) => {return x.dog.showDog.id == entry.showDog.showDog.id})){
                        let armband : number
                        if(entry.showDog.showDog.dog.sex == Sex.MALE){
                            armbandMale += 2
                            armband = armbandMale
                        }else{
                            armbandFemale +=  2
                            armband = armbandFemale
                        }
                        this._armbands.push({dog : entry.showDog, armband : armband})
                    }
                }
            }
        }

        this._ownerAddresses = []
        for(let armband of this._armbands){
            let ownerAddress : {owners : Array<Person>, armbands : Array<number>, address : Address} | undefined = this._ownerAddresses.find((x) => {return this.ownersMatch(armband.dog,x.owners)})
            if(ownerAddress){
                ownerAddress.armbands.push(armband.armband)
                ownerAddress.armbands.sort((x,y) => {return (x > y ? 1 : -1)})
            }else{
                this._ownerAddresses.push({owners : armband.dog.showDog.dog.owners, armbands : [armband.armband], address : armband.dog.showDog.dog.address})
            }
        }
        this._ownerAddresses.sort((x,y) => {return (x.owners[0].name.split(' ').slice(-1)[0] > y.owners[0].name.split(' ').slice(-1)[0] ? 1 : -1)})
    }

    private ownersMatch(showDog: ShowDog, owners : Array<Person>):boolean{
        for(let owner of showDog.showDog.dog.owners){
            if(!owners.find((x) => {return owner.comparePerson(x)})){
                return false
            }
        }
        return true
    }

    printDog(dog: Dog):string{
        let out : string = ""
        out += "<b>"+dog.name.name+".</b> "
        out += dog.registrationNumber.number+". "
        out += dog.printBirthDate()+". "
        out += (dog.sex == Sex.MALE ? "Dog" : "Bitch")+". "
        out += dog.breeders.map((x) => {x.name}).join(", ")+". "
        


        return out
    }

    printShowDog(showDog: ShowDog): string{
        let dog : Dog
        // if(showDog.showDogEdit){
        //     dog = showDog.showDogEdit.dog
        // }else{
        //     dog = showDog.showDog.dog
        // }
        let out : string = ""
        // out += "<b>"+dog.name.name+".</b> "
        // out += dog.registrationNumber + ". "
        // out += dog.printBirthDate()+ ". "
        // out += (dog.sex == Sex.MALE ? "Dog" : "Bitch")+". "
        // out += (dog.breeders.length > 1 ? "Breeders: " : "Breeder: ")+dog.breeders.map((x) => {return x.name}).join(", ")+". "
        // out += "By: "+dog.sire.name+" - "+dog.dam.name+". "
        // out += (dog.owners.length > 1 ? "Owners: " : "Owner: ")+dog.owners.map((x) => {return x.name}).join(", ")+"."
        return out
    }

    assignArmbands(){
        let armbandMale = 2
        let armbandFemale = 3

    }

    printCatalog(el: Element){
        for(let item of this._printOrder){
            el.appendChild(item.element)
        }
    }
}



export interface CatalogConfigurationInterface{
    orderings : Array< { events: Array<number>, classOrderings: Array<number>}>
}

export enum PageSizeTypes {
 A6 = 'A6',
 A5 = 'A5',
 A4 = 'A4',
 A3 = 'A3',
 A2 = 'A2',
 A1 = 'A1',
 A0 = 'A0',
 CUSTOM = 'Custom'
}

export class PageSize{
    _width : number
    _height : number
    _type : PageSizeTypes
    get width():number{
        return this._width
    }
    set width(x : number){
        this._width = x
        this._type = PageSizeTypes.CUSTOM
    }
    get height():number{
        return this._height
    }
    set height(x : number){
        this._height = x
        this._type = PageSizeTypes.CUSTOM
    }
    get type(): PageSizeTypes{
        return this._type
    }
    set type(x : PageSizeTypes){
        this._type = x
    }
    constructor(type: PageSizeTypes, width : number, height : number)
    constructor(type: PageSizeTypes)
    constructor(type: PageSizeTypes, width? : number, height? : number){
        if(width && height && type === PageSizeTypes.CUSTOM){
            this._type = PageSizeTypes.CUSTOM
            this._width = width
            this._height = height
        }else{
            this._type = type
            let baseHeight = Math.pow(2,0.25)*1000
            let baseWidth = 1.0/Math.pow(2,0.25)*1000
            let k = 0
            if(type == PageSizeTypes.A0){
                k = 0
            }else if (type == PageSizeTypes.A1){
                k = 1
            }else if (type == PageSizeTypes.A2){
                k = 2
            }else if (type == PageSizeTypes.A3){
                k = 3
            }else if (type == PageSizeTypes.A4){
                k = 4 
            }else if (type == PageSizeTypes.A5){
                k = 5
            }else if (type == PageSizeTypes.A6){
                k = 6
            }
            let m = Math.floor(k/2)
            if(k % 2 == 0){
                this._width = Math.round(baseWidth/Math.pow(2,m))
                this._height = Math.round(baseHeight/Math.pow(2,m))
            }else{
                this._width = Math.round(baseHeight/Math.pow(2,m+1))
                this._height = Math.round(baseWidth/Math.pow(2,m))
            }
        }
    }
}

// export class PageMargin{
//     _leftPageLeftMargin : number
//     _rightPageRightMargin : 
// }


// export class CatalogPage{
//     _pageSize : PageSize
//     _pageMargins : PageMargin
//     _pageNumber : boolean
//     _startPageNumber : number
//     _
//     constructor()
// }

export class CatalogConfiguration{
}

export class CatalogPrintItem{
    private _content : string = ''
    private _element : Element

    get element():Element{
        return this._element
    }

    constructor(){
        this._element = document.createElement('div')
    }

}

export class CatalogPrintElement{
    private _children : Array<CatalogPrintElement> = []
    private _style : string = ''

    appendChild(x : CatalogPrintElement){
        this._children.push(x)
    }
    clearChildren(){
        this._children = []
    }
}

export enum CatalogElementStyles {

}

export enum CatalogFont{
    HELVETICA = "Helvetica",
    GARAMOND = "Garamond",
    FUTURA = 'Futura',
    BODONI = 'Bodoni',
    ARIAL = 'Arial',
    TIMES_NEW_ROMAN = 'Times New Roman',
    VERANDA = 'Veranda',
    ROCKWELL = 'Rockwell',
    FRANKLIN_GOTHIC = 'Frankline Gothic'
}

export enum CatalogFontSize{
    F10 = '10',
    F12 = '12',
    F14 = '14',
    F16 = '16'
}