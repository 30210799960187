
import { DogEvent, Show } from "@app/common";
import { Component, Vue } from "vue-property-decorator";
import DogEntriesSecretary from "../DogEntriesSecretary.vue"
import SecretaryStore from "../../store/modules/secretary"
@Component({components: {DogEntriesSecretary}})
export default class ShowManagerEntriesPanel extends Vue {
    search: string = "";
      selectedEvents: Array<DogEvent> = [];
  paymentStatusItems: Array<"Paid" | "Unpaid" | "Refunded"> = ["Paid","Unpaid","Refunded"];
  paymentStatusSelected :  Array<"Paid" | "Unpaid" | "Refunded"> = ["Paid","Unpaid","Refunded"]
  entryMethodItems: Array<"Online" | "Manual"> = ["Online","Manual"]
  entryMethodSelected: Array<"Online" | "Manual"> = ["Online","Manual"]

  get show():Show | undefined{
    return SecretaryStore.selectedShow
  }

  get abbr():string{
    return (this.show ? this.show.abbr : "")
  }

    get excludedEvents(): Array<DogEvent>{
    if(this.show){
      return this.show.events.filter((x)=>{return !this.selectedEvents.find((y)=>{return y.id == x.id})})
    }
    return []
  }

  async mounted() {
    if (this.show) {
      this.selectedEvents = this.show.events;
    }
  }

  close(){
    this.$emit("close")
  }

}
