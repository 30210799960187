
import { DogEvent, Entry, ShowEntry } from "@app/common";
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class DogEntry extends Vue {
  @Prop() readonly showEntry!: ShowEntry;
  @Prop({ default: false }) readonly refund!: boolean;
  @Prop({ default: false }) readonly refundItem!: boolean;
  @Prop({ default: false }) readonly showRefunded!: boolean;
  @Prop({ default: true }) readonly showPaid!: boolean;
  @Prop({ default: true }) readonly showUnpaid!: boolean;
  @Prop({
    default: () => {
      return [];
    },
  })
  readonly excludeEvents!: Array<DogEvent>;
  @Prop({ default: false }) readonly showTime!: boolean;

  refundItems: Array<boolean> = [];
  arrowDown: Array<boolean> = [];

  switchArrow(idx: number) {
    this.$set(this.arrowDown, idx, !this.arrowDown[idx]);
  }

  beforeMounted() {
    for (let entry of this.showEntry.entries) {
      this.refundItems.push(false);
      this.arrowDown.push(false);
    }
  }

  secretaryFee(idx : number): number {
    return this.showEntry.show.secretaryFee * this.showEntry.entries[idx].classes.length;
  }

  addRefundItem(x: number) {
    this.$set(this.refundItems, x, !this.refundItems[x]);
    let refundEntries: Array<Entry> = [];
    for (let k = 0; k < this.showEntry.entries.length; k++) {
      if (this.refundItems[k]) {
        refundEntries.push(this.showEntry.entries[k]);
      }
    }
    this.$emit("refund-entries", refundEntries);
  }

  // checkEntryPaymentStatus(entry : Entry){
  //   if(entry.paid )
  // }

  get entries(): Array<Entry> {
    let out: Array<Entry> = [];
    if (this.showUnpaid) {
      out = out.concat(this.showEntry.unpaidEntries);
    }
    if (this.showPaid) {
      out = out.concat(this.showEntry.paidUnrefundedEntries);
    }
    if (this.showRefunded) {
      out = out.concat(this.showEntry.refundedEntries);
    }
    out = out.filter((x) => {
      return !this.excludeEvents.find((y) => {
        return x.event.id == y.id;
      });
    });
    return out;
    // if(this.showPaid && this.showUnpaid && this.showRefunded){
    //   return this.showEntry.entries
    // }else if(this.showPaid && this.showUnpaid && !this.showRefunded){
    //   return this.showEntry.entriesNotRefunded
    // }else if(this.showPaid && !this.showUnpaid && this.showRefunded){
    //   return this.showEntry.refundedEntries
    // }else if(this.showPaid && !this.showUnpaid && !this.showRefunded){
    //   return this.showEntry.paidEntries
    // }else if(!this.showPaid && this.showUnpaid && this.showRefunded){
    //   return this.showEntry.unpaidEntries.concat(this.showEntry.refundedEntries)
    // }else if(!this.showPaid && this.showUnpaid && !this.showRefunded){
    //   return this.showEntry.unpaidEntries
    // }else if(!this.showPaid && !this.showUnpaid && this.showRefunded){
    //   return this.showEntry.
    // }
    // if(this.showRefunded){
    //   return this.showEntry.entries
    // }else{
    //   return this.showEntry.entriesNotRefunded
    // }
  }

  arrowStyle(idx: number): Array<string> {
    let out: Array<string> = ["arrow"];
    if (this.arrowDown[idx]) {
      out.push("arrow-down");
    }
    return out;
  }
}
