
import { Component, Vue } from "vue-property-decorator";
import DogFormInfo from "../components/DogForm/DogFormInfo.vue";
import DogFormConfirm from "../components/DogForm/DogFormConfirm.vue";
import DogFormProblemEntries from "../components/DogForm/DogFormProblemEntries.vue";
import StickyFooter from "../components/StickyFooter.vue";
import {
  AddDogInterface,
  AddDogRequest,
  Address,
  AddressInterface,
  CheckEditDogRequest,
  Dog,
  DogInterface,
  EditDogRequest,
  Person,
  SecretaryEditDogRequest,
  ShowDog,
} from "@app/common";
import UserStore from "../store/modules/user";
import DogStore from "../store/modules/dog";
import SecretaryStore from "../store/modules/secretary"
import SecretaryEntryStore from "../store/modules/secretary_entries"
@Component({
  components: {
    DogFormInfo,
    DogFormConfirm,
    DogFormProblemEntries,
    StickyFooter,
  },
})
export default class DogForm extends Vue {
  page = 0;
  dog: Dog = new Dog();
  dogInfoErrors: Array<string> = [];
  dogErrorModal: boolean = false;
  transitionOut: boolean = false;
  mode: string = "";
  restoreDogModal: boolean = false;
  restoredDog: Dog | undefined = undefined;

  cannotEdit : boolean = true
  secretary : boolean = false

  reset() {
    this.$router.go(-1);
  }
  previous() {
    if (this.page == 1) {
      this.page = 0;
    }
  }

  submit() {
    this.dogInfoErrors = (this.$refs.dogInfo as any).validate();
    if (this.dogInfoErrors.length > 0) {
      this.dogErrorModal = true;
    }
    if (this.dogInfoErrors.length == 0) {
      this.page = 1;
      window.scrollTo(0, 0);
      // setTimeout(() => {
        
      // }, 0);
    }
  }

  async secretaryConfirm(){
    console.log(this.mode)
    console.log(SecretaryStore.selectedShow)
    if(this.mode === "new" && SecretaryStore.selectedShow && SecretaryStore.selectedShow.id){
      SecretaryEntryStore.setSelectedShowDog(new ShowDog(-1,this.dog))
      this.$router.push("/enter-dog/new/secretary")
    }else if(SecretaryStore.selectedShow && SecretaryStore.selectedShow.id && SecretaryEntryStore.selectedShowDog){
      let payload : SecretaryEditDogRequest = {
        show : SecretaryStore.selectedShow.id,
        showDog : SecretaryEntryStore.selectedShowDog.showDog.id,
        ... this.dog.exportAddDogInterface(),
        csrfSecurityToken : UserStore.csrfSecurityToken
      }
      await SecretaryEntryStore.editDog(payload)
      if(!SecretaryEntryStore.editDogError){
        await SecretaryStore.dogEntryRoutine({abbr : SecretaryStore.selectedShow.abbr})
        this.$router.push("/show-manager/"+SecretaryStore.selectedShow.abbr)
      }
    }
  }

  async exhibitorConfirm(){
    if (this.mode === "new") {
      let payload: AddDogRequest = {
        ...this.dog.exportAddDogInterface(),
        csrfSecurityToken: UserStore.csrfSecurityToken,
      };
      await DogStore.addDog(payload);
      if (!DogStore.addDogError) {
        await DogStore.fetchDogData();
        window.localStorage.removeItem("newDog");
        if (this.$route.params.abbr) {
          let newDogId: number | undefined = DogStore.newDog;
          let newDog: Dog | undefined = DogStore.dogs.find((x) => {
            return x.id == newDogId;
          });
          if (newDog) {
            DogStore.setSelectedDog(newDog);
            this.$router.push("/enter-dog/new");
          } else {
            console.error("DogForm: Dog not found");
          }
        } else {
          this.$router.push("/dashboard/exhibitor");
        }
      }
    } else if (this.mode === "edit" && this.page == 1) {
      if (this.dog.id == null) {
        throw "Edit dog must have an id.";
      }
      let payload: CheckEditDogRequest = {
        dog: this.dog.id,
        ...this.dog.exportAddDogInterface(),
        csrfSecurityToken: UserStore.csrfSecurityToken,
      };
      await DogStore.checkEditDog(payload);
      if (DogStore.checkEditDogProblemEntries.length > 0) {
        this.cannotEdit = DogStore.checkEditDogProblemEntries.filter((x) => {return x.paid}).length > 0
        this.page = 2;
      } else {
        this.editDog()
      }
    } else {
      await this.editDog()
    }
  }

  async confirm() {
    if(this.secretary){
      await this.secretaryConfirm()
    }else{
      await this.exhibitorConfirm()
    }
  }

  async editDog() {
    if (this.dog.id == null) {
      throw "Edit dog must have an id.";
    }
    let payload: EditDogRequest = {
      dog: this.dog.id,
      ...this.dog.exportAddDogInterface(),
      csrfSecurityToken: UserStore.csrfSecurityToken,
    };
    await DogStore.editDog(payload);
    if (!DogStore.editDogError) {
      await DogStore.fetchDogData()
      this.$router.go(-1)
      // if (this.$route.params.abbr) {
      //   this.$router.push("/show" + this.$route.params.abbr);
      // } else {
      //   this.$router.push("/dashboard/exhibitor");
      // }
    }
  }

  changePage(x: { page: number; location: string }) {
    if (this.page !== x.page) {
      this.page = x.page;
      this.transitionOut = true;
    }
    if (x.page == 0) {
      setTimeout(() => {
        (this.$refs.dogInfo as any).scrollTo(x.location);
      }, 700);
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 700);
    }
  }

  get submitting(): boolean {
    return (this.secretary ? SecretaryEntryStore.editingDog : DogStore.addingDog);
  }

  restoredDogName(): string {
    if (this.restoredDog) {
      console.log(this.restoredDog.printName());
      return this.restoredDog.printName();
    } else {
      return "";
    }
  }

  restoreDog(x: boolean) {
    if (x && this.restoredDog) {
      this.dog = this.restoredDog;
    } else {
      window.localStorage.removeItem("newDog");
    }
    this.restoreDogModal = false;
  }

  mounted() {
    this.mode = this.$route.params.mode;
    let abbr = this.$route.params.abbr;
    if(this.$route.params.secretary == 'secretary'){
      this.secretary = true
    }
    if (this.mode === "edit" && !this.secretary && DogStore.selectedDog) {
      this.dog = DogStore.selectedDog.clone();
      this.$nextTick(() => {
        this.$set(this.dog, "color", this.dog.color);
      });
    }else if(this.mode === "edit" && this.secretary && SecretaryEntryStore.selectedShowDog){
      this.dog = SecretaryEntryStore.selectedShowDog.showDog.dog.clone()
      //something should be done about this...
      this.dog.breeders = this.dog.breeders.map((x,idx) => {return new Person(idx+1,x.name)})
      this.dog.owners = this.dog.owners.map((x,idx) => {return new Person(idx+1,x.name)})
      let addressInterface : AddressInterface = this.dog.address.exportInterface()
      addressInterface.id = 1
      this.dog.address = Address.addressFromJSON(addressInterface)
      this.$nextTick(() => {
        this.$set(this.dog, "color", this.dog.color);
      });
    }
    

    let newDogData: string | null = window.localStorage.getItem("newDog");
    if (this.mode === "new" && newDogData) {
      let restoredDogInterface: AddDogInterface = JSON.parse(newDogData);
      this.restoredDog = Dog.dogFromAddDogInterface(restoredDogInterface);
      if (this.restoredDog.name.baseName.trim().length > 0) {
        this.restoreDogModal = true;
      }
    }
  }
}
