
import { CSVDataItem, CSVDogEntryColumns, CSVPaymentColumns, CSVRefundColumns } from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class CsvDataSelector extends Vue {
  @Prop({
    default: () => {
      return [];
    },
  })
  readonly value!: Array<CSVDataItem<CSVDogEntryColumns | CSVPaymentColumns | CSVRefundColumns>>;
  @Prop({
    default: () => {
      return [];
    },
  })
  readonly dataItems!: Array<CSVDataItem<CSVDogEntryColumns | CSVPaymentColumns | CSVRefundColumns>>;
  selectedItems: Array<CSVDataItem<CSVDogEntryColumns | CSVPaymentColumns | CSVRefundColumns>> = [];
  swapIdx: number | null = null;

  get remainingItems(): Array<CSVDataItem<CSVDogEntryColumns | CSVPaymentColumns | CSVRefundColumns>> {
    let out: Array<CSVDataItem<CSVDogEntryColumns | CSVPaymentColumns | CSVRefundColumns>> = [];
    for (let dataItem of this.dataItems) {
      if (
        !this.selectedItems.find((x) => {
          return x.column === dataItem.column;
        })
      ) {
        out.push(dataItem);
      }
    }
    return out;
  }

  select(idx: number) {
    this.selectedItems.push(this.remainingItems[idx]);
  }

  remove(idx: number) {
    this.selectedItems[idx].columnName = this.selectedItems[idx].column;
    this.selectedItems.splice(idx, 1);
  }

  startSwap(idx: number) {
    this.swapIdx = idx;
    console.log("swap");
  }

  endSwap(idx: number) {
    if (this.swapIdx !== null && this.swapIdx !== idx) {
      let itemA = this.selectedItems[this.swapIdx];
      let itemB = this.selectedItems[idx];
      this.selectedItems[idx] = itemA;
      this.selectedItems[this.swapIdx] = itemB;
    }
    this.swapIdx = null;
    console.log("endsap");
  }

  update() {
    this.$emit("input", this.selectedItems);
  }

  @Watch("selectedItems")
  onSelectedItemsChange(x: Array<CSVDataItem<CSVDogEntryColumns | CSVPaymentColumns | CSVRefundColumns>>) {
    this.update();
  }

  @Watch("value")
  onValueCahnge(x: Array<CSVDataItem<CSVDogEntryColumns | CSVPaymentColumns | CSVRefundColumns>>) {
    this.selectedItems = x;
  }

  get selectedStyle(): {
    "background-color": string;
    color: string;
    cursor: string;
  } {
    return {
      "background-color": this.$vuetify.theme.themes.light.primary as string,
      color: "white",
      cursor: "pointer",
    };
  }
}
