
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DogStore from "../../../store/modules/dog";
import { Person } from "@app/common";
@Component
export default class DogPersonModal extends Vue {
  @Prop() readonly label!: string;
  @Prop({
    default: () => {
      return [];
    },
  })
  readonly removedPeople!: Array<Person>;

  selectedPeople: Array<Person> = [];

  get people(): Array<Person> {
    if (this.label === "Breeder") {
      return DogStore.breeders.filter((x) => {
        return !this.removedPeople.find((y) => {
          return y.id == x.id;
        });
      });
    } else if (this.label === "Owner") {
      return DogStore.owners.filter((x) => {
        return !this.removedPeople.find((y) => {
          return y.id == x.id;
        });
      });
    } else {
      return [];
    }
  }

  close() {
    this.$emit("close");
    this.selectedPeople.splice(0)
  }

  add() {
    this.$emit("add", this.selectedPeople);
    this.close()
  }
}
