var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.show)?_c('v-card',[_c('v-card-title',[_vm._v(" Event summary ")]),_c('loading',{attrs:{"loading":_vm.loading}},[_c('v-card-text',[_c('v-data-table',{attrs:{"disable-pagination":"","headers":[
            { text: 'Event', value: 'eventName' },
            { text: 'Date', value: 'dates' },
            { text: 'Number of dogs', value: 'dogTotal' },
            { text: 'Number of exhibitors', value: 'exhibitorTotal' },
            { text: 'Total fees', value: 'feeTotal' },
          ],"items":_vm.dataTableSummaryItems,"item-key":"subId","hide-default-footer":""}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }