
import { Color, Dog, Variety } from "@app/common";
import { DogClass } from "@app/common/classes";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import fuzzySearch from "../services/fuzzySearch";
@Component
export default class DogSplit extends Vue {
  @Prop() readonly dogClass!: DogClass;
  splitAge: boolean = false;
  splitColor: boolean = false;
  splitVariety: boolean = false;
  splitSex: boolean = false;
  addSuffix: boolean = false;
  colorSplit: Array<Color> = [];
  ageSplit: Array<number> = [6, Infinity];
  varietySplit: Array<Variety> = [];
  splitModal: boolean = false;

  //colors: Array<Color> = Dog.colors;
  //varieties: Array<Variety> = Dog.varieties;

  get varieties():Array<Variety>{
    if(this.dogClass.rules.ruleSet.breed && this.dogClass.rules.ruleSet.breed !== null && this.dogClass.rules.ruleSet.variety !== null){
      return Dog.getVaritiesByBreed(this.dogClass.rules.ruleSet.breed)
    }
    return []
  }

  get colors():Array<Color>{
    if(this.dogClass.rules.ruleSet.breed && this.dogClass.rules.ruleSet.breed !== null && (this.varieties.length == 0 || this.dogClass.rules.ruleSet.variety)){ 
      return Dog.getColorsByBreedAndVariety(this.dogClass.rules.ruleSet.breed,(this.dogClass.rules.ruleSet.variety ? this.dogClass.rules.ruleSet.variety : null))
    }
    return []
  }

  get showSplitBySex():boolean{
    return !this.dogClass.rules.ruleSet.sex
  }

  get showSplitByAge():boolean{
    if(this.dogClass.rules.ruleSet.age){
      return this.dogClass.rules.ruleSet.age.min == 6 && !this.dogClass.rules.ruleSet.age.max
    }
    return true
  }

  get showSplitByVariety():boolean{
    return !!this.dogClass.rules.ruleSet.breed && !this.dogClass.rules.ruleSet.variety && this.varieties.length > 0
  }

  get showSplitByColor():boolean{
    if(this.dogClass.rules.ruleSet.color){
      return false
    }
    if(this.dogClass.rules.ruleSet.colorSet){
      return false
    }
    if(this.dogClass.rules.ruleSet.notColor){
      return false
    }
    return !!this.dogClass.rules.ruleSet.breed && (this.varieties.length == 0  || !!this.dogClass.rules.ruleSet.variety)
  }

  get splitClasses(): Array<DogClass> {
    let newDogClasses: Array<DogClass> = [this.dogClass];
    if (this.splitSex) {
      newDogClasses = newDogClasses
        .map((x) => {
          return x.splitClassBySex(false);
        })
        .flat();
    }
    if (this.splitAge) {
      newDogClasses = newDogClasses
        .map((x) => {
          return x.splitClassByAges(this.ageSplit, false);
        })
        .flat();
    }
    if (this.splitColor) {
      newDogClasses = newDogClasses
        .map((x) => {
          return x.splitClassByColors(this.colorSplit, false);
        })
        .flat();
    }
    if (this.splitVariety) {
      newDogClasses = newDogClasses
        .map((x) => {
          return x.splitClassByVariety(this.varietySplit, false);
        })
        .flat();
    }
    newDogClasses = newDogClasses.map((x) => {
      return x.addRuleSuffix();
    });
    return newDogClasses;
  }

  newAge: string = "";

  get canAddAge(): boolean {
    if (!/^\d+$/.test(this.newAge)) {
      return false;
    }
    let newNumber = parseInt(this.newAge);
    return !this.ageSplit.find((x) => {
      return x == newNumber;
    });
  }
  get canAddInfinity(): boolean {
    return !this.ageSplit.find((x) => {
      return x == Infinity;
    });
  }

  removeAge(idx: number) {
    this.ageSplit.splice(idx, 1);
  }

  addNewAge() {
    if (this.canAddAge) {
      this.ageSplit.push(parseInt(this.newAge));
      this.ageSplit.sort((x, y) => {
        return x > y ? 1 : -1;
      });
      this.newAge = "";
    }
  }

  addInfinity() {
    this.ageSplit.push(Infinity);
  }

  update() {
    this.$emit("input", this.splitClasses);
  }

  @Watch("splitClasses")
  onSplitClassesChange(x: Array<DogClass>) {
    this.$emit("input", x);
  }

  colorSearch: string = "";
  get filteredColors(): Array<Color> {
    if (this.colorSearch && this.colorSearch.trim().length > 1) {
      let fuzzy = new fuzzySearch((x: Color) => {
        return x.color;
      }, []);
      fuzzy.input = this.colors;
      fuzzy.search = this.colorSearch;
      let out: Array<Color> = fuzzy.output;
      for (let color of this.colorSplit) {
        if (
          !out.find((x) => {
            return x.id == color.id;
          })
        ) {
          out.push(color);
        }
      }
      return out;
    } else {
      return this.colors;
    }
  }
}
