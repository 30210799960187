
import { Dog, RetireDogRequest, ShowEntry } from "@app/common";
import { Component, Vue, Prop } from "vue-property-decorator";
import UserStore from "../store/modules/user";
import DogStore from "../store/modules/dog";
import DogInfo from "./DogInfo.vue";
import ExpansionList from "./ExpansionList.vue";
import SafetyButton from "./SafetyButton.vue";
import DogEntries from "./DogEntries.vue"
@Component({
  components: {
    DogInfo,
    ExpansionList,
    SafetyButton,
    DogEntries,
  },
})
export default class DogList extends Vue {
  @Prop() readonly dogs!: Array<Dog>;
  @Prop({ default: null }) readonly show!: number;
  deleteDogModal: boolean = false;
  deleteDog: Dog | undefined = undefined;
  retireDog: boolean = true;
  entriesModal : boolean = false;
  entriesDog : Dog | null = null

  deleteText(dog: Dog): string {
    let entries: Array<ShowEntry> = DogStore.entries;
    if (
      entries.find((x) => {
        if (x.showDog.dog && x.showDog.dog.id == dog.id) {
          return true;
        } else {
          return false;
        }
      })
    ) {
      return "Retire";
    } else {
      return "Delete";
    }
  }

  remove(dog: Dog) {
    this.deleteDog = dog;
    let entries: Array<ShowEntry> = DogStore.entries;
    this.retireDog = !!entries.find((x) => {
      if (x.showDog.dog && x.showDog.dog.id == dog.id) {
        return true;
      } else {
        return false;
      }
    });
    this.deleteDogModal = true;
  }

  async removeConfirmed() {
    if (this.deleteDog && this.deleteDog.id) {
      let payload: RetireDogRequest = {
        dog: this.deleteDog?.id,
        csrfSecurityToken: UserStore.csrfSecurityToken,
      };
      DogStore.retireDog(payload)
      if(!DogStore.retireDogError){
        this.deleteDogModal = false
        DogStore.fetchDogData()
      }else{
        console.log(DogStore.retireDogError)
      }
    }
  }

  get retiringDog():boolean{
    return DogStore.retiringDog
  }

  enter(dog: Dog) {
    DogStore.setSelectedDog(dog);
    this.$router.push("/enter-dog/new");
  }

  edit(dog: Dog) {
    DogStore.setSelectedDog(dog);
    this.$router.push("/dog-form/edit");
  }

  isEntered(dog: Dog): boolean {
    if (this.show !== null) {
      return (
        DogStore.entries.filter((x) => {
          return x.show.id == this.show && x.showDog.dog.id == dog.id && !x.allRefunded;
        }).length > 0
      );
    } else {
      return false;
    }
  }

  viewEntries(x : Dog){
    
    this.entriesDog = x
    this.entriesModal = true
  }
}
