
import { SquareToken } from "@app/common";
import { Component, Vue } from "vue-property-decorator";
import Loading from "../Loading.vue";
import DogSquareTokens from "../DogSquareTokens.vue";
import NewShowStore from "../../store/modules/newshow";
@Component({ components: { DogSquareTokens, Loading } })
export default class NewShowSquare extends Vue {

  validate(): Array<string> {
    let out : Array<string> = []
    if(NewShowStore.squareToken<0){
      out.push("You must select a Square token.")
    }
    return out;
  }
}
