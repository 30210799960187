
import { Component, Vue, Prop } from "vue-property-decorator";
import Loading from "./Loading.vue";
import ExpansionList from "./ExpansionList.vue";
import DogEntry from "./DogEntry.vue";
import DogInfo from "./DogInfo.vue";
import SafetyButton from "../components/SafetyButton.vue";
import DogEntriesAlert from "./DogEntriesList/DogEntriesAlert.vue"
import ShowDogInfo from "./DogEntriesList/ShowDogInfo.vue"
import DogStore from "../store/modules/dog";
import UserStore from "../store/modules/user"
import ShowStore from "../store/modules/show";
import { Dog, Entry, RemoveEntryRequest, Show, ShowEntry } from "@app/common";
@Component({
  components: {
    ExpansionList,
    Loading,
    DogInfo,
    DogEntry,
    SafetyButton,
    DogEntriesAlert,
    ShowDogInfo,
  },
})
export default class DogEntries extends Vue {
  @Prop({ default: null }) readonly show!: number | null;
  @Prop({ default: false }) readonly paymentMode!: boolean;
  @Prop({ default: null }) readonly dog!: number | null;

  showDogInfo = false;
  infoDog: ShowEntry | null = null;
  payEntry: Array<boolean> = [];
  removeShowEntry: ShowEntry | null = null;
  removeEntryModal: boolean = false;

  get loading(): boolean {
    return DogStore.fetchingDogData;
  }

  get removeEntryDogName(): string {
    if (this.removeShowEntry && this.removeEntryModal) {
      return this.removeShowEntry.showDog.showDog.dog.printName();
    } else {
      return "";
    }
  }

  get showRefunded():boolean{
    return this.show == null
  }

  get showEntries(): Array<ShowEntry> {
    if (this.show !== null) {
      if (!this.paymentMode) {
        return DogStore.entries.filter((x) => {
          return x.show.id == this.show && !x.allRefunded
        });
      } else {
        return DogStore.entries.filter((x) => {
          return x.unpaidEntries.length > 0 && x.show.id == this.show
          // let atLeastOneUnpaid: boolean = false;
          // for (let entry of x.entries) {
          //   if (!entry.paid) {
          //     atLeastOneUnpaid = true;
          //     break;
          //   }
          // }
          // return x.show.id == this.show && atLeastOneUnpaid;
        });
      }
    } else if (this.dog !== null) {
      return DogStore.entries.filter((x) => {return x.showDog.dog.id == this.dog})
    }else {
      return DogStore.entries;
    }
  }

  get secretaryFee():number{
    return ShowEntry.secretaryFee(this.showEntries)
  }

  entriesOpen(show: Show): boolean {
    return (new Date().getTime()) <= show.closingTime.getTime();
  }

  async mounted() {
    if (DogStore.dogDataNeedsUpdating && !DogStore.fetchingDogData) {
      await DogStore.fetchDogData();
    }
    this.payEntry = [];
    for (let showEntry of this.showEntries) {
      this.payEntry.push(true);
    }
    this.updatePaymentEntries();
  }

  updatePaymentEntries() {
    let paymentEntries: Array<ShowEntry> = [];
    for (let k = 0; k < this.showEntries.length; k++) {
      if (this.payEntry[k]) {
        paymentEntries.push(this.showEntries[k]);
      }
    }
    this.$emit("payment", paymentEntries);
  }

  searchShows() {
    this.$router.push("/shows");
  }

  dogInfo(showEntry: ShowEntry) {
    this.infoDog = showEntry
  }

  editDog(showEntry: ShowEntry) {
    let dog: Dog = showEntry.showDog.dog;
    DogStore.setSelectedDog(dog);
    this.$router.push("/dog-form/edit");
  }

  removeEntry(showEntry: ShowEntry) {
    this.removeShowEntry = showEntry;

    this.removeEntryModal = true;
  }

  get removingEntries(): boolean{
    return DogStore.removingEntry
  }

  async removeEntries() {
    if (this.removeShowEntry && this.removeShowEntry.showDog.dog.id && this.removeShowEntry.show.id) {
      let payload: RemoveEntryRequest = {
        dog: this.removeShowEntry.showDog.dog.id,
        show : this.removeShowEntry.show.id,
        entries : this.removeShowEntry.entries.filter((x) => {return !x.paid}).map((x) => {return x.exportInterface()}),
        csrfSecurityToken : UserStore.csrfSecurityToken
      };
      await DogStore.removeEntry(payload);
      if(!DogStore.removeEntryError){
        await DogStore.fetchDogData()
        this.removeEntryModal = false
      }
    }
  }

  changeClasses(showEntry : ShowEntry){
    DogStore.setSelectedDog(showEntry.showDog.dog)
    DogStore.setSelectedShowEntry(showEntry)
    this.$router.push("/enter-dog/edit")
  }

  test() {
    let test: ShowEntry = this.showEntries[0];
    this.showEntries[0];
    this.showEntries[0].entries[0].classes[0].dogClass.name;
    this.showEntries[0].entries[0].event.printDateRange;
    this.showEntries[0].showDog.showDog.dog.printName();
  }
}
