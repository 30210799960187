import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Splash from "../views/Splash.vue";
import ResetPassword from "../views/ResetPassword.vue";
import UserProfile from "../views/UserProfile.vue";
import Shows from "../views/Shows.vue";
import Home from "../views/Home.vue";
import ExhibitorDashboard from "../views/ExhibitorDashboard.vue";
import DogForm from "../views/DogForm.vue";
import ShowHome from "../views/ShowHome.vue";
import EnterDog from "../views/EnterDog.vue";
import Payment from "../views/Payment.vue";
import SquareAuthorize from "../views/SquareAuthorize.vue";
import Refund from "../views/Refund.vue";
import Refunds from "../views/Refunds.vue";
import NewShow from "../views/NewShow.vue";
import QRReceipt from "../views/QRReceipt.vue";
import VerifyEmail from "../views/VerifyEmail.vue";
import SecretaryDashboard from "../views/SecretaryDashboard.vue";
import ShowManager from "../views/ShowManager.vue";
import ShowManagerEntries from "../views/ShowManager/ShowManagerEntries.vue";
import ShowManagerPayments from "../views/ShowManager/ShowManagerPayments.vue";
import Catalog from "../views/Catalog.vue";
import Test from "../views/Test.vue";
import Test2 from "../views/Test2.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Splash",
    component: Splash,
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
  },
  {
    path: "/test2",
    name: "Test2",
    component: Test2,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/user-profile",
    name: "User Profile",
    component: UserProfile,
  },
  {
    path: "/shows",
    name: "Shows",
    component: Shows,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/dashboard/exhibitor",
    name: "Exhibitor Dashboard",
    component: ExhibitorDashboard,
  },
  {
    path: "/dashboard/secretary",
    name: "Secretary Dashboard",
    component: SecretaryDashboard,
  },
  {
    path: "/dog-form/:mode",
    name: "Dog Form",
    component: DogForm,
  },
  {
    path: "/dog-form/:mode/show/:abbr",
    name: "Dog Form",
    component: DogForm,
  },
  {
    path: "/dog-form/:mode/show/:abbr/:secretary",
    name: "Dog Form",
    component: DogForm,
  },
  {
    path: "/show/:abbr",
    name: "Show Home",
    component: ShowHome,
  },
  {
    path: "/enter-dog/:mode",
    name: "Enter dog",
    component: EnterDog,
  },
  {
    path: "/enter-dog/:mode/:secretary",
    name: "Enter dog",
    component: EnterDog,
  },
  {
    path: "/payment/:abbr",
    name: "Payment",
    component: Payment,
  },
  {
    path: "/refund/:abbr",
    name: "Refund",
    component: Refund,
  },
  {
    path: "/refunds/:abbr",
    name: "Refunds",
    component: Refunds,
  },
  {
    path: "/square-authorize",
    name: "SquareAuthorize",
    component: SquareAuthorize,
  },
  {
    path: "/square-authorize/:callback",
    name: "SquareAuthorize",
    component: SquareAuthorize,
  },
  {
    path: "/new-show",
    name: "NewShow",
    component: NewShow,
  },
  {
    path: "/qr",
    name: "QRReceipt",
    component: QRReceipt,
  },
  {
    path: "/verify-email",
    name: "VerifyEmail",
    component: VerifyEmail,
  },
  {
    path: "/show-manager/:abbr",
    name: "ShowManager",
    component: ShowManager,
  },
  {
    path: "/show-manager/:abbr/catalog",
    name: "Catalog",
    component: Catalog,
  },
  {
    path: "/show-manager/:abbr/payments",
    name: "ShowManagerPayments",
    component: ShowManagerPayments,
  },
  {
    path: "/show-manager/:abbr/entries",
    name: "ShowManagerEntries",
    component: ShowManagerEntries,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
