
import { UserForgotRequest } from "@app/common";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import UserStore from "../../store/modules/user";
@Component
export default class EmailWindow extends Vue {
  @Prop(String) readonly email!: string;
  forgotEmail = "";
  forgotSubmitted = false;
  forgotError = false;
  forgotReady = false;

  @Watch("forgotReady")
  onForgotReadyChange(val: boolean) {
    this.$emit("ready", val);
  }

  @Watch("email")
  onEmailChange(val: string) {
    this.forgotEmail = val;
  }

  get rules() {
    return {
      emailRequired: (x: string) => {
        return !x || x.trim() === "" ? "Your email is required" : true;
      },
    };
  }

  async requestReset() {
    let cleanEmail = this.forgotEmail.trim().toLowerCase();
    let payload: UserForgotRequest = {
      email: cleanEmail,
    };
    await UserStore.requestPasswordReset(payload);
    if (UserStore.requestingForgotError) {
      this.forgotError = true;
    }
    this.forgotSubmitted = true;
  }

  mounted() {
    this.forgotEmail = this.email;
  }
}
