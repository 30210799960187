
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class DogFee extends Vue {
  @Prop({ default: 0 }) readonly value!: number;
  @Prop({ default: "Fee" }) readonly label!: string;
  fee: number = 0;
  feeString: string = "";

  updateFee() {
    let reg = this.feeString.match(/\d+\.*\d{0,2}/);
    if (!reg) {
      this.$nextTick(() => {
        this.feeString = "";
      });
    } else {
      let word = reg[0];
      let words = word.split(".");
      if (words.length > 1) {
        this.$nextTick(() => {
          this.feeString = words[0] + "." + words[1].substring(0, 2);
        });
      } else {
        this.$nextTick(() => {
          this.feeString = words[0];
        });
      }
    }
  }

  changeFee() {
    let words = this.feeString.split(".")
        if(words.length == 1){
            this.$nextTick(()=>{
                this.feeString = words[0] + ".00"
            })
        }else if(words[1].length == 0){
            this.$nextTick(()=>{
                this.feeString = words[0] + ".00"
            })
        }else if(words[1].length == 1){
            this.$nextTick(()=>{
                this.feeString = words[0] + "." + words[1]+"0"
            })
        }else{
            this.$nextTick(()=>{
                this.feeString = words[0] + "." + words[1].substring(0,2)
            })
        }
  }

  @Watch("feeString")
  onFeeStringChange(){
    this.fee = Math.round(parseFloat(this.feeString)*100)
  }

  @Watch("fee")
  onFeeChanged(){
    this.$emit("input",this.fee)
  }

  @Watch("value")
  onValueChange(x: number) {
    this.fee = x;
    this.feeString = (x/100).toFixed(2)
  }
}
