export class User {

    public static userFromJSON(x:UserInterface): User{
        return new User(x.id,x.firstName,x.lastName,x.email,x.phone)
    }
    public static userArrayFromJSON(x : Array<UserInterface>): Array<User>{
        return x.map((y) => {return User.userFromJSON(y)})
    }

    private _id: number | null
    private _firstName: string
    private _lastName: string
    private _email: string
    private _phone: string | null

    public get id(): number | null {
        return this._id
    }
    //Do not set id
    public get firstName(): string {
        return this._firstName
    }
    public set firstName(value: string) {
        this._firstName = value
    }
    public get lastName(): string {
        return this._lastName
    }
    public set lastName(value: string) {
        this._lastName = value
    }
    public get email(): string {
        return this._email
    }
    public set email(value: string) {
        this._email = value
    }
    public get phone(): string | null {
        return this._phone
    }
    public set phone(value: string | null) {
        this._phone = value
    }


    constructor()
    constructor(id: number | null, firstName: string, lastName: string, email: string, phone: string | null)
    constructor(id?: number | null, firstName?: string, lastName?: string, email?: string , phone?: string | null){
        this._id = id || null
        this._firstName = firstName || ''
        this._lastName = lastName || ''
        this._email = email || ''
        this._phone = phone || null
    }
    get fullName(){
        return this._firstName + " " + this._lastName
    }

}

class Secretary extends User {

}

export interface UserInterface {
    id: number | null
    firstName: string
    lastName: string
    email: string
    phone: string | null
}

export class PrivateMessage {

    public static PrivateMessageFromJSON(){
        
    }

    private _id: number | null
    private _receipent: User
    private _sender: User
    private _message: string
    private _time: Date
    private _read: boolean

    get id(): number | null{
        return this._id
    }

    constructor(id: number, receipent: User, sender: User, message: string, time: Date, read: boolean){
        this._id = id
        this._receipent = receipent
        this._sender = sender
        this._message = message
        this._time = time
        this._read = read
    }
}

export interface PrivateMessageInterface{
    id: number,
    receipent: UserInterface,
    sender: UserInterface,
    message: string,
    time: string,
    read: boolean
}