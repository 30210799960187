import { Breed, BreedDataInterface, Color, DogInterface, Group, Variety } from './dog'
import { PersonInterface, AddressInterface, JudgeInterface } from './people'
import { DogEventInterface, ShowDogBaseInterface, ShowDogInterface, ShowInterface } from './show'
import { EventClassInterface, ShowEntryInterface } from './entries'
import { PaymentInterface, PaymentType, RefundInterface, RefundStatus } from './payment'
import { DogClass, DogClassInterface } from './classes'
import { ShowPermissions } from './secretary'
import { UserInterface } from './user'

export enum GeneralErrors{
    AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
    GENERAL_ERROR = 'GENERAL_ERROR',
}


export interface ActionResponse{
    csrfSecurityToken : string
}

export interface UserInfo {
    firstName : string,
    lastName : string,
    email : string,
}

export interface NewCSRFResponse{
    success: boolean,
    csrfSecurityToken : string
}

export interface AuthenticationResponse extends ActionResponse{
    success: boolean,
    authenticated: boolean,
    userInfo? : UserInfo
}

export interface FetchDogs {
    success: boolean,
    dogs: Array<DogInterface>,
    breeders: Array<PersonInterface>,
    owners: Array<PersonInterface>,
    addresses: Array<AddressInterface>
}

export interface FetchEntries{
    success: boolean,
    entries: Array<[{show: ShowInterface, entries: Array<ShowEntryInterface>}]>
}

export interface FetchPayments{
    success: boolean,
    payments: Array<PaymentInterface>
    refunds: Array<RefundInterface>
}

export enum PaymentErrors{
    ADDRESS_VERIFICATION_FAILURE = 'ADDRESS_VERIFICATION_FAILURE',
    BAD_EXPIRATION = 'BAD_EXPIRATION',
    CARD_EXPIRED = 'CARD_EXPIRED',
    INVALID_INFO = 'INVALID_INFO',
    CVV_FAILURE = 'CVV_FAILURE',
    GENERIC_DECLINE = 'GENERIC_DECLINE',
    SQUARE_TOKEN_ERROR = 'SQUARE_TOKEN_ERROR',
    PAYMENT_ERROR = 'PAYMENT_ERROR',
    AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR',
    RECORDING_ERROR = 'RECORDING_ERROR',
    RECEIPT_ERROR = 'RECEIPT_ERROR',
    PAYMENT_EMAIL_ERROR = 'PAYMENT_EMAIL_ERROR',
    TIMEOUT_ERROR = 'TIMEOUT_ERROR',
    TRANSACTION_LIMIT = 'TRANSACTION_LIMIT',
    UNRESOLVED_PAYMENT = 'UNRESOLVED_PAYMENT',
    COMPLETION_ERROR = 'COMPLETION_ERROR',
    GENERAL_PAYMENT_ERROR = 'GENERAL_PAYMENT_ERROR',
    NO_ONLINE_CATALOGS = 'NO_ONLINE_CATALOGS'
}

export interface PaymentResponse extends ActionResponse{
    success: boolean,
    authenticated: boolean,
    errors: Array<PaymentErrors | GeneralErrors>,
    paymentExecuted: boolean,
    receipt: number
}

export interface PayByCheckResponse extends ActionResponse{
    success: boolean,
    authenticated: boolean,
    errors: Array<PaymentErrors | GeneralErrors>
}

export interface RefundResponse extends ActionResponse{
    success: boolean,
    authenticated: boolean,
    errors: Array<RefundErrors | GeneralErrors>,
    status: RefundStatus,
    requestExecuted: boolean,
    receipt: number,
}

export enum RefundErrors{
    SQUARE_TOKEN_ERROR = 'SQUARE_TOKEN_ERROR',
    RECORDING_ERROR = 'RECORDING_ERROR',
    RECEIPT_ERROR = 'RECEIPT_ERROR',
    REFUND_EMAIL_ERROR = 'REFUND_EMAIL_ERROR',
    GENERAL_REFUND_ERROR = 'GENERAL_REFUND_ERROR',
    SHOW_DEADLINE_EXPIRED = 'SHOW_DEADLINE_EXPIRED',
    REFUND_FAILED = 'REFUND_FAILED'
}

export enum DogErrors{
    ADDRESS_OWNERSHIP = 'ADDRESS_OWNERSHIP',
    PEOPLE_OWNERSHIP = 'PEOPLE_OWNERSHIP',
    INVALID_EXISTING_ENTRIES = 'INVALID_EXISTING_ENTRIES',
    DOG_OWNERSHIP = 'DOG_OWNERSHIP'
}

export interface DogResponse{
    succes: boolean,
    errors: Array<DogErrors | GeneralErrors>
    dog: number
}

export enum EntryErrors{
    DOG_OWNERSHIP = 'DOG_OWNERSHIP',
    FAILED_RULE_CHECK = 'FAILED_RULE_CHECK',
    DEADLINE_PASSED = 'DEADLINE_PASSED',
    ENTRIES_NOT_OPEN = 'ENTRIES_NOT_OPEN',
    EXISTING_ENTRY = 'EXISTING_ENTRY',
    EXISTING_PAID_ENTRY = 'EXISTING_PAID_ENTRY',
    ENTRY_DOES_NOT_EXIST = 'ENTRY_DOES_NOT_EXIST',
    SHOW_MISMATCH = 'SHOW_MISMATCH',
    NO_ENTRIES = 'NO_ENTRIES'
}

export interface EntryResponse{
    success: boolean,
    errors: Array< EntryErrors | GeneralErrors>
}

export interface LoginResponse extends ActionResponse{
    success: boolean,
    errors: Array<GeneralErrors.GENERAL_ERROR | 'INVALID_EMAIL_OR_PASSWORD' | 'FAILED_BRUTE_CHECK' | 'EMAIL_NOT_VALIDATED' >,
    userInfo?: {email: string, firstName: string, lastName: string} 
}

export interface LogoutResponse extends ActionResponse{
    success: boolean,
    errors: Array<GeneralErrors>
}


export interface SignupResponse extends ActionResponse{
    success: boolean,
    errors: Array<GeneralErrors.GENERAL_ERROR | 'EMAIL_IN_USE' | 'INVALID_EMAIL'>
}

export interface UserForgotResponse{
    success: boolean,
}

export interface VerifyResetTokenResponse{
    success: boolean,
    errors: Array<GeneralErrors.GENERAL_ERROR | 'INVALID_OR_EXPIRED_TOKEN' | 'REQUEST_DOES_NOT_EXIST'>,
    email: string,
    user?: number,
}

export interface ResetPasswordResponse{
    success: boolean,
    errors: Array<GeneralErrors.GENERAL_ERROR | 'INVALID_OR_EXPIRED_TOKEN' | 'REQUEST_DOES_NOT_EXIST'>,
}

export interface ChangePasswordResponse extends ActionResponse{
    success: boolean,
    authenticated: boolean,
    errors: Array<GeneralErrors | 'INCORRECT_PASSWORD'>
}

export interface VerifyEmailResponse{
    success: boolean,
    errors: Array<GeneralErrors | 'VALIDATION_REQUEST_DOES_NOT_EXIST'>
}

export interface RequestVerificationEmailResponse{
    success: boolean,
    errors: Array<GeneralErrors.GENERAL_ERROR | 'NO_SUCH_EMAIL' | 'TOO_MANY_REQUESTS'>
}

export interface AddDogResponse extends ActionResponse{
    success: boolean,
    authenticated: boolean,
    dog : number,
    errors: Array<GeneralErrors>
}

export interface EditDogResponse extends ActionResponse{
    success: boolean,
    authenticated: boolean,
    errors: Array<GeneralErrors | 'DOG_OWNERSHIP' | 'PROBLEM_ENTRIES'>,
    problemEntries: Array<{entry: number, paid: boolean, problemEventClasses: Array<{eventClass: number, classEntry: number}>}>
}

export interface CheckEditDogResponse{
    success: boolean,
    authenticated: boolean,
    errors: Array<GeneralErrors | 'DOG_OWNERSHIP'>,
    problemEntries: Array<{entry: number, paid: boolean, problemEventClasses: Array<{eventClass: number, classEntry: number}>}>
}

export interface RetireDogResponse extends ActionResponse{
    success: boolean,
    authenticated: boolean,
    errors: Array<GeneralErrors | 'DOG_OWNERSHIP' | 'ACTIVE_PAID_ENTRIES'>
    activeEntries: Array<number>
}

export interface EnterDogResponse extends ActionResponse{
    success: boolean,
    authenticated: boolean,
    errors: Array<GeneralErrors | EntryErrors>
}

export interface EditEntryResponse extends ActionResponse{
    success: boolean,
    authenticated: boolean,
    errors: Array<GeneralErrors | EntryErrors>
}

export interface RemoveEntryResponse extends ActionResponse{
    success: boolean,
    authenticated: boolean,
    errors: Array<GeneralErrors | EntryErrors>
}

export interface FetchDogDataResponse{
    success: boolean
    authenticated: boolean
    errors: Array<GeneralErrors>
    dogs : Array<DogInterface>
    addresses: Array<AddressInterface>
    people: Array<PersonInterface>
    breeders: Array<number>
    owners: Array<number>
    entries: Array<ShowEntryInterface>
    shows: Array<ShowInterface>
    classes: Array<DogClassInterface>
    eventClasses: Array<EventClassInterface>
    showDogs: Array<ShowDogInterface>
    showDogsEdits: Array<ShowDogInterface>
    judges: Array<JudgeInterface>
    user: UserInterface
    //show dogs
    //show dogs edits
    //entry edits
}

export interface SquareAuthResponse extends ActionResponse{
    success: boolean,
    authenticated: boolean,
    errors: Array<GeneralErrors>,
    url : string
}

export interface SquareTokenDataResponse{
    success: boolean,
    authenticated: boolean,
    error?: number,
    tokens : Array<{id: number, expiration : number, merchant : string, shows: Array<number>}>
}

export interface SquareTokenRemoveResponse{
    success: boolean,
    authenticated: boolean,
    error?: number
}


export interface SquareTokenRemoveResponse{
    success: boolean,
    authenticated: boolean,
    error?: number
}

export interface PaymentDataResponse{
    success: boolean,
    authenticated: boolean,
    payments: Array<PaymentInterface>
    refunds: Array<RefundInterface>
}

export interface ShowListResponse{
    success: boolean,
    shows: Array<ShowInterface>
}

export interface ShowDataResponse{
    success: boolean,
    show?: ShowInterface,
    eventClasses: Array<EventClassInterface>,
    dogClasses: Array<DogClassInterface>,
    judges: Array<JudgeInterface>
}


export interface BreedDataResponse{
    success: boolean,
    breeds: Array<Breed>,
    varieties: Array<Variety>,
    colors: Array<Color>,
    groups: Array<Group>,
    breedData: Array<BreedDataInterface>
}

export interface IdempotencyKeyResponse{
    success : boolean,
    idempotencyKey : string
}

export enum QRErrors {
    NOT_FOUND = 'NOT_FOUND'
}

export interface QRReceiptResponse{
    success : boolean,
    amount: number,
    surcharge: number,
    catalogFee: number,
    catalog: boolean,
    entries: Array<{dogName: string, event: DogEventInterface, classes: Array<{name : string, fee : number}>}>
    showName: string,
    time: number,
    id: number,
    qr: string,
    lastFour?: string,
    paymentType?: PaymentType
    type : 'PAYMENT' | 'REFUND'
    userInfo? : {email : string, firstName : string, lastName : string}
    errors : Array<GeneralErrors | QRErrors>
}



// export interface SecretaryShowDataResponse{
//     success: boolean,
//     authenticated: boolean,
//     showPermissions: ShowPermissions,
//     showDogs: Array<ShowDogInterface>,
//     entries: Array<ShowEntryInterface>,
//     payments: Array<PaymentInterface>,
//     refunds: Array<RefundInterface>
// }

// export interface SecretaryEnterDogResponse{
//     success: boolean,
//     authenticated: boolean,
// }