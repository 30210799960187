
import { Address } from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import SmartAddress from "../../SmartAddress.vue";

@Component({components: {SmartAddress}})
export default class DogAddressSmart extends Vue {
  @Prop() readonly value!: Address;
  address: Address = new Address();
  zipCode = ""

  cleanZip(): void{
    this.$nextTick(()=>{
      this.zipCode = this.zipCode.replace(/[^0-9]/g, "")
    })
  }
  
  update(x : Address) : void{
    this.address = x
    this.$emit("input",this.address)
  }

  validate(): Array<string>{
    let out = []
    let test : boolean = this.address.street.length == 0 || this.address.city.length == 0 || this.address.state.length == 0 || this.address.postal.length == 0
    if(test){
      out.push("You must have an address.")
    }
    return out
  }
}
