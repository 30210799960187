
import { SquareAuthRequest } from "@app/common";
import { Component, Vue } from "vue-property-decorator";
import UserStore from "../store/modules/user"
import PaymentStore from "../store/modules/payment"
@Component
export default class SquareAuthorize extends Vue {
  page: number = 0;
  get loading(): boolean{
      return PaymentStore.fetchingSquareAuthorizationRequest
  }
  get url(): string{
      return PaymentStore.squareAuthorizationUrl
  }


  async mounted() {
    console.log(this.$route.params);

    if (this.$route.params.callback == "success") {
      this.page = 1;
    } else if (this.$route.params.callback == "failure") {
      this.page = 2;
    } else {
      this.page = 0;
      let payload : SquareAuthRequest ={
          csrfSecurityToken : UserStore.csrfSecurityToken
      }
      await PaymentStore.fetchSquareAuthorization(payload)
      console.log(PaymentStore.squareAuthorizationError)
      if(PaymentStore.squareAuthorizationError){
          this.page = 4
      }
    }
  }

  openLink() {
    window.open(this.url);
  }
}
