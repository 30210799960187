
import { Component, Vue } from "vue-property-decorator";
import Loading from "../components/Loading.vue";
import DogEntries from "../components/DogEntries.vue";
import DogList from "../components/DogList.vue";
import DogPayments from "../components/DogPayments.vue";
import DogRefunds from "../components/DogRefunds.vue";
import DogFooter from "../components/DogFooter.vue";
import UserStore from "../store/modules/user";
import DogStore from "../store/modules/dog";
import ShowStore from "../store/modules/show";
import PaymentStore from "../store/modules/payment";
import { Dog, DogEvent, Judge, Show, ShowDataRequest } from "@app/common";

@Component({
  components: {
    Loading,
    DogEntries,
    DogList,
    DogPayments,
    DogRefunds,
    DogFooter,
  },
})
export default class ShowHome extends Vue {
  get show(): Show | undefined {
    return ShowStore.show;
  }

  get loading(): boolean {
    return ShowStore.fetchingShowData;
  }

  get loggedIn(): boolean {
    return UserStore.loggedIn;
  }

  get fee(): number {
    if (this.show && this.show.id) {
      let showId = this.show.id;
      let entries = DogStore.entries.filter((x) => {
        return x.show.id == showId;
      });
      let fee = 0;
      for (let entry of entries) {
        fee += entry.feeRemaining;
      }
      return fee;
    }
    return 0;
  }

  get suspended(): boolean {
    return !!this.show && this.show.suspended;
  }

  async mounted() {
    let payload: ShowDataRequest = {
      abbr: this.$route.params.abbr,
    };
    await ShowStore.fetchShowData(payload);
    await ShowStore.setSelectedShow(ShowStore.show);
    // if(this.show){
    //    this.eventClasses(this.show?.events[0])[0].dogClass.name
    // }
  }

  signup() {
    UserStore.setSignupModal(true);
  }
  login() {
    UserStore.setLoginModal(true);
  }

  eventClasses(event: DogEvent) {
    return ShowStore.eventClasses.filter((x) => {
      return x.event.id == event.id;
    });
  }

  judges(): Array<Judge> {
    return ShowStore.judges;
  }

  get entriesClosed(): boolean {
    if (ShowStore.show) {
      return ShowStore.show.closingTime.getTime() <= new Date().getTime();
    } else {
      return false;
    }
  }

  get entriesOpened(): boolean {
    if (ShowStore.show) {
      return ShowStore.show.openingTime.getTime() < new Date().getTime();
    } else {
      return false;
    }
  }

  get dogs(): Array<Dog> {
    return DogStore.dogs;
  }

  get showPayments(): boolean {
    if (this.show) {
      let showId = this.show.id;
      return (
        PaymentStore.payments.filter((x) => {
          return x.show.id === showId;
        }).length > 0
      );
    }
    return false;
  }

  get showRefunds(): boolean {
    if (this.show) {
      let showId = this.show.id;
      return (
        PaymentStore.refunds.filter((x) => {
          return x.show.id === showId;
        }).length > 0
      );
    }
    return false;
  }

  get canRefund(): boolean {
    if (this.show) {
      let showId: number | null = this.show.id;
      let refundableEntries = DogStore.entries.filter((x) => {
        let paidAndNotRefund: boolean = false;
        for (let entry of x.entries) {
          if (entry.paid && !entry.refunded) {
            paidAndNotRefund = true;
            break;
          }
        }
        return x.show.id == showId && paidAndNotRefund;
      });
      return refundableEntries.length > 0;
    } else {
      return false;
    }
  }

  enterDialog = false;
  classDialog = false;
}
