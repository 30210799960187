import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "..";
import { fetchErrorMessage, generalErrorMessage } from "../error";
import { DogClass } from "@app/common/classes";
import UserStore from "./user";
import { Address, Entry, EventClass, GeneralErrors, Judge, Payment, Person, Refund, SecretaryEntryDataRequest, SecretaryEntryDataResponse, SecretaryErrors, SecretaryFetchClassesResponse, SecretaryPaymentDataRequest, SecretaryPaymentDataResponse, SecretaryShowDataRequest, SecretaryShowDataResponse, SecretaryShowInterfaceRequest, SecretaryShowInterfaceResponse, SecretaryShowsResponse, Show, ShowDog, ShowEntry, ShowEntryInterface, User } from "@app/common";
import { Ref } from "vue-property-decorator";

@Module({ name: "SecretaryStore", dynamic: true, store, namespaced: true })
class SecretaryStore extends VuexModule {
  private _shows: Array<Show> = []
  private _fetchingShows: boolean = false
  private _selectedShow: Show | undefined = undefined
  private _permissions: { readEntries: boolean, writeEntries: boolean, readPayments: boolean, writePayments: boolean } = { readEntries: false, writeEntries: false, readPayments: false, writePayments: false }
  get shows(): Array<Show> {
    return this._shows
  }
  @Mutation
  setShows(x: Array<Show>) {
    this._shows = x
  }
  get fetchingShows(): boolean {
    return this._fetchingShows
  }
  @Mutation
  setFetchingShows(x: boolean) {
    this._fetchingShows = x
  }
  get selectedShow(): Show | undefined {
    return this._selectedShow
  }
  @Mutation
  setSelectedShow(x: Show | undefined) {
    this._selectedShow = x
  }
  get permissions(): { readEntries: boolean, writeEntries: boolean, readPayments: boolean, writePayments: boolean } {
    return this._permissions
  }
  @Mutation
  setPermissions(x: { readEntries: boolean, writeEntries: boolean, readPayments: boolean, writePayments: boolean }) {
    this._permissions = x
  }

  @Action
  async fetchShows() {
    this.setFetchingShows(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/secretary/fetch-shows",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({}),
        credentials: "include",
      }
    );
    this.setFetchingShows(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: SecretaryShowsResponse = await response.json();
      if (data.success) {
        this.setShows(Show.showArrayFromJSON(data.shows))
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
      }
    }
  }
  private _fetchingShowByAbbr : boolean = false
  get fetchingShowByAbbr(): boolean{
    return this._fetchingShowByAbbr
  }
  @Mutation
  setFetchingShowByAbbr(x : boolean){
    this._fetchingShowByAbbr = x
  }

  @Action
  async fetchShow(payload : SecretaryShowInterfaceRequest) {
    this.setFetchingShowByAbbr(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/secretary/fetch-show",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
      }
    );
    this.setFetchingShowByAbbr(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: SecretaryShowInterfaceResponse = await response.json();
      if (data.success && data.show) {
        let show : Show = Show.showFromJSON(data.show);
        this.setSelectedShow(show)
      }
    }
  }


  private _dogClasses: Array<DogClass> = []
  private _eventClasses: Array<EventClass> = []
  private _judges: Array<Judge> = []
  private _users: Array<User> = []
  private _fetchingShowData: boolean = false
  private _fetchShowDataSuccess : boolean = false

  get dogClasses(): Array<DogClass> {
    return this._dogClasses
  }
  @Mutation
  setDogClasses(x: Array<DogClass>) {
    this._dogClasses = x
  }
  get eventClasses(): Array<EventClass> {
    return this._eventClasses
  }
  @Mutation
  setEventClasses(x: Array<EventClass>) {
    this._eventClasses = x
  }
  get judges(): Array<Judge> {
    return this._judges
  }
  @Mutation
  setJudges(x: Array<Judge>) {
    this._judges = x
  }
  get users(): Array<User> {
    return this._users
  }
  @Mutation
  setUsers(x: Array<User>) {
    this._users = x
  }
  get fetchingShowData(): boolean {
    return this._fetchingShowData
  }
  @Mutation
  setFetchingShowData(x: boolean) {
    this._fetchingShowData = x
  }

  @Mutation
  private setFetchShowDataSuccess(x : boolean){
    this._fetchShowDataSuccess = x
  }

  @Action
  async fetchShowData(payload: SecretaryShowDataRequest) {
    this.setFetchingShowData(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/secretary/fetch-show-data",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      }
    );
    this.setFetchingShowData(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: SecretaryShowDataResponse = await response.json();
      if (data.success) {
        if (this.selectedShow) {
          this.setDogClasses(DogClass.dogClassArrayFromJSON(data.classes))
          this.setJudges(Judge.judgeArrayFromJSON(data.judges))
          this.setEventClasses(EventClass.eventClassArrayFromJSON(data.eventClasses, this.selectedShow.events,this.dogClasses,this.judges))
          this.setUsers(User.userArrayFromJSON(data.users))
          this.setFetchShowDataSuccess(true)
        }

      } else {
        this.setFetchShowDataSuccess(false)
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
      }
    }
  }

  private _showEntries : Array<ShowEntry> = []
  private _entries : Array<Entry> = []
  private _showDogs : Array<ShowDog> =[]
  private _showDogEdits : Array<ShowDog> = []
  private _juniorAddresses : Array<Address> = []
  private _fetchingEntries : boolean = false
  private _fetchEntryError : SecretaryErrors | GeneralErrors | undefined = undefined
  
  get showEntries(): Array<ShowEntry>{
    return this._showEntries
  }
  @Mutation
  setShowEntries(x : Array<ShowEntry>){
    this._showEntries = x
  }
  get entries(): Array<Entry>{
    return this._entries
  }
  @Mutation
  setEntries(x : Array<Entry>){
    this._entries = x
  }
  get showDogs(): Array<ShowDog>{
    return this._showDogs
  }
  @Mutation
  setShowDogs(x : Array<ShowDog>){
    this._showDogs = x
  }
  get showDogEdits(): Array<ShowDog>{
    return this._showDogEdits
  }
  @Mutation
  setShowDogEdits(x : Array<ShowDog>){
    this._showDogEdits = x
  }
  get juniorAddresses() : Array<Address>{
    return this._juniorAddresses
  }
  @Mutation
  setJuniorAddresses(x : Array<Address>){
    this._juniorAddresses = x
  }
  get fetchingEntries():boolean{
    return this._fetchingEntries
  }
  @Mutation
  setFetchingEntries(x : boolean){
    this._fetchingEntries = x
  }
  get fetchEntryError(): SecretaryErrors | GeneralErrors | undefined{
    return this._fetchEntryError
  }
  @Mutation
  setFetchEntryError(x : SecretaryErrors | GeneralErrors | undefined){
    this._fetchEntryError = x
  }


  @Action
  async fetchEntries(payload : SecretaryEntryDataRequest) {
    this.setFetchingEntries(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/secretary/fetch-entries",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      }
    );
    this.setFetchingEntries(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: SecretaryEntryDataResponse = await response.json();
      if (data.success && this.selectedShow) {
        this.setShowDogs(ShowDog.showDogArrayFromJSON(data.showDogs,this.users))
        this.setShowDogEdits(ShowDog.showDogArrayFromJSON(data.showDogEdits,this.users))
        this.setJuniorAddresses(Address.addressArrayFromJSON(data.juniorAddresses))
        this.setShowEntries(ShowEntry.showEntryArrayFromJSON(data.entries,this.showDogs,this.showDogEdits,[this.selectedShow],this.selectedShow.events,this.eventClasses,this.juniorAddresses))
        let entries : Array<Entry> = []
        for(let showEntry of this.showEntries){
          entries = entries.concat(showEntry.entries)
        }
        this.setEntries(entries)
        this.setFetchEntryError(undefined);
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
        this.context.rootState.errorCode = data.errors[0];
        this.setFetchEntryError(data.errors[0]);
      }
    }
  }


  private _payments : Array<Payment> = []
  private _refunds : Array<Refund> = []
  private _fetchingPaymentData : boolean = false
  private _fetchPaymentDataError : SecretaryErrors | GeneralErrors | undefined = undefined

  get payments():Array<Payment>{
    return this._payments
  }
  @Mutation
  setPayments(x : Array<Payment>){
    this._payments = x
  }
  get refunds():Array<Refund>{
    return this._refunds
  }
  @Mutation
  setRefunds(x : Array<Refund>){
    this._refunds = x
  }
  get fetchingPaymentData() : boolean{
    return this._fetchingPaymentData
  }
  @Mutation
  setFetchingPaymentData(x : boolean){
    this._fetchingPaymentData = x
  }
  get fetchPaymentDataError() : SecretaryErrors | GeneralErrors | undefined{
    return this._fetchPaymentDataError
  }
  @Mutation
  setFetchPaymentDataError(x : SecretaryErrors | GeneralErrors | undefined){
    this._fetchPaymentDataError = x
  }


  @Action
  async fetchPaymentData(payload : SecretaryPaymentDataRequest) {
    this.setFetchingPaymentData(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/secretary/fetch-payments",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      }
    );
    this.setFetchingPaymentData(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: SecretaryPaymentDataResponse = await response.json();
      if (data.success && this.selectedShow) {
        this.setPayments(Payment.paymentArrayFromJSON(data.payments,this.entries,[this.selectedShow],this.users))
        this.setRefunds(Refund.refundArrayFromJSON(data.refunds,this.entries,[this.selectedShow],this.payments,this.users))
        this.setFetchPaymentDataError(undefined);
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
        this.context.rootState.errorCode = data.errors[0];
        this.setFetchPaymentDataError(data.errors[0]);
      }
    }
  }

  @Action
  async dogEntryRoutine(payload : {abbr : string}){
    if(!this.selectedShow || !this.selectedShow.id){
        await this.fetchShow(payload)
    }
    if(this.selectedShow && this.selectedShow.id && !this._fetchShowDataSuccess){
        await this.fetchShowData({ show: this.selectedShow.id})
    }
    if(this.selectedShow && this.selectedShow.id){
      await this.fetchEntries({show : this.selectedShow.id})
    }
  }

  @Action
  async paymentRoutine(payload : {abbr: string}){
    await this.dogEntryRoutine(payload)
    if(!this.fetchEntryError && this.selectedShow && this.selectedShow.id){
      await this.fetchPaymentData({show : this.selectedShow.id})
    }
  }

  // private _dogClasses : Array<DogClass> = []
  // get dogClasses(): Array<DogClass>{
  //     return this._dogClasses
  // }
  // @Mutation
  // setDogClasses(x : Array<DogClass>){
  //     this._dogClasses = x
  // }

  // private _fetchingDogClasses : boolean = false
  // get fetchingDogClasses() : boolean{
  //     return this._fetchingDogClasses
  // }
  // @Mutation
  // setFetchingDogClasses(x : boolean){
  //     this._fetchingDogClasses = x
  // }

  // @Action
  // async fetchClasses() {
  //   this.setFetchingDogClasses(true);
  //   const response = await fetch(
  //     process.env.VUE_APP_DOG_API + "/secretary/fetch-classes",
  //     {
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       method: "POST",
  //       body: JSON.stringify({}),
  //       credentials: "include",
  //     }
  //   );
  //   this.setFetchingDogClasses(false);
  //   if (!response.ok) {
  //     this.context.rootState.errorModal = true;
  //     this.context.rootState.errorMsg = fetchErrorMessage;
  //     this.context.rootState.errorCode =
  //       response.status.toString() + " " + response.statusText;
  //   } else {
  //     const data: SecretaryFetchClassesResponse = await response.json();
  //     if (data.success) {
  //       this.setDogClasses(DogClass.dogClassArrayFromJSON(data.classes))
  //     } else {
  //       this.context.rootState.errorMsg = generalErrorMessage;
  //       this.context.rootState.errorModal = true;
  //     }
  //   }
  // }

  get manualShowDogs():Array<ShowDog>{
    return this.showDogs.filter((x)=>{return !x.user})
  }

  get manualBreeders(): Array<Person>{
    let breederNames : Array<string> = this.manualShowDogs.map((x) => {return x.showDog.dog.breeders.map((x) => {return x.name})}).reduce((s,x) => {return s.concat(x)},[])
    let uniqueBreederNames : Array<string> = Array.from(new Set(breederNames))
    return uniqueBreederNames.map((x)=>{return new Person(null,x)})
  }

  get manualOwners(): Array<Person>{
    let ownerNames : Array<string> = this.manualShowDogs.map((x) => {return x.showDog.dog.owners.map((x) => {return x.name})}).reduce((s,x) => {return s.concat(x)},[])
    let uniqueOwnerNames : Array<string> = Array.from(new Set(ownerNames))
    return uniqueOwnerNames.map((x)=>{return new Person(null,x)})
  }

  get manualPeople(): Array<Person>{
    let peopleNames : Array<string> = this.manualBreeders.concat(this.manualOwners).map((x) => {return x.name})
    let uniquePeopleNames : Array<string> = Array.from(new Set(peopleNames))
    return uniquePeopleNames.map((x) => {return new Person(null,x)})
  }

  get manualAddresses(): Array<Address>{
    let addresses : Array<Address> = this.manualShowDogs.map((x) => {return x.showDog.dog.address})
    let out : Array<Address> = []
    for(let address of addresses){
      if(!out.find((x) => {return x.similarAddress(address)})){
        out.push(address)
      }
    }
    return out
  }



  get showAddresses():Array<Address>{
    let out : Array<Address> = []
    for(let show of this._shows){
      if(!out.find((x) => {return x.id == show.address.id})){
        out.push(show.address)
      }
    }
    return out
  }

  get checkAddresses():Array<Address>{
    let out : Array<Address> = []
    for(let show of this._shows){
      if(!out.find((x) => {return x.id == show.checkAddress.id})){
        out.push(show.checkAddress)
      }
    }
    return out
  }
}

export default getModule(SecretaryStore);
