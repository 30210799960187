
import { nanoid } from "nanoid";
import Loading from "../components/Loading.vue";
import DogFooter from "../components/DogFooter.vue";
import UserStore from "../store/modules/user";
import DogStore from "../store/modules/dog";
import PaymentStore from "../store/modules/payment";
import DogPayments from "../components/DogPayments.vue";
import RefundError from "../components/RefundError.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DogEvent,
  Entry,
  GeneralErrors,
  Payment,
  RefundErrors,
  RefundShowRequest,
  Show,
} from "@app/common";
import DogEntries from "@/components/DogEntries.vue";
@Component({
  components: {
    Loading,
    DogFooter,
    DogPayments,
    RefundError,
  },
})
export default class Refunds extends Vue {
  state: number = 0;
  refunds: Array<Entry> = [];
  payment: Payment | null = null;
  refundItems: Array<boolean> = [];
  submitting: boolean = false;

  get loading(): boolean {
    return DogStore.fetchingDogData || DogStore.dogDataNeedsUpdating;
  }
  get showId(): number | undefined {
    let abbr: string | undefined = this.$route.params.abbr;
    let show: Show | undefined = DogStore.shows.find((x) => {
      return x.abbr === abbr;
    });
    if (show && show.id) {
      return show.id;
    } else {
      return undefined;
    }
  }

  get catalogIdx(): number {
    if (this.payment && this.payment.catalogs) {
      return this.payment.entries.length;
    } else {
      return -1;
    }
  }

  get hasCatalog(): boolean {
    if (this.payment && this.payment.catalogs) {
      return true;
    } else {
      return false;
    }
  }

  get total(): number {
    let amount: number = 0;
    if (this.payment) {
      for (let k = 0; k < this.entriesByEventGroup.length; k++) {
        if (this.refundItems[k]) {
          for (let entry of this.entriesByEventGroup[k]) {
            amount += entry.fee+entry.show.secretaryFee;
          }
        }
      }
      if (this.payment.catalogs && this.refundItems[this.catalogIdx]) {
        amount += this.payment.catalogs.amount;
      }
    }
    return amount;
  }

  get catalogFee(): number {
    if (this.payment && this.payment.catalogs) {
      return this.payment.catalogs.amount;
    } else {
      return 0;
    }
  }

  get entries(): Array<Entry> {
    if (this.payment) {
      return this.payment.entries
        .map((x) => {
          return x.entry;
        })
        .filter((y) => {
          return !y.refunded;
        });
    } else {
      return [];
    }
  }

  get eventGroups(): Array<Array<DogEvent>> {
    if (this.payment) {
      return this.entries[0].show.eventGroups;
    } else {
      return [];
    }
  }

  get entriesByEventGroup(): Array<Array<Entry>> {
    if (this.payment) {
      let out: Array<Array<Entry>> = [];
      for (let eventGroup of this.eventGroups) {
        let entries: Array<Entry> = this.entries.filter((x) => {
          return eventGroup.find((y) => {
            return y.id == x.event.id;
          });
        });
        let dogs: Array<number> = [];
        for (let entry of entries) {
          let checkDog: number | undefined = dogs.find((x) => {
            return x == entry.dog.dog.id;
          });
          if (!checkDog && entry.dog.dog.id) {
            let newDogEntries = entries.filter((x) => {
              return x.dog.dog.id == entry.dog.dog.id;
            });
            dogs.push(entry.dog.dog.id);
            console.log(newDogEntries)
            out.push(newDogEntries);
          }
        }
      }
      console.log(this.eventGroups);
      console.log(out);
      return out;
    } else {
      return [];
    }
  }

  setRefundItem(idx: number) {
    this.$set(this.refundItems, idx, !this.refundItems[idx]);
  }

  next(x: Payment) {
    this.refundItems = [];
    this.payment = x;
    for (let k = 0; k < this.entriesByEventGroup.length; k++) {
      this.refundItems.push(false);
    }
    this.$nextTick(() => {
      this.state += 1;
    });
  }

  async refund() {
    if (this.payment && this.payment.id) {
      let entries: Array<number> = [];
      for (let k = 0; k < this.entriesByEventGroup.length; k++) {
        let refundEntries: Array<number | null> = this.entriesByEventGroup[
          k
        ].map((x) => {
          return x.id;
        });
        if (this.refundItems[k]) {
          for (let refundEntry of refundEntries) {
            if (refundEntry !== null) {
              entries.push(refundEntry);
            }
          }
        }
      }
      let catalog = this.refundItems[this.catalogIdx];
      let payload: RefundShowRequest = {
        payment: this.payment.id,
        entries: entries,
        catalog: catalog,
        idempotencyKey: nanoid(),
        csrfSecurityToken: UserStore.csrfSecurityToken,
      };
      this.submitting = true;
      await PaymentStore.makeRefund(payload);
      this.submitting = false;
      this.state = 2;
    }
  }

  get refundErrors(): Array<RefundErrors | GeneralErrors> {
    if (PaymentStore.refundErrors) {
      return PaymentStore.refundErrors;
    } else {
      return [];
    }
  }
}
