var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',[_c('loading',{attrs:{"loading":_vm.loading}},[_c('v-card',[_c('v-card-title',[_vm._v(" Payment summary ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"disable-pagination":"","headers":[
              { text: 'Event', value: 'name' },
              { text: 'Total fees', value: 'totalFees' },
              { text: 'Refunded fees', value: 'refundedFees' },
              { text: 'Dogalog fees', value: 'dogalogFees' },
              { text: 'Net fees', value: 'netFees' },
            ],"items":_vm.dataTableSummaryItems,"item-key":"subId","hide-default-footer":""}}),_vm._v(" Total : "+_vm._s(_vm.totalFees)+" ")],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){_vm.paymentModal = true}}},[_vm._v("View payments")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","transition":"dialog-bottom-transition"},model:{value:(_vm.paymentModal),callback:function ($$v) {_vm.paymentModal=$$v},expression:"paymentModal"}},[_c('show-manager-payments-panel',{on:{"close":function($event){_vm.paymentModal = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }