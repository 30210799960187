
import { DogEvent, Sex } from "@app/common";
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class CatalogWinners extends Vue {
  @Prop({
    default: () => {
      return [];
    },
  })
  events!: Array<DogEvent>;
  @Prop({ default: Sex.MALE }) sex!: Sex;

  get sexName(): string {
    if (this.sex == Sex.MALE) {
      return "Dog";
    } else {
      return "Bitch";
    }
  }

  get sexNamePlural(): string {
    if (this.sex == Sex.MALE) {
      return "Dogs";
    } else {
      return "Bitches";
    }
  }
}
