import {
  Action,
  getModule,
  Module,
  Mutation,
  MutationAction,
  VuexModule,
} from "vuex-module-decorators";
import store from "..";
import { fetchErrorMessage, generalErrorMessage } from "../error";
import { DogClass, DogClassInterface } from "@app/common/classes";
import UserStore from "./user";
import { Address, AddressInterface, Breed, DogEvent, DogEventInterface, EventClass, EventClassInterface, Group, Judge, JudgeInterface, NewEventClassInterface, SecretaryCheckAbbrRequest, SecretaryCheckAbbrResponse, SecretaryClassAndJudgeRequest, SecretaryClassAndJudgeResponse, SecretaryFetchClassesResponse, SecretaryFetchJudgesResponse, SecretaryFetchSquareTokensResponse, SecretaryGenerateAbbrRequest, SecretaryGenerateAbbrResponse, SecretarySubmitShowRequest, SecretarySubmitShowResponse, Show, ShowInterface, ShowType, SquareToken } from "@app/common";

@Module({ name: "NewShowStore", dynamic: true, store, namespaced: true })
class NewShowStore extends VuexModule {
  private _show: ShowInterface = {
    id: -1,
    name: "",
    type: ShowType.OTHER,
    events: [],
    address: new Address().exportInterface(),
    secretary: { id: -1, firstName: "", lastName: "", email: "", phone: null },
    openingTime: -1,
    closingTime: -1,
    catalog: { canPurchase: false, fee: 0 },
    title: "",
    subtitle: "",
    abbr: "",
    checkPayableTo: "",
    checkAddress: new Address().exportInterface(),
    checkRecipient: "",
    secretaryFee: 0,
    surcharge: false,
    suspended: false,
    published: false
  }
  private _eventClasses: Array<Array<EventClass>> = [[]]
  private _judges: Array<Judge> = []
  private _squareToken: number = -1


  get show(): ShowInterface {
    return this._show
  }
  get judges(): Array<Judge> {
    return this._judges
  }
  get eventClasses(): Array<Array<EventClass>> {
    return this._eventClasses
  }
  get squareToken(): number {
    return this._squareToken
  }

  @Mutation
  setShow(x: ShowInterface) {
    this._show = x
  }

  @Mutation
  setEventClasses(x: Array<Array<EventClass>>) {
    console.log("set event classes")
    console.log(x)
    this._eventClasses = x
  }

  @Mutation
  setShowInfo(x: { name: string, webAddress: string, type: ShowType, address: AddressInterface, openingTime: number, closingTime: number, group?: Group, breed?: Breed }) {
    this._show.name = x.name
    this._show.webAddress = (x.webAddress.trim().length > 0 ? x.webAddress.trim() : undefined)
    this._show.type = x.type
    this._show.address = x.address
    this._show.openingTime = x.openingTime
    this._show.closingTime = x.closingTime
    if (x.group) {
      this._show.group = x.group.id
    }
    if (x.breed) {
      this._show.breed = x.breed.id
    }
  }

  private _showInfoReady: boolean = false
  get showInfoReady(): boolean {
    return this._showInfoReady
  }
  @Mutation
  setShowInfoReady(x: boolean) {
    this._showInfoReady = x
  }

  @Mutation
  setEvents(x: Array<DogEventInterface>) {
    this._show.events = x
  }
  @Mutation
  setEventGroups(x: Array<Array<number>>) {
    if (x.length > 0) {
      this._show.eventGroups = x
    } else {
      this._show.eventGroups = undefined
    }
  }


  private _showEventsReady: boolean = false
  get showEventsReady(): boolean {
    return this._showEventsReady
  }
  @Mutation
  setShowEventsReady(x: boolean) {
    this._showEventsReady = x
  }

  @Mutation
  setJudges(x: Array<Judge>) {
    this._judges = x
  }

  @Mutation
  setSquareToken(x: number) {
    this._squareToken = x
  }

  @Mutation
  setSurcharge(x: boolean) {

  }

  private _squareTokenReady: boolean = false
  get squareTokenReady(): boolean {
    return this._squareTokenReady
  }
  @Mutation
  setSquareTokenReady(x: boolean) {
    this._squareTokenReady = x
  }

  @Mutation
  setCatalog(x: { canPurchase: boolean, fee: number }) {
    this._show.catalog = x
  }

  private _catalogReady: boolean = false
  get catalogRead(): boolean {
    return this._catalogReady
  }
  @Mutation
  setCatalogReady(x: boolean) {
    this._catalogReady = x
  }

  @Mutation
  setCheckInfo(x: { payee: string, addressee: string, address: Address }) {
    this._show.checkPayableTo = x.payee
    this._show.checkRecipient = x.addressee
    this._show.checkAddress = x.address.exportInterface()
  }

  private _fetchingDogClasses: boolean = false
  get fetchingDogClasses(): boolean {
    return this._fetchingDogClasses
  }
  @Mutation
  setFetchingDogClasses(x: boolean) {
    this._fetchingDogClasses = x
  }


  private _fetchedClasses: Array<DogClass> = []
  private _fetchingClasses: boolean = false

  get fetchedClasses(): Array<DogClass> {
    return this._fetchedClasses
  }
  @Mutation
  setFetchedClasses(x: Array<DogClass>) {
    this._fetchedClasses = x
  }

  get fetchingClasses(): boolean {
    return this._fetchingClasses
  }
  @Mutation
  setFetchingClasses(x: boolean) {
    this._fetchingClasses = x
  }

  @Action
  async fetchClasses() {
    this.setFetchingDogClasses(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/secretary/new-show/fetch-classes",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({}),
        credentials: "include",
      }
    );
    this.setFetchingDogClasses(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: SecretaryFetchClassesResponse = await response.json();
      if (data.success) {
        this.setFetchedClasses(DogClass.dogClassArrayFromJSON(data.classes))
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
      }
    }
  }

  private _fetchedJudges: Array<Judge> = []
  private _fetchingJudges: boolean = false

  get fetchedJudges(): Array<Judge> {
    return this._fetchedJudges
  }
  @Mutation
  setFetchedJudges(x: Array<Judge>) {
    this._fetchedJudges = x
  }

  get fetchingJudges(): boolean {
    return this._fetchingJudges
  }
  @Mutation
  setFetchingJudges(x: boolean) {
    this._fetchingJudges = x
  }

  @Action
  async fetchJudges() {
    this.setFetchingJudges(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/secretary/new-show/fetch-judges",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({}),
        credentials: "include",
      }
    );
    this.setFetchingJudges(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: SecretaryFetchJudgesResponse = await response.json();
      if (data.success) {
        this.setFetchedJudges(Judge.judgeArrayFromJSON(data.judges))
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
      }
    }
  }

  private _squareTokens: Array<SquareToken> = []
  private _fetchingSquareTokens: boolean = false
  private _selectedToken: SquareToken | undefined = undefined
  get squareTokens(): Array<SquareToken> {
    return this._squareTokens
  }
  @Mutation
  setSquareTokens(x: Array<SquareToken>) {
    this._squareTokens = x
  }
  get fetchingSquareTokens(): boolean {
    return this._fetchingSquareTokens
  }
  @Mutation
  setFetchingSquareTokens(x: boolean) {
    this._fetchingSquareTokens = x
  }
  get selectedToken(): SquareToken | undefined {
    return this._selectedToken
  }
  @Mutation
  setSelectedToken(x: SquareToken | undefined) {
    this._selectedToken = x
  }

  @Action
  async fetchSquareTokens() {
    this.setFetchingSquareTokens(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/secretary/new-show/fetch-square-tokens",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({}),
        credentials: "include",
      }
    );
    this.setFetchingSquareTokens(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: SecretaryFetchSquareTokensResponse = await response.json();
      if (data.success) {
        this.setSquareTokens(SquareToken.squareTokenArrayFromJSON(data.squareTokens))
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
      }
    }
  }


  private _abbrAvailable: boolean = false
  private _checkingAbbr: boolean = false
  get abbAvailable(): boolean {
    return this._abbrAvailable
  }
  get checkingAbbr(): boolean {
    return this._checkingAbbr
  }
  @Mutation
  setAbbrAvailable(x: boolean) {
    this._abbrAvailable = x
  }
  @Mutation
  setCheckingAbbr(x: boolean) {
    this._checkingAbbr = x
  }

  @Action
  async checkAbbr(data: SecretaryCheckAbbrRequest) {
    this.setCheckingAbbr(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/secretary/new-show/check-abbr",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
    this.setCheckingAbbr(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: SecretaryCheckAbbrResponse = await response.json();
      if (data.success) {
        this.setAbbrAvailable(data.available)
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
      }
    }
  }

  private _generatingAbbr: boolean = false
  private _abbr: string | undefined

  get generatingAbbr(): boolean {
    return this._generatingAbbr
  }
  get abbr(): string | undefined {
    return this._abbr
  }

  @Mutation
  setGeneratingAbbr(x: boolean) {
    this._generatingAbbr = x
  }
  @Mutation
  setAbbr(x: string | undefined) {
    this._abbr = x
  }

  @Action
  async generateAbbr(data: SecretaryGenerateAbbrRequest) {
    this.setGeneratingAbbr(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/secretary/new-show/generate-abbr",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
    this.setGeneratingAbbr(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: SecretaryGenerateAbbrResponse = await response.json();
      if (data.success) {
        this.setAbbr(data.abbr)
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
      }
    }
  }

  @Mutation
  setAdditionalFees(x: { catalog: { canPurchase: boolean, fee: number }, surcharge: boolean, secretaryFee: number }) {
    this._show.catalog = x.catalog
    this._show.surcharge = x.surcharge
    this._show.secretaryFee = x.secretaryFee
  }

  @Mutation
  setFinalDetails(x: { abbr: string, topLine: string, note: string, bottomLine?: string }) {
    this._show.abbr = x.abbr
    this._show.title = x.topLine
    this._show.subtitle = x.bottomLine
    this._show.note = x.note
  }

  private get prepareSubmission(): SecretarySubmitShowRequest {
    console.log("pp")
    let customClassesStart: Array<DogClass> = []
    for (let thisEventClasses of this.eventClasses) {
      for (let eventClass of thisEventClasses) {
        if (eventClass.dogClass.id < 0) {
          customClassesStart.push(eventClass.dogClass)
        }
      }
    }

    let customClasses: Array<DogClass> = []
    let newJudges: Array<Judge> = []
    for (let dogClass of customClassesStart) {
      if (!customClasses.find((x) => { return x.classCompare(dogClass) })) {
        customClasses.push(dogClass)
      }
    }
    for (let judge of this._judges) {
      if (judge.id == null || (judge.id !== null && judge.id < 0)) {
        newJudges.push(judge)
      }
    }

    let newEventClasses: Array<NewEventClassInterface> = []
    for (let k = 0; k < this.eventClasses.length; k++) {
      let eventClasses: Array<EventClass> = this.eventClasses[k]

      for (let eventClass of eventClasses) {
        let isClassCustom: boolean = false
        let classId: number = eventClass.dogClass.id
        if (eventClass.dogClass.id < 0) {
          let customClassIdx: number = customClasses.findIndex((x) => { return x.classCompare(eventClass.dogClass) })
          if (customClassIdx >= 0) {
            isClassCustom = true
            classId = customClassIdx
          }
        }
        let isNewJudge: boolean = false
        let judgeId: number = (eventClass.judge.id !== null ? eventClass.judge.id : -1)
        if (eventClass.judge.id === null || eventClass.judge.id < 0) {
          let newJudgeIdx: number = newJudges.findIndex((x) => { return x.judgeCompare(eventClass.judge) })
          if (newJudgeIdx >= 0) {
            isNewJudge = true
            judgeId = newJudgeIdx
          }
        }

        let newEventClassInterface: NewEventClassInterface = {
          event: k,
          dogClass: { id: classId, newCustomClass: isClassCustom },
          judge: { id: judgeId, newJudge: isNewJudge },
          fee: eventClass.fee
        }
        newEventClasses.push(newEventClassInterface)
      }
    }
    let out: SecretarySubmitShowRequest = {
      show: this._show,
      squareToken: this._squareToken,
      eventClasses: newEventClasses,
      newJudges: newJudges.map((x) => { return x.exportInterface() }),
      customClasses: customClasses.map((x) => { return x.exportInterface() }),
      csrfSecurityToken: UserStore.csrfSecurityToken
    }
    console.log('prepare')
    console.log(out)
    return out
  }

  private _submittingShow: boolean = false
  private _trusted: boolean = false
  private _submitSuccess: boolean = false

  get submittingShow(): boolean {
    return this._submittingShow
  }
  @Mutation
  setSubmittingShow(x: boolean) {
    this._submittingShow = x
  }

  get trusted(): boolean {
    return this._trusted
  }
  @Mutation
  setTrusted(x: boolean) {
    this._trusted = x
  }

  get submitSuccess(): boolean {
    return this._submitSuccess
  }
  @Mutation
  setSubmitSuccess(x: boolean) {
    this._submitSuccess = x
  }


  @Action
  async submitShow() {
    let payload: SecretarySubmitShowRequest = this.prepareSubmission
    this.setSubmittingShow(true)
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/secretary/new-show/submit-show",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      }
    );
    this.setSubmittingShow(false)
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: SecretarySubmitShowResponse = await response.json();
      this.setSubmitSuccess(data.success)
      if (data.success) {
        this.setTrusted(data.trusted)
      }
      UserStore.authCheck(data);
    }
  }


  private _lookupClasses: Array<DogClass> = []
  private _lookupJudges: Array<Judge> = []
  private _lookingUpClassesAndJudges: boolean = false

  get lookupClasses(): Array<DogClass> {
    return this._lookupClasses
  }
  get lookupJudges(): Array<Judge> {
    return this._lookupJudges
  }
  get lookingUpClassesAndJudges(): boolean {
    return this._lookingUpClassesAndJudges
  }
  @Mutation
  setLookupClasses(x: Array<DogClass>) {
    this._lookupClasses = x
  }
  @Mutation
  setLookupJudges(x: Array<Judge>) {
    this._lookupJudges = x
  }
  @Mutation
  setLookingUpClassesAndJudges(x: boolean) {
    this._lookingUpClassesAndJudges = x
  }

  @Action
  async lookupClassesAndJudges(data: SecretaryClassAndJudgeRequest) {
    this.setLookingUpClassesAndJudges(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/secretary/new-show/fetch-classes-and-judges",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
    this.setLookingUpClassesAndJudges(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: SecretaryClassAndJudgeResponse = await response.json();
      if (data.success) {
        this.setLookupClasses(DogClass.dogClassArrayFromJSON(data.classes))
        this.setLookupJudges(Judge.judgeArrayFromJSON(data.judges))
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
      }
    }
  }

  private _settingUpShowDataFromLocalStorage: boolean = false
  private _loadFromLocalStorage: boolean = false

  get settingUpShowDataFromLocalStorage(): boolean {
    return this._settingUpShowDataFromLocalStorage
  }
  @Mutation
  setSettingUpShowDataFromLocalStorage(x: boolean) {
    this._settingUpShowDataFromLocalStorage = x
  }

  get loadFromLocalStorage(): boolean {
    return this._loadFromLocalStorage
  }
  @Mutation
  setLoadFromLocalStorage(x: boolean) {
    this._loadFromLocalStorage = x
  }

  @Action
  async setShowFromLocalStorage(x: SecretarySubmitShowRequest) {
    this.setSettingUpShowDataFromLocalStorage(true)

    this.setShow(x.show)
    let showEventClasses: Array<Array<EventClass>> = []
    let classesIds: Array<number> = x.eventClasses.filter((y) => { return !y.dogClass.newCustomClass }).map((y) => { return y.dogClass.id })
    let judgeIds: Array<number> = x.eventClasses.filter((y) => { return !y.judge.newJudge }).map((y) => { return y.judge.id })
    let judges: Array<Judge> = []
    let payload: SecretaryClassAndJudgeRequest = { classes: classesIds, judges: judgeIds }
    let events: Array<DogEvent> = DogEvent.dogEventArrayFromJSON(x.show.events)
    await this.lookupClassesAndJudges(payload)
    let customClasses: Array<DogClass> = DogClass.dogClassArrayFromJSON(x.customClasses)
    let newJudges: Array<Judge> = Judge.judgeArrayFromJSON(x.newJudges)
    for (let k = 0; k < this._show.events.length; k++) {
      let event: number = this._show.events[k].id
      let newEventClasses: Array<NewEventClassInterface> = x.eventClasses.filter((y) => { return y.event == event })
      let eventClasses: Array<EventClass> = []
      for (let newLocalEventClass of newEventClasses) {
        let dogClass: DogClass | undefined = (newLocalEventClass.dogClass.newCustomClass ? customClasses[newLocalEventClass.dogClass.id] : this.lookupClasses.find((z) => { return z.id == newLocalEventClass.dogClass.id }))
        let judgeSearch: Judge | undefined = (newLocalEventClass.judge.newJudge ? newJudges[newLocalEventClass.judge.id] : this.lookupJudges.find((z) => { return z.id == newLocalEventClass.judge.id }))
        let judge: Judge
        if (judgeSearch) {
          judge = judgeSearch
        } else {
          judge = new Judge()
        }
        if (dogClass) {
          newLocalEventClass
          let newEventClass: EventClass = new EventClass(-1, events[newLocalEventClass.event], dogClass, newLocalEventClass.fee, judge)
          eventClasses.push(newEventClass)
          if (!judges.find((y) => { return y.judgeCompare(judge!) })) {
            judges.push(judge)
          }
        }

      }
      showEventClasses.push(eventClasses)
    }
    this.setEventClasses(showEventClasses)
    this.setJudges(judges)
    await this.fetchSquareTokens()
    let squareToken: number = this.squareTokens.findIndex((y) => { return y.id == x.squareToken })
    this.setSquareToken(squareToken)
    this.setLoadFromLocalStorage(true)
    this.setSettingUpShowDataFromLocalStorage(false)
  }

  @Action
  save() {
    let show = JSON.parse(JSON.stringify(this.prepareSubmission))
    show.csrfSecurityToken = ""
    window.localStorage.setItem("newShow", JSON.stringify(show))
  }



}

export default getModule(NewShowStore);
