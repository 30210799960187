export enum PrefixTitles {
    AFC = 'AFC',
    AGCH = 'AGCH',
    BN_V = 'BN-V',
    CCH = 'CCH',
    CD_V = 'CD-V',
    CFC = 'CFC',
    CGCH = 'CGCH',
    CGF = 'CGF',
    CGN = 'CGN',
    CGW = 'CGW',
    CH = 'CH',
    CJN = 'CJN',
    CNC = 'CNC',
    CRCG = 'CRCG',
    CSG = 'CSG',
    CSGF = 'CSGF',
    CSGN = 'CSGN',
    CSGW = 'CSGW',
    CT = 'CT',
    CWC = 'CWC',
    CWGN = 'CWGN',
    CWSG = 'CWSG',
    DC = 'DC',
    FC = 'FC',
    FCB = 'FCB',
    FCGD = 'FCGD',
    FCLP = 'FCLP',
    GAFC = 'GAFC',
    GCH = 'GCH',
    GCHB = 'GCHB',
    GCHG = 'GCHG',
    GCHP = 'GCHP',
    GCHS = 'GCHS',
    GFC = 'GFC',
    HC = 'HC',
    HXADM = 'HXADM',
    MACH = 'MACH',
    NAC = 'NAC',
    NAFC = 'NAFC',
    NAGDC = 'NAGDC',
    NBC = 'NBC',
    NFC = 'NFC',
    NGBC = 'NGBC',
    NGDC = 'NGDC',
    NLPC = 'NLPC',
    NOC = 'NOC',
    NSPC = 'NSPC',
    NTCPC = 'NTCPC',
    NWGDC = 'NWGDC',
    OTCH = 'OTCH',
    PACH = 'PACH',
    PNAC = 'PNAC',
    POC = 'POC',
    RACH = 'RACH',
    RATN = 'RATN',
    RNC = 'RNC',
    TC = 'TC',
    VCCH = 'VCCH',
    VSWB = 'VSWB',
    VSWI = 'VSWI',
    VSWE = 'VSWE',
    WNC = 'WNC'
}

export enum SuffixTitles {
    ACT1 = 'ACT1',
    ACT2 = 'ACT2',
    ACT1J = 'ACT1J',
    ACT2J = 'ACT2J',
    AE = 'AE',
    AEA = 'AEA',
    AEX = 'AEX',
    AJ = 'AJ',
    AJA = 'AJA',
    AJP = 'AJP',
    AJX = 'AJX',
    AM = 'AM',
    AMA = 'AMA',
    AMX = 'AMX',
    AN = 'AN',
    ANA = 'ANA',
    ANX = 'ANX',
    AS = 'AS',
    ASA = 'ASA',
    ASX = 'ASX',
    AX = 'AX',
    BCAT = 'BCAT',
    BDD = 'BDD',
    BH = 'BH',
    BN = 'BN',
    CA = 'CA',
    CAA = 'CAA',
    CAX = 'CAX',
    CC = 'CC',
    CCA = 'CCA',
    CD = 'CD',
    CDX = 'CDX',
    CGC = 'CGC',
    CGCA = 'CGCA',
    CGCU = 'CGCU',
    CI = 'CI',
    CIT = 'CIT',
    CM = 'CM',
    CS = 'CS',
    CST = 'CST',
    CX = 'CX',
    CXT = 'CXT',
    DCAT = 'DCAT',
    DD = 'DD',
    DE = 'DE',
    DEA = 'DEA',
    DEX = 'DEX',
    DJ = 'DJ',
    DJA = 'DJA',
    DJX = 'DJX',
    DM = 'DM',
    DMA = 'DMA',
    DMX = 'DMX',
    DN = 'DN',
    DNA = 'DNA',
    DNX = 'DNX',
    DS = 'DS',
    DSA = 'DSA',
    DSX = 'DSX',
    EE = 'EE',
    FCAT = 'FCAT',
    FDC = 'FDC',
    FDCH = 'FDCH',
    FDGCH = 'FDGCH',
    FH1 = 'FH1',
    FH2 = 'FH2',
    FM = 'FM',
    GN = 'GN',
    GO = 'GO',
    HI = 'HI',
    HIAC = 'HIAC',
    HIACM = 'HIACM',
    HIAD = 'HIAD',
    HIADM = 'HIADM',
    HIAS = 'HIAS',
    HIASM = 'HIASM',
    HIBC = 'HIBC',
    HIBD = 'HIBD',
    HIBDM = 'HIBDM',
    HIBS = 'HIBS',
    HIBSM = 'HIBSM',
    HICS = 'HICS',
    HICSM = 'HICSM',
    HS = 'HS',
    HSAC = 'HSAC',
    HSACM = 'HSACM',
    HSAD = 'HSAD',
    HSADM = 'HSADM',
    HSAS = 'HSAS',
    HSASM = 'HSASM',
    HSBC = 'HSBC',
    HSBD = 'HSBD',
    HSBDM = 'HSBDM',
    HSBS = 'HSBS',
    HSBSM = 'HSBSM',
    HSCS = 'HSCS',
    HSCSM = 'HSCSM',
    HT = 'HT',
    HX = 'HX',
    HXAC = 'HXAC',
    HXACM = 'HXACM',
    HXAD = 'HXAD',
    HXAS = 'HXAS',
    HXASM = 'HXASM',
    HXBC = 'HXBC',
    HXBD = 'HXBD',
    HXBDM = 'HXBDM',
    HXBS = 'HXBS',
    HXBSM = 'HXBSM',
    HXCS = 'HXCS',
    HXCSM = 'HXCSM',
    IPO1 = 'IPO1',
    IPO2 = 'IPO2',
    IPO3 = 'IPO3',
    JC = 'JC',
    JE = 'JE',
    JH = 'JH',
    JHA = 'JHA',
    JHR = 'JHR',
    JHU = 'JHU',
    JHUA = 'JHUA',
    LCX = 'LCX',
    MC = 'MC',
    ME = 'ME',
    MFB = 'MFB',
    MFC = 'MFC',
    MFG = 'MFG',
    MFP = 'MFP',
    MFPB = 'MFPB',
    MFPC = 'MFPC',
    MFPG = 'MFPG',
    MFPS = 'MFPS',
    MFS = 'MFS',
    MH = 'MH',
    MHA = 'MHA',
    MHR = 'MHR',
    MHU = 'MHU',
    MHUA = 'MHUA',
    MJB = 'MJB',
    MJC = 'MJC',
    MJPC = 'MJPC',
    MJG = 'MJG',
    MJP = 'MJP',
    MJPB = 'MJPB',
    MJPG = 'MJPG',
    MJPS = 'MJPS',
    MJS = 'MJS',
    MNH = 'MNH',
    MT = 'MT',
    MTI = 'MTI',
    MTX = 'MTX',
    MX = 'MX',
    MXB = 'MXB',
    MXC = 'MXC',
    MXF = 'MXF',
    MXG = 'MXG',
    MXJ = 'MXJ',
    MXP = 'MXP',
    MXPB = 'MXPB',
    MXPC = 'MXPC',
    MXPG = 'MXPG',
    MXPS = 'MXPS',
    MXS = 'MXS',
    NA = 'NA',
    NAJ = 'NAJ',
    NAP = 'NAP',
    NBDD = 'NBDD',
    NDD = 'NDD',
    NF = 'NF',
    NFP = 'NFP',
    NJP = 'NJP',
    OA = 'OA',
    OAJ = 'OAJ',
    OAP = 'OAP',
    OF = 'OF',
    OFP = 'OFP',
    OGM = 'OGM',
    OJP = 'OJP',
    OM = 'OM',
    ONYX = 'ONYX',
    PAD = 'PAD',
    PADP = 'PADP',
    PAX = 'PAX',
    PCD = 'PCD',
    PCDX = 'PCDX',
    PCJH = 'PCJH',
    PCMH = 'PCMH',
    PCSH = 'PCSH',
    PDB = 'PDB',
    PDBP = 'PDBP',
    PDC = 'PDC',
    PDCP = 'PDCP',
    PDG = 'PDG',
    PDGP = 'PDGP',
    PDS = 'PDS',
    PDSP = 'PDSP',
    PJB = 'PJB',
    PJBP = 'PJBP',
    PJC = 'PJC',
    PJCP = 'PJCP',
    PJD = 'PJD',
    PJDP = 'PJDP',
    PJG = 'PJG',
    PJGP = 'PJGP',
    PJS = 'PJS',
    PJSP = 'PJSP',
    PT = 'PT',
    PUDX = 'PUDX',
    PUTD = 'PUTD',
    QA2 = 'QA2',
    RA = 'RA',
    RAE = 'RAE',
    RATCH = 'RATCH',
    RATCHX = 'RATCHX',
    RATM = 'RATM',
    RATO = 'RATO',
    RATS = 'RATS',
    RD = 'RD',
    RDX = 'RDX',
    RE = 'RE',
    RI = 'RI',
    RM = 'RM',
    RN = 'RN',
    SAR_U1 = 'SAR-U1',
    SAR_U2 = 'SAR-U2',
    SAR_U3 = 'SAR-U3',
    SAR_W = 'SAR-W',
    SBA = 'SBA',
    SBAE = 'SBAE',
    SBE = 'SBE',
    SBEE = 'SBEE',
    SBM = 'SBM',
    SBME = 'SBME',
    SBN = 'SBN',
    SBNE = 'SBNE',
    SC = 'SC',
    SCA = 'SCA',
    SCAE = 'SCAE',
    SCE = 'SCE',
    SCEE = 'SCEE',
    SCHH1 = 'SCHH1',
    SCHH2 = 'SCHH2',
    SCHH3 = 'SCHH3',
    SCHHA = 'SCHHA',
    SCM = 'SCM',
    SCME = 'SCME',
    SCN = 'SCN',
    SCNE = 'SCNE',
    SD = 'SD',
    SDO = 'SDO',
    SDX = 'SDX',
    SE = 'SE',
    SEA = 'SEA',
    SEAE = 'SEAE',
    SEE = 'SEE',
    SEEE = 'SEEE',
    SEM = 'SEM',
    SEME = 'SEME',
    SEN = 'SEN',
    SENE = 'SENE',
    SH = 'SH',
    SHA = 'SHA',
    SHDA = 'SHDA',
    SHDAE = 'SHDAE',
    SHDE = 'SHDE',
    SHDEE = 'SHDEE',
    SHDM = 'SHDM',
    SHDME = 'SHDME',
    SHDN = 'SHDN',
    SHDNE = 'SHDNE',
    SHR = 'SHR',
    SHU = 'SHU',
    SHUA = 'SHUA',
    SIA = 'SIA',
    SIAE = 'SIAE',
    SIE = 'SIE',
    SIEE = 'SIEE',
    SIM = 'SIM',
    SIME = 'SIME',
    SIN = 'SIN',
    SINE = 'SINE',
    SWA = 'SWA',
    SWAE = 'SWAE',
    SWD = 'SWD',
    SWE = 'SWE',
    SWEE = 'SWEE',
    SWM = 'SWM',
    SWME = 'SWME',
    SWN = 'SWN',
    SWNE = 'SWNE',
    T2B = 'T2B',
    T2BP = 'T2BP',
    TD = 'TD',
    TDD = 'TDD',
    TDU = 'TDU',
    TDX = 'TDX',
    THD = 'THD',
    THDA = 'THDA',
    THDD = 'THDD',
    THDN = 'THDN',
    THDX = 'THDX',
    TKA = 'TKA',
    TKI = 'TKI',
    TKN = 'TKN',
    TKP = 'TKP',
    TQX = 'TQX',
    TQXP = 'TQXP',
    TT = 'TT',
    UD = 'UD',
    UDX = 'UDX',
    VCD = 'VCD',
    VER = 'VER',
    VST = 'VST',
    WC = 'WC',
    WCI = 'WCI',
    WCX = 'WCX',
    WDS1 = 'WDS1',
    WDS2 = 'WDS2',
    WDS3 = 'WDS3',
    XF = 'XF',
    XFP = 'XFP',
}

export const PREFIX_TITLES: Array<{ title: PrefixTitles, multiple: boolean }> = [
    { title: PrefixTitles.AFC, multiple: false },
    { title: PrefixTitles.AGCH, multiple: false },
    { title: PrefixTitles.BN_V, multiple: false },
    { title: PrefixTitles.CCH, multiple: false },
    { title: PrefixTitles.CD_V, multiple: false },
    { title: PrefixTitles.CFC, multiple: false },
    { title: PrefixTitles.CGCH, multiple: false },
    { title: PrefixTitles.CGF, multiple: false },
    { title: PrefixTitles.CGN, multiple: false },
    { title: PrefixTitles.CGW, multiple: false },
    { title: PrefixTitles.CH, multiple: false },
    { title: PrefixTitles.CJN, multiple: false },
    { title: PrefixTitles.CNC, multiple: false },
    { title: PrefixTitles.CRCG, multiple: false },
    { title: PrefixTitles.CSG, multiple: false },
    { title: PrefixTitles.CSGF, multiple: false },
    { title: PrefixTitles.CSGN, multiple: false },
    { title: PrefixTitles.CSGW, multiple: false },
    { title: PrefixTitles.CT, multiple: false },
    { title: PrefixTitles.CWC, multiple: false },
    { title: PrefixTitles.CWGN, multiple: false },
    { title: PrefixTitles.CWSG, multiple: false },
    { title: PrefixTitles.DC, multiple: false },
    { title: PrefixTitles.FC, multiple: false },
    { title: PrefixTitles.FC, multiple: false },
    { title: PrefixTitles.FCB, multiple: false },
    { title: PrefixTitles.FCGD, multiple: false },
    { title: PrefixTitles.FCLP, multiple: false },
    { title: PrefixTitles.GAFC, multiple: false },
    { title: PrefixTitles.GCH, multiple: false },
    { title: PrefixTitles.GCHB, multiple: false },
    { title: PrefixTitles.GCHG, multiple: false },
    { title: PrefixTitles.GCHP, multiple: true },
    { title: PrefixTitles.GCHS, multiple: false },
    { title: PrefixTitles.GFC, multiple: false },
    { title: PrefixTitles.HC, multiple: false },
    { title: PrefixTitles.HXADM, multiple: false },
    { title: PrefixTitles.MACH, multiple: true },
    { title: PrefixTitles.NAC, multiple: false },
    { title: PrefixTitles.NAFC, multiple: false },
    { title: PrefixTitles.NAGDC, multiple: false },
    { title: PrefixTitles.NBC, multiple: false },
    { title: PrefixTitles.NFC, multiple: false },
    { title: PrefixTitles.NFC, multiple: false },
    { title: PrefixTitles.NGBC, multiple: false },
    { title: PrefixTitles.NGDC, multiple: false },
    { title: PrefixTitles.NLPC, multiple: false },
    { title: PrefixTitles.NOC, multiple: false },
    { title: PrefixTitles.NSPC, multiple: false },
    { title: PrefixTitles.NTCPC, multiple: false },
    { title: PrefixTitles.NWGDC, multiple: false },
    { title: PrefixTitles.OTCH, multiple: false },
    { title: PrefixTitles.PACH, multiple: true },
    { title: PrefixTitles.PNAC, multiple: false },
    { title: PrefixTitles.POC, multiple: true },
    { title: PrefixTitles.RACH, multiple: true },
    { title: PrefixTitles.RATN, multiple: false },
    { title: PrefixTitles.RNC, multiple: false },
    { title: PrefixTitles.TC, multiple: false },
    { title: PrefixTitles.VCCH, multiple: false },
    { title: PrefixTitles.VSWB, multiple: false },
    { title: PrefixTitles.VSWI, multiple: false },
    { title: PrefixTitles.VSWE, multiple: false },
    { title: PrefixTitles.WNC, multiple: false }
]

export const SUFFIX_TITLES: Array<{ title: SuffixTitles, multiple: boolean }> = [
    { title: SuffixTitles.ACT1, multiple: false },
    { title: SuffixTitles.ACT2, multiple: false },
    { title: SuffixTitles.ACT1J, multiple: false },
    { title: SuffixTitles.ACT2J, multiple: false },
    { title: SuffixTitles.AE, multiple: false },
    { title: SuffixTitles.AEA, multiple: false },
    { title: SuffixTitles.AEX, multiple: true },
    { title: SuffixTitles.AJ, multiple: false },
    { title: SuffixTitles.AJA, multiple: false },
    { title: SuffixTitles.AJP, multiple: false },
    { title: SuffixTitles.AJX, multiple: true },
    { title: SuffixTitles.AM, multiple: false },
    { title: SuffixTitles.AMA, multiple: false },
    { title: SuffixTitles.AMX, multiple: true },
    { title: SuffixTitles.AN, multiple: false },
    { title: SuffixTitles.ANA, multiple: false },
    { title: SuffixTitles.ANX, multiple: true },
    { title: SuffixTitles.AS, multiple: false },
    { title: SuffixTitles.ASA, multiple: false },
    { title: SuffixTitles.ASX, multiple: true },
    { title: SuffixTitles.AX, multiple: false },
    { title: SuffixTitles.BCAT, multiple: false },
    { title: SuffixTitles.BDD, multiple: false },
    { title: SuffixTitles.BH, multiple: false },
    { title: SuffixTitles.BN, multiple: false },
    { title: SuffixTitles.CA, multiple: false },
    { title: SuffixTitles.CAA, multiple: false },
    { title: SuffixTitles.CAX, multiple: true },
    { title: SuffixTitles.CC, multiple: false },
    { title: SuffixTitles.CCA, multiple: false },
    { title: SuffixTitles.CD, multiple: false },
    { title: SuffixTitles.CDX, multiple: false },
    { title: SuffixTitles.CGC, multiple: false },
    { title: SuffixTitles.CGCA, multiple: false },
    { title: SuffixTitles.CGCU, multiple: false },
    { title: SuffixTitles.CI, multiple: false },
    { title: SuffixTitles.CIT, multiple: false },
    { title: SuffixTitles.CM, multiple: true },
    { title: SuffixTitles.CS, multiple: false },
    { title: SuffixTitles.CST, multiple: false },
    { title: SuffixTitles.CX, multiple: false },
    { title: SuffixTitles.CXT, multiple: false },
    { title: SuffixTitles.DCAT, multiple: false },
    { title: SuffixTitles.DD, multiple: false },
    { title: SuffixTitles.DE, multiple: false },
    { title: SuffixTitles.DEA, multiple: false },
    { title: SuffixTitles.DEX, multiple: true },
    { title: SuffixTitles.DJ, multiple: false },
    { title: SuffixTitles.DJA, multiple: false },
    { title: SuffixTitles.DJX, multiple: true },
    { title: SuffixTitles.DM, multiple: false },
    { title: SuffixTitles.DMA, multiple: false },
    { title: SuffixTitles.DMX, multiple: true },
    { title: SuffixTitles.DN, multiple: false },
    { title: SuffixTitles.DNA, multiple: false },
    { title: SuffixTitles.DNX, multiple: true },
    { title: SuffixTitles.DS, multiple: false },
    { title: SuffixTitles.DSA, multiple: false },
    { title: SuffixTitles.DSX, multiple: true },
    { title: SuffixTitles.EE, multiple: true },
    { title: SuffixTitles.FCAT, multiple: true },
    { title: SuffixTitles.FDC, multiple: false },
    { title: SuffixTitles.FDCH, multiple: false },
    { title: SuffixTitles.FDGCH, multiple: false },
    { title: SuffixTitles.FH1, multiple: false },
    { title: SuffixTitles.FH2, multiple: false },
    { title: SuffixTitles.FM, multiple: false },
    { title: SuffixTitles.GN, multiple: false },
    { title: SuffixTitles.GO, multiple: false },
    { title: SuffixTitles.HI, multiple: false },
    { title: SuffixTitles.HIAC, multiple: false },
    { title: SuffixTitles.HIACM, multiple: false },
    { title: SuffixTitles.HIAD, multiple: false },
    { title: SuffixTitles.HIADM, multiple: false },
    { title: SuffixTitles.HIAS, multiple: false },
    { title: SuffixTitles.HIASM, multiple: false },
    { title: SuffixTitles.HIBC, multiple: false },
    { title: SuffixTitles.HIBD, multiple: false },
    { title: SuffixTitles.HIBDM, multiple: false },
    { title: SuffixTitles.HIBS, multiple: false },
    { title: SuffixTitles.HIBSM, multiple: false },
    { title: SuffixTitles.HICS, multiple: false },
    { title: SuffixTitles.HICSM, multiple: false },
    { title: SuffixTitles.HS, multiple: false },
    { title: SuffixTitles.HSAC, multiple: false },
    { title: SuffixTitles.HSACM, multiple: false },
    { title: SuffixTitles.HSAD, multiple: false },
    { title: SuffixTitles.HSADM, multiple: false },
    { title: SuffixTitles.HSAS, multiple: false },
    { title: SuffixTitles.HSASM, multiple: false },
    { title: SuffixTitles.HSBC, multiple: false },
    { title: SuffixTitles.HSBD, multiple: false },
    { title: SuffixTitles.HSBDM, multiple: false },
    { title: SuffixTitles.HSBS, multiple: false },
    { title: SuffixTitles.HSBSM, multiple: false },
    { title: SuffixTitles.HSCS, multiple: false },
    { title: SuffixTitles.HSCSM, multiple: false },
    { title: SuffixTitles.HT, multiple: false },
    { title: SuffixTitles.HX, multiple: false },
    { title: SuffixTitles.HXAC, multiple: false },
    { title: SuffixTitles.HXACM, multiple: false },
    { title: SuffixTitles.HXAD, multiple: false },
    { title: SuffixTitles.HXAS, multiple: false },
    { title: SuffixTitles.HXASM, multiple: false },
    { title: SuffixTitles.HXBC, multiple: false },
    { title: SuffixTitles.HXBD, multiple: false },
    { title: SuffixTitles.HXBDM, multiple: false },
    { title: SuffixTitles.HXBS, multiple: false },
    { title: SuffixTitles.HXBSM, multiple: false },
    { title: SuffixTitles.HXCS, multiple: false },
    { title: SuffixTitles.HXCSM, multiple: false },
    { title: SuffixTitles.IPO1, multiple: false },
    { title: SuffixTitles.IPO2, multiple: false },
    { title: SuffixTitles.IPO3, multiple: false },
    { title: SuffixTitles.JC, multiple: false },
    { title: SuffixTitles.JE, multiple: false },
    { title: SuffixTitles.JH, multiple: false },
    { title: SuffixTitles.JHA, multiple: false },
    { title: SuffixTitles.JHR, multiple: false },
    { title: SuffixTitles.JHU, multiple: false },
    { title: SuffixTitles.JHUA, multiple: false },
    { title: SuffixTitles.LCX, multiple: true },
    { title: SuffixTitles.MC, multiple: false },
    { title: SuffixTitles.ME, multiple: false },
    { title: SuffixTitles.MFB, multiple: true },
    { title: SuffixTitles.MFC, multiple: true },
    { title: SuffixTitles.MFG, multiple: true },
    { title: SuffixTitles.MFP, multiple: false },
    { title: SuffixTitles.MFPB, multiple: true },
    { title: SuffixTitles.MFPC, multiple: true },
    { title: SuffixTitles.MFPG, multiple: true },
    { title: SuffixTitles.MFPS, multiple: true },
    { title: SuffixTitles.MFS, multiple: true },
    { title: SuffixTitles.MH, multiple: true },
    { title: SuffixTitles.MHA, multiple: false },
    { title: SuffixTitles.MHR, multiple: false },
    { title: SuffixTitles.MHU, multiple: false },
    { title: SuffixTitles.MHUA, multiple: false },
    { title: SuffixTitles.MJB, multiple: true },
    { title: SuffixTitles.MJC, multiple: true },
    { title: SuffixTitles.MJPC, multiple: true },
    { title: SuffixTitles.MJG, multiple: true },
    { title: SuffixTitles.MJP, multiple: false },
    { title: SuffixTitles.MJPB, multiple: true },
    { title: SuffixTitles.MJPG, multiple: true },
    { title: SuffixTitles.MJPS, multiple: true },
    { title: SuffixTitles.MJS, multiple: true },
    { title: SuffixTitles.MNH, multiple: true },
    { title: SuffixTitles.MT, multiple: false },
    { title: SuffixTitles.MTI, multiple: false },
    { title: SuffixTitles.MTX, multiple: false },
    { title: SuffixTitles.MX, multiple: false },
    { title: SuffixTitles.MXB, multiple: true },
    { title: SuffixTitles.MXC, multiple: true },
    { title: SuffixTitles.MXF, multiple: false },
    { title: SuffixTitles.MXG, multiple: true },
    { title: SuffixTitles.MXJ, multiple: false },
    { title: SuffixTitles.MXP, multiple: false },
    { title: SuffixTitles.MXPB, multiple: true },
    { title: SuffixTitles.MXPC, multiple: true },
    { title: SuffixTitles.MXPG, multiple: true },
    { title: SuffixTitles.MXPS, multiple: true },
    { title: SuffixTitles.MXS, multiple: true },
    { title: SuffixTitles.NA, multiple: false },
    { title: SuffixTitles.NAJ, multiple: false },
    { title: SuffixTitles.NAP, multiple: false },
    { title: SuffixTitles.NBDD, multiple: false },
    { title: SuffixTitles.NDD, multiple: false },
    { title: SuffixTitles.NF, multiple: false },
    { title: SuffixTitles.NFP, multiple: false },
    { title: SuffixTitles.NJP, multiple: false },
    { title: SuffixTitles.OA, multiple: false },
    { title: SuffixTitles.OAJ, multiple: false },
    { title: SuffixTitles.OAP, multiple: false },
    { title: SuffixTitles.OF, multiple: false },
    { title: SuffixTitles.OFP, multiple: false },
    { title: SuffixTitles.OGM, multiple: false },
    { title: SuffixTitles.OJP, multiple: false },
    { title: SuffixTitles.OM, multiple: true },
    { title: SuffixTitles.ONYX, multiple: false },
    { title: SuffixTitles.PAD, multiple: false },
    { title: SuffixTitles.PADP, multiple: false },
    { title: SuffixTitles.PAX, multiple: true },
    { title: SuffixTitles.PCD, multiple: false },
    { title: SuffixTitles.PCDX, multiple: false },
    { title: SuffixTitles.PCJH, multiple: false },
    { title: SuffixTitles.PCMH, multiple: false },
    { title: SuffixTitles.PCSH, multiple: false },
    { title: SuffixTitles.PDB, multiple: true },
    { title: SuffixTitles.PDBP, multiple: true },
    { title: SuffixTitles.PDC, multiple: true },
    { title: SuffixTitles.PDCP, multiple: true },
    { title: SuffixTitles.PDG, multiple: true },
    { title: SuffixTitles.PDGP, multiple: true },
    { title: SuffixTitles.PDS, multiple: true },
    { title: SuffixTitles.PDSP, multiple: true },
    { title: SuffixTitles.PJB, multiple: true },
    { title: SuffixTitles.PJBP, multiple: true },
    { title: SuffixTitles.PJC, multiple: true },
    { title: SuffixTitles.PJCP, multiple: true },
    { title: SuffixTitles.PJD, multiple: false },
    { title: SuffixTitles.PJDP, multiple: false },
    { title: SuffixTitles.PJG, multiple: true },
    { title: SuffixTitles.PJGP, multiple: true },
    { title: SuffixTitles.PJS, multiple: true },
    { title: SuffixTitles.PJSP, multiple: true },
    { title: SuffixTitles.PT, multiple: false },
    { title: SuffixTitles.PUDX, multiple: true },
    { title: SuffixTitles.PUTD, multiple: false },
    { title: SuffixTitles.QA2, multiple: false },
    { title: SuffixTitles.RA, multiple: false },
    { title: SuffixTitles.RAE, multiple: true },
    { title: SuffixTitles.RATCH, multiple: false },
    { title: SuffixTitles.RATCHX, multiple: true },
    { title: SuffixTitles.RATM, multiple: false },
    { title: SuffixTitles.RATO, multiple: false },
    { title: SuffixTitles.RATS, multiple: false },
    { title: SuffixTitles.RD, multiple: false },
    { title: SuffixTitles.RDX, multiple: false },
    { title: SuffixTitles.RE, multiple: false },
    { title: SuffixTitles.RI, multiple: false },
    { title: SuffixTitles.RM, multiple: true },
    { title: SuffixTitles.RN, multiple: false },
    { title: SuffixTitles.SAR_U1, multiple: false },
    { title: SuffixTitles.SAR_U2, multiple: false },
    { title: SuffixTitles.SAR_U3, multiple: false },
    { title: SuffixTitles.SAR_W, multiple: false },
    { title: SuffixTitles.SBA, multiple: false },
    { title: SuffixTitles.SBAE, multiple: false },
    { title: SuffixTitles.SBE, multiple: false },
    { title: SuffixTitles.SBEE, multiple: false },
    { title: SuffixTitles.SBM, multiple: false },
    { title: SuffixTitles.SBME, multiple: false },
    { title: SuffixTitles.SBN, multiple: false },
    { title: SuffixTitles.SBNE, multiple: false },
    { title: SuffixTitles.SC, multiple: false },
    { title: SuffixTitles.SCA, multiple: false },
    { title: SuffixTitles.SCAE, multiple: false },
    { title: SuffixTitles.SCE, multiple: false },
    { title: SuffixTitles.SCEE, multiple: false },
    { title: SuffixTitles.SCHH1, multiple: false },
    { title: SuffixTitles.SCHH2, multiple: false },
    { title: SuffixTitles.SCHH3, multiple: false },
    { title: SuffixTitles.SCHHA, multiple: false },
    { title: SuffixTitles.SCM, multiple: false },
    { title: SuffixTitles.SCME, multiple: false },
    { title: SuffixTitles.SCN, multiple: false },
    { title: SuffixTitles.SCNE, multiple: false },
    { title: SuffixTitles.SD, multiple: false },
    { title: SuffixTitles.SDO, multiple: false },
    { title: SuffixTitles.SDX, multiple: false },
    { title: SuffixTitles.SE, multiple: false },
    { title: SuffixTitles.SEA, multiple: false },
    { title: SuffixTitles.SEAE, multiple: false },
    { title: SuffixTitles.SEE, multiple: false },
    { title: SuffixTitles.SEEE, multiple: false },
    { title: SuffixTitles.SEM, multiple: false },
    { title: SuffixTitles.SEME, multiple: false },
    { title: SuffixTitles.SEN, multiple: false },
    { title: SuffixTitles.SENE, multiple: false },
    { title: SuffixTitles.SH, multiple: false },
    { title: SuffixTitles.SHA, multiple: false },
    { title: SuffixTitles.SHDA, multiple: false },
    { title: SuffixTitles.SHDAE, multiple: false },
    { title: SuffixTitles.SHDE, multiple: false },
    { title: SuffixTitles.SHDEE, multiple: false },
    { title: SuffixTitles.SHDM, multiple: false },
    { title: SuffixTitles.SHDME, multiple: false },
    { title: SuffixTitles.SHDN, multiple: false },
    { title: SuffixTitles.SHDNE, multiple: false },
    { title: SuffixTitles.SHR, multiple: false },
    { title: SuffixTitles.SHU, multiple: false },
    { title: SuffixTitles.SHUA, multiple: false },
    { title: SuffixTitles.SIA, multiple: false },
    { title: SuffixTitles.SIAE, multiple: false },
    { title: SuffixTitles.SIE, multiple: false },
    { title: SuffixTitles.SIEE, multiple: false },
    { title: SuffixTitles.SIM, multiple: false },
    { title: SuffixTitles.SIME, multiple: false },
    { title: SuffixTitles.SIN, multiple: false },
    { title: SuffixTitles.SINE, multiple: false },
    { title: SuffixTitles.SWA, multiple: false },
    { title: SuffixTitles.SWAE, multiple: false },
    { title: SuffixTitles.SWD, multiple: false },
    { title: SuffixTitles.SWE, multiple: false },
    { title: SuffixTitles.SWEE, multiple: false },
    { title: SuffixTitles.SWM, multiple: false },
    { title: SuffixTitles.SWME, multiple: false },
    { title: SuffixTitles.SWN, multiple: false },
    { title: SuffixTitles.SWNE, multiple: false },
    { title: SuffixTitles.T2B, multiple: true },
    { title: SuffixTitles.T2BP, multiple: true },
    { title: SuffixTitles.TD, multiple: false },
    { title: SuffixTitles.TDD, multiple: false },
    { title: SuffixTitles.TDU, multiple: true },
    { title: SuffixTitles.TDX, multiple: true },
    { title: SuffixTitles.THD, multiple: false },
    { title: SuffixTitles.THDA, multiple: false },
    { title: SuffixTitles.THDD, multiple: false },
    { title: SuffixTitles.THDN, multiple: false },
    { title: SuffixTitles.THDX, multiple: false },
    { title: SuffixTitles.TKA, multiple: false },
    { title: SuffixTitles.TKI, multiple: false },
    { title: SuffixTitles.TKN, multiple: false },
    { title: SuffixTitles.TKP, multiple: false },
    { title: SuffixTitles.TQX, multiple: false },
    { title: SuffixTitles.TQXP, multiple: false },
    { title: SuffixTitles.TT, multiple: false },
    { title: SuffixTitles.UD, multiple: false },
    { title: SuffixTitles.UDX, multiple: true },
    { title: SuffixTitles.VCD, multiple: true },
    { title: SuffixTitles.VER, multiple: false },
    { title: SuffixTitles.VST, multiple: true },
    { title: SuffixTitles.WC, multiple: false },
    { title: SuffixTitles.WCI, multiple: false },
    { title: SuffixTitles.WCX, multiple: false },
    { title: SuffixTitles.WDS1, multiple: false },
    { title: SuffixTitles.WDS2, multiple: false },
    { title: SuffixTitles.WDS3, multiple: false },
    { title: SuffixTitles.XF, multiple: false },
    { title: SuffixTitles.XFP, multiple: false }
]

export interface DogTitle {
    title: PrefixTitles | SuffixTitles,
    order: number
}

export class DogName {
    public static dogNameFromJSON(x: DogNameInterface): DogName {
        return new DogName(x.baseName, x.prefixTitles, x.suffixTitles)
    }

    private _baseName: string
    private _prefixTitles: Array<DogTitle>
    private _suffixTitles: Array<DogTitle>

    public get prefixTitles(): Array<DogTitle> {
        return this._prefixTitles
    }
    public set prefixTitles(x : Array<DogTitle>){
        this._prefixTitles = x
    }
    public get suffixTitles(): Array<DogTitle> {
        return this._suffixTitles
    }
    public set suffixTitles(x : Array<DogTitle>){
        this._suffixTitles = x
    }

    public get baseName(): string {
        return this._baseName
    }
    public set baseName(x : string){
        this._baseName =x
    }

    public get name(): string {
        return this.fullName()
    }


    constructor()
    constructor(baseName: string, prefixTitles: Array<DogTitle>, suffixTitles: Array<DogTitle>)
    constructor(baseName?: string, prefixTitles?: Array<DogTitle>, suffixTitles?: Array<DogTitle>) {
        this._baseName = baseName || ''
        this._prefixTitles = prefixTitles || []
        this._suffixTitles = suffixTitles || []
    }

    public setName(value: string) {
        this.nameFunction(value)
    }

    public forceName(value: string) {
        this._baseName = value
        this._prefixTitles = []
        this._suffixTitles = []
    }

    public clone(): DogName {
        let prefixTitles: Array<DogTitle> = []
        for (let title of this._prefixTitles) {
            prefixTitles.push(Object.assign({}, title))
        }
        let suffixTitles: Array<DogTitle> = []
        for (let title of this._suffixTitles) {
            suffixTitles.push(Object.assign({}, title))
        }
        return new DogName(this._baseName, prefixTitles, suffixTitles)
    }


    private findTitle(
        x: string,
        titles: Array<{ title: PrefixTitles | SuffixTitles; multiple: boolean }>
    ): DogTitle | null {
        let alphaNumbericTitles = titles.filter((y) => {
            return y.title.match(/\d+/g);
        });
        for (let title of alphaNumbericTitles) {
            if (title.title === x.toUpperCase().trim()) {
                return { title: title.title, order: 1 };
            }
        }
        let numberString = "";
        let letterString = x;
        let numbers = x.match(/\d+/g);
        if (numbers) {
            if (x.match(/\d+\D+/g)) {
                return null;
            }
            let idx = x.search(numbers[0]);
            numberString = x.substring(idx);
            letterString = x.substring(0, idx);
        }
        letterString = letterString.replace(".", "").toUpperCase();
        let title = titles.find((y) => {
            return y.title === letterString;
        });
        if (title) {
            if (!title.multiple && numberString.length > 0) {
                return null;
            } else {
                let order = 1;
                if (numberString.length > 0) {
                    order = parseInt(numberString);
                }
                return { title: title.title, order: order };
            }
        } else {
            return null;
        }
    }

    private fullName(): string {
        let prefixString = this._prefixTitles
            .map((x) => {
                return x.title + (x.order > 1 ? x.order : "");
            })
            .join(" ");
        let suffixString = this._suffixTitles
            .map((x) => {
                return x.title + (x.order > 1 ? x.order : "");
            })
            .join(" ");
        prefixString = prefixString.length > 0 ? prefixString + " " : "";
        suffixString = suffixString.length > 0 ? " " + suffixString : "";
        return prefixString + this._baseName + suffixString;
    }

    private nameFunction(name: string) {
        let trimmedName = name
            .split(" ")
            .filter((x) => {
                return x.length > 0;
            })
            .join(" ")
            .trim();
        let pieces = trimmedName.split(" ");
        let prefixTitles: Array<DogTitle> = []
        let suffixTitles: Array<DogTitle> = []
        let baseName: string = ""

        let baseBeginIdx = 0;
        let baseEndIdx = 0;
        for (let k = 0; k < pieces.length; k++) {
            let piece = pieces[k];
            let title = this.findTitle(piece, PREFIX_TITLES);
            if (title) {
                prefixTitles.push(title);
            } else {
                baseBeginIdx = k;
                break;
            }
        }

        for (let k = pieces.length - 1; k >= 0; k--) {
            let piece = pieces[k];
            let title = this.findTitle(piece, SUFFIX_TITLES);
            if (title) {
                suffixTitles.push(title);
            } else {
                baseEndIdx = k;
                break;
            }
        }
        suffixTitles = suffixTitles.reverse();

        let baseNamePieces: Array<string> = [];
        for (let k = baseBeginIdx; k <= baseEndIdx; k++) {
            baseNamePieces.push(pieces[k]);
        }
        baseName = baseNamePieces.join(" ");
        if (
            baseName === baseName.toUpperCase() ||
            baseName === baseName.toLowerCase()
        ) {
            baseName = baseName
                .split(" ")
                .map((x) => {
                    return (
                        x.charAt(0).toUpperCase() +
                        (x.length > 1 ? x.slice(1).toLowerCase() : "")
                    );
                })
                .join(" ");
        }
        this._prefixTitles = prefixTitles
        this._suffixTitles = suffixTitles
        this._baseName = baseName
        console.log(this._baseName)
    }

    printPrefixTitles():string{
        return this.prefixTitles.map((x) => {return (x.order > 1 ? x.title+x.order : x.title)}).join(" ")
    }

    printSuffixTitles():string{
        return this.suffixTitles.map((x) => {return (x.order > 1 ? x.title+x.order : x.title)}).join(" ")
    }

    printName():string{
        return (this._prefixTitles.length > 0 ? this.printPrefixTitles() + " ": "") + this._baseName + (this._suffixTitles.length > 0 ? " " + this.printSuffixTitles() : "")
    }

    public exportInterface(): DogNameInterface{
        let out: DogNameInterface = {
            baseName: this._baseName,
            suffixTitles: this._suffixTitles,
            prefixTitles: this._prefixTitles
        }
        return out
    }
}

export interface DogNameInterface {
    baseName: string,
    prefixTitles: Array<DogTitle>
    suffixTitles: Array<DogTitle>
}