
import { Component, Vue, Prop } from "vue-property-decorator";
import UserStore from "../store/modules/user";
import DogStore from "../store/modules/dog";
import PaymentStore from "../store/modules/payment";
import Loading from "./Loading.vue";
import ExpansionList from "./ExpansionList.vue";
import { Payment, ReceiptRequest } from "@app/common";
import { fetchReceipt } from "../services/receipt";
@Component({
  components: {
    Loading,
    ExpansionList,
  },
})
export default class DogPayments extends Vue {
  @Prop({ default: null }) readonly show!: number | null;
  @Prop({ default: false }) readonly refund!: boolean;
  @Prop({ default: false }) readonly showRefunds!: boolean;
  get loading(): boolean {
    return (
      PaymentStore.fetchingPaymentData ||
      DogStore.fetchingDogData ||
      DogStore.dogDataNeedsUpdating
    );
  }
  async mounted() {
    if (DogStore.dogDataNeedsUpdating && !DogStore.fetchingDogData) {
      await DogStore.fetchDogData();
    }
    if (PaymentStore.paymentDataNeedsUpdating && !PaymentStore.fetchPayments) {
      await PaymentStore.fetchPayments();
    }
  }
  get payments(): Array<Payment> {
    if (this.show) {
      let payments: Array<Payment> = PaymentStore.payments.filter((x) => {
        return x.show.id == this.show;
      });
      if (this.refund) {
        let out : Array<Payment> = []
        for(let payment of payments){
          for(let entry of payment.entries){
            if(!entry.refunded){
              out.push(payment)
              break
            }
          }
        }
        return out
      } else {
        return payments;
      }
    }
    return PaymentStore.payments;
  }

  async fetchReceipt(id: number) {
    let payload: ReceiptRequest = {
      id: id,
      type: "PAYMENT",
    };
    await fetchReceipt(payload);
  }

  refundPayment(x: Payment) {
    this.$emit("refund-payment", x);
  }

  test() {
    this.payments[0].entries[0].entry.classes[0].dogClass.name;
    this.payments[0].catalogs;
  }
}
