
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import fuzzySearch from "../services/fuzzySearch";
@Component
export default class FuzzyAutocomplete<K> extends Vue {
  @Prop() readonly label!: string;
  @Prop() readonly items!: Array<K>;
  @Prop({ default: false }) readonly showAll!: boolean;
  @Prop() readonly value!: K | null;
  @Prop({
    default: () => {
      return [];
    },
  })
  readonly errorMessages!: Array<string>;
  @Prop({
    default: () => {
      return [];
    },
  })
  readonly messages!: Array<string>;
  @Prop({ default : () => {return []}}) readonly synonyms!: Array<{word: string, similarTo: string}>
  @Prop({ default: false }) readonly dense!: boolean;
  @Prop({ default: false }) readonly hideDetails!: boolean;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({
    default: (x: any) => {
      return "";
    },
  })
  readonly stringExtract!: (x: any) => string;
  @Prop({
    default: () => {return (x : any) => {return x} }
  })
  readonly valueExtract!: (x: any) => any;
  
  @Prop({default : 1}) readonly minSearchLength!:number
  search: string = "";
  selectItems: Array<any> = [];
  select: any = null;

  mounted() {
    this.selectItems = this.items;
  }

  update() {
    this.$emit("input", this.select);
  }

  @Watch("search")
  onSearchChange() {
    if (this.search && this.search.length >= this.minSearchLength) {
      let fs = new fuzzySearch(this.stringExtract, this.synonyms);
      fs.input = this.items;
      fs.search = this.search;
      this.selectItems = fs.output;
    }else{
      this.selectItems = []
    }
  }

  @Watch("value")
  onValueChange(){
    this.selectItems = this.items
    this.select = this.value
  }
}
