
import { LoginRequest } from "@app/common";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import UserStore from "../../store/modules/user";
import sha512 from "../../services/hash";

@Component
export default class LoginWindow extends Vue {
  shake = false;
  loginReady = false;
  email = "";
  password = "";
  showPassword = false;

  @Watch("loginReady")
  onLoginReadyChange(val: boolean) {
    this.$emit("ready", val);
  }

  @Watch("email")
  onEmailChange(val: string) {
    this.$emit("email", val);
  }

  get rules() {
    return {
      required: (x: string) => {
        return x === "" ? "This field is required" : true;
      },
      passwordRequired: (x: string) => {
        return !x || x.trim() === "" ? "Your password is required" : true;
      },
      emailRequired: (x: string) => {
        return !x || x.trim() === "" ? "Your email is required" : true;
      },
    };
  }

  get loginFailed(): boolean {
    if (UserStore.loginError) {
      return true;
    } else {
      return false;
    }
  }

  get loginBruteCheck(): boolean {
    if (UserStore.loginError && UserStore.loginError === "FAILED_BRUTE_CHECK") {
      return true;
    } else {
      return false;
    }
  }

  async login() {
    if (this.loginReady) {
      this.shake = false;
      let hashedPassword = await sha512(this.password + this.email.trim().toLowerCase());
      let payload: LoginRequest = {
        email: this.email.trim().toLowerCase(),
        password: hashedPassword,
        csrfSecurityToken: UserStore.csrfSecurityToken,
      };
      await UserStore.login(payload);
      if (this.loginFailed && UserStore.loginError !== "EMAIL_NOT_VALIDATED") {
        this.shake = true;
      } else if (
        this.loginFailed &&
        UserStore.loginError === "EMAIL_NOT_VALIDATED"
      ) {
        this.$emit("next");
      } else {
        this.$emit("close");
      }
    }
  }

  mounted() {
    let localEmail = localStorage.getItem("email");
    if (localEmail) {
      this.email = localEmail.trim().toLowerCase();
    }
  }
}
