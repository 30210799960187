
import { SquareToken } from "@app/common";
import Loading from "./Loading.vue";
import NewShowStore from "../store/modules/newshow";
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({ components: { Loading } })
export default class DogSquareTokens extends Vue {
  // @Prop({ default: new SquareToken(-1, "", new Date(), false, []) })
  // readonly squareToken!: SquareToken;
  // @Prop({ default: null }) readonly idx!: number | null;
  // longExpiration: boolean = false;
  @Prop({ default: false }) readonly select!: boolean;
  get loading(): boolean {
    return NewShowStore.fetchingSquareTokens;
  }

  async mounted() {
    await NewShowStore.fetchSquareTokens();
    if(NewShowStore.loadFromLocalStorage){
      let tokenIdx : number = this.squareTokens.findIndex((x)=>{return x.id == NewShowStore.squareToken})
      if(tokenIdx > - 1){
        this.selectToken(tokenIdx)
      }
    }
  }

  get squareTokens(): Array<SquareToken> {
    return NewShowStore.squareTokens;
  }

  //selectedToken: SquareToken | null = null
  selectedTokenIdx: number = -1;

  get selectedStyle(): { "background-color": string; color: string } {
    return {
      "background-color": this.$vuetify.theme.themes.light.primary as string,
      color: "white !important",
    };
  }

  selectToken(idx: number) {
    if (this.select) {
      if (idx === this.selectedTokenIdx) {
        this.selectedTokenIdx = -1;
      } else {
        this.selectedTokenIdx = idx;
        console.log(this.squareTokens[idx]);
        NewShowStore.setSquareToken(this.squareTokens[idx].id);
      }
    }
  }

  get authURL(): string {
    return process.env.VUE_APP_DOG_SITE + "/square-authorize";
  }

  addNewToken() {
    window.open(process.env.VUE_APP_DOG_SITE + "/square-authorize");
  }

  async refresh() {
    await NewShowStore.fetchSquareTokens();
  }

  showAll: Array<boolean> = [];
  shows(id: number): Array<{ id: number; name: string }> {
    if (this.showAll[id]) {
      return this.squareTokens[id].shows;
    } else {
      return this.squareTokens[id].shows.slice(0, 3);
    }
  }

  changeShowAll(idx : number){
    this.$set(this.showAll,idx,!this.showAll[idx])
  }
}
