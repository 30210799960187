
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class ExpansionList extends Vue {
  @Prop({default : false}) readonly insetShadow!: boolean
  styles = ["panel-content-closed", "panel-content-open"];
  panelStyles = ["panel-face-unselected", "panel-face-selected"];
  arrowStyle = ["arrow", "arrow-up"];
  idx = 0;

  @Prop(String) readonly icon!: string;
  @Prop({default : null}) readonly iconColor!:string;
}
