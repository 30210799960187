
import { DogEvent, EventClass } from "@app/common";
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class ClassesTable extends Vue {
  @Prop({
    default: () => {
      return [];
    },
  })
  readonly events!: Array<DogEvent>;
  @Prop({
    default: () => {
      return [];
    },
  })
  readonly eventClasses!: Array<Array<EventClass>>;
  @Prop({default : false}) readonly showPointer!:boolean
  @Prop({default : false}) readonly showHover!:boolean
  // @Prop({ default: null }) readonly selectedEvent!: DogEvent | null;
  selectedEvent : DogEvent | null = null

  get selectedEventIdx(): number {
    if (this.selectedEvent) {
      let dogEvent = this.selectedEvent;
      return this.events.findIndex((x) => {
        return x.id == dogEvent.id;
      });
    } else {
      return -1;
    }
  }

  get selectedEventClasses(): Array<EventClass> {
    if (this.selectedEvent) {
      return this.eventClasses[this.selectedEventIdx];
    } else {
      console.log("null")
      return [];
    }
  }

  selectEvent(idx : number){
    this.selectedEvent = this.events[idx]
    this.$emit("event-changed",idx)
  }

  mounted() {
    this.selectedEvent = this.events[0]
  }

  clickedClass(idx : number){
    this.$emit("clickedClass",idx)
  }

  get selectedStyle(): { "background-color": string; color: string } {
    return {
      "background-color": this.$vuetify.theme.themes.light.primary as string,
      color: "white",
    };
  }
  
}
