
import { Breed, DogTitle, nullBreed, Sex } from "@app/common";
import { DogClass, DogClassType } from "@app/common/classes";
import { Rule, RuleSet } from "@app/common/class_rules";
import { Component, Vue, Prop } from "vue-property-decorator";
const fuzz = require("fuzzball");

@Component
export default class ClassFromText extends Vue {
  @Prop({ default: () => {return nullBreed} }) readonly breed!: Breed;
  text: string = "";
  windowValue: number = 0;
  close() {}
  next() {
    if (this.windowValue == 0) {
      this.extractClasses()
      this.windowValue = 1;
    } else if (this.windowValue == 1) {
    }
  }

  textClasses: Array<string> = [];
  dogClasses: Array<DogClass> = [];
  extractClasses() {
    let textClasses: Array<string> = this.text.split(/\r?\n/);
    textClasses = textClasses
      .map((x) => {
        return x.trim();
      })
      .filter((x) => {
        return x.length > 0;
      });
    this.textClasses = textClasses;
    this.dogClasses = []

    for (let textClass of this.textClasses) {
      let testText = textClass.toLowerCase();
      let ruleSet: RuleSet = {};
      let dogClassType : DogClassType = DogClassType.CONFORMATION_REGULAR
      if (!testText.includes("beginner")) {
        ruleSet.age = { min: 6 };
      } else {
        ruleSet.age = { min: 4, max: 6 };
      }

      let firstNumberMatch = testText.match(/\d/)
      if(firstNumberMatch && firstNumberMatch.index){
        let firstNumberIdx : number = firstNumberMatch.index
        let subString : string = testText.substring(firstNumberIdx)
        let useMonths : boolean = testText.includes('mos') || testText.includes('month')
        let numbers = subString.match(/\d+/g)
        if(numbers && numbers.length <= 2){
          let factor : number = (useMonths ? 1 : 12)
          if(numbers.length == 2){
            ruleSet.age = {min : factor*parseInt(numbers[0]), max: factor*parseInt(numbers[1])}
          }else{
            ruleSet.age = {min : factor*parseInt(numbers[0])}
          }
        }
      }

      if (testText.includes("dog")) {
        ruleSet.sex = Sex.MALE;
      } else if (testText.includes("bitch")) {
        ruleSet.sex = Sex.FEMALE;
      }

      if (testText.includes("bred-by")) {
        ruleSet.noHandler = true;
      }

      if (testText.includes("american")) {
        ruleSet.usaNumberOne = true;
      }


      let prerequesuiteClasses : Array<DogClassType> = []
      if (testText.includes("sweepstake")) {
        prerequesuiteClasses.push(DogClassType.CONFORMATION_REGULAR)
        dogClassType = DogClassType.SWEEPSTAKE
      }

      if(testText.includes('stud') || testText.includes('brood')){
        dogClassType = DogClassType.CONFORMATION_NON_REGULAR
      }

      if(testText.includes('rally')){
        dogClassType = DogClassType.RALLY_REGULAR
      }

      let dogClass : DogClass = new DogClass(-1,testText,new Rule(ruleSet),prerequesuiteClasses,dogClassType,true)
      this.dogClasses.push(dogClass)
    }
  }
}
