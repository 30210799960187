
import { Breed, Color, Dog, Sex, Variety } from "@app/common";
import { DogClassType } from "@app/common/classes";
import { RuleSet } from "@app/common/class_rules";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class DogRule extends Vue {
  @Prop({
    default: () => {
      return { rules: {}, prerequisiteClasses: [] };
    },
  })
  readonly value!: {
    rules: RuleSet;
    prerequesuiteClasses: Array<DogClassType>;
  };
  requireSex: boolean = false;
  requireMinAge: boolean = true;
  requireMaxAge: boolean = false;
  minAge: number | null = 6;
  maxAge: number | null = null;
  sex: "MALE" | "FEMALE" = "MALE";
  requireColor: boolean = false;
  selectedColors: Array<Color> = [];
  requirePrerequisiteClasses: boolean = false;
  classTypes: Array<DogClassType> = Object.values(DogClassType);
  selectedTypes: Array<DogClassType> = [];
  requireBreed: boolean = false;
  breeds: Array<Breed> = Dog.breeds;
  selectedBreed: Breed | null = null;
  requireVariety: boolean = false;
  selectedVariety: Variety | null = null;
  usBorn: boolean = false;
  noHandler: boolean = false;
  noTitles: boolean = false;
  requireExcludeColor: boolean = false;
  excludedColor: Color | null = null;

  get varieties(): Array<Variety> {
    if (this.selectedBreed) {
      return Dog.getVaritiesByBreed(this.selectedBreed.id);
    }
    return [];
  }

  get colors(): Array<Color> {
    if (
      this.selectedBreed &&
      (this.varieties.length == 0 || this.selectedVariety)
    ) {
      return Dog.getColorsByBreedAndVariety(
        this.selectedBreed.id,
        this.selectedVariety ? this.selectedVariety.id : null
      );
    }
    return [];
  }

  get ruleSet(): RuleSet {
    let newRuleSet: RuleSet = {};
    if (this.requireSex) {
      newRuleSet.sex = this.sex === "MALE" ? Sex.MALE : Sex.FEMALE;
    }
    if (this.requireVariety && this.selectedVariety) {
      newRuleSet.variety = this.selectedVariety.id;
    }
    if (this.requireBreed && this.selectedBreed) {
      newRuleSet.breed = this.selectedBreed.id;
    }
    if (
      this.requireMinAge &&
      this.requireMaxAge &&
      this.minAge &&
      this.maxAge
    ) {
      newRuleSet.age = { min: this.minAge, max: this.maxAge };
    } else if (this.requireMinAge && this.minAge) {
      newRuleSet.age = { min: this.minAge };
    } else if (this.requireMaxAge && this.maxAge) {
      newRuleSet.age = { max: this.maxAge };
    }
    if (this.requireColor && this.selectedColors.length == 1) {
      newRuleSet.color = this.selectedColors[0].id;
    } else if (this.requireColor && this.selectedColors.length > 1) {
      newRuleSet.colorSet = this.selectedColors.map((x) => {
        return x.id;
      });
    }

    if (this.usBorn) {
      newRuleSet.usaNumberOne = true;
    }
    if (this.noHandler) {
      newRuleSet.noHandler = true;
    }
    if (this.noTitles) {
      newRuleSet.noPrefixTitles = true;
    }

    return newRuleSet;
  }

  get breedSelected(): boolean {
    return this.requireBreed && !!this.selectedBreed;
  }

  get enableVarieties(): boolean {
    return (
      this.requireBreed && !!this.selectedBreed && this.varieties.length > 0
    );
  }

  get enableColors(): boolean {
    if (this.requireBreed && !!this.selectedBreed) {
      return (
        this.varieties.length == 0 ||
        (this.requireVariety && !!this.selectedVariety)
      );
    }
    return false;
  }

  update() {
    console.log(this.ruleSet);
    this.$nextTick(() => {
      this.$emit("input", {
        rules: this.ruleSet,
        prerequisiteClasses: this.selectedTypes,
      });
    });
  }

  mounted() {
    console.log("Dog rule mounted");
  }

  @Watch("requireBreed")
  onRequireBreedChnage(x: boolean) {
    if (!x) {
      this.selectedVariety = null;
      this.selectedColors = [];
      this.excludedColor = null;
      this.requireVariety = false;
      this.requireColor = false;
      this.requireExcludeColor = false;
    }
  }

  @Watch("selectedBreed")
  onSelectedBreedChange() {
    this.selectedVariety = null;
    this.selectedColors = [];
    this.excludedColor = null;
    this.requireVariety = false;
    this.requireColor = false;
    this.requireExcludeColor = false;
  }

  @Watch("selectedVariety")
  onSelectedVarietyChange(){
    this.selectedColors = [];
    this.excludedColor = null;
    this.requireColor = false;
    this.requireExcludeColor = false;
  }

  @Watch("value")
  onValueChange(x: {
    rules: RuleSet;
    prerequisiteClasses: Array<DogClassType>;
  }) {
    console.log("DOG RULE VALUE CHANGE");
    if (x.rules.sex) {
      this.requireSex = true;
      this.sex = x.rules.sex === Sex.MALE ? "MALE" : "FEMALE";
    } else {
      this.requireSex = false;
    }
    if (x.rules.age && x.rules.age.min) {
      this.requireMinAge = true;
      this.minAge = x.rules.age.min;
    } else {
      this.requireMinAge = false;
    }
    if (x.rules.age && x.rules.age.max) {
      this.requireMaxAge = true;
      this.maxAge = x.rules.age.max;
    } else {
      this.requireMaxAge = false;
    }
    if (x.rules.variety) {
      this.requireVariety = true;
      this.selectedVariety = Dog.findVariety(x.rules.variety);
    } else {
      this.requireVariety = false;
    }
    if (x.rules.color) {
      this.requireColor = true;
      this.selectedColors = [Dog.findColor(x.rules.color)];
    }

    if (x.rules.colorSet) {
      this.requireColor = true;
      this.selectedColors = x.rules.colorSet.map((y) => {
        return Dog.findColor(y);
      });
    }

    if (!x.rules.color && !x.rules.colorSet) {
      this.requireColor = false;
    }

    if (x.rules.notColor) {
      this.requireExcludeColor = true;
      this.excludedColor = Dog.findColor(x.rules.notColor);
    } else {
      this.requireExcludeColor = false;
    }
    if (x.rules.usaNumberOne) {
      this.usBorn = true;
    } else {
      this.usBorn = false;
    }
    if (x.rules.noHandler) {
      this.noHandler = true;
    } else {
      this.noHandler = false;
    }
    if (x.rules.noPrefixTitles) {
      this.noTitles = true;
    } else {
      this.noTitles = false;
    }
    if (x.prerequisiteClasses.length > 0) {
      console.log(x.prerequisiteClasses);
      this.requirePrerequisiteClasses = true;
      this.selectedTypes = x.prerequisiteClasses;
    } else {
      this.requirePrerequisiteClasses = false;
      this.selectedTypes = [];
    }
  }
}
