
import { Judge } from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class DogJudge extends Vue {
    @Prop({default : () =>{return new Judge()}}) readonly value! : Judge
    name : string = ""
    number : string = ""
    get disabled():boolean{
        return this.value.id !== null
    }
    @Prop({default : false}) readonly edit!: boolean
    mounted(){
        this.name = this.value.name || ""
        this.number = this.value.number || ""
        console.log("judge")
        console.log(this.value)
    }
    @Watch("value")
    onJudgeChange(x : Judge){
        this.name = x.name || ""
        this.number = x.number || ""
    }
    update(){
        this.$emit("input",new Judge(null,this.name,this.number))
    }
}
