
import {
  Address,
  Breed,
  Dog,
  Group,
  ShowInterface,
  ShowType,
} from "@app/common";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import DogAddress from "../DogForm/DogAddress/DogAddress.vue";
import FuzzyAutocomplete from "../FuzzyAutocomplete.vue";
import NewShowStore from "../../store/modules/newshow";
import SecretaryStore from "../../store/modules/secretary"
@Component({ components: { DogAddress, FuzzyAutocomplete } })
export default class NewShowInfo extends Vue {
  @Prop() readonly show!: ShowInterface;
  showType: ShowType | null = null;
  showTypes: Array<ShowType> = Object.values(ShowType);
  showName: string = "";
  showSpecialty: boolean = false;
  showGroup: boolean = false;
  breed: Breed | null = null;
  breeds: Array<Breed> = Dog.breeds;
  group: Group | null = null;
  groups: Array<Group> = Dog.groups;
  openingDateMenu: boolean = false;
  openingDate: string | null = null;
  openingTimeMenu: boolean = false;
  openingTime: string | null = null;
  openTimeZone: { name: string; utcOffset: number } | null = null;
  openingDateAndTime: Date = new Date();
  closingDateMenu: boolean = false;
  closingDate: string | null = null;
  closingTimeMenu: boolean = false;
  closingTime: string | null = null;
  closeTimeZone: { name: string; utcOffset: number } | null = null;
  closingDateAndTime: Date = new Date();
  showAddress: Address = new Address();
  timeZones = [
    { name: "UTC", utcOffset: 0 },
    { name: "Atlantic Standard Time", utcOffset: -4 * 60 * 60 * 1000 },
    { name: "Atlantic Daylight Time", utcOffset: -3 * 60 * 60 * 1000 },
    { name: "Eastern Standard Time", utcOffset: -5 * 60 * 60 * 1000 },
    { name: "Eastern Daylight Time", utcOffset: -4 * 60 * 60 * 1000 },
    { name: "Cental Standard Time", utcOffset: -6 * 60 * 60 * 1000 },
    { name: "Cental Daylight Time", utcOffset: -5 * 60 * 60 * 1000 },
    { name: "Mountain Standard Time", utcOffset: -7 * 60 * 60 * 1000 },
    { name: "Mountain Daylight Time", utcOffset: -6 * 60 * 60 * 1000 },
    { name: "Pacific Standard Time", utcOffset: -8 * 60 * 60 * 1000 },
    { name: "Pacific Daylight Time", utcOffset: -7 * 60 * 60 * 1000 },
    { name: "Alaska Standard Time", utcOffset: -9 * 60 * 60 * 1000 },
    { name: "Alaska Daylight Time", utcOffset: -8 * 60 * 60 * 1000 },
    { name: "Hawaii-Aleutian Standard Time", utcOffset: -10 * 60 * 60 * 1000 },
    { name: "Hawaii-Aleutian Daylight Time", utcOffset: -9 * 60 * 60 * 1000 },
    { name: "American Samoa Standard Time", utcOffset: -11 * 60 * 60 * 1000 },
    { name: "Chamorro Standard Time", utcOffset: 10 * 60 * 60 * 100 },
  ];

  @Watch("showType")
  onShowTypeChange(x: ShowType | null) {
    this.showSpecialty = false;
    this.showGroup = false;
    if (x && x === ShowType.GROUP) {
      Dog.groups;
    } else if (x && x === ShowType.SPECIALTY) {
    }
  }

  breedExtract(x: Breed): string {
    return x.breed;
  }

  get showBreeds(): boolean {
    return !!this.showType && this.showType === ShowType.SPECIALTY;
  }

  get showGroups(): boolean {
    return !!this.showType && this.showType === ShowType.GROUP;
  }

  showWebsite: string = "";
  webAddressError: Array<string> = [];
  update() {
    console.log("update");
    if (
      this.showName.length > 0 &&
      this.showType &&
      this.openingDate &&
      this.openingTime &&
      this.openTimeZone &&
      this.closingDate &&
      this.closingTime &&
      this.closeTimeZone
    ) {
      let openingUNIXTime: number = this.getUNIXTime(
        new Date(this.openingDate),
        this.openingTime,
        this.openTimeZone
      );
      let closingUNIXTime: number = this.getUNIXTime(
        new Date(this.closingDate),
        this.closingTime,
        this.closeTimeZone
      );
      NewShowStore.setShowInfo({
        name: this.showName,
        webAddress: this.showWebsite,
        type: this.showType,
        address: this.showAddress.exportInterface(),
        openingTime: openingUNIXTime,
        closingTime: closingUNIXTime,
        group: this.group ? this.group : undefined,
        breed: this.breed ? this.breed : undefined,
      });
      console.log(NewShowStore.show);
      NewShowStore.setShowInfoReady(true);
    } else {
      NewShowStore.setShowInfoReady(false);
    }
  }

  getUNIXTime(
    date: Date,
    time: string,
    timeZone: { name: string; utcOffset: number }
  ): number {
    let out: number = date.getTime();
    out +=
      1000 *
      (60 * 60 * parseInt(time.split(":")[0]) +
        60 * parseInt(time.split(":")[1]));
    out -= timeZone.utcOffset;
    return out;
  }

  async testWebAddress() {
    if (
      this.showWebsite.substring(0, 8).toLowerCase() !== "https://" &&
      this.showWebsite.substring(0, 7).toLowerCase() !== "http://"
    ) {
      this.showWebsite = "https://" + this.showWebsite;
    }

    // const response = await fetch(
    //   this.showWebsite,
    //   {
    //     headers: {
    //       Accept: "text/plain",
    //       "Content-Type": "text/plain",
    //     },
    //     method: "GET",
    //   }
    // );
    try {
      const response = await fetch(this.showWebsite);
      console.log(response);
      if (!response.ok) {
        this.webAddressError = ["Invalid web address."];
      } else {
        this.webAddressError = [];
      }
    } catch (err) {
      this.webAddressError = ["Invalid web address."];
    }
  }

  mounted(){
    if(NewShowStore.loadFromLocalStorage){
      let show : ShowInterface = NewShowStore.show
      this.showType = show.type
      if(show.breed){
        this.breed = Dog.findBreed(show.breed)
      }
      this.showName = show.name
      this.showAddress = Address.addressFromJSON(show.address)
      let openingTime : Date = new Date(show.openingTime)
      let closingTime : Date = new Date(show.closingTime)
      this.openTimeZone = this.timeZones[0]
      this.closeTimeZone = this.timeZones[0]
      //this.openingDate = openingTime.getUTCFullYear() + "-" + (openingTime.getUTCMonth()+1) + "-" + openingTime.getUTCDate()
      //this.closingDate = closingTime.getUTCFullYear() + "-" + (closingTime.getUTCMonth()+1) + "-" + closingTime.getUTCDate()
      this.openingDate = openingTime.toISOString().split('T')[0]
      this.closingDate = closingTime.toISOString().split('T')[0]
      //this.openingTime = openingTime.getUTCHours()+":"+openingTime.getUTCMinutes()
      this.openingTime = openingTime.toISOString().split('T')[1].split(':').splice(0,2).join(':')
      //this.closingTime = closingTime.getUTCHours()+":"+closingTime.getUTCMinutes()
      this.closingTime = closingTime.toISOString().split('T')[1].split(':').splice(0,2).join(':')
    }
  }

  validate(): Array<string> {
    let out: Array<string> = [];
    if (this.showType == null) {
      out.push("You must select a show type.");
    }
    if (this.showType == ShowType.SPECIALTY && this.breed == null) {
      out.push("You must select a breed.");
    }
    if (this.showType == ShowType.GROUP && this.group == null) {
      out.push("You must select a group.");
    }
    if (this.openingTime == null) {
      out.push("You must set an opening time.");
    }
    if (this.closingTime == null) {
      out.push("You must set an closing time.");
    }
    out = out.concat((this.$refs.showAddress as any).validate())
    return out;
  }

  get addresses():Array<Address>{
    return SecretaryStore.showAddresses
  }
}
