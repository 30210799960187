
import { DogName } from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class DogTextField extends Vue {
  @Prop() readonly label!: string;
  @Prop() readonly value!: DogName;
  name: string = "";
  oldDogName: string = "";
  retries: number = 0;
  dogName: DogName = new DogName();
  messages: Array<string> = [];
  errorMessages: Array<string> = [];

  update() {
    this.errorMessages = []
    //this.dogName.setName(this.name);
    //this.$emit("input", this.dogName);
  }

  nameFunction() {
    this.dogName.setName(this.name);
    let newDogName: string = this.dogName.name;
    // oldDogName blank
    // If newDogName is same as name, then removed displayed.
    // If newDogName is different than name, name is same as oldDogName, and retries < 2, then display modified and retries++ and set name = newDogName.
    // If newDogName is different than name, name is same as oldDogName, and retries >= 2, then display ok if your insist and leave name unchanged.
    // If newDogName is different than name, name is different than oldDogName, and retries < 2, then display modified and set oldDogName = name and then name = newDogName.
    // If newDogName is different than name, name is different than oldDogName, and retires >=2, then remove display.

    if (newDogName === this.name) {
      this.messages = [];
    } else if (this.name === this.oldDogName && this.retries < 1) {
      this.name = newDogName;
      this.retries++;
      this.messages = ["Name modified to conform to our styling standards."];
      setTimeout(() => {
        this.messages = [];
      }, 2000);
    } else if (this.name === this.oldDogName && this.retries >= 1) {
      this.dogName.forceName(this.name);
      this.messages = ["Ok, if you insist."];
      setTimeout(() => {
        this.messages = [];
      }, 2000);
    } else if (this.retries < 1) {
      this.oldDogName = this.name;
      this.name = newDogName;
      this.messages = ["Name modified to conform to our styling standards."];
      setTimeout(() => {
        this.messages = [];
      }, 2000);
    } else {
      this.oldDogName = this.name;
      this.messages = [];
    }
    this.$emit("input", this.dogName);
  }

  @Watch("value")
  onValueChange() {
    this.dogName = this.value
    this.name = this.dogName.name
  }

  validate(): Array<string>{
    if(this.name.trim().length == 0){
      this.errorMessages = ["You must have a " + this.label.split(" ")[0] + " name."]
      return this.errorMessages
    }
    return []
  }
}
