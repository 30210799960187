import { DogName } from "./dog_titles"
import { Address } from "./people"

export enum CSVDogEntryColumns {
    DOG_ID = 'Dog ID',
    DOG_NAME = 'Dog name',
    DOG_BASE_NAME = 'Dog base name',
    DOG_PREFIX_TITLES = 'Dog prefix titles',
    DOG_SUFFIX_TITLES = 'Dog suffix titles',
    SIRE_NAME = 'Sire name',
    SIRE_BASE_NAME = 'Sire base name',
    SIRE_PREFIX_TITLES = 'Sire prefix titles',
    SIRE_SUFFIX_TITLES = 'Sire suffix titles',
    DAM_NAME = 'Dam name',
    DAM_BASE_NAME = 'Dam base name',
    DAM_PREFIX_TITLES = 'Dam prefix titles',
    DAM_SUFFIX_TITLES = 'Dam suffix titles',
    BREED = 'Breed',
    VARIETY = 'Variety',
    COLOR = 'Color',
    SEX = 'Sex',
    BIRTH_DATE = 'Birth date',
    REGISTRATION_NUMBER = 'Registration number',
    REGISTRATION_TYPE = 'Reigstration type',
    REGISTRATION_COUNTRY = 'Registration country',
    BIRTH_PLACE = 'Birth place',
    ADDRESS = 'Address',
    OWNERS = 'Owners',
    BREEDERS = 'Breeders',
    ENTRY_ID = 'Entry ID',
    EVENT_ID = 'Event ID',
    EVENT_START_DATE = 'Event start date',
    EVENT_END_DATE = 'Event end date',
    EVENT_TYPE = 'Event type',
    EVENT_NUMBER = 'Event number',
    CLASS_NAME = 'Class name',
    CLASS_TYPE = 'Class type',
    AGENT = 'Agent',
    OWNER_HANDLER = 'Owner/Handler eligible',
    NEW_EXHIBITOR = 'New exhibitor',
    PHONE = 'Phone',
    SIGNATURE = 'Signature',
    OBEDIENCE_HEIGHT = 'Obedience height',
    RALLY_HEIGHT = 'Rally height',
    JUNIOR_NAME = 'Junior name',
    JUNIOR_NUMBER = 'Junior number',
    JUNIOR_ADDRESS = 'Junior address',
    JUNIOR_BIRTH_DATE = 'Junior birth date',
    JUNIOR_RELATIONSHIP = 'Junior relationship',
    USER_EMAIL = 'User email',
    USER_FIRST_NAME = 'User first name',
    USER_LAST_NAME = 'User last name',
    UNIT_TIME = 'UNIX time',
    UTC_TIME = 'UTC time',
    PAID = 'Paid',
    PAYMENT_ID = 'Payment ID',
    REFUNDED = 'Refunded',
    REFUND_ID = 'Refund ID',
}

export enum CSVDogDataFormat {
    ALL_IN_ONE_COLUMN = 'All in one column',
    GROUPED_BY_EVENT = 'Grouped by event',
    ROW_FOR_EACH_ITEM = 'Row for each item'
}


export enum CSVPaymentColumns {
    PAYMENT_ID = 'Payment ID',
    PAYMENT_AMOUNT = 'Amount',
    DOGALOG_FEE = 'Dogalog fee',
    SURCHARGE_FEE = 'Surcharge fee',
    PAYMENT_TYPE = 'Payment type',
    UNIX_TIME = 'UNIX time',
    UTC_TIME = 'UTC time',
    USER_EMAIL = 'User email',
    USER_FIRST_NAME = 'User first name',
    USER_LAST_NAME = 'User last name',
    CATALOG = 'Catalog'
}

export enum CSVRefundColumns {
    REFUND_ID = 'Refund ID',
    PAYMENT_ID = 'Payment ID',
    REFUND_AMOUNT = 'Amount',
    DOGALOG_FEE_REFUNDED = 'Dogalog fee refunded',
    SURCHARGE_FEE_REFUNDED = 'Surcharge fee refunded',
    UNIX_TIME = 'UNIX time',
    UTC_TIME = 'UTC time',
    STATUS = 'Status',
    USER_EMAIL = 'User email',
    USER_FIRST_NAME = 'User first name',
    USER_LAST_NAME = 'User last name',
    CATALOG_REFUNDED = 'Catalog refunded'
}

export class CSVDataItem<K extends CSVDogEntryColumns | CSVPaymentColumns | CSVRefundColumns> {
    public static csvDataItemFromJSON<K extends CSVDogEntryColumns | CSVPaymentColumns | CSVRefundColumns>(x : CSVDataItemInterface<K> ) : CSVDataItem<K>{
        return new CSVDataItem<K>(x.column,x.columnName)
    }
    public static csvDataItemArrayFromJSON<K extends CSVDogEntryColumns | CSVPaymentColumns | CSVRefundColumns>(x : Array<CSVDataItemInterface<K>>) : Array<CSVDataItem<K>>{
        return x.map((y) => {return CSVDataItem.csvDataItemFromJSON<K>(y)})
    }

    private _column: K
    private _columnName: string
    get column(): K {
        return this._column
    }
    set column(x: K) {
        this._column = x
    }
    get columnName(): string {
        return this._columnName
    }
    set columnName(x: string) {
        this._columnName = x
    }
    constructor(column: K)
    constructor(column: K, columnName: string)
    constructor(column: K, columnName?: string) {
        this._column = column
        if (columnName && columnName.trim().length > 0) {
            this._columnName = columnName
        } else {
            this._columnName = column
        }
    }
    exportInterface():CSVDataItemInterface<K>{
        return {column : this._column, columnName : this._columnName}
    }
}

export interface CSVDataItemInterface<K extends CSVDogEntryColumns | CSVPaymentColumns | CSVRefundColumns> {
    column: K,
    columnName: string
}

export interface CSVDogEntrySettingsInterface {
    columns: Array<CSVDataItemInterface<CSVDogEntryColumns>>
}

export interface CSVPaymentSettingsInterface {
    columns: Array<CSVDataItemInterface<CSVPaymentColumns>>
}

export interface CSVRefundSettingsInterface {
    columns: Array<CSVDataItemInterface<CSVRefundColumns>>
}


export interface CSVSettings{
    dogEntryColumns? : Array<CSVDataItemInterface<CSVDogEntryColumns>>,
    paymentColumns? : Array<CSVDataItemInterface<CSVPaymentColumns>>,
    refundColumns? : Array<CSVDataItemInterface<CSVRefundColumns>>,
    reduceDogEntry : boolean,
    reducePayment : boolean,
    reduceRefund : boolean,
}

export interface CSVPreset extends CSVSettings{
    id : number,
    name : string
}


export const CSVDogEntryMappings: { [key in CSVDogEntryColumns]: (x: any) => string | null } = {
    [CSVDogEntryColumns.DOG_ID]: (x: any) => { return x.id },
    [CSVDogEntryColumns.DOG_NAME]: (x: any) => { return DogName.dogNameFromJSON(x.name).printName() },
    [CSVDogEntryColumns.DOG_BASE_NAME]: (x: any) => { return x.name.baseName },
    [CSVDogEntryColumns.DOG_PREFIX_TITLES]: (x: any) => { return x.name.prefixTitles },
    [CSVDogEntryColumns.DOG_SUFFIX_TITLES]: (x: any) => { return x.name.suffixTitles },
    [CSVDogEntryColumns.SIRE_NAME]: (x: any) => { return DogName.dogNameFromJSON(x.sire).printName() },
    [CSVDogEntryColumns.SIRE_BASE_NAME]: (x: any) => { return x.sire.baseName },
    [CSVDogEntryColumns.SIRE_PREFIX_TITLES]: (x: any) => { return x.sire.prefixTitles },
    [CSVDogEntryColumns.SIRE_SUFFIX_TITLES]: (x: any) => { return x.sire.suffixTitles },
    [CSVDogEntryColumns.DAM_NAME]: (x: any) => { return DogName.dogNameFromJSON(x.dam).printName() },
    [CSVDogEntryColumns.DAM_BASE_NAME]: (x: any) => { return x.dam.baseName },
    [CSVDogEntryColumns.DAM_PREFIX_TITLES]: (x: any) => { return x.dam.prefixTitles },
    [CSVDogEntryColumns.DAM_SUFFIX_TITLES]: (x: any) => { return x.dam.suffixTitles },
    [CSVDogEntryColumns.BREED]: (x: any) => { return x.breedName },
    [CSVDogEntryColumns.VARIETY]: (x: any) => { return x.varietyName },
    [CSVDogEntryColumns.COLOR]: (x: any) => { return x.colorName },
    [CSVDogEntryColumns.SEX]: (x: any) => { return x.sex },
    [CSVDogEntryColumns.BIRTH_DATE]: (x: any) => { return x.birthDate.toLocaleString('en-US').split(',')[0] },
    [CSVDogEntryColumns.REGISTRATION_NUMBER]: (x: any) => { return x.regNumber },
    [CSVDogEntryColumns.REGISTRATION_TYPE]: (x: any) => { return x.regType },
    [CSVDogEntryColumns.REGISTRATION_COUNTRY]: (x: any) => { return x.regCountry },
    [CSVDogEntryColumns.BIRTH_PLACE]: (x: any) => { return x.id },
    [CSVDogEntryColumns.ADDRESS]: (x: any) => { return Address.addressFromJSON(x.address).printAddress() },
    [CSVDogEntryColumns.OWNERS]: (x: any) => { return x.owners.people.join(", ") },
    [CSVDogEntryColumns.BREEDERS]: (x: any) => { return x.breeders.people.join(", ") },
    [CSVDogEntryColumns.ENTRY_ID]: (x: any) => { return x.entryId },
    [CSVDogEntryColumns.EVENT_ID]: (x: any) => { return x.eventId },
    [CSVDogEntryColumns.EVENT_START_DATE]: (x: any) => { return x.startDate.toLocaleString('en-US').split(',')[0] },
    [CSVDogEntryColumns.EVENT_END_DATE]: (x: any) => { return x.endDate.toLocaleString('en-US').split(',')[0] },
    [CSVDogEntryColumns.EVENT_TYPE]: (x: any) => { return x.eventType },
    [CSVDogEntryColumns.EVENT_NUMBER]: (x: any) => { return x.AKCNumber },
    [CSVDogEntryColumns.CLASS_NAME]: (x: any) => { return x.className },
    [CSVDogEntryColumns.CLASS_TYPE]: (x: any) => { return x.classType },
    [CSVDogEntryColumns.AGENT]: (x: any) => { return x.agent },
    [CSVDogEntryColumns.OWNER_HANDLER]: (x: any) => { return x.ownerHandler },
    [CSVDogEntryColumns.NEW_EXHIBITOR]: (x: any) => { return x.newExhibitor },
    [CSVDogEntryColumns.PHONE]: (x: any) => { return x.phone },
    [CSVDogEntryColumns.SIGNATURE]: (x: any) => { return x.signature },
    [CSVDogEntryColumns.OBEDIENCE_HEIGHT]: (x: any) => { return x.obdienceJumpHeigt },
    [CSVDogEntryColumns.RALLY_HEIGHT]: (x: any) => { return x.rallyJumpHeight },
    [CSVDogEntryColumns.JUNIOR_NAME]: (x: any) => { return x.juniorName },
    [CSVDogEntryColumns.JUNIOR_NUMBER]: (x: any) => { return x.juniorNumber },
    [CSVDogEntryColumns.JUNIOR_ADDRESS]: (x: any) => { return (x.addressId ? new Address(x.addressId, x.street, x.secondary, x.city, x.state, x.postal, x.country, x.addressType).printAddress() : null)},
    [CSVDogEntryColumns.JUNIOR_BIRTH_DATE]: (x: any) => { return (x.juniorBirthDate ? x.juniorBirthDate.toLocaleString('en-US').split(',')[0] : null) },
    [CSVDogEntryColumns.JUNIOR_RELATIONSHIP]: (x: any) => { return x.juniorRelationship },
    [CSVDogEntryColumns.USER_EMAIL]: (x: any) => { return x.email },
    [CSVDogEntryColumns.USER_FIRST_NAME]: (x: any) => { return x.firstName },
    [CSVDogEntryColumns.USER_LAST_NAME]: (x: any) => { return x.lastName },
    [CSVDogEntryColumns.UNIT_TIME]: (x: any) => { return x.time },
    [CSVDogEntryColumns.UTC_TIME]: (x: any) => { return new Date(parseInt(x.time)).toUTCString() },
    [CSVDogEntryColumns.PAID]: (x: any) => { return (x.entryPaymentId !== null ? "true" : "false") },
    [CSVDogEntryColumns.PAYMENT_ID]: (x: any) => { return x.entryPaymentId },
    [CSVDogEntryColumns.REFUNDED]: (x: any) => { return (x.entryRefundId !== null ? "true" : "false") },
    [CSVDogEntryColumns.REFUND_ID]: (x: any) => { return (x.entryRefundId) },


}


export const CSVPaymentMappings: { [key in CSVPaymentColumns]: (x: any) => string | null } = {
    [CSVPaymentColumns.PAYMENT_ID]: (x: any) => { return x.id },
    [CSVPaymentColumns.PAYMENT_AMOUNT]: (x: any) => { return x.amount },
    [CSVPaymentColumns.DOGALOG_FEE]: (x: any) => { return x.dogalogFees },
    [CSVPaymentColumns.SURCHARGE_FEE]: (x: any) => { return x.surcharge },
    [CSVPaymentColumns.PAYMENT_TYPE]: (x: any) => { return x.type },
    [CSVPaymentColumns.UNIX_TIME]: (x: any) => { return x.time },
    [CSVPaymentColumns.UTC_TIME]: (x: any) => { return new Date(parseInt(x.time)).toUTCString() },
    [CSVPaymentColumns.USER_EMAIL]: (x: any) => { return x.email },
    [CSVPaymentColumns.USER_FIRST_NAME]: (x: any) => { return x.firstName },
    [CSVPaymentColumns.USER_LAST_NAME]: (x: any) => { return x.lastName },
    [CSVPaymentColumns.CATALOG]: (x: any) => { return (x.catalogId !== null ? "true" : "false") },
}

export const CSVRefundMappings: { [key in CSVRefundColumns]: (x: any) => string | null } = {
    [CSVRefundColumns.REFUND_ID] : (x : any) => {return x.id},
    [CSVRefundColumns.PAYMENT_ID] : (x : any) => {return x.payment},
    [CSVRefundColumns.REFUND_AMOUNT] : (x : any) => {return x.amount},
    [CSVRefundColumns.DOGALOG_FEE_REFUNDED] : (x : any) => {return x.dogalogFee},
    [CSVRefundColumns.SURCHARGE_FEE_REFUNDED] : (x : any) => {return x.surchargeRefunded},
    [CSVRefundColumns.UNIX_TIME] : (x : any) => {return x.time},
    [CSVRefundColumns.UTC_TIME] : (x : any) => {return new Date(parseInt(x.time)).toUTCString()},
    [CSVRefundColumns.STATUS] : (x : any) => {return x.status},
    [CSVRefundColumns.USER_EMAIL] : (x : any) => {return x.email},
    [CSVRefundColumns.USER_FIRST_NAME] : (x : any) => {return x.firstName},
    [CSVRefundColumns.USER_LAST_NAME] : (x : any) => {return x.lastName},
    [CSVRefundColumns.CATALOG_REFUNDED] : (x : any) => {return (x.catalogId !== null ? "true" : "false")},
}


///This algorithm is slow with O(N^2). Change it to sort rows and then elminate duplicates for O(N log(N)) performance.
export function CSVEliminateRowRedundancy(x : (string | null)[][]): (string | null)[][]{
    let out : (string | null) [][] = [[]]
    for(let row of x){
        if(!out.find((y) => {
            let equal : boolean = true;
            for(let k = 0; k<y.length; k++){
                if(y[k] !== row[k]){
                    equal = false
                    break;
                }
            }
            return equal
        })){
            out.push(row)
        }
    }
    return out
}

