
import { Address } from "@app/common";
import { Component, Vue } from "vue-property-decorator";
import DogAddress from "../DogForm/DogAddress/DogAddress.vue";
import NewShowStore from "../../store/modules/newshow";
import SecretaryStore from "../../store/modules/secretary"
@Component({ components: { DogAddress } })
export default class NewShowChecks extends Vue {
  address: Address = new Address();
  addressee: string = "";
  payee: string = "";
  update() {
    NewShowStore.setCheckInfo({
      payee: this.payee,
      addressee: this.addressee,
      address: this.address,
    });
  }

  beforeMount() {
    if(NewShowStore.loadFromLocalStorage){
      this.payee = NewShowStore.show.checkPayableTo
      this.addressee = NewShowStore.show.checkRecipient
      this.address = Address.addressFromJSON(NewShowStore.show.checkAddress)
    }    
  }

  validate(): Array<string> {
    let out : Array<string> = []
    if(this.payee.trim().length == 0){
      out.push("You must provide a payee.")
    }
    if(this.addressee.trim().length == 0){
      out.push("You must provide an addressee.")
    }
    let addressError = (this.$refs.checkAddress as any).validate()
    if(addressError.length > 0){
      out = out.concat(addressError)
    }
    return out;
  }

  get addresses():Array<Address>{
    return SecretaryStore.checkAddresses
  }
}
