
import { Address, AddressType } from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DogCountry from "../DogCountry.vue";

@Component({ components: { DogCountry } })
export default class DogAddressForeign extends Vue {
  @Prop() readonly value!: Address;
  address: Address = new Address(null,"","","","","","",AddressType.INTERNATIONAL)

  update() {
    console.log("update");
    this.$emit("input",this.address)
    if(this.address.country.length > 0){
      this.countryErrors = []
    }
  }

  get rules() {
    return {
      required: (x: string) => {
        return !x || x === "" ? "This field is required" : true;
      },
      requireLocality: (x: string) => {
        return !x || x.trim() === "" ? "Locality required" : true;
      },
      requireAA: (x: string) => {
        return !x || x.trim() === "" ? "Administrative area required" : true;
      },
      requirePostal: (x: string) => {
        return !x || x.trim() === "" ? "You must provide a postal code" : true;
      },
      requireAddressLine: (x: string) => {
        return !x || x.trim() === "" ? "You must provide an address" : true;
      },
      requireCountry: (x: string) => {
        return !x || x.trim() === "" ? "You must select a country" : true;
      },
    };
  }

  countryErrors : Array<string> = []
  validate():Array<string>{
    if(!(this.$refs.foreignAddress as any).validate() || this.address.country.length == 0){
      if(this.address.country.length == 0){
        this.countryErrors = ["You must select a country."]
      }
      console.log("country errors")
      console.log(this.countryErrors)
      console.log(this.address.country)
      console.log(this.address)
      console.log("assdf")
      return ["Your address information is incomplete."];
    }
    return []
  }

  // @Watch("value")
  // onValueChange(x: Address){
  //   this.address = x
  // }

}
