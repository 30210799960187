
import { DogEvent, DogEventType } from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class NewShowEventsEvent extends Vue {
  //eventTypes : Array<DogEventType> = [DogEventType.CONFORMATION, DogEventType.SWEEPSTAKES, DogEventType.OBEDIENCE, DogEventType.RALLY, DogEventType.FUTURITY_MATURITY, DogEventType.OTHER]
  @Prop() readonly value!: DogEvent;
  @Prop({ default: -1 }) readonly idx!: number;
  eventTypes: Array<DogEventType> = Object.values(DogEventType);
  eventType: DogEventType | null = null;
  eventTypeText(x: DogEventType) {
    return DogEvent.printEventType(x);
  }
  akcNumber: string = "";
  startDate: string | null = null;
  endDate: string | null = null;
  menuStart: boolean = false;
  menuEnd: boolean = false;
  update() {
    if (this.eventType && this.startDate && this.endDate) {
      let newDogEvent: DogEvent = new DogEvent(
        this.idx,
        new Date(this.startDate),
        new Date(this.endDate),
        this.akcNumber,
        this.eventType
      );
      this.$emit("input", newDogEvent);
      this.$emit("update", newDogEvent);
    }
  }

  mounted() {
    if (this.value) {
      this.akcNumber = this.value.AKCNumber ? this.value.AKCNumber : "";
      this.startDate =
        this.value.startDate.getUTCFullYear() +
        "-" +
        (this.value.startDate.getUTCMonth() + 1) +
        "-" +
        this.value.startDate.getUTCDate();
      this.endDate =
        this.value.endDate.getUTCFullYear() +
        "-" +
        (this.value.endDate.getUTCMonth() + 1) +
        "-" +
        this.value.endDate.getUTCDate();
      this.eventType = this.value.type
    }
  }

  validate(): Array<string> {
    let out: Array<string> = [];
    if (this.eventType == null) {
      out.push("You must select a type for each event.");
    }
    if (this.startDate == null) {
      out.push("You must set a starting date for each event.");
    }
    if (this.endDate == null) {
      out.push("You must set an ending date for each event.");
    }
    return out;
  }

  @Watch("value")
  onValueChange(x: DogEvent) {
    if (x.AKCNumber) {
      this.akcNumber = x.AKCNumber;
    }
    this.eventType = x.type;
    this.startDate =
      x.startDate.getUTCFullYear() +
      "-" +
      (x.startDate.getUTCMonth() + 1) +
      "-" +
      x.startDate.getUTCDate();
    this.endDate =
      x.endDate.getUTCFullYear() +
      "-" +
      (x.endDate.getUTCMonth() + 1) +
      "-" +
      x.endDate.getUTCDate();
  }
}
