
import { Component, Vue, Watch } from "vue-property-decorator";
import NewShowGettingStarted from "../components/NewShow/NewShowGettingStarted.vue";
import NewShowInfo from "../components/NewShow/NewShowInfo.vue";
import NewShowEvents from "../components/NewShow/NewShowEvents.vue";
import NewShowEventGroups from "../components/NewShow/NewShowEventGroups.vue";
import NewShowJudges from "../components/NewShow/NewShowJudges.vue";
import NewShowFees from "../components/NewShow/NewShowFees.vue";
import NewShowJudgeAssignments from "../components/NewShow/NewShowJudgeAssignments.vue";
import NewShowClasses from "../components/NewShow/NewShowClasses.vue";
import NewShowSquare from "../components/NewShow/NewShowSquare.vue";
import NewShowChecks from "../components/NewShow/NewShowChecks.vue";
import NewShowAdditionalFees from "../components/NewShow/NewShowAdditionalFees.vue";
import NewShowWrappingUp from "../components/NewShow/NewShowWrappingUp.vue";
import NewShowSubmitted from "../components/NewShow/NewShowSubmitted.vue";
import NewShowHelp from "../components/NewShow/NewShowHelp.vue";
import MultiPage from "../components/MultiPage/MultiPage.vue";
import MultiPageSectionItem from "../components/MultiPage/MultiPageSectionItem.vue";
import TabBar from "../components/TabBar.vue";
import StickyFooter from "../components/StickyFooter.vue";
import NewShowStore from "../store/modules/newshow";
import {
  Address,
  SecretaryNewShowRequest,
  SecretarySubmitShowRequest,
  ShowInterface,
  ShowType,
} from "@app/common";

@Component({
  components: {
    NewShowGettingStarted,
    NewShowInfo,
    NewShowEvents,
    NewShowEventGroups,
    NewShowClasses,
    NewShowJudges,
    NewShowFees,
    NewShowJudgeAssignments,
    NewShowSquare,
    NewShowChecks,
    NewShowAdditionalFees,
    NewShowWrappingUp,
    NewShowSubmitted,
    NewShowHelp,
    TabBar,
    StickyFooter,
    MultiPage,
    MultiPageSectionItem,
  },
})
export default class NewShow extends Vue {
  //tabSelect = 0;
  //tabItems: Array<number> = [];
  showTab = true;
  showNav = false;
  showHelp = false;
  errors: Array<string> = [];
  errorModal = false;

  get tabItems(): Array<number> {
    let out = [];
    let sectionLength = this.sectionData[this.sectionIdx].pages.length;
    for (let k = 0; k < sectionLength; k++) {
      out.push(k + 1);
    }
    return out;
  }

  get progress(): number {
    let out = 0;
    for (let k = 0; k < this.sectionIdx; k++) {
      out += this.sectionData[k].pages.length;
    }
    out += this.pageIdx;
    return (out / (this.maxIdx - 1)) * 100;
  }

  get tabSelect(): number {
    return this.pageIdx + 1;
  }

  get done(): boolean {
    return (
      false &&
      this.sectionData[this.sectionIdx].pages[this.pageIdx].action == "done"
    );
  }

  get loading(): boolean {
    return NewShowStore.submittingShow;
  }

  get nextText(): string {
    return this.sectionData[this.sectionIdx].pages[this.pageIdx].nextText;
  }

  sectionData = [
    {
      name: "Show Information",
      icon: "info",
      pages: [
        {
          title: "Getting Started",
          component: "new-show-getting-started",
          nextText: "Next",
          action: "next",
          key: "gettingStarted",
        },
        {
          title: "Type of show",
          component: "new-show-info",
          nextText: "Next",
          action: "next",
          key: "typeOfShow",
        },
        {
          title: "Events",
          component: "new-show-events",
          nextText: "Next",
          action: "next",
          key: "events",
        },
        {
          title: "Event groups",
          component: "new-show-event-groups",
          nextText: "Next",
          action: "next",
          key: "eventGroups",
        },
      ],
    },
    {
      name: "Classes & Judges",
      icon: "menu_book",
      pages: [
        {
          title: "Classes",
          component: "new-show-classes",
          nextText: "Next",
          action: "next",
          key: "classes",
        },
        {
          title: "Judges",
          component: "new-show-judges",
          nextText: "Next",
          action: "next",
          key: "judges",
        },
        {
          title: "Fees",
          component: "new-show-fees",
          nextText: "Next",
          action: "next",
          key: "fees",
        },
        {
          title: "Judge Assignments",
          component: "new-show-judge-assignments",
          nextText: "Next",
          action: "next",
          key: "judgeAssignments",
        },
      ],
    },
    {
      name: "Payment Information",
      icon: "monetization_on",
      pages: [
        {
          title: "Square Token",
          component: "new-show-square",
          nextText: "Next",
          action: "next",
          key: "squareToken",
        },
        {
          title: "Checks",
          component: "new-show-checks",
          nextText: "Next",
          action: "next",
          key: "checks",
        },
        {
          title: "Catalogs and Additional fees",
          component: "new-show-additional-fees",
          nextText: "Next",
          action: "next",
          key: "additionalFees",
        },
      ],
    },
    {
      name: "Final details",
      icon: "published_with_changes",
      pages: [
        {
          title: "Wrapping up",
          component: "new-show-wrapping-up",
          nextText: "Submit",
          action: "submit",
          key: "wrappingUp",
        },
        {
          title: "Show submitted",
          component: "new-show-submitted",
          nextText: "Done",
          action: "done",
          key: "submitted",
        },
      ],
    },
  ];

  get pages(): Array<{ title: string; component: string }> {
    let out = [];
    for (let section of this.sectionData) {
      for (let page of section.pages) {
        out.push(page);
      }
    }
    return out;
  }

  idx: number = 0;
  helpSection : number = 0

  get sectionIdx(): number {
    let sum = this.idx;
    let sectionIdx = 0;
    for (let section of this.sectionData) {
      if (sum - section.pages.length >= 0) {
        sum -= section.pages.length;
        sectionIdx++;
      } else {
        break;
      }
    }
    return sectionIdx;
  }

  get pageIdx(): number {
    let sum = 0;
    for (let k = 0; k < this.sectionIdx; k++) {
      sum += this.sectionData[k].pages.length;
    }
    return this.idx - sum;
  }

  get maxIdx(): number {
    let out = 0;
    for (let section of this.sectionData) {
      out += section.pages.length;
    }
    return out - 1;
  }

  beforeMount() {}

  localStorageModal: boolean = false;
  localStorageShow: SecretarySubmitShowRequest | null = null;
  mounted() {
    let newShow: string | null = window.localStorage.getItem("newShow");
    console.log(newShow)
    if (newShow && newShow !== "undefined") {
      console.log("what?")
      this.localStorageShow = JSON.parse(newShow);
      console.log(this.localStorageShow)
      this.localStorageModal = true;
    }
  }

  async localStorageLoad() {
    let newShow: string | null = window.localStorage.getItem("newShow");
    if (newShow && newShow !== "undefined" &&  this.localStorageShow) {
      await NewShowStore.setShowFromLocalStorage(this.localStorageShow);
    }
    this.localStorageModal = false;
  }

  get loadingLocalStorageShow(): boolean {
    return NewShowStore.settingUpShowDataFromLocalStorage;
  }

  doNotLoad() {
    window.localStorage.removeItem("newShow");
    this.localStorageModal = false;
  }

  async next() {
    let action: string =
      this.sectionData[this.sectionIdx].pages[this.pageIdx].action;
    if (action == "next") {
      let errors: Array<string> = (
        this.$refs[
          this.sectionData[this.sectionIdx].pages[this.pageIdx].key
        ] as any
      )[0].validate();
      if (errors.length == 0) {
        if (this.idx > 0) {
          NewShowStore.save();
          console.log("saving")
        }
        this.idx++;
        this.helpSection = this.sectionIdx+1
      } else {
        this.errors = errors;
        this.errorModal = true;
      }
    } else if (action == "submit") {
      await NewShowStore.submitShow();
      this.idx++;
      if (NewShowStore.submitSuccess) {
        window.localStorage.removeItem("newShow");
      }
    } else if (action == "done") {
    }
  }

  previous() {
    if (this.idx > 0) {
      this.idx--;
      this.helpSection = this.sectionIdx+1
    }
  }

  get title(): string {
    return this.sectionData[this.sectionIdx].name;
  }

  get subtitle(): string {
    return this.sectionData[this.sectionIdx].pages[this.pageIdx].title;
  }

  update(x: ShowInterface) {}

  closeHelp() {
    this.showHelp = false;
  }

  cancel() {}

  show: ShowInterface = {
    id: -1,
    name: "",
    type: ShowType.OTHER,
    events: [],
    address: new Address().exportInterface(),
    secretary: { id: -1, firstName: "", lastName: "", email: "", phone: null },
    openingTime: -1,
    closingTime: -1,
    catalog: { canPurchase: false, fee: 0 },
    title: "",
    subtitle: "",
    abbr: "",
    checkPayableTo: "",
    checkAddress: new Address().exportInterface(),
    checkRecipient: "",
    secretaryFee: 0,
    surcharge: false,
    suspended: false,
    published: false,
  };
}
