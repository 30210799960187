import { Color, Dog, Sex, Variety } from './dog'
import { DogEvent } from './show'
import { Rule, RuleSet } from './class_rules'

export enum DogClassType {
    CONFORMATION_REGULAR = 'CONFORMATION_REGULAR',
    CONFORMATION_NON_REGULAR = 'CONFORMATION_NON_REGULAR',
    CONFORMATION_JUNIOR = 'CONFORMATION_JUNIOR',
    SWEEPSTAKE = 'SWEEPSTAKE',
    FUTURITY_MATURITY = 'FUTURITY_MATURITY',
    RALLY_REGULAR = 'RALLY_REGULAR',
    OBEDIENCE_REGULAR = 'OBEDIENCE_REGULAR',
    OBEDIENCE_NON_REGULAR = 'OBEDIENCE_NON_REGULAR',
    OBEDIENCE_PREFERRED = 'OBEDIENCE_PREFERRED',
    CONFORMATION_BEGINNER_PUPPY = 'CONFORMATION_BEGINNER_PUPPY',
    OTHER = 'OTHER',
    NULL = 'NULL'
}

enum DogClassType2 {
    CONFORMATION_REGULAR = 'CONFORMATION_REGULAR',
    CONFORMATION_NON_REGULAR = 'CONFORMATION_NON_REGULAR',
    CONFORMATION_JUNIOR = 'CONFORMATION_JUNIOR',
    CONFORMATION_ADDITIONAL = 'CONFORMATION_ADDITIONAL',
    CONFORMATION_SWEEPSTAKE = 'CONFORMATION_SWEEPSTAKE',
    OBIEDENCE = 'OBIEDENCE',
    RALLY = 'RALLY',
    OTHER = 'OTHER',
    NULL = 'NULL'
}

export class DogClass {
    public static dogClassFromJSON(dogClass: DogClassInterface) {
        let rule: Rule = new Rule(dogClass.ruleSet)
        return new DogClass(dogClass.id, dogClass.name, rule, dogClass.prerequisiteClasses, dogClass.type, dogClass.custom, dogClass.newClassId)
    }

    public static dogClassArrayFromJSON(dogClasses: Array<DogClassInterface>) {
        return dogClasses.map((x) => { return this.dogClassFromJSON(x) })
    }

    public static sortClasses(dogClasses : Array<DogClass>):Array<DogClass>{
        let out : Array<DogClass> = dogClasses.map((x) => {return x.clone()})
        out.sort((x,y)=>{return (x.greaterThan(y) ? -1 : 1)})
        return out
    }



    private _id: number
    private _name: string
    private _rules: Rule

    private _prerequisiteClasses: Array<DogClassType>
    private _type: DogClassType
    private _custom: boolean
    private _newClassId?: number


    public get id(): number {
        return this._id
    }
    //Do not set id
    public get name(): string {
        return this._name
    }
    //Do not set name
    public get rules(): Rule {
        return this._rules
    }
    //Do not set rules
    public get prerequisiteClasses(): Array<DogClassType> {
        return this._prerequisiteClasses
    }
    //Do not set prerequisiteClasses
    public get type(): DogClassType {
        return this._type
    }
    //Do not set type
    public get custom(): boolean {
        return this._custom
    }
    //Do not set custom
    public get newClassId(): undefined | number {
        return this._newClassId
    }
    public set newClassId(x : undefined | number){
        this._newClassId = x
    }


    constructor(id: number, name: string, rule: Rule, prerequisiteClasses: Array<DogClassType> = [], type: DogClassType, custom: boolean, newClassId?: number) {
        this._id = id
        this._name = name
        this._rules = rule
        this._prerequisiteClasses = prerequisiteClasses
        this._type = type
        this._custom = custom
        if (newClassId) {
            this._newClassId = newClassId
        }
    }

    testDog(dog: Dog, event: DogEvent, agent: string | null): boolean {
        return this._rules.ruleFunction(dog, event, agent)
    }

    public printType(): string {
        let words: Array<string> = this._type.split("_")
        let newWords: Array<string> = []
        for (let word of words) {
            newWords.push(word[0].toUpperCase() + word.substring(1).toLowerCase())
        }
        return newWords.join(" ")
        // let out : string = this._type.toLocaleLowerCase()
        // out = out[0].toUpperCase()+out.substring(1)
        // return out
    }

    public splitClassBySex(addSuffix: boolean): Array<DogClass> {
        let out: Array<DogClass> = []
        let dogRuleSet: RuleSet = {
            ...this._rules.ruleSet,
            sex: Sex.MALE
        }
        let dogRule = new Rule(dogRuleSet)
        let bitchRuleSet: RuleSet = {
            ...this._rules.ruleSet,
            sex: Sex.FEMALE
        }
        let bitchRule = new Rule(bitchRuleSet)
        let dogName = this._name + (addSuffix ? " " + dogRule.nameFromRuleSet() : "")
        let bitchName = this._name + (addSuffix ? " " + bitchRule.nameFromRuleSet() : "")
        let dogClass: DogClass = new DogClass(this._id, dogName, dogRule, this._prerequisiteClasses, this._type, true)
        let bitchClass: DogClass = new DogClass(this._id, bitchName, bitchRule, this._prerequisiteClasses, this._type, true)
        out.push(dogClass)
        out.push(bitchClass)
        return out
    }

    public splitClassByColors(colors: Array<Color>, addSuffix: boolean): Array<DogClass> {
        let out: Array<DogClass> = []
        for (let color of colors) {
            let rulesSet: RuleSet = {
                ...this._rules.ruleSet,
                color: color.id
            }
            let colorRule = new Rule(rulesSet)
            let colorName = this._name + (addSuffix ? " " + colorRule.nameFromRuleSet() : "")
            let colorClass: DogClass = new DogClass(this._id, colorName, colorRule, this._prerequisiteClasses, this._type, true)
            out.push(colorClass)
        }
        if (colors.length > 0) {
            return out
        } else {
            return [this]
        }
    }

    public splitClassByAges(ages: Array<number>, addSuffix: boolean): Array<DogClass> {
        let out: Array<DogClass> = []
        for (let k = 0; k < ages.length - 1; k++) {
            let ruleSet: RuleSet
            ruleSet = {
                ...this._rules.ruleSet,
                age: {
                    min: ages[k],
                    max: (ages[k + 1] < Infinity ? ages[k + 1] : undefined)
                }
            }

            let rule: Rule = new Rule(ruleSet)
            let name = this._name + (addSuffix ? " " + rule.nameFromRuleSet() : "")
            let ageClass = new DogClass(this._id, name, rule, this._prerequisiteClasses, this._type, true)
            out.push(ageClass)

        }
        if (ages.length > 0) {
            return out
        } else {
            return [this]
        }
    }

    public splitClassByVariety(varieties: Array<Variety>, addSuffix: boolean): Array<DogClass> {
        let out: Array<DogClass> = []
        for (let variety of varieties) {
            let ruleSet: RuleSet = {
                ...this._rules.ruleSet,
                variety: variety.id
            }
            let rule: Rule = new Rule(ruleSet)
            let name = this._name + (addSuffix ? " " + rule.nameFromRuleSet() : "")
            let varietyClass = new DogClass(this._id, name, rule, this._prerequisiteClasses, this._type, true)
            out.push(varietyClass)
        }
        if (varieties.length > 0) {
            return out
        } else {
            return [this]
        }
    }

    public addRuleSuffix(): DogClass {
        let name = this._rules.nameFromRuleSet(this._name)
        return new DogClass(this._id, name, this._rules, this._prerequisiteClasses, this._type, true)
    }

    public classCompare(x : DogClass) : boolean{
        if(x.type !== this._type){
            return false
        }
        if(x.name !== this._name){
            return false
        }
        for(let classType of this._prerequisiteClasses){
            if(!x._prerequisiteClasses.find((y) => {return y == classType})){
                return false
            }
        }
        if(JSON.stringify(x.rules.ruleSet) !== JSON.stringify(this._rules.ruleSet)){
            return false
        }
        return true
    }

    public greaterThan(x : DogClass): boolean{
        if(this._rules.ruleSet.sex && x._rules.ruleSet.sex){
            return this._rules.ruleSet.sex == Sex.MALE && x._rules.ruleSet.sex == Sex.FEMALE
        }
        if(this._rules.ruleSet.age && x._rules.ruleSet.age){
            if(this._rules.ruleSet.age.min && x._rules.ruleSet.age.min){
                return this._rules.ruleSet.age.min > x._rules.ruleSet.age.min
            }
        }
        if(this._type == DogClassType.CONFORMATION_REGULAR && (x.type == DogClassType.CONFORMATION_NON_REGULAR || x.type == DogClassType.CONFORMATION_JUNIOR)){
            return true
        }
        if(this._rules.ruleSet.color && x.rules.ruleSet.color){
            return this._rules.ruleSet.color > x.rules.ruleSet.color
        }
        return false
    }

    public clone(): DogClass{
        return new DogClass(this._id,this._name,new Rule(JSON.parse(JSON.stringify(this._rules.ruleSet))),this._prerequisiteClasses.map((x)=>{return x}),this._type,this._custom,this._newClassId)
    }

    public exportInterface(): DogClassInterface{
        let out : DogClassInterface = {
            id : this._id,
            name : this._name,
            ruleSet : this._rules.ruleSet,
            prerequisiteClasses : this._prerequisiteClasses,
            type : this._type,
            custom : this._custom,
            newClassId : this._newClassId
        }
        return out
    }
    
}

export interface DogClassInterface {
    id: number,
    name: string,
    ruleSet: RuleSet,
    prerequisiteClasses: Array<DogClassType>,
    type: DogClassType,
    custom: boolean,
    newClassId?:number,
}