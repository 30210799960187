import {
    Breed,
    BreedDataResponse,
    Color,
    Dog,
    GeneralErrors,
    Group,
    Variety,
} from "@app/common";
import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "..";
import { fetchErrorMessage, generalErrorMessage } from "../error";

@Module({ name: "BreedStore", dynamic: true, store, namespaced: true })
class BreedStore extends VuexModule {
    private _fetchingBreedData = false;
    private _breedDataFetched = false;
    private _breedDataError: GeneralErrors.GENERAL_ERROR | undefined = undefined;

    get fetchingBreedData(): boolean {
        return this._fetchingBreedData;
    }
    @Mutation
    setFetchingBreedData(x: boolean) {
        this._fetchingBreedData = x;
    }

    get breedDataFetched(): boolean {
        return this._breedDataFetched;
    }
    @Mutation
    setBreedDataFetched(x: boolean) {
        this._breedDataFetched = x;
    }

    get breedDataError(): GeneralErrors.GENERAL_ERROR | undefined {
        return this._breedDataError;
    }
    @Mutation
    setBreedDataError(x: GeneralErrors.GENERAL_ERROR | undefined) {
        this._breedDataError = x;
    }

    @Action
    async fetchBreedData() {
        this.setFetchingBreedData(true);
        const response = await fetch(
            process.env.VUE_APP_DOG_API + "/fetch-breed-data",
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({}),
            }
        );
        this.setFetchingBreedData(false);
        if (!response.ok) {
            this.context.rootState.errorModal = true;
            this.context.rootState.errorMsg = fetchErrorMessage;
            this.context.rootState.errorCode =
                response.status.toString() + " " + response.statusText;
        } else {
            const data: BreedDataResponse = await response.json();
            if (data.success) {
                Dog.initializeBreedData(
                    data.breeds,
                    data.groups,
                    data.varieties,
                    data.colors,
                    data.breedData
                );
                this.setBreedDataError(undefined);
                this.setBreedDataFetched(true);

                this.setBreeds(Dog.breeds)
                this.setGroups(Dog.groups)
                this.setVarieties(Dog.varieties)
                this.setColors(Dog.colors)
                this.setBreedData(Dog.breedData)
            } else {
                this.context.rootState.errorMsg = generalErrorMessage;
                this.context.rootState.errorModal = true;
                this.context.rootState.errorCode = GeneralErrors.GENERAL_ERROR;
                this.setBreedDataError(GeneralErrors.GENERAL_ERROR);
            }
        }
    }

    private _breeds: Array<Breed> = []
    private _groups: Array<Group> = []
    private _varieties: Array<Variety> = []
    private _colors: Array<Color> = []
    private _breedData: Array<{ breed: Breed, groups: Array<{ group: Group | null, varieties: Array<{ variety: Variety | null, colors: Array<Color> }> }> }> = []

    get breeds(): Array<Breed> {
        return this._breeds
    }
    get groups(): Array<Group> {
        return this._groups
    }
    get varieties(): Array<Variety> {
        return this._varieties
    }
    get colors(): Array<Color> {
        return this._colors
    }
    get breedData(): Array<{ breed: Breed, groups: Array<{ group: Group | null, varieties: Array<{ variety: Variety | null, colors: Array<Color> }> }> }> {
        return this._breedData
    }
    @Mutation
    setBreeds(x: Array<Breed>) {
        this._breeds = x
    }
    @Mutation
    setGroups(x: Array<Group>) {
        this._groups = x
    }
    @Mutation
    setVarieties(x: Array<Variety>) {
        this._varieties = x
    }
    @Mutation
    setColors(x: Array<Color>) {
        this._colors = x
    }
    @Mutation
    setBreedData(x: Array<{ breed: Breed, groups: Array<{ group: Group | null, varieties: Array<{ variety: Variety | null, colors: Array<Color> }> }> }>) {
        this._breedData = x
    }
}

export default getModule(BreedStore);
