
import { Dog } from "@app/common";
import { Component, Vue, Prop } from "vue-property-decorator";
import DogNameConfirm from "./DogNameConfirm.vue"
@Component({components : {DogNameConfirm}})
export default class DogFormConfirm extends Vue {
  @Prop() readonly dog!: Dog;
  nameModal : boolean = false
  selectedName : number = 0

  goBack(x: string) {
    console.log(x);
    this.$emit("change-page", { page: 0, location: x });
  }

  mounted() {

  }

  editName(idx : number){

  }

}
