
import { Dog, RegistrationTypes, Sex } from "@app/common";
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class DogInfo extends Vue {
  @Prop() readonly dog!: Dog;
  mounted() {
      console.log(this.dog)
  }
}
