
import { DogEvent, DogEventType, ShowType } from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class NewShowHelp extends Vue {
  @Prop({ default: 0 }) readonly value!: number;
  section = 0;
  close() {
    this.$emit("close-help");
  }

  get authURL(): string {
    return process.env.VUE_APP_DOG_SITE + "/square-authorize";
  }

  get showTypes(): Array<string> {
    return Object.assign(ShowType);
  }

  get eventTypes(): Array<string> {
    return Object.assign(DogEventType);
  }

  @Watch("value")
  onValueChange(x: number) {
    this.section = x;
    console.log("section changed");
    console.log(x);
  }
}
