import {
  EventClass,
  GeneralErrors,
  Judge,
  SecretaryShowInterfaceRequest,
  SecretaryShowInterfaceResponse,
  Show,
  ShowDataRequest,
  ShowDataResponse,
  ShowInterface,
  ShowListResponse,
} from "@app/common";
import { DogClass } from "@app/common/classes";
import {
  Action,
  getModule,
  Module,
  Mutation,
  MutationAction,
  VuexModule,
} from "vuex-module-decorators";
import store from "..";
import { fetchErrorMessage, generalErrorMessage } from "../error";

@Module({ name: "ShowStore", dynamic: true, store, namespaced: true })
class ShowStore extends VuexModule {
  private _selectedShow: Show | undefined = undefined;
  get selectedShow(): Show | undefined {
    return this._selectedShow;
  }
  @Mutation
  setSelectedShow(x: Show | undefined) {
    this._selectedShow = x;
  }

  private _shows: Array<Show> = [];
  private _fetchingShows = false;

  get shows(): Array<Show> {
    return this._shows;
  }
  @Mutation
  setShows(x: Array<Show>) {
    this._shows = x;
  }
  get fetchingShows(): boolean {
    return this._fetchingShows;
  }
  @Mutation
  setFetchingShows(x: boolean) {
    this._fetchingShows = x;
  }
  @Action
  async fetchShows() {
    this.setFetchingShows(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/show/fetch-shows",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({}),
      }
    );
    this.setFetchingShows(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: ShowListResponse = await response.json();
      if (data.success) {
        this.setShows(Show.showArrayFromJSON(data.shows));
      }
    }
  }

  
  private _fetchingShowData = false;
  get fetchingShowData(): boolean {
    return this._fetchingShowData;
  }
  @Mutation
  setFetchingShowData(x: boolean) {
    this._fetchingShowData = x;
  }

  private _fetchShowDataError: GeneralErrors | undefined = undefined;
  get fetchShowDataError(): GeneralErrors | undefined {
    return this._fetchShowDataError;
  }
  @Mutation
  setFetchShowDataError(x: GeneralErrors | undefined) {
    this._fetchShowDataError = x;
  }

  private _show: Show | undefined = undefined;
  get show(): Show | undefined {
    return this._show;
  }
  @Mutation
  setShow(x: Show | undefined) {
    this._show = x;
  }

  private _eventClasses: Array<EventClass> = [];
  get eventClasses(): Array<EventClass> {
    return this._eventClasses;
  }
  @Mutation
  setEventClasses(x: Array<EventClass>) {
    this._eventClasses = x;
  }

  private _classes: Array<DogClass> = [];
  get classes(): Array<DogClass> {
    return this._classes;
  }
  @Mutation
  setClasses(x: Array<DogClass>) {
    this._classes = x;
  }

  private _judges: Array<Judge> = [];
  get judges(): Array<Judge> {
    return this._judges;
  }
  @Mutation
  setJudges(x: Array<Judge>) {
    this._judges = x;
  }

  @Action
  async fetchShowData(payload: ShowDataRequest) {
    this.setFetchingShowData(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/show/fetch-show-data",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      }
    );
    this.setFetchingShowData(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: ShowDataResponse = await response.json();
      if (data.success && data.show) {
        this.setShow(Show.showFromJSON(data.show));
        this.setJudges(Judge.judgeArrayFromJSON(data.judges));
        this.setClasses(DogClass.dogClassArrayFromJSON(data.dogClasses));
        if (this.show) {
          this.setEventClasses(
            EventClass.eventClassArrayFromJSON(
              data.eventClasses,
              this.show.events,
              this.classes,
              this.judges
            )
          );
        }
        this.setFetchShowDataError(undefined);
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
        this.context.rootState.errorCode = GeneralErrors.GENERAL_ERROR;
        this.setFetchShowDataError(GeneralErrors.GENERAL_ERROR);
      }
    }
  }

}

export default getModule(ShowStore);
