import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const opts = {    theme: {
    options: {
        customProperties: true
    },
    themes: {
        light: {
            primary: '#673ab7',
            secondary: '#e91e63',
            accent: '#9c27b0',
            error: '#f44336',
            warning: '#ff5722',
            info: '#3f51b5',
            success: '#4caf50',
            softwhite: '#f5f5f5'
        },
    },
}};

export default new Vuetify(opts);
