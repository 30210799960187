<template>
  <div
    style="
      background: linear-gradient(90deg, #ee6d87 0%, #6d7dce 100%);
      height: 100%;
    "
  >
    <div
      class="text-center white--text dogalog"
      :style="'font-size: ' + (!$vuetify.breakpoint.xs ? '6' : '4') + 'em'"
    >
      doga<b>Log</b>
    </div>
    <!-- <p class="text-center white--text">
      Catalogs are for the cats. Dogalogs are for the dogs.
    </p> -->
    <p class="text-center white--text">
      The entry system for conformation shows.
    </p>
    <v-container
      class="text-center white--text"
      :class="{
        'text-h4': !$vuetify.breakpoint.xs,
        'text-subtitle-1 font-weight-bold': $vuetify.breakpoint.xs,
      }"
    >
      <v-layout row wrap>
        <v-flex xs4>
          <div class="hovertext" @click="signup">
            <v-icon x-large color="white"> person_add </v-icon>
            Sign Up
          </div>
        </v-flex>
        <v-flex xs4>
          <div class="hovertext" @click="login">
            <v-icon x-large color="white"> vpn_key </v-icon>
            Log In
          </div>
        </v-flex>
        <v-flex xs4>
          <div class="hovertext" @click="$router.push('/shows')">
            <v-icon x-large color="white"> search </v-icon>
            Shows
          </div>
        </v-flex>
      </v-layout>
    </v-container>
    <br />
    <p class="text-center display-1 white--text">
      Use dogalog for your next show for only $3.00 per entry.
    </p>
    <br />

    <div class="text-center white--text">
      <v-container>
        <v-row>
          <v-col>
            <p class="text-center title">Online entries</p>
            <p class="text-center">
              <v-icon style="font-size: 8em" color="white">assignment</v-icon>
            </p>
          </v-col>
          <v-col>
            <p class="text-center title">Online payments</p>
            <p class="text-center">
              <v-icon style="font-size: 8em" color="white">payment</v-icon>
            </p>
          </v-col>
          <v-col>
            <p class="text-center title">Instant catalogs</p>
            <p class="text-center">
              <v-icon style="font-size: 8em" color="white">list_alt</v-icon>
            </p>
          </v-col>
          <v-col>
            <p class="text-center title">Error correction</p>
            <p class="text-center">
              <v-icon style="font-size: 8em" color="white">check_circle</v-icon>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div style="height: 400px"></div>
    <!-- <v-container grid-list-md text-xs-center>
    <v-layout row wrap>
      <v-flex xs12 sm6>
        <div class="showcase">
          <p class="headline text-center">Exhibitors</p>
          <p>dogaLog is the easiest online entry system you'll ever use.</p>
          

          Once your dog is entered into our database, you can enter them into as many shows as you like within seconds.
          Never enter your dog into the wrong class ever again.

          
        </div>
        
      </v-flex>
      <v-flex xs12 sm6>
        <div class="showcase">
        <p class="headline text-center">Show Secretaries</p>
        You will love dogaLog.

        Instant catalog generation: 

        Error correction software: dogaLog focuses on looking out for every concieveable error exhibitors makes when submitting entries. 
        The most important, is making it impossible for exhibitors to enter their dog into the wrong class. Does their dog turn one year old on the second day of your show? dogaLog will sniff it out and ensure they can only submit
        
        

        </div>
      </v-flex>
    </v-layout>
    </v-container> -->
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: 0,
  }),
  methods: {
    signup: function () {
      console.log("abc");
    },
    login: function () {
      console.log("abc");
    },
  },
};
</script>

<style>
.showcase {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.75);
  padding: 20px;
  background-color: transparent;
  color: white;
  transform: translateY(0px);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
}

.showcase:hover {
  background-color: white;
  border: 2px solid rgba(255, 255, 255, 1);
  transform: translateY(-5px);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  color: black;
}

.hovertext {
  cursor: pointer;
  transform: translateY(0px);
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
}

.hovertext:hover {
  text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
  transform: translateY(-5px);
}
</style>
