import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "..";
import UserStore from "./user";
import SecretaryStore from "./secretary";
import { fetchErrorMessage, generalErrorMessage } from "../error";
import {
  CatalogOrder,
  GeneralErrors,
  SecretaryErrors,
  SecretaryLoadCatalogSettingsRequest,
  SecretaryLoadCatalogSettingsResponse,
  SecretarySaveCatalogSettingsRequest,
  SecretarySaveCatalogSettingsResponse,
  SecretarySuspendShowRequest,
  SecretarySuspendShowResponse,
} from "@app/common";
import { DogClass } from "@app/common/classes";

@Module({
  name: "SecretaryCatalogStore",
  dynamic: true,
  store,
  namespaced: true,
})
class SecretaryCatalogStore extends VuexModule {
  private _savingSettings: boolean = false;
  private _loadingSettings: boolean = false;

  get savingSettings(): boolean {
    return this._savingSettings;
  }
  @Mutation
  setSavingSettings(x: boolean) {
    this._savingSettings = x;
  }
  get loadingSettings(): boolean {
    return this._loadingSettings;
  }
  @Mutation
  setLoadingSettings(x: boolean) {
    this._loadingSettings = x;
  }

  private _catalogOrder: CatalogOrder | undefined = undefined;

  get catalogOrder(): CatalogOrder | undefined {
    return this._catalogOrder;
  }
  @Mutation
  setCatalogOrder(x: CatalogOrder | undefined) {
    this._catalogOrder = x;
  }

  @Action
  async saveSettings(payload: SecretarySaveCatalogSettingsRequest) {
    this.setSavingSettings(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/secretary/catalog/save-settings",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      }
    );
    this.setSavingSettings(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: SecretarySaveCatalogSettingsResponse = await response.json();
      // if (data.success) {
      //   this.setCatalogOrder(payload.ordering)
      // } else {
      //   this.context.rootState.errorMsg = generalErrorMessage;
      //   this.context.rootState.errorModal = true;
      //   this.context.rootState.errorCode = data.errors[0];
      //   this.setSuspendingShowError([data.errors[0]]);
      // }
      UserStore.authCheck(data);
    }
  }

  @Action
  async loadSettings(payload: SecretaryLoadCatalogSettingsRequest) {
    this.setLoadingSettings(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/secretary/catalog/load-settings",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      }
    );
    this.setLoadingSettings(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: SecretaryLoadCatalogSettingsResponse = await response.json();
      if (data.success) {
        console.log("loadSettings");
        console.log(data);
        if (SecretaryStore.selectedShow && data.ordering) {
          console.log(data.ordering);
          const dogClasses: Array<DogClass> = [];
          for (const eventClass of SecretaryStore.eventClasses) {
            if (
              !dogClasses.find((x) => {
                return x.id == eventClass.dogClass.id;
              })
            ) {
              dogClasses.push(eventClass.dogClass);
            }
          }
          const catalogOrder: CatalogOrder = CatalogOrder.catalogOrderFromJSON(
            data.ordering,
            SecretaryStore.selectedShow.events,
            dogClasses
          );
          this.setCatalogOrder(catalogOrder);
        }
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
      }
    }
  }
}

export default getModule(SecretaryCatalogStore);
