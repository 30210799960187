
import sha512 from "@/services/hash";
import { SignupRequest } from "@app/common";
import { Component, Prop, Vue } from "vue-property-decorator";
import UserStore from "../store/modules/user";

@Component
export default class Signup extends Vue {
  step = 0;
  signupDisabled = true;

  get topText(): string {
    if (this.step == 0) {
      return "Sign Up";
    } else {
      return "Success";
    }
  }
  get nextText(): string {
    if (this.step == 0) {
      return "Sign Up";
    } else {
      return "";
    }
  }

  get loading(): boolean {
    return UserStore.signingUp;
  }

  async submit() {
    let cleanEmail = this.email.trim().toLowerCase();
    let passwordHash = await sha512(this.password + cleanEmail);
    let payload: SignupRequest = {
      firstName: this.firstName.trim(),
      lastName: this.lastName.trim(),
      email: cleanEmail,
      password: passwordHash,
      csrfSecurityToken: UserStore.csrfSecurityToken,
    };
    await UserStore.signup(payload);
    console.log(UserStore.signupError);
    if (UserStore.signupError && UserStore.signupError === "EMAIL_IN_USE") {
      this.emailTaken = true;
      const form = this.$refs.form as any;
      form.validate();
    } else {
      this.step++;
    }
  }

  firstName = "";
  lastName = "";
  email = "";
  password = "";
  confirmPassword = "";
  emailTaken = false;

  get rules() {
    return {
      required: (x: string) => {
        return x === "" ? "This field is required" : true;
      },
      firstNameRequired: (x: string) => {
        return !x || x.trim() === "" ? "Your first name is required" : true;
      },
      lastNameRequired: (x: string) => {
        return !x || x.trim() === "" ? "Your last name is required" : true;
      },
      passwordRequired: (x: string) => {
        return !x || x.trim() === "" ? "A password is required" : true;
      },
      emailRequired: (x: string) => {
        return !x || x.trim() === "" ? "Your email is required" : true;
      },
      emailValid: (x: string) => {
        return !/\S+@\S+\.\S+/.test(x) ? "This email is invalid" : true;
      },
      emailTaken: () => {
        return this.emailTaken ? "This email is already in use." : true;
      },
      passwordLength: (x: string) => {
        return !x || x.length < 6
          ? "Password must be at least 6 characters long"
          : true;
      },
      passwordMatch: (x: string) => {
        return !x || x !== this.password ? "Passwords must match" : true;
      },
    };
  }
}
