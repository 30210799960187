
import {
  EditEntryInterface,
  EditEntryRequest,
  EditEntryResponse,
  EnterDogRequest,
  Entry,
  EntryInterface,
  NewEntryInterface,
  SecretaryEditEntryRequest,
  SecretaryEnterDogRequest,
  ShowDog,
  ShowEntry,
} from "@app/common";
import { Component, Vue, Watch } from "vue-property-decorator";
import UserStore from "../store/modules/user";
import DogStore from "../store/modules/dog";
import ShowStore from "../store/modules/show";
import EntryClasses from "../components/DogEntry/EntryClasses.vue";
import EntryConfirm from "../components/DogEntry/EntryConfirm.vue";
import StickyFooter from "../components/StickyFooter.vue";
import SecretaryStore from "../store/modules/secretary";
import SecretaryEntryStore from "../store/modules/secretary_entries";

@Component({
  components: {
    EntryClasses,
    EntryConfirm,
    StickyFooter,
  },
})
export default class EnterDog extends Vue {
  page = 0;
  entries: Array<Array<Entry>> = [];
  confirmData : {ownerHandler : boolean, newExhibitor : boolean, signature : string, phone : string} = {ownerHandler : false, newExhibitor : false, signature : "", phone : ""}
  confirmErrors: Array<string> = [];
  confirmErrorModal: boolean = false;
  showEntry!: ShowEntry;
  secretary: boolean = false;

  get fee(): number {
    let fee = 0;
    console.log("fee");
    console.log(this.entries);
    for (let entryGroup of this.entries) {
      for (let entry of entryGroup) {
        fee += entry.fee;
      }
    }
    return fee;
  }

  get edit(): boolean {
    return this.$route.params.mode === "edit";
  }

  cancel() {
    this.$router.go(-1);
  }

  next() {
    this.page = 1;
  }

  async exhibitorSumbit() {
    if (this.$route.params.mode === "new") {
      let entries: Array<NewEntryInterface> = [];
      for (let entryGroup of this.entries) {
        for (let entry of entryGroup) {
          entry.ownerHandler = this.confirmData.ownerHandler
          entry.newExhibitor = this.confirmData.newExhibitor
          entry.signature = this.confirmData.signature;
          entry.phone = this.confirmData.phone;
          entries.push(entry.exportNewEntryInterface());
        }
      }
      if (
        this.confirmErrors.length == 0 &&
        DogStore.selectedDog &&
        DogStore.selectedDog.id &&
        ShowStore.selectedShow &&
        ShowStore.selectedShow.id
      ) {
        let payload: EnterDogRequest = {
          dog: DogStore.selectedDog.id,
          show: ShowStore.selectedShow.id,
          entries: entries,
          csrfSecurityToken: UserStore.csrfSecurityToken,
        };
        await DogStore.enterDog(payload);
        if (!DogStore.enterDogError) {
          await DogStore.fetchDogData();
          this.$router.push("/show/" + ShowStore.selectedShow.abbr);
        }
      }
    } else {
      let editEntries: Array<EditEntryInterface> = [];
      let newEntries: Array<NewEntryInterface> = [];
      for (let entryGroup of this.entries) {
        for (let entry of entryGroup) {
          entry.ownerHandler = this.confirmData.ownerHandler
          entry.newExhibitor = this.confirmData.newExhibitor
          entry.signature = this.confirmData.signature;
          entry.phone = this.confirmData.phone;
          if (entry.id && !entry.paid) {
            editEntries.push(entry.exportEditEntryInterface());
          } else if (!entry.id) {
            newEntries.push(entry.exportNewEntryInterface());
          }
        }
      }
      if (
        this.confirmErrors.length == 0 &&
        DogStore.selectedDog &&
        DogStore.selectedDog.id &&
        ShowStore.selectedShow &&
        ShowStore.selectedShow.id
      ) {
        let error: boolean = false;
        let editPayload: EditEntryRequest = {
          dog: DogStore.selectedDog.id,
          show: ShowStore.selectedShow.id,
          entries: editEntries,
          csrfSecurityToken: UserStore.csrfSecurityToken,
        };
        await DogStore.editEntry(editPayload);
        error = !!DogStore.editDogError;
        if (!error && newEntries.length > 0) {
          let newPayload: EnterDogRequest = {
            dog: DogStore.selectedDog.id,
            show: ShowStore.selectedShow.id,
            entries: newEntries,
            csrfSecurityToken: UserStore.csrfSecurityToken,
          };
          await DogStore.enterDog(newPayload);
          console.log(DogStore.enterDogError);
          error &&= !!DogStore.enterDogError;
        }

        if (!error) {
          await DogStore.fetchDogData();
          this.$router.push("/show/" + ShowStore.selectedShow.abbr);
        }
      }
    }
  }

  async secretarySubmit() {
    if (this.$route.params.mode === "new") {
      let entries: Array<NewEntryInterface> = [];
      for (let entryGroup of this.entries) {
        for (let entry of entryGroup) {
          entry.ownerHandler = this.confirmData.ownerHandler
          entry.newExhibitor = this.confirmData.newExhibitor
          entry.signature = this.confirmData.signature;
          entry.phone = this.confirmData.phone;
          entries.push(entry.exportNewEntryInterface());
        }
      }
      if (
        SecretaryStore.selectedShow &&
        SecretaryStore.selectedShow.id &&
        SecretaryEntryStore.selectedShowDog
      ) {
        let payload: SecretaryEnterDogRequest = {
          show: SecretaryStore.selectedShow.id,
          ...SecretaryEntryStore.selectedShowDog.showDog.dog.exportAddDogInterface(),
          entries: entries,
          csrfSecurityToken: UserStore.csrfSecurityToken,
        };
        await SecretaryEntryStore.enterDog(payload)
        if(!SecretaryEntryStore.enterDogError){
          await SecretaryStore.dogEntryRoutine({abbr : SecretaryStore.selectedShow.abbr})
          this.$router.push("/show-manager/" + SecretaryStore.selectedShow.abbr);
        }
      }
    }else{
      let editEntries: Array<EditEntryInterface> = [];
      let newEntries: Array<NewEntryInterface> = [];
      for (let entryGroup of this.entries) {
        for (let entry of entryGroup) {
          entry.ownerHandler = this.confirmData.ownerHandler
          entry.newExhibitor = this.confirmData.newExhibitor
          entry.signature = this.confirmData.signature;
          entry.phone = this.confirmData.phone;
          if (entry.id && !entry.paid) {
            editEntries.push(entry.exportEditEntryInterface());
          } else if (!entry.id) {
            newEntries.push(entry.exportNewEntryInterface());
          }
        }
      }
      if (
        this.confirmErrors.length == 0 &&
        SecretaryEntryStore.selectedShowDog &&
        SecretaryEntryStore.selectedShowDog.showDog.id &&
        SecretaryStore.selectedShow &&
        SecretaryStore.selectedShow.id
      ) {
        let payload : SecretaryEditEntryRequest = {
          show : SecretaryStore.selectedShow.id,
          showDog : SecretaryEntryStore.selectedShowDog.showDog.id,
          entries : editEntries,
          csrfSecurityToken : UserStore.csrfSecurityToken
        }
      }
    }
  }

  async submit() {
    this.confirmErrors = (this.$refs.confirm as any).validate();
    this.confirmErrorModal = this.confirmErrors.length > 0;
    if (!(this.confirmErrors.length > 0) && !this.secretary) {
      await this.exhibitorSumbit();
    } else if (!(this.confirmErrors.length > 0) && this.secretary) {
      await this.secretarySubmit();
    }
  }

  get submitting(): boolean {
    return DogStore.enteringDog;
  }

  get entriesReady(): boolean {
    for (let entryGroup of this.entries) {
      for (let entry of entryGroup) {
        console.log("entries ready");
        console.log(entry);
        if (entry.classes && entry.classes.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  updateClasses(x: Array<Array<Entry>>) {
    this.entries = x;
    console.log("updated classes");
  }

  @Watch("entries")
  onEntriesChange() {
    console.log("entries changed");
    console.log(this.entries);
  }

  @Watch("confirmData")
  onConfirmDataChange(){
    console.log("confirmdata changed")
    console.log(this.confirmData)
  }


  mounted() {
    this.secretary = this.$route.params.secretary == "secretary";
    // let showDog : ShowDog = new ShowDog()
    // this.showEntry = new ShowEntry()
  }
}
