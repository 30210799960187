
import { RequestVerificationEmailRequest } from "@app/common";
import { Component, Prop, Vue } from "vue-property-decorator";
import UserStore from '../../store/modules/user'
@Component
export default class EmailWindow extends Vue {
  @Prop({default : ''})
  readonly email!: string;

  requestedNewEmail = false;

  requestingVerify():boolean{
    return UserStore.requestingVerificationEmail
  }

  async requestVerify() {
    let payload : RequestVerificationEmailRequest = {
      email : this.email
    }
    await UserStore.requestVerifcationEmail(payload)
    if(!UserStore.requestVerificationEmailError){
      this.requestedNewEmail = true;
    }
  }
}
