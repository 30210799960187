
import { Component, Vue, Watch } from "vue-property-decorator";
import ClassesTable from "./ClassesTable.vue";
import NewShowStore from "../../store/modules/newshow";
import { DogEvent, EventClass, Judge } from "@app/common";
@Component({ components: { ClassesTable } })
export default class NewShowJudgeAssignments extends Vue {
  selectedEventIdx: number = 0;
  eventClasses: Array<Array<EventClass>> = [[]];
  judge: Judge | null = null;
  setMultiple: boolean = false;
  get storeEventClasses(): Array<Array<EventClass>> {
    return NewShowStore.eventClasses;
  }
  get events(): Array<DogEvent> {
    return NewShowStore.show.events.map((x) => {
      return new DogEvent(
        x.id,
        new Date(x.startDate),
        new Date(x.endDate),
        x.AKCNumber,
        x.type
      );
    });
  }
  get judges(): Array<Judge> {
    return NewShowStore.judges;
  }
  get showPointer(): boolean {
    return !!this.judge;
  }
  get showHover(): boolean {
    return !!this.judge;
  }
  mounted() {
    this.eventClasses = NewShowStore.eventClasses.map((x) => {
      return x.map((y) => {
        return new EventClass(y.id, y.event, y.dogClass, y.fee, y.judge);
      });
    });
  }

  @Watch("storeEventClasses")
  onStoreEventClassesChange() {
    this.eventClasses = NewShowStore.eventClasses.map((x) => {
      return x.map((y) => {
        return new EventClass(y.id, y.event, y.dogClass, y.fee, y.judge);
      });
    });
  }

  changeJudge(idx: number) {
    this.judge = this.eventClasses[this.selectedEventIdx][idx].judge;
    NewShowStore.setEventClasses(this.eventClasses);
  }

  eventChanged(idx: number) {
    this.selectedEventIdx = idx;
  }

  printJudge(idx: number): string {
    let judge: Judge = this.eventClasses[this.selectedEventIdx][idx].judge;
    if (judge.name.trim().length > 0) {
      return judge.name + (judge.number ? " (" + judge.number + ")" : "");
    }
    return "No judge assigned.";
  }

  clickedClass(idx: number) {
    if (this.judge) {
      this.eventClasses[this.selectedEventIdx][idx].judge = this.judge;
      NewShowStore.setEventClasses(this.eventClasses);
    }
  }

  applyAll() {
    if (this.judge) {
      for (let eventClass of this.eventClasses[this.selectedEventIdx]) {
        eventClass.judge = this.judge;
      }
    }
    NewShowStore.setEventClasses(this.eventClasses);
  }

  validate(): Array<string> {
    let out : Array<string> = []
    for(let eventClasses of this.eventClasses){
      for(let eventClass of eventClasses){
        if(eventClass.judge.name.trim().length == 0){
          out.push("The class " + eventClass.dogClass.name + " has no judge assigned.")
        }
      }
    }
    return out;
  }
}
