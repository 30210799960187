const dLL = {
    // stringCapitalization: function (w: string): string {
    //     let newWord: string = w
    //     if (!/[a-z]/.test(w) || !/[A-Z]/.test(w)) {
    //         newWord = this.stringCleanWhiteSpace(newWord)
    //         newWord = newWord.split(" ")
    //             .map(function (x) {
    //                 return x.charAt(0).toUpperCase() + x.slice(1).toLowerCase();
    //             })
    //             .join(" ");
    //     }
    //     return newWord
    // },
    // stringCleanWhiteSpace: function (x: string): string {
    //     return x.trim().split(' ').map((s) => { return s.trim() }).join(' ')
    // },
    // stringFixCapitalization: function (x: string): string {
    //     return "";
    // },

    // differenceInMonths(x: Date, y: Date): number {
    //     return 0;
    // },
    // groupByA<T, K extends keyof T>(list: T[], getKey: (item: T) => K){
    //     return list.reduce((previous, currentItem) => {
    //         const group = getKey(currentItem);
    //         if (!previous[group]) previous[group] = [];
    //         previous[group].push(currentItem);
    //         return previous;
    //     }, {} as Record<K, T[]>)
    // },
    groupBy<T,K extends keyof T>(list: T[], key: K): T[][]{
        return list.reduce((s : T[][],x : T) => {
            let u : T[] | undefined =  s.find((v) => { return v[0][key] == x[key]})
            if(!u){
                s.push([x])
            }else{
                u.push(x)
            }
            return s
        },[])
    }
}

export default dLL