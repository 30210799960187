
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ShowStore from "../../store/modules/show";
import DogStore from "../../store/modules/dog";
import SecretaryStore from "../../store/modules/secretary"
import SecretaryEntryStore from "../../store/modules/secretary_entries"
import DogBirthDate from "../DogForm/DogBirthDate.vue";
import DogAddress from "../DogForm/DogAddress/DogAddress.vue";
import EventGroupEntry from "./EventGroupEntry.vue";
import {
  Dog,
  DogEvent,
  Entry,
  EventClass,
  Show,
  ShowDog,
  ShowEntry,
  ShowType,
} from "@app/common";
import { DogClass, DogClassType } from "@app/common/classes";
@Component({ components: { DogBirthDate, DogAddress, EventGroupEntry } })
export default class EntryClasses extends Vue {
  @Prop({
    default: () => {
      return [];
    },
  })
  readonly value!: Array<Entry>;
  @Prop({default : false}) readonly edit! : boolean
  @Prop({default : false}) readonly secretary! : boolean
  entries: Array<Array<Entry>> = [];

  get dog(): Dog | undefined {
    if(!this.secretary){
      return DogStore.selectedDog;
    }else{
      return SecretaryEntryStore.selectedShowDog?.showDog.dog
    }
  }

  get show(): Show | undefined {
    if(!this.secretary){
      return ShowStore.show;
    }else{
      return SecretaryStore.selectedShow
    }
    
  }


  get events(): Array<DogEvent> {
    if(!this.secretary && ShowStore.show){
      return ShowStore.show.events
    }else if(this.secretary && SecretaryStore.selectedShow){
      return SecretaryStore.selectedShow.events
    }else{
      return []
    }
  }

  get eventGroups(): Array<Array<DogEvent>> {
    if(!this.secretary && ShowStore.show){
      return ShowStore.show.eventGroups
    }else if(this.secretary && SecretaryStore.selectedShow){
      return SecretaryStore.selectedShow.eventGroups
    }else{
      return []
    }
  }

  mounted() {
    let entries : Array<Array<Entry>> = []
    if (this.show && this.dog && !this.edit) {
      let showDog: ShowDog = new ShowDog(-1, this.dog);
      for (let eventGroup of this.eventGroups) {
        let eventGroupEntries : Array<Entry> = []
        for (let event of eventGroup) {
          let newEntry = new Entry(this.show, event, showDog);
          eventGroupEntries.push(newEntry)
        }
        entries.push(eventGroupEntries)
      }
    }else if(this.show && this.dog && this.edit && DogStore.selectedShowEntry){
      for (let eventGroup of this.eventGroups) {
        let eventGroupEntries : Array<Entry> = []
        for (let event of eventGroup) {
          let newEntry = DogStore.selectedShowEntry.entriesNotRefunded.find((x) => {return x.event.id === event.id})
          if(newEntry){
            eventGroupEntries.push(newEntry)
          }
        }
        entries.push(eventGroupEntries)
      }
    }
    this.entries = entries
  }

  update(x: Array<Entry>,idx : number) {
    //this.entries[idx] = x
    this.$set(this.entries,idx,x)
    this.$emit("input", this.entries);
  }
}
