
import { UserInfo } from "@app/common";
import { Component, Vue } from "vue-property-decorator";
import UserStore from "../store/modules/user";

@Component
export default class Home extends Vue {
  get userInfo(): UserInfo {
    return UserStore.userInfo;
  }
}
