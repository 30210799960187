
import { DogEvent, Show } from "@app/common";
import { Component, Vue } from "vue-property-decorator";
import DogPaymentsSecretary from "../DogPaymentsSecretary.vue";
import ShowManagerCreatePayment from "./ShowManagerCreatePayment.vue"
import SecretaryStore from "../../store/modules/secretary";
@Component({ components: { DogPaymentsSecretary, ShowManagerCreatePayment } })
export default class ShowManagerPaymentsPanel extends Vue {
  search: string = "";
  selectedEvents: Array<DogEvent> = [];
  paymentStatusItems: Array<"Completed" | "Pending" | "Failed"> = [
    "Completed",
    "Pending",
    "Failed",
  ];
  paymentStatusSelected: Array<"Completed" | "Pending" | "Failed"> = [
    "Completed",
    "Pending",
    "Failed",
  ];
  paymentMethodItems: Array<"All" | "Paid onsite" | "Paid offsite"> = [
    "All",
    "Paid onsite",
    "Paid offsite",
  ];
  paymentMethodSelected: "All" | "Paid onsite" | "Paid offsite" = "All";
  transactionTypeItems: Array<"All" | "Payment" | "Refund"> = [
    "All",
    "Payment",
    "Refund",
  ];
  transactionTypeSelected: "All" | "Payment" | "Refund" = "All";

  createPaymentModal : boolean = false

  get show(): Show | undefined {
    return SecretaryStore.selectedShow;
  }

  get showPayments(): boolean {
    return (
      this.transactionTypeSelected == "All" ||
      this.transactionTypeSelected == "Payment"
    );
  }
  get showRefunds(): boolean {
    return (
      this.transactionTypeSelected == "All" ||
      this.transactionTypeSelected == "Refund"
    );
  }

  get showOnline(): boolean {
    return (
      this.paymentMethodSelected == "All" ||
      this.paymentMethodSelected == "Paid onsite"
    );
  }
  get showManual(): boolean {
    return (
      this.paymentMethodSelected == "All" ||
      this.paymentMethodSelected == "Paid offsite"
    );
  }
  get showCompleted(): boolean {
    return this.paymentStatusSelected.includes("Completed");
  }
  get showPending(): boolean {
    return this.paymentStatusSelected.includes("Pending");
  }
  get showFailed(): boolean {
    return this.paymentStatusSelected.includes("Failed");
  }

  //   @Prop({ default: false }) readonly showPayments!: boolean;
  // @Prop({ default: false }) readonly showRefunds!: boolean;
  // @Prop({ default: "" }) readonly search!: string;
  // @Prop({ default: true }) readonly showOnline!: boolean;
  // @Prop({ default: true }) readonly showManual!: boolean;
  // @Prop({ default: true }) readonly showComplete!: boolean;
  // @Prop({ default: true }) readonly showPending!: boolean;

  createPayment(){
    this.createPaymentModal = true
  }

  async mounted() {
    if (this.show) {
      this.selectedEvents = this.show.events;
    }
  }

  close() {
    this.$emit("close");
  }
}
