import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import {
  AddDogRequest,
  AddDogResponse,
  Address,
  CheckEditDogRequest,
  CheckEditDogResponse,
  Dog,
  DogEvent,
  EditDogRequest,
  EditDogResponse,
  EditEntryRequest,
  EditEntryResponse,
  EnterDogRequest,
  EnterDogResponse,
  Entry,
  EntryErrors,
  EventClass,
  FetchDogDataResponse,
  GeneralErrors,
  Judge,
  Person,
  RemoveEntryRequest,
  RemoveEntryResponse,
  RetireDogRequest,
  RetireDogResponse,
  Show,
  ShowDog,
  ShowEntry,
  User,
} from "@app/common";
import store from "..";
import { fetchErrorMessage, generalErrorMessage } from "../error";
import { DogClass } from "@app/common/classes";
import UserStore from "./user";

export interface DogState {
  dogs: Array<Dog>;
}

@Module({ dynamic: true, store, name: "DogStore", namespaced: true })
class DogStore extends VuexModule {
  private _selectedDog: Dog | undefined = undefined;
  get selectedDog(): Dog | undefined {
    return this._selectedDog;
  }
  @Mutation
  setSelectedDog(x: Dog | undefined) {
    this._selectedDog = x;
  }
  private _selectedShowEntry: ShowEntry | undefined = undefined;
  get selectedShowEntry() : ShowEntry | undefined{
    return this._selectedShowEntry
  }
  @Mutation
  setSelectedShowEntry(x : ShowEntry | undefined){
    this._selectedShowEntry = x
  }

  private _dogs: Array<Dog> = [];
  private _entries: Array<ShowEntry> = [];
  private show: Show | undefined;
  private _addresses: Array<Address> = [];
  private _people: Array<Person> = [];
  private dogsFetched = false;
  private _breeders: Array<Person> = [];
  private _owners: Array<Person> = [];
  private _judges: Array<Judge> = [];
  private _shows: Array<Show> = [];
  private _eventClasses: Array<EventClass> = [];
  private _classes: Array<DogClass> = [];
  private _showDogs: Array<ShowDog> = [];
  private _showDogEdits: Array<ShowDog> = [];
  private _events: Array<DogEvent> = [];

  private _fetchingDogData = false;
  private _fetchingDogDataError: GeneralErrors | undefined = undefined;
  private _dogDataNeedsUpdating = true;

  get dogs(): Array<Dog> {
    return this._dogs;
  }
  @Mutation
  setDogs(x: Array<Dog>) {
    this._dogs = x;
  }

  get entries(): Array<ShowEntry> {
    return this._entries;
  }
  @Mutation
  setEntries(x: Array<ShowEntry>) {
    this._entries = x;
  }

  get addresses(): Array<Address> {
    return this._addresses;
  }
  @Mutation
  setAddresses(x: Array<Address>) {
    this._addresses = x;
  }

  get people(): Array<Person> {
    return this._people;
  }
  @Mutation
  setPeople(x: Array<Person>) {
    this._people = x;
  }

  get breeders(): Array<Person> {
    return this._breeders;
  }
  @Mutation
  setBreeders(x: Array<Person>) {
    this._breeders = x;
  }

  get owners(): Array<Person> {
    return this._owners;
  }
  @Mutation
  setOwners(x: Array<Person>) {
    this._owners = x;
  }

  get judges(): Array<Judge> {
    return this._judges;
  }
  @Mutation
  setJudges(x: Array<Judge>) {
    this._judges = x;
  }

  get shows(): Array<Show> {
    return this._shows;
  }
  @Mutation
  setShows(x: Array<Show>) {
    this._shows = x;
  }

  get events(): Array<DogEvent> {
    return this._events;
  }
  @Mutation
  setEvents(x: Array<DogEvent>) {
    this._events = x;
  }

  get eventClasses(): Array<EventClass> {
    return this._eventClasses;
  }
  @Mutation
  setEventClasses(x: Array<EventClass>) {
    this._eventClasses = x;
  }

  get classes(): Array<DogClass> {
    return this._classes;
  }
  @Mutation
  setClasses(x: Array<DogClass>) {
    this._classes = x;
  }

  get showDogs(): Array<ShowDog> {
    return this._showDogs;
  }
  @Mutation
  setShowDogs(x: Array<ShowDog>) {
    this._showDogs = x;
  }
  get showDogEdits(): Array<ShowDog> {
    return this._showDogEdits;
  }
  @Mutation
  setShowDogEdits(x: Array<ShowDog>) {
    this._showDogEdits = x;
  }

  get fetchingDogData(): boolean {
    return this._fetchingDogData;
  }
  @Mutation
  setFetchingDogData(x: boolean) {
    this._fetchingDogData = x;
  }

  get fetchingDogDataError(): GeneralErrors | undefined {
    return this._fetchingDogDataError;
  }
  @Mutation
  setFetchingDogDataError(x: GeneralErrors | undefined) {
    this._fetchingDogDataError = x;
  }

  get dogDataNeedsUpdating(): boolean {
    return this._dogDataNeedsUpdating;
  }
  @Mutation
  setDogDataNeedsUpdating(x: boolean) {
    this._dogDataNeedsUpdating = x;
  }

  @Action
  async fetchDogData() {
    this.setFetchingDogData(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/dog/fetch-data",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({}),
        credentials: "include",
      }
    );
    this.setFetchingDogData(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: FetchDogDataResponse = await response.json();
      if (data.success) {
        const user: User = User.userFromJSON(data.user);
        this.setPeople(Person.personArrayFromJSON(data.people));
        const breeders: Array<Person> = this.people.filter((x) => {
          if (x.id) {
            return data.breeders.includes(x.id);
          } else {
            return false;
          }
        });
        this.setBreeders(breeders);
        const owners: Array<Person> = this.people.filter((x) => {
          if (x.id) {
            return data.owners.includes(x.id);
          } else {
            return false;
          }
        });
        this.setOwners(owners);
        this.setAddresses(Address.addressArrayFromJSON(data.addresses));
        this.setDogs(
          Dog.dogArrayFromJSON(
            data.dogs,
            this.owners,
            this.breeders,
            this.addresses
          )
        );
        this.setShows(Show.showArrayFromJSON(data.shows));
        let events: Array<DogEvent> = [];
        for (const show of this.shows) {
          events = events.concat(show.events);
        }
        this.setEvents(events);
        this.setJudges(Judge.judgeArrayFromJSON(data.judges));
        this.setClasses(DogClass.dogClassArrayFromJSON(data.classes));
        this.setEventClasses(
          EventClass.eventClassArrayFromJSON(
            data.eventClasses,
            this.events,
            this.classes,
            this.judges,
          )
        );
        this.setShowDogs(
          ShowDog.showDogArrayFromJSON(data.showDogs, [user], this.dogs)
        );
        this.setShowDogEdits(
          ShowDog.showDogArrayFromJSON(data.showDogsEdits, [user])
        );
        this.setEntries(
          ShowEntry.showEntryArrayFromJSON(
            data.entries,
            this.showDogs,
            this.showDogEdits,
            this.shows,
            this.events,
            this.eventClasses,
            this.addresses
          )
        );
        this.setFetchingDogDataError(undefined);
        this.setDogDataNeedsUpdating(false);
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
        this.context.rootState.errorCode = data.errors[0];
        this.setFetchingDogDataError(data.errors[0]);
      }
    }
  }

  private _addingDog = false;
  get addingDog(): boolean {
    return this._addingDog;
  }
  @Mutation
  setAddingDog(x: boolean) {
    this._addingDog = x;
  }

  private _addDogError: GeneralErrors | undefined = undefined;
  get addDogError(): GeneralErrors | undefined {
    return this._addDogError;
  }
  @Mutation
  setAddDogError(x: GeneralErrors | undefined = undefined) {
    this._addDogError = x;
  }

  private _newDog : number | undefined = undefined
  get newDog(): number | undefined{
    return this._newDog
  }
  @Mutation
  setNewDog(x : number | undefined){
    this._newDog = x
  }
  

  @Action
  async addDog(payload: AddDogRequest) {
    this.setAddingDog(true);
    const response = await fetch(process.env.VUE_APP_DOG_API + "/dog/add-dog", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(payload),
      credentials: "include",
    });
    this.setAddingDog(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: AddDogResponse = await response.json();
      if (data.success) {
        this.setAddDogError(undefined);
        this.setDogDataNeedsUpdating(true);
        this.setNewDog(data.dog)
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
        this.context.rootState.errorCode = data.errors[0];
        this.setAddDogError(data.errors[0]);
      }
      UserStore.authCheck(data);
    }
  }

  private _checkingEditDog = false;
  get checkingEditDog(): boolean {
    return this._checkingEditDog;
  }
  @Mutation
  setCheckingEditDog(x: boolean) {
    this._checkingEditDog = x;
  }

  private _checkEditDogErrors: GeneralErrors | "DOG_OWNERSHIP" | undefined =
    undefined;
  get checkEditDogErrors(): GeneralErrors | "DOG_OWNERSHIP" | undefined {
    return this._checkEditDogErrors;
  }
  @Mutation
  setCheckEditDogErrors(x: GeneralErrors | "DOG_OWNERSHIP" | undefined) {
    this._checkEditDogErrors = x;
  }

  private _checkEditDogProblemEntries: Array<{
    entry: number;
    paid: boolean;
    problemEventClasses: Array<{ eventClass: number; classEntry: number }>;
  }> = [];
  get checkEditDogProblemEntries(): Array<{
    entry: number;
    paid: boolean;
    problemEventClasses: Array<{ eventClass: number; classEntry: number }>;
  }> {
    return this._checkEditDogProblemEntries;
  }
  @Mutation
  setCheckEditDogProblemEntries(
    x: Array<{
      entry: number;
      paid: boolean;
      problemEventClasses: Array<{ eventClass: number; classEntry: number }>;
    }>
  ) {
    this._checkEditDogProblemEntries = x;
  }

  @Action
  async checkEditDog(payload: CheckEditDogRequest) {
    this.setCheckingEditDog(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/dog/check-dog-edit",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      }
    );
    this.setCheckingEditDog(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: CheckEditDogResponse = await response.json();
      if (data.success) {
        this.setCheckEditDogErrors(undefined);
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
        this.context.rootState.errorCode = data.errors[0];
        this.setCheckEditDogErrors(data.errors[0]);
      }
      this.setCheckEditDogProblemEntries(data.problemEntries);
    }
  }

  private _editingDog = false;
  get editingDog(): boolean {
    return this._editingDog;
  }
  @Mutation
  setEditingDog(x: boolean) {
    this._editingDog = x;
  }

  private _editDogError:
    | GeneralErrors
    | "DOG_OWNERSHIP"
    | "PROBLEM_ENTRIES"
    | undefined = undefined;
  get editDogError():
    | GeneralErrors
    | "DOG_OWNERSHIP"
    | "PROBLEM_ENTRIES"
    | undefined {
    return this._editDogError;
  }
  @Mutation
  setEditDogError(
    x: GeneralErrors | "DOG_OWNERSHIP" | "PROBLEM_ENTRIES" | undefined
  ) {
    this._editDogError = x;
  }

  private _editDogProblemEntries: Array<{
    entry: number;
    paid: boolean;
    problemEventClasses: Array<{ eventClass: number; classEntry: number }>;
  }> = [];
  get editDogProblemEntries(): Array<{
    entry: number;
    paid: boolean;
    problemEventClasses: Array<{ eventClass: number; classEntry: number }>;
  }> {
    return this._editDogProblemEntries;
  }
  @Mutation
  setEditDogProblemEntries(
    x: Array<{
      entry: number;
      paid: boolean;
      problemEventClasses: Array<{ eventClass: number; classEntry: number }>;
    }>
  ) {
    this._editDogProblemEntries = x;
  }

  @Action
  async editDog(payload: EditDogRequest) {
    this.setEditingDog(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/dog/edit-dog",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      }
    );
    this.setEditingDog(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: EditDogResponse = await response.json();
      if (data.success) {
        this.setEditDogError(undefined);
        this.setEditDogProblemEntries([])
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
        this.context.rootState.errorCode = data.errors[0];
        this.setEditDogError(data.errors[0]);
        this.setEditDogProblemEntries(data.problemEntries)
      }
      this.setEditDogProblemEntries(data.problemEntries);
      UserStore.authCheck(data);
    }
  }

  private _retiringDog = false;
  get retiringDog(): boolean {
    return this._retiringDog;
  }
  @Mutation
  setRetiringDog(x: boolean) {
    this._retiringDog = x;
  }

  private _retireDogError:
    | GeneralErrors
    | "DOG_OWNERSHIP"
    | "ACTIVE_PAID_ENTRIES"
    | undefined = undefined;
  get retireDogError():
    | GeneralErrors
    | "DOG_OWNERSHIP"
    | "ACTIVE_PAID_ENTRIES"
    | undefined {
    return this._retireDogError;
  }
  @Mutation
  setRetireDogError(
    x: GeneralErrors | "DOG_OWNERSHIP" | "ACTIVE_PAID_ENTRIES" | undefined
  ) {
    this._retireDogError = x;
  }

  @Action
  async retireDog(payload: RetireDogRequest) {
    this.setRetiringDog(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/dog/retire-dog",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      }
    );
    this.setRetiringDog(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: RetireDogResponse = await response.json();
      if (data.success) {
        this.setRetireDogError(undefined);
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
        this.context.rootState.errorCode = data.errors[0];
        this.setRetireDogError(data.errors[0]);
      }
      UserStore.authCheck(data);
    }
  }

  private _enteringDog = false;
  get enteringDog(): boolean {
    return this._enteringDog;
  }
  @Mutation
  setEnteringDog(x: boolean) {
    this._editingDog = x;
  }

  private _enterDogError: GeneralErrors | EntryErrors | undefined = undefined;
  get enterDogError(): GeneralErrors | EntryErrors | undefined {
    return this._enterDogError;
  }
  @Mutation
  setEnterDogError(x: GeneralErrors | EntryErrors | undefined) {
    this._enterDogError = x;
  }

  @Action
  async enterDog(payload: EnterDogRequest) {
    this.setEnteringDog(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/entry/enter-dog",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      }
    );
    this.setEnteringDog(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: EnterDogResponse = await response.json();
      if (data.success) {
        this.setEnterDogError(undefined);
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
        this.context.rootState.errorCode = data.errors[0];
        this.setEnterDogError(data.errors[0]);
      }
      UserStore.authCheck(data);
    }
  }

  private _editingEntry = false;
  get editingEntry(): boolean {
    return this.editingEntry;
  }
  @Mutation
  setEditingEntry(x: boolean) {
    this._editingEntry = x;
  }

  private _editEntryError: GeneralErrors | EntryErrors | undefined = undefined;
  get editEntryError(): GeneralErrors | EntryErrors | undefined {
    return this._editEntryError;
  }
  @Mutation
  setEditEntryError(x: GeneralErrors | EntryErrors | undefined) {
    this._editEntryError = x;
  }

  @Action
  async editEntry(payload: EditEntryRequest) {
    this.setEditingEntry(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/entry/edit-entry",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      }
    );
    this.setEditingEntry(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: EditEntryResponse = await response.json();
      if (data.success) {
        this.setEditEntryError(undefined);
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
        this.context.rootState.errorCode = data.errors[0];
        this.setEditEntryError(data.errors[0]);
      }
      UserStore.authCheck(data);
    }
  }

  private _removingEntry = false;
  get removingEntry(): boolean {
    return this._removingEntry;
  }
  @Mutation
  setRemovingEntry(x: boolean) {
    this._removingEntry = x;
  }

  private _removeEntryError: GeneralErrors | EntryErrors | undefined =
    undefined;
  get removeEntryError(): GeneralErrors | EntryErrors | undefined {
    return this._removeEntryError;
  }
  @Mutation
  setRemoveEntryError(x: GeneralErrors | EntryErrors | undefined) {
    this._removeEntryError = x;
  }

  @Action
  async removeEntry(payload: RemoveEntryRequest) {
    this.setRemovingEntry(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/entry/remove-entry",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      }
    );
    this.setRemovingEntry(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: RemoveEntryResponse = await response.json();
      if (data.success) {
        this.setRemoveEntryError(undefined);
      } else {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
        this.context.rootState.errorCode = data.errors[0];
        this.setRemoveEntryError(data.errors[0]);
      }
      UserStore.authCheck(data);
    }
  }
}

export default getModule(DogStore);
