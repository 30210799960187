
import { DogEvent, QRReceiptResponse } from "@app/common";
import { Component, Vue } from "vue-property-decorator";
import Loading from "../components/Loading.vue";
import PaymentStore from "../store/modules/payment";
@Component({ components: { Loading } })
export default class QRReceipt extends Vue {
    loading : boolean = true
    failed : boolean = false
    receiptData : QRReceiptResponse = { success : false, showName: "", entries: [], catalog: false, catalogFee: 0, surcharge: 0, amount: 0, time: -1, id: -1, qr: "", type: 'PAYMENT', errors: [] }
    entries : Array<{dogName : string, event : DogEvent, classes : Array<{name : string, fee: number}>}> = []
    get QRReceiptData() : QRReceiptResponse | undefined {
        return PaymentStore.QRReceiptData
    }

    async mounted(){
        let x : string | undefined = this.$route.query.x as string
        if(x){
            await PaymentStore.QRFetch({x : x})
            if((!PaymentStore.QRReceiptErrors || PaymentStore.QRReceiptErrors.length == 0) && this.QRReceiptData){
                this.receiptData = this.QRReceiptData
                for(let entry of this.receiptData.entries){
                    let newEntry = {
                        dogName : entry.dogName,
                        event : new DogEvent(entry.event.id,new Date(entry.event.startDate),new Date(entry.event.endDate),entry.event.AKCNumber, entry.event.type),
                        classes : entry.classes
                    }
                    this.entries.push(newEntry)
                }
            }else{
                this.failed = true
            }
        }else{
            this.failed = true
        }
        this.loading = false
    }

}
