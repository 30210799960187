var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',[_c('loading',{attrs:{"loading":_vm.loading}},[_vm._v(" Our automatically generated abbrevation for your show is "),_c('b',[_vm._v(_vm._s(_vm.abbr))]),_vm._v(". It will appear in the URL and may appear in receipts. For almost all cases, you will not need to modify this abbrevation. However, in the event that this generated abbrevation contains obscene or offensive content, then you can modify it in the text field below. Note that your abbrevation may only contain alphanumeric characters and hyphens. "),_c('v-text-field',{attrs:{"label":"Show abbreviation","error-messages":_vm.abbrError},on:{"input":function($event){return _vm.checkAbbr()},"change":function($event){return _vm.checkAbbr()}},model:{value:(_vm.customAbbr),callback:function ($$v) {_vm.customAbbr=$$v},expression:"customAbbr"}}),_c('br'),_c('br'),_vm._v(" The title of your show will appear as below. Every title has two lines, one in a larger font followed by one in a smaller font. It's suggested that you modify this so it displays nicely across all devices."),_c('br'),_c('br'),_c('v-card',{staticStyle:{"background":"linear-gradient(90deg, #ee6d87 0%, #6d7dce 100%)"}},[(_vm.show)?_c('div',{staticClass:"text-center white--text pa-3",staticStyle:{"background":"linear-gradient(90deg, #ee6d87 0%, #6d7dce 100%)"}},[_c('div',{class:{
              'text-h2': _vm.$vuetify.breakpoint.smAndUp,
              'text-h4': !_vm.$vuetify.breakpoint.smAndUp,
            }},[_vm._v(" "+_vm._s(_vm.topLine)+" ")]),_c('div',{class:{
              'text-h4': _vm.$vuetify.breakpoint.smAndUp,
              'text-h5': !_vm.$vuetify.breakpoint.smAndUp,
            }},[_vm._v(" "+_vm._s(_vm.bottomLine)+" ")])]):_vm._e(),_c('div',{staticStyle:{"width":"100%","background-color":"white","height":"50px"}})]),_c('br'),_c('br'),_c('v-text-field',{attrs:{"label":"Top line"},model:{value:(_vm.topLine),callback:function ($$v) {_vm.topLine=$$v},expression:"topLine"}}),_c('v-text-field',{attrs:{"label":"Bottom line"},model:{value:(_vm.bottomLine),callback:function ($$v) {_vm.bottomLine=$$v},expression:"bottomLine"}}),_c('br'),_c('br'),_vm._v(" Finally, you can add a notice to your show that will appear on your show page. This option should rarely be used and only pertain to matters regarding online entries on this site. "),_c('v-textarea',{attrs:{"label":"Notice"},model:{value:(_vm.notice),callback:function ($$v) {_vm.notice=$$v},expression:"notice"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }