
import { RegistrationNumber, RegistrationTypes } from "@app/common";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DogCountry from "./DogCountry.vue";
@Component({ components: { DogCountry } })
export default class DogNumber extends Vue {
  @Prop() readonly value!: RegistrationNumber | undefined;
  toggle: number = 0;
  type: number = 0;
  country: string = "";

  registrationNumber: RegistrationNumber = new RegistrationNumber();
  update() {
    console.log("update");
    this.$emit("input", this.registrationNumber);
  }

  akcRules = function (x: string) {
    if (x.substr(0, 2)) {
      if (!/^[a-zA-Z]+$/.test(x.substr(0, 2))) {
        return "AKC numbers must consist of 2 letters followed by 8 digits.";
      }
    }

    if (x.substr(2, 10)) {
      if (!/^\d+$/.test(x.substr(2, 10))) {
        return "AKC numbers must consist of 2 letters followed by 8 digits.";
      }
    }

    return true;
  };

  @Watch("type")
  onTypeChange(x: number) {
    if (x == 0) {
      this.registrationNumber.type = RegistrationTypes.AKC;
      this.akcUpdate();
    } else if (x == 1) {
      this.registrationNumber.type = RegistrationTypes.AKCLIT;
      this.akcLitErrors = []
    } else if (x == 2) {
      this.registrationNumber.type = RegistrationTypes.ILP;
      this.piErrors = []
    } else if (x == 3) {
      this.registrationNumber.type = RegistrationTypes.FOREIGN;
      this.foreignErrors = []
    }
  }

  akcErrors: Array<string> = [];
  akcLitErrors: Array<string> = [];
  piErrors: Array<string> = [];
  foreignErrors: Array<string> = [];

  akcInput() {
    this.registrationNumber.number =
      this.registrationNumber.number.toUpperCase();
    this.$emit("input", this.registrationNumber)
  }
  akcUpdate() {
    if (this.registrationNumber.number.length !== 10) {
      this.akcErrors = [
        "AKC numbers must consist of 2 letters followed by 8 digits.",
      ];
    } else if (
      this.registrationNumber.number.length == 10 &&
      this.akcRules(this.registrationNumber.number) == true
    ) {
      this.akcErrors = [];
      this.$emit("input",this.registrationNumber)
    }
  }

  @Watch("value")
  onValueChange() {
    if (this.value) {
      this.registrationNumber = this.value;
      if (this.registrationNumber.type === RegistrationTypes.AKC) {
        this.type = 0;
      } else if (this.registrationNumber.type === RegistrationTypes.AKCLIT) {
        this.type = 1;
      } else if (this.registrationNumber.type === RegistrationTypes.ILP) {
        this.type = 2;
      } else if (this.registrationNumber.type === RegistrationTypes.FOREIGN) {
        this.type = 3;
        this.country = this.registrationNumber.country;
      }
    }
  }

  numberInput(){
    if(this.registrationNumber.number.trim().length > 0){
      if(this.type == 1){
        this.akcLitErrors = []
      }else if(this.type == 2){
        this.piErrors = []
      }else if(this.type == 3){
        this.foreignErrors = []
      }
      this.$emit("input",this.registrationNumber)
    }
  }

  validate(): Array<string> {
    this.akcLitErrors = [];
    this.piErrors = [];
    this.foreignErrors = [];
    if (this.type == 0) {
      if(this.registrationNumber.number.trim().length == 0){
        this.akcErrors = ["You must have a registration number."]
      }
      if(this.akcErrors.length > 0){
        return this.akcErrors;
      }
    } else if (this.type == 1 && this.registrationNumber.number.trim().length == 0) {
        this.akcLitErrors = ["You must have a registration number."];
        return this.akcLitErrors;
    } else if (this.type == 2 && this.registrationNumber.number.trim().length == 0){
        this.piErrors = ["You must have a registration number."]
        return this.piErrors
    } else if (this.type == 3){
        if(this.registrationNumber.number.trim().length == 0){
          this.foreignErrors = ["You must have a registration number."]
        }
        if(this.registrationNumber.country.trim().length == 0){
          this.foreignErrors = this.foreignErrors.concat(["You must select a country for your registration number."])
        }
        return this.foreignErrors
    }
    return [];
  }
}
