
import {
  GeneralErrors,
  PayByCheckRequest,
  PaymentErrors,
  Show,
  ShowDataRequest,
  ShowEntry,
} from "@app/common";
import { Component, Vue } from "vue-property-decorator";
import Loading from "../components/Loading.vue";
import CreditCardPayment from "../components/CreditCardPayment/CreditCardPayment.vue";
import StickyFooter from "../components/StickyFooter.vue";
import DogEntries from "../components/DogEntries.vue";
import ShowStore from "../store/modules/show";
import UserStore from "../store/modules/user";
import PaymentStore from "../store/modules/payment";
@Component({
  components: { Loading, CreditCardPayment, DogEntries, StickyFooter },
})
export default class PaymentPage extends Vue {
  show: Show | undefined = undefined;
  loading: boolean = true;
  showReady: boolean = false;
  showEntries: Array<ShowEntry> = [];
  buyCatalog: boolean = false;
  abbr: string = "";
  disablePay: boolean = false;

  async beforeMount() {
    this.abbr = this.$route.params.abbr;
    if (ShowStore.selectedShow && ShowStore.selectedShow.abbr === this.abbr) {
      this.show = ShowStore.selectedShow;
    } else {
      let payload: ShowDataRequest = {
        abbr: this.abbr,
      };
      await ShowStore.fetchShowData(payload);
      this.show = ShowStore.show;
    }
    this.loading = false;
    this.showReady = true;
  }

  setShowEntries(x: Array<ShowEntry>) {
    this.showEntries = x;
  }

  get fee(): number {
    return (
      ShowEntry.fee(this.showEntries) +
      (this.show && this.buyCatalog ? this.show.catalog.fee : 0)
    );
  }

  payByCheckPage: number = 0;

  get payingByCheck(): boolean {
    return PaymentStore.payingByCheck;
  }

  get payByCheckError(): GeneralErrors | PaymentErrors | undefined {
    return PaymentStore.payByCheckErrors
      ? PaymentStore.payByCheckErrors[0]
      : undefined;
  }

  async payByCheck() {
    if (this.show && this.show.id) {
      let entries: Array<number> = [];
      for (let showEntry of this.showEntries) {
        for (let entry of showEntry.unpaidEntries) {
          if (entry.id !== null) {
            entries.push(entry.id);
          }
        }
      }
      let payload: PayByCheckRequest = {
        show: this.show.id,
        entries: entries,
        catalog: this.buyCatalog,
        csrfSecurityToken: UserStore.csrfSecurityToken,
      };
      await PaymentStore.makeCheckPayment(payload);
      this.payByCheckPage = 1;
      this.disablePay = true;
    }
  }
}
