
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DogAddressSmart from "./DogAddressSmart.vue";
import DogAddressManual from "./DogAddressManual.vue";
import DogAddressForeign from "./DogAddressForeign.vue";
import DogToggle from "../DogToggle.vue";
import DogStore from "../../../store/modules/dog";
import { Address, AddressType } from "@app/common";

@Component({
  components: {
    DogAddressSmart,
    DogAddressManual,
    DogAddressForeign,
    DogToggle,
  },
})
export default class DogAddress extends Vue {
  @Prop() readonly value!: Address
  @Prop({default : "Address"}) readonly label!: string
  @Prop({default : true}) readonly international!: boolean
  @Prop({default : undefined}) readonly addresses!: Array<Address> | undefined
  address: Address = new Address();
  mode = 0;
  addressUSSmart : Address = new Address()
  addressUSManual : Address = new Address(null,"","","","","","United States",AddressType.USMANUAL)
  addressForeign : Address = new Address(null,"","","","","","",AddressType.INTERNATIONAL)
  get existingAddresses(): Array<Address> {
    if(this.addresses){
      return this.addresses
    }else{
      return DogStore.addresses;
    }
  }
  

  useAddress(idx: number) {
    this.address = this.existingAddresses[idx];
    this.update(this.address)
  }

  selectedStyle(idx: number): { "background-color": string } | undefined {
    if (
      this.$vuetify.theme.themes.light.primary &&
      this.address.id == this.existingAddresses[idx].id
    ) {
      return {
        "background-color": this.$vuetify.theme.themes.light.primary.toString(),
      };
    }
  }

  selectedClass(idx: number): string {
    if (this.address.id == this.existingAddresses[idx].id) {
      return "selected";
    } else {
      return "";
    }
  }

  mounted(){
    if(this.existingAddresses.length == 0 ){
      this.mode = 1
    }
  }

  update(x : Address){
    this.$emit("input",x)
  }

  @Watch("value")
  onValueChange(x : Address){
    this.address = x
  }

  type = 0
  @Watch("type")
  onTypeChange(x : number){
    if(x == 0){
      this.address.type = AddressType.USSMART
    }else if(x == 1){
      this.address.type = AddressType.USMANUAL
    }else if(x == 2){
      this.address.type = AddressType.INTERNATIONAL
    }
  }

  @Watch("addresses")
  onAddressesChange(x : Array<Address> | undefined){
    if(x && x.length > 0){
      this.mode = 0
    }else{
      this.mode = 1
    }
  }

  checkIfAddressComplete(x: Address): boolean{
    return false
  }

  validate():Array<string>{
    if(this.mode == 0 && this.address.id === null){
      return ["You must have an address."]
    }else if(this.mode == 1){
      if(this.type == 0){
        return (this.$refs.dogAddressSmart as any).validate()
      }else if(this.type == 1){
        return (this.$refs.dogAddressManual as any).validate()
      }else{
        return (this.$refs.dogAddressForeign as any).validate()
      }
    }
    return []
  }
}
