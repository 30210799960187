
import {
  Address,
  Dog,
  DogEvent,
  DogEventType,
  Entry,
  EventClass,
  JuniorShowmanship,
  OBEDIENCE_JUMP_HEIGHTS,
  RALLY_JUMP_HEIGHTS,
  Show,
  ShowDog,
} from "@app/common";
import { DogClassType } from "@app/common/classes";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ShowStore from "../../store/modules/show";
import SecretaryStore from "../../store/modules/secretary";
import SecretaryEntryStore from "../../store/modules/secretary_entries";
import DogStore from "../../store/modules/dog";
import DogBirthDate from "../DogForm/DogBirthDate.vue";
import DogAddress from "../DogForm/DogAddress/DogAddress.vue";
import DogEntry from "../DogEntry.vue";

@Component({
  components: {
    DogBirthDate,
    DogAddress,
  },
})
export default class EventGroupEntry extends Vue {
  @Prop() readonly eventGroup!: Array<DogEvent>;
  @Prop() readonly showDog!: Array<DogEvent>;
  @Prop({ default: false }) readonly edit!: boolean;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ default: false }) readonly secretary!: boolean;
  eventClasses: Array<EventClass> = [];
  entries: Array<Entry> = [];
  initialized: boolean = false;
  formEntries: Array<{
    hasAgent: boolean;
    agent: string;
    hasJS: boolean;
    JSName: string;
    JSNameError: Array<string>;
    JSNumber: string;
    JSNumberError: Array<string>;
    JSAddress: Address;
    JSAddressError: Array<string>;
    JSBirthDate: Date | null;
    JSBirthDateError: Array<string>;
    JSRelationship: string | undefined;
    JSClass: EventClass | null;
    JSClassError: Array<string>;
    obedienceHeight: number | undefined;
    obedienceHeightError: Array<string>;
    rallyHeight: number | undefined;
    rallyHeightError: Array<string>;
    classes: Array<EventClass | null>;
  }> = [];

  itemDisabled(x: EventClass, idx: number, jdx: number): boolean {
    let thisClass = this.formEntries[idx].classes.find((y) => {
      return y && y.id == x.id;
    });
    if (thisClass) {
      if (
        this.formEntries[idx].classes[jdx] &&
        this.formEntries[idx].classes[jdx]?.id === x.id
      ) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  gotoRefunds() {
    if (ShowStore.selectedShow) {
      this.$router.push("/refunds/" + ShowStore.selectedShow.abbr);
    }
  }

  get dateRange(): string {
    this.eventGroup[0].eventType;
    return DogEvent.printEventGroupDateRange(this.eventGroup);
  }

  get paid(): boolean {
    if (DogStore.selectedShowEntry && this.edit) {
      let eventGroupEntries: Array<Entry> =
        DogStore.selectedShowEntry.entries.filter((x) => {
          return this.eventGroup.find((y) => {
            return y.id === x.event.id;
          });
        });
      return !!eventGroupEntries.find((x) => {
        return x.paid && !x.refunded;
      });
    }
    return false;
  }

  get showEventClasses(): Array<EventClass> {
    if (!this.secretary) {
      return ShowStore.eventClasses;
    } else {
      return SecretaryStore.eventClasses;
    }
  }

  eligibleCLasses(x: DogEvent, idx: number, jdx: number): Array<EventClass> {
    let availableClasses: Array<EventClass> = this.showEventClasses.filter(
      (y) => {
        return y.event.id === x.id;
      }
    );
    if (x.type === DogEventType.CONFORMATION) {
      availableClasses = availableClasses.filter((x) => {
        return x.dogClass.type !== DogClassType.CONFORMATION_JUNIOR;
      });
    }
    if (this.show && this.dog) {
      let showDog: ShowDog = new ShowDog(-1, this.dog, this.dog);
      let entry: Entry = new Entry(this.show, x, showDog);
      entry.agent = this.formEntries[idx].hasAgent
        ? this.formEntries[idx].agent
        : null;
      let classTypes: Array<DogClassType> = [];
      for (let formEntry of this.formEntries) {
        let filteredClasses: Array<EventClass> = formEntry.classes.filter(
          (x) => {
            return !!x;
          }
        ) as Array<EventClass>;
        if (x.type === DogEventType.CONFORMATION) {
          filteredClasses = filteredClasses.filter((x) => {
            return x.dogClass.type !== DogClassType.CONFORMATION_JUNIOR;
          });
        }
        classTypes = classTypes.concat(
          filteredClasses.map((x) => {
            return x.dogClass.type;
          })
        );
      }
      let eligibleClasses: Array<EventClass> = [
        EventClass.getNoneClass(entry.event),
      ];
      for (let eventClass of entry.eligibleClasses(
        availableClasses,
        classTypes
      )) {
        let hide: boolean = !!this.formEntries[idx].classes.find((y) => {
          return y && y.id === eventClass.id;
        });
        let currentClass: EventClass | null =
          this.formEntries[idx].classes[jdx];
        if (currentClass && currentClass.id == eventClass.id) {
          hide = false;
        }
        let thisClass = this.eventClasses.find((y) => {
          return y.id == eventClass.id;
        });
        if (thisClass) {
          eligibleClasses.push(thisClass);
        }
      }

      return eligibleClasses;
    } else {
      return [];
    }
  }

  juniorClasses(x: DogEvent): Array<EventClass> {
    return this.showEventClasses.filter((y) => {
      return (
        y.event.id === x.id &&
        y.dogClass.type === DogClassType.CONFORMATION_JUNIOR
      );
    });
  }

  eventHasJS(x: DogEvent): boolean {
    let eventClasses: Array<EventClass> = this.showEventClasses.filter((y) => {
      return y.event.id === x.id;
    });
    return !!eventClasses.find((y) => {
      return y.dogClass.type === DogClassType.CONFORMATION_JUNIOR;
    });
  }

  beforeMount() {
    this.formEntries = [];
    let show: Show | undefined = this.secretary
      ? SecretaryStore.selectedShow
      : ShowStore.show;
    if (show) {
      for (let k = 0; k < this.eventGroup.length; k++) {
        this.formEntries.push({
          hasAgent: false,
          agent: "",
          hasJS: false,
          JSName: "",
          JSNumber: "",
          JSNameError: [],
          JSNumberError: [],
          JSBirthDate: null,
          JSBirthDateError: [],
          JSAddress: new Address(),
          JSAddressError: [],
          JSRelationship: undefined,
          JSClass: null,
          JSClassError: [],
          obedienceHeight: undefined,
          obedienceHeightError: [],
          rallyHeight: undefined,
          rallyHeightError: [],
          classes: [null],
        });
      }
      this.eventClasses = this.showEventClasses;
    }
  }

  mounted() {
    let show: Show | undefined = ShowStore.show;
    if (show && this.edit && DogStore.selectedShowEntry) {
      for (let k = 0; k < this.eventGroup.length; k++) {
        let event: DogEvent = this.eventGroup[k];
        let entry: Entry | undefined =
          DogStore.selectedShowEntry.entriesNotRefunded.find((x) => {
            return x.event.id == event.id;
          });
        if (entry) {
          this.formEntries[k] = {
            hasAgent: entry.agent ? true : false,
            agent: entry.agent ? entry.agent : "",
            hasJS: !!entry.junior,
            JSName: entry.junior ? entry.junior.name : "",
            JSNameError: [],
            JSNumber: entry.junior ? entry.junior.number : "",
            JSNumberError: [],
            JSBirthDate: entry.junior ? entry.junior.birthDate : new Date(),
            JSBirthDateError: [],
            JSAddress: entry.junior ? entry.junior.address : new Address(),
            JSAddressError: [],
            JSRelationship: entry.junior
              ? entry.junior.relationship
              : undefined,
            JSClass:
              this.eventClasses.find((x) => {
                return (
                  entry &&
                  entry.classes.find((y) => {
                    return (
                      y.dogClass.type === DogClassType.CONFORMATION_JUNIOR &&
                      y.id === x.id
                    );
                  })
                );
              }) || null,
            JSClassError: [],
            // entry.classes.find((x) => {
            //   return x.dogClass.type === DogClassType.CONFORMATION_JUNIOR;
            // }) || null,
            obedienceHeight: entry.obedienceJumpHeight,
            obedienceHeightError: [],
            rallyHeight: entry.rallyJumpHeight,
            rallyHeightError: [],
            classes: this.eventClasses.filter((x) => {
              return entry?.classes.find((y) => {
                return (
                  y.id == x.id &&
                  y.dogClass.type !== DogClassType.CONFORMATION_JUNIOR
                );
              });
            }),

            // entry.classes
            //   .filter((x) => {
            //     return x.dogClass.type !== DogClassType.CONFORMATION_JUNIOR;
            //   })
            //   .map((y) => {
            //     return { eventClass: y, hide: true };
            //   }),
          };
          this.$set(this.formEntries, k, this.formEntries[k]);
          console.log(entry);
          console.log(this.formEntries[k]);
          this.update();
        }
      }
    }
  }

  addButtonDisabled(idx: number): boolean {
    let classes = this.formEntries[idx].classes;
    return classes[classes.length - 1] === null;
  }

  addNewClass(idx: number) {
    this.formEntries[idx].classes.push(null);
  }

  selectionUpdate(idx: number) {
    if (
      this.eventGroup[idx].type === DogEventType.CONFORMATION ||
      this.eventGroup[idx].type === DogEventType.RALLY ||
      this.eventGroup[idx].type === DogEventType.OBEDIENCE
    ) {
      let classes = this.formEntries[idx].classes;
      if (
        !classes.find((x) => {
          return !x || x.id < 0;
        }) &&
        classes[classes.length - 1] !== null
      ) {
        this.formEntries[idx].classes.push(null);
      }
      let newClasses: Array<EventClass | null> = [];
      for (let k = 0; k < classes.length; k++) {
        let dogClass = classes[k];
        if (k == classes.length - 1 || !dogClass || dogClass.id >= 0) {
          newClasses.push(dogClass);
        }
      }
      this.formEntries[idx].classes = newClasses;
    }
    this.update();
  }

  itemText(x: any): string {
    return x.eventClass.dogClass.name;
  }

  update() {
    this.entries = [];
    if (this.show && this.dog) {
      let showDog: ShowDog = new ShowDog(-1, this.dog);
      for (let k = 0; k < this.formEntries.length; k++) {
        let formEntry = this.formEntries[k];
        let event = this.eventGroup[k];
        //let newEntry: Entry = new Entry(this.show, event, showDog);
        //newEntry.agent = formEntry.agent;
        let formClasses: Array<EventClass> = [];
        for (let formClass of formEntry.classes) {
          if (formClass) {
            formClasses.push(formClass);
          }
        }
        let classes: Array<EventClass> = formClasses;
        if (formEntry.JSClass) {
          classes.push(formEntry.JSClass);
        }
        let agent: string | null = formEntry.hasAgent ? formEntry.agent : null;
        console.log("AGENT");
        console.log(formEntry.hasAgent);
        console.log(agent);
        let obedienceHeight: number | undefined = formEntry.obedienceHeight;
        let rallyHeight: number | undefined = formEntry.rallyHeight;
        let junior: JuniorShowmanship | undefined = undefined;
        let JSBirthDate: Date = new Date();
        if (formEntry.JSBirthDate) {
          JSBirthDate = formEntry.JSBirthDate;
        }
        if (formEntry.hasJS) {
          junior = new JuniorShowmanship(
            formEntry.JSName,
            formEntry.JSNumber,
            JSBirthDate,
            formEntry.JSAddress,
            formEntry.JSRelationship
          );
        }
        let entryId: number | null = null;
        if (this.edit && DogStore.selectedShowEntry) {
          let entry: Entry | undefined =
            DogStore.selectedShowEntry.entries.find((x) => {
              return x.event.id === event.id;
            });
          if (entry) {
            entryId = entry.id;
          }
        }
        console.log(event.id);
        let newEntry: Entry = new Entry(
          this.show,
          event,
          showDog,
          entryId,
          classes,
          agent,
          false,
          false,
          false,
          false,
          "",
          null,
          new Date(),
          obedienceHeight,
          rallyHeight,
          junior
        );
        if (newEntry.classes.length > 0) {
          this.entries.push(newEntry);
        }
      }
    }
    this.$emit("input", this.entries);
  }

  get dog(): Dog | undefined {
    return this.secretary
      ? SecretaryEntryStore.selectedShowDog?.showDog.dog
      : DogStore.selectedDog;
  }

  get show(): Show | undefined {
    return this.secretary ? SecretaryStore.selectedShow : ShowStore.show;
  }

  get obedienceHeights(): Array<number> {
    return OBEDIENCE_JUMP_HEIGHTS;
  }
  get rallyHeights(): Array<number> {
    return RALLY_JUMP_HEIGHTS;
  }

  setEntries(x: Array<Entry>) {
    console.log("event group entry changed");
    console.log(x);
    this.entries = [];
    for (let k = 0; k < this.eventGroup.length; k++) {
      let event = this.eventGroup[k];
      let entry: Entry | undefined = x.find((y) => {
        return y.event.id == event.id;
      });
      if (entry) {
        this.entries.push(entry);
        // let classes: Array<{ eventClass: EventClass; hide: boolean } | null> =
        //   entry.classes.map((x) => {
        //     return { eventClass: x, hide: true };
        //   });
        let classes: Array<EventClass | null> = [];
        for (let eventClass of entry.classes) {
          let thisClass = this.eventClasses.find((x) => {
            return x.id == eventClass.id;
          });
          if (thisClass) {
            classes.push(thisClass);
          }
        }
        classes.push(null);
        //this.formEntries[k] =
        let newFormEntry = {
          hasAgent: entry.agent === null ? false : true,
          agent: entry.agent === null ? "" : entry.agent,
          hasJS: !!entry.junior,
          JSNumber: entry.junior ? entry.junior.number : "",
          JSBirthDate: entry.junior ? entry.junior.birthDate : new Date(),
          JSAddress: entry.junior ? entry.junior.address : new Address(),
          JSRelationship: entry.junior ? entry.junior.relationship : undefined,
          JSClass:
            entry.classes.find((x) => {
              return x.dogClass.type === DogClassType.CONFORMATION_JUNIOR;
            }) || null,
          obedienceHeight: entry.obedienceJumpHeight,
          rallyHeight: entry.rallyJumpHeight,
          classes: classes,
        };
        this.$set(this.formEntries, k, newFormEntry);
        this.$set(this.formEntries[k], "classes", classes);
        console.log(this.formEntries);
      }
    }
  }

  validate(): Array<string> {
    let out: Array<string> = [];
    for (let k = 0; k < this.formEntries.length; k++) {
      let formEntry = this.formEntries[k];
      let event = this.eventGroup[k];
      if (formEntry.hasJS) {
        if (formEntry.JSName.trim().length == 0) {
          out.push("Junior Showmanship name is missing.");
          formEntry.JSNameError = ["Name is required."];
        } else {
          formEntry.JSNameError = [];
        }
        if (formEntry.JSNumber.trim().length == 0) {
          out.push("Junior Showmanship number is missing.");
          formEntry.JSNumberError = ["Number is required."];
        } else {
          formEntry.JSNumberError = [];
        }
        if (!formEntry.JSBirthDate) {
          out.push("Junior Showmanship must have a birthdate");
          formEntry.JSBirthDateError = ["Birthdate is required."];
        } else {
          formEntry.JSBirthDateError = [];
        }
        let addressError: Array<string> = (
          this.$refs["address-" + k] as any
        ).validate();
        if (addressError) {
          out.push("Junior Showmanship must have an addresss.");
          formEntry.JSAddressError = ["Address is required"];
        } else {
          formEntry.JSAddressError = [];
        }
        if (!formEntry.JSClass) {
          out.push("Junior Showmanship must have a class.");
          formEntry.JSClassError = ["You must select a class."];
        } else {
          formEntry.JSClassError = [];
        }
      }
      if (event.eventType === DogEventType.OBEDIENCE) {
        if (formEntry.classes[0] && !formEntry.obedienceHeight) {
          out.push("Obedience class must have a height.");
          formEntry.obedienceHeightError = ["You must select a heigt."];
        } else {
          formEntry.obedienceHeightError = [];
        }
      }
      if (event.eventType === DogEventType.RALLY) {
        if (formEntry.classes[0] && !formEntry.rallyHeight) {
          out.push("Rally class must have a height.");
          formEntry.rallyHeightError = ["You must select a height."];
        } else {
          formEntry.rallyHeightError = [];
        }
      }
    }
    return out;
  }
}
