
import { Dog, Breed, Color, Variety, Sex } from "./dog";
import { DogEvent } from "./show"


export interface RuleSet {
    age?: { min?: number, max?: number },
    breed?: number,
    variety?: number
    color?: number,
    notColor?: number,
    colorSet?: Array<number>,
    sex?: Sex,
    usaNumberOne?: boolean,
    noHandler?: boolean,
    noPrefixTitles?: boolean,
}

export enum RuleErrors{
    AGE = 'Violates age contraints',
    BREED = 'Violates breed constraint',
    VARIETY = 'Vioaltes variety contraint',
    COLOR = 'Violates inclusive color contraint',
    NOT_COLOR = 'Violates exclusive color constraint',
    SEX = 'Violates sex constraint',
    USA = 'Violates country of birth constraint',
    HANDLER = 'Violates handler exclusion constraint',
    TITLES = 'Violates '
}

// brace 
// shared owner
// entered into show


// export function Rule(ruleSet : RuleSet): (Dog) => boolean {

// }

export class Rule {
    private _ruleSet: RuleSet
    private _ruleFunction: (dog: Dog, event: DogEvent, agent: string | null) => boolean

    public get ruleSet(): RuleSet {
        return this._ruleSet
    }
    public set ruleSet(value: RuleSet) {
        this._ruleSet = value
    }
    public get ruleFunction(): (dog: Dog, event: DogEvent, agent: string | null) => boolean {
        return this._ruleFunction
    }
    constructor(ruleSet: RuleSet) {
        this._ruleSet = ruleSet
        this._ruleFunction = this.generateRuleFunction()
    }

    private generateRuleFunction(): (dog: Dog, event: DogEvent, agent: string | null) => boolean{
        let rule = (dog: Dog, event: DogEvent, agent: string | null) => {
            if (this._ruleSet.breed) {
                if (dog.breed.id !== this._ruleSet.breed) {
                    return false
                }
            }
            if (this._ruleSet.variety) {
                if (!dog.variety || dog.variety.id !== this._ruleSet.variety) {
                    return false
                }
            }
            if (this._ruleSet.color) {
                if (dog.color.id !== this._ruleSet.color) {
                    return false
                }
            }
            if(this._ruleSet.notColor){
                if(dog.color.id == this._ruleSet.notColor){
                    return false
                }
            }
            if (this._ruleSet.colorSet) {
                if (!this._ruleSet.colorSet.find((x) => { return x === dog.color.id })) {
                    return false
                }
            }
            if (this._ruleSet.sex) {
                if (dog.sex !== this._ruleSet.sex) {
                    return false
                }
            }
            if (this._ruleSet.usaNumberOne) {
                if (dog.birthPlace !== "United States") {
                    return false
                }
            }
            if (this._ruleSet.noPrefixTitles) {
                if (dog.name.prefixTitles.length > 0) {
                    return false
                }
            }
            if (this._ruleSet.age) {
                let months : number =
                    event.startDate.getMonth() -
                    dog.birthDate.getMonth() +
                    12 * (event.startDate.getFullYear() - dog.birthDate.getFullYear()) -
                    1 +
                    (event.startDate.getDate() >= dog.birthDate.getDate() ? 1 : 0);
                if (this._ruleSet.age.min && this._ruleSet.age.min > months) {
                    return false
                }
                if (this._ruleSet.age.max && this._ruleSet.age.max <= months) {
                    return false
                }
            }
            if(this._ruleSet.usaNumberOne && dog.birthPlace !== "United States"){
                return false
            }
            if(this._ruleSet.noHandler && agent){
                return false
            }
            if(this._ruleSet.noPrefixTitles && dog.name.prefixTitles.length > 0){
                return false
            }
            return true
        }

        return rule
    }

    public nameFromRuleSet(x? : string, y? : string):string{
        let out = ""
        if(x){
            out = x
        }
        if(this._ruleSet.sex){
            out += " "+(this._ruleSet.sex === Sex.MALE ? "Dogs" : "Bitches")
        }

        if(this._ruleSet.age && !(this._ruleSet.age.min == 6 && !this._ruleSet.age.max)){
            let lowerMosOrYrs : string = (this._ruleSet.age.min && this._ruleSet.age.min > 1 ? "mos." : "mo.")
            let upperMosOrYrs : string =  (this._ruleSet.age.max && this._ruleSet.age.max > 1 ? "mos." : "mo.")
            let lowerBound : number | undefined = this._ruleSet.age.min
            let upperBound : number | undefined = this._ruleSet.age.max
            if(this._ruleSet.age.min && this._ruleSet.age.min % 12 == 0 && this._ruleSet.age.max && this._ruleSet.age.max % 12 == 0){
                lowerBound = this._ruleSet.age.min/12
                upperBound = this._ruleSet.age.max/12
                lowerMosOrYrs = (lowerBound > 1 ? "yrs." : "yr.")
                upperMosOrYrs = (upperBound > 1 ? "yrs." : "yr.")
            }else if(this._ruleSet.age.min && this._ruleSet.age.min % 12 == 0 && !this._ruleSet.age.max){
                lowerBound = this._ruleSet.age.min/12
                lowerMosOrYrs = (lowerBound > 1 ? "yrs." : "yr.")
            }else if(!this._ruleSet.age.min && this._ruleSet.age.max && this._ruleSet.age.max % 12 == 0){
                upperBound = this._ruleSet.age.max/12
                upperMosOrYrs = (upperBound > 1 ? "yrs." : "yr.")
            }
            
            if(lowerBound && upperBound){
                out += " " + lowerBound + " " + lowerMosOrYrs + " & under " + upperBound + " " + upperMosOrYrs
            }else if(lowerBound){
                out += " " + lowerBound + " " + lowerMosOrYrs + " & older"
            }else if(upperBound){
                out += " under " + upperBound + " " + upperMosOrYrs
            }
        }

        if(this._ruleSet.variety){
            let variety : Variety | null = Dog.findVariety(this._ruleSet.variety)
            if(variety){
                out += " - " + variety.variety
            }
        }

        if(this._ruleSet.color){
            let color : Color = Dog.findColor(this._ruleSet.color)
            if(color){
                out += " - " + color.color
            }
        }

        if(this._ruleSet.colorSet){
            let colors : Array<string> = this._ruleSet.colorSet.map((z) => {return Dog.findColor(z).color})
            out += " - "+colors.join("/")
        }
        if(y){
            out += " " + y
        }
        out = out.trim()
        
        return out
    }
}