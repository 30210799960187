
import {
  CatalogFont,
  CatalogFontSize,
  CatalogOrder,
  DogEvent,
  PageSize,
  PageSizeTypes,
  SecretaryLoadCatalogSettingsRequest,
  SecretarySaveCatalogSettingsRequest,
} from "@app/common";
import { Component, Vue, Watch } from "vue-property-decorator";
import CatalogOrdering from "./CatalogOrdering.vue";
import CatalogClassOrdering from "./CatalogClassOrdering.vue";
import SecretaryCatalogStore from "../../store/modules/secretary_catalog";
import SecretaryStore from "../../store/modules/secretary";
import UserStore from "../../store/modules/user";
import { DogClass } from "@app/common/classes";
@Component({ components: { CatalogOrdering, CatalogClassOrdering } })
export default class ShowManagerCatalogPanel extends Vue {
  catalogOrder: CatalogOrder | undefined = undefined;

  metric: boolean = true;

  pageSize: { type: PageSizeTypes; width: number; height: number } = {
    type: PageSizeTypes.A4,
    width: 10,
    height: 10,
  };
  selectedPageSizeType: PageSizeTypes = PageSizeTypes.A4;
  pageSizeTypes: Array<PageSizeTypes> = Object.values(PageSizeTypes);
  //pageSizes : Array<{type : PageSizeTypes, width : number, height : number} >
  showPageNumber: boolean = false;

  get units(): string {
    return this.metric ? "mm" : "in";
  }

  get customPageSizeType(): boolean {
    return this.selectedPageSizeType === PageSizeTypes.CUSTOM;
  }

  metricToImperial(x: number): number {
    return x / 25.4;
  }
  imperialToMetric(x: number): number {
    return x * 25.4;
  }

  async mounted() {
    console.log(this.fonts);
    console.log(this.font);
    console.log("mounted");
    console.log(SecretaryStore.selectedShow);
    if (SecretaryStore.selectedShow && SecretaryStore.selectedShow.id) {
      let payload: SecretaryLoadCatalogSettingsRequest = {
        show: SecretaryStore.selectedShow.id,
      };
      await SecretaryCatalogStore.loadSettings(payload);
      if (SecretaryCatalogStore.catalogOrder) {
        console.log("load payload");
        console.log(SecretaryStore.selectedShow);
        let catalogOrder: CatalogOrder = SecretaryCatalogStore.catalogOrder;
        this.classOrderings = catalogOrder.ordering;
        this.catalogOrder = new CatalogOrder(this.classOrderings);
      }
    }
    console.log(SecretaryStore.selectedShow);
  }

  font: CatalogFont = CatalogFont.HELVETICA;
  fonts: Array<CatalogFont> = Object.values(CatalogFont);
  fontSizes: Array<CatalogFontSize> = Object.values(CatalogFontSize);
  bodyFontSize: CatalogFontSize = CatalogFontSize.F12;
  titleFontSize: CatalogFontSize = CatalogFontSize.F14;
  subtitleFontSize: CatalogFontSize = CatalogFontSize.F12;
  test() {
    console.log(this.font);
  }

  eventBlocks: Array<Array<DogEvent>> = [[]];

  @Watch("selectedPageSizeType")
  onSelectedPageSizeType(x: PageSizeTypes) {
    if (x !== PageSizeTypes.CUSTOM) {
      let y: PageSize = new PageSize(x);
      console.log(y);
    }
  }

  update() {
    console.log("update");
  }

  close() {
    this.$emit("close");
  }

  classOrderings: Array<{
    events: Array<DogEvent>;
    classOrderings: Array<DogClass>;
  }> = [];

  updateClassOrderings(
    x: Array<{
      events: Array<DogEvent>;
      classOrderings: Array<DogClass>;
    }>
  ) {
    this.classOrderings = x;
    console.log("updateclassorderings");
    this.catalogOrder = new CatalogOrder(this.classOrderings);
    SecretaryCatalogStore.setCatalogOrder(this.catalogOrder);
    console.log(this.classOrderings);
    console.log(this.catalogOrder);
  }

  get saving(): boolean {
    return SecretaryCatalogStore.savingSettings;
  }

  get loading(): boolean {
    return SecretaryCatalogStore.loadingSettings;
  }

  async save() {
    console.log(this.catalogOrder);
    console.log(SecretaryStore.selectedShow);
    if (
      this.catalogOrder &&
      SecretaryStore.selectedShow &&
      SecretaryStore.selectedShow.id
    ) {
      let payload: SecretarySaveCatalogSettingsRequest = {
        show: SecretaryStore.selectedShow.id,
        ordering: this.catalogOrder.exportInterface(),
        csrfSecurityToken: UserStore.csrfSecurityToken,
      };
      await SecretaryCatalogStore.saveSettings(payload);
    }
  }

  generate() {
    console.log("generate");
    if (SecretaryStore.selectedShow) {
      this.$router.push(
        "/show-manager/" + SecretaryStore.selectedShow.abbr + "/catalog"
      );
    }
  }
}
