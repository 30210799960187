import { render, staticRenderFns } from "./DogFormProblemEntries.vue?vue&type=template&id=fe5b3c12&scoped=true"
import script from "./DogFormProblemEntries.vue?vue&type=script&lang=ts"
export * from "./DogFormProblemEntries.vue?vue&type=script&lang=ts"
import style0 from "./DogFormProblemEntries.vue?vue&type=style&index=0&id=fe5b3c12&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe5b3c12",
  null
  
)

export default component.exports