
import { Component, Vue, Prop } from "vue-property-decorator";
import UserStore from "../store/modules/user";
import DogStore from "../store/modules/dog";
import PaymentStore from "../store/modules/payment";
import Loading from "./Loading.vue";
import ExpansionList from "./ExpansionList.vue";
import { Payment, ReceiptRequest, Refund } from "@app/common";
import { fetchReceipt } from "../services/receipt";
@Component({
  components: {
    Loading,
    ExpansionList,
  },
})
export default class DogRefunds extends Vue {
  @Prop({ default: null }) readonly show!: number | null;
  get loading(): boolean {
    return (
      PaymentStore.fetchingPaymentData ||
      DogStore.fetchingDogData ||
      DogStore.dogDataNeedsUpdating
    );
  }
  async mounted() {
    if (DogStore.dogDataNeedsUpdating && !DogStore.fetchingDogData) {
      await DogStore.fetchDogData();
    }
    if (
      PaymentStore.paymentDataNeedsUpdating &&
      !PaymentStore.fetchingPaymentData
    ) {
      await PaymentStore.fetchPayments();
    }
  }
  get refunds(): Array<Refund> {
    if (this.show) {
      return PaymentStore.refunds.filter((x) => {
        return x.payment.show.id == this.show;
      });
    }
    return PaymentStore.refunds;
  }

  async fetchReceipt(id: number) {
    let payload: ReceiptRequest = {
      id: id,
      type: "REFUND",
    };
    await fetchReceipt(payload);
  }

  test() {
    this.refunds[0];
  }
}
