
import { Component, Vue } from "vue-property-decorator";
import NewShowStore from "../../store/modules/newshow"
@Component
export default class NewShowSubmitted extends Vue {
  get trusted():boolean{
    return NewShowStore.trusted
  }
  get success():boolean{
    return NewShowStore.submitSuccess
  }
}
