
import { GeneralErrors, RefundErrors } from "@app/common";
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class RefundError extends Vue {
  @Prop() readonly errors!: Array<RefundErrors | GeneralErrors>;

  get text(): string {
    return "";
  }

  errorText(err: RefundErrors | GeneralErrors): string {
    if(err === RefundErrors.SQUARE_TOKEN_ERROR){
      return "The refund failed because payments and refunds cannot be processed on this show at this time. Please contact the show secretary if you believe this is in error.";
    }
    if(err === RefundErrors.RECORDING_ERROR){
      return "Your refund was recieved however there was an error while attempting to record it in our database. Please contact support@dogalog.dog about this issue. After you contact us, we will notify you of the status of your refund.";
    }
    if(err === RefundErrors.RECEIPT_ERROR){
      return "Your refund request was recieved and recorded. The refund request will appear in your dogalog account. However, there was an issue generating the receipt. Please contact support@dogalog.dog to notify us of this issue.";
    }
    if(err === RefundErrors.REFUND_EMAIL_ERROR){
      return "Your refund request was successful but there was an issue sending your confirmation email. The refund request and receipt will be availabe from your dogalog account."
    }
    if(err === RefundErrors.GENERAL_REFUND_ERROR){
      return "An unknown error occured while processing your refund. Please contact support@dogalog.dog if this issue persists."
    }
    if(err === RefundErrors.SHOW_DEADLINE_EXPIRED){
      return "Your refund request failed because the show deadline has expired."
    }
    if(err === RefundErrors.REFUND_FAILED){
      return "Your refund request failed. This is due to reasons beyond our control, such as your card issuer refusing the refund or the show's account having insufficient funds. Nevertheless, you will be provided with a receipt proving you requested a refund prior to the deadline. Please contact the show secretary to arrange alternative means to process your refund."
    }
    if (err === GeneralErrors.AUTHENTICATION_ERROR){
      return "The refund failed because you're not currently logged in. If this problem persists, then please contact support@dogalog.dog"
    }
    if (err === GeneralErrors.GENERAL_ERROR){
      return "The refund failed for an unkown reason. Please report this issue to support@dogalog.dog."
    }
    return "";
  }
}
