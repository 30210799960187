
import Loading from "../components/Loading.vue";
import { Component, Vue } from "vue-property-decorator";
import UserStore from "../store/modules/user";
import { VerifyEmailRequest } from "@app/common";
@Component({ components: { Loading } })
export default class VerifyEmail extends Vue {
  verifyChecked: boolean = false;
  success: boolean = false;
  get loading(): boolean {
    return UserStore.verifyingEmail || !this.verifyChecked;
  }
  async mounted() {
    let email: string | undefined = this.$route.query.email as string;
    let token: string | undefined = this.$route.query.token as string;
    let payload: VerifyEmailRequest = {
      email: email,
      token: token,
    };
    if (email && token) {
      await UserStore.verifyEmail(payload);
      if (!UserStore.verifyEmailErrors) {
        this.success = true;
      } 
    }
    this.verifyChecked = true;
  }
}
