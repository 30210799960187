
import {
  CatalogBlock,
  CatalogBlockPageBreak,
  CatalogBlockType,
  CatalogTextLine,
} from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class CatalogBlockComponent extends Vue {
  @Prop({
    default: () => {
      return new CatalogBlock();
    },
  })
  value!: CatalogBlock;
  @Prop({ default: 0 }) idx!: number;
  blockTypes: Array<CatalogBlockType> = Object.values(CatalogBlockType);

  block: CatalogBlock = new CatalogBlock();

  text: Array<CatalogTextLine> = [
    { text: "", bold: false, size: 12, align: "CENTER" },
  ];
  html: string = "";

  textSizes: Array<number> = [
    4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40,
  ];
  pageBreaks: Array<CatalogBlockPageBreak> = Object.values(
    CatalogBlockPageBreak
  );

  @Watch("html")
  onHtmlChange(x: string) {
    let element = document.getElementById("html-content");
    if (element) {
      element.innerHTML = x;
    }
  }

  @Watch("value")
  onValueChange(x: CatalogBlock) {
    this.block = x;
  }

  update() {
    console.log(this.block);
    for (let k = 0; k < this.block.text.length; k++) {
      this.$set(this.block.text, k, this.block.text[k]);
    }
    this.$set(this.block.text, "text", this.block.text);
    this.$emit("input", this.block);
  }

  remove() {
    this.$emit("remove");
  }
}
