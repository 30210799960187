
import { Component, Vue } from "vue-property-decorator";
import Loading from "../../components/Loading.vue";
import StickyFooter from "../../components/StickyFooter.vue";
import DogEntriesSecretary from "../../components/DogEntriesSecretary.vue";
import ShowManagerEntriesPanel from "../../components/ShowManager/ShowManagerEntriesPanel.vue"
import {
  DogEvent,
  Entry,
  EventClass,
  SecretaryShowDataRequest,
  SecretaryShowInterfaceRequest,
  Show,
  ShowEntry,
} from "@app/common";
import SecretaryStore from "../../store/modules/secretary";
@Component({ components: { Loading, StickyFooter, DogEntriesSecretary, ShowManagerEntriesPanel} })
export default class ShowManagerEntries extends Vue {

  dataTableItems(idx: number): Array<{ name: string; total: number }> {
    let out: Array<{ name: string; total: number }> = [];
    if (this.show) {
      let event: DogEvent = this.show.events[idx];
      let eventClasses: Array<EventClass> = SecretaryStore.eventClasses.filter(
        (x) => {
          return x.event.id == event.id;
        }
      );
      let eventTotals: Array<{ eventClass: EventClass; total: number }> =
        eventClasses.map((x) => {
          return { eventClass: x, total: 0 };
        });
      let entries: Array<Entry> = SecretaryStore.entries.filter((x) => {
        return x.event.id == event.id && x.paid && !x.refunded;
      });
      for (let entry of entries) {
        for (let eventClass of entry.classes) {
          let eventTotal = eventTotals.find((x) => {
            return x.eventClass.id == eventClass.id;
          });
          if (eventTotal) {
            eventTotal.total++;
          }
        }
      }
      out = eventTotals.map((x) => {
        return { name: x.eventClass.dogClass.name, total: x.total };
      });
    }
    return out;
  }
  get show(): Show | undefined {
    return SecretaryStore.selectedShow;
  }

  loading: boolean = true;
  abbr: string = "";
  tab: number = 0;
  entryModal = false;


  get totalDogs(): number {
    return SecretaryStore.showDogs.length;
  }

  get dataTableSummaryItems(): Array<{
    eventName: string;
    dates: string;
    dogTotal: number;
    exhibitorTotal: number;
    feeTotal: string;
  }> {
    let out: Array<{
      eventName: string;
      dates: string;
      dogTotal: number;
      exhibitorTotal: number;
      feeTotal: string;
    }> = [];
    if (this.show) {
      for (let event of this.show.events) {
        let eventEntries: Array<Entry> = SecretaryStore.entries.filter((x) => {
          return x.event.id == event.id && x.paid && !x.refunded;
        });
        let dogTotal: number = Array.from(
          new Set(
            eventEntries.map((x) => {
              return x.dog.showDog.id;
            })
          )
        ).length;
        let exhibitorTotal: number = Array.from(
          new Set(
            eventEntries.map((x) => {
              return x.dog.user ? x.dog.user.id : -1;
            })
          )
        ).length;
        let feeTotal: number = 0;
        for (let entry of eventEntries) {
          if (!entry.refunded) {
            feeTotal += entry.fee;
          }
        }
        let feeTotalString: string = "$" + (feeTotal / 100).toFixed(2);
        let eventName: string = event.eventTypeAndNumber;
        let dates: string = event.printDateRange();
        out.push({
          eventName,
          dates,
          dogTotal,
          exhibitorTotal,
          feeTotal: feeTotalString,
        });
      }
    }
    return out;
  }



  async mounted() {
    this.loading = true;
    this.abbr = this.$route.params.abbr;
    await SecretaryStore.dogEntryRoutine({ abbr: this.abbr });
    this.loading = false;
  }
}
