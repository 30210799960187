
import { Component, Vue } from "vue-property-decorator";
import DogEntriesSecretary from "../DogEntriesSecretary.vue";
import SecretaryPaymentStore from "../../store/modules/secretary_payments";
import SecretaryEntryStore from "../../store/modules/secretary_entries";
import SecretaryStore from "../../store/modules/secretary";
import { PaymentType, ShowEntry, User } from "@app/common";
@Component({ components: { DogEntriesSecretary } })
export default class ShowManagerCreatePayment extends Vue {
  search: string = "";
  cancel() {
    this.$emit("close");
  }

  window: number = 0;

  get showCancel(): boolean {
    return this.window < 2;
  }

  get nextText(): string {
    if (this.window == 0) {
      return "Next";
    } else if (this.window == 1) {
      return "Submit";
    } else {
      return "Done";
    }
  }

  get nextEnabled(): boolean {
    if (this.window == 0) {
      return this.paymentEntries.length > 0;
    } else if (this.window == 1) {
      return this.name.trim().length > 0
    } else {
      return true;
    }
  }

  next() {
    if (this.nextEnabled && this.window < 2) {
      this.window++;
    } else if (this.nextEnabled) {
      this.$emit("close");
    }
  }

  get showExhibitorInfo():boolean{
    if(this.paymentEntries.length > 0){
      return !this.paymentEntries[0].user
    }
    return false
  }

  paymentEntries: Array<ShowEntry> = [];
  updatePaymentEntries(x: Array<ShowEntry>) {
    this.paymentEntries = x;
  }

  paymentTypes: Array<PaymentType> = [
    PaymentType.MANUAL_CREDIT_CARD,
    PaymentType.CHECK,
    PaymentType.WAIVER,
    PaymentType.OTHER,
  ];
  paymentType: PaymentType = PaymentType.CHECK;

  name : string = ""
  email : string = ""
  phone : string = ""

  get hasCatalog(): boolean {
    if (SecretaryStore.selectedShow) {
      return SecretaryStore.selectedShow.catalog.canPurchase;
    } else {
      return false;
    }
  }

  get catalogFee():number{
    if(SecretaryStore.selectedShow){
      return SecretaryStore.selectedShow.catalog.fee
    }else{
      return 0
    }
  }

  get hasSecretaryFee():boolean{
    if(SecretaryStore.selectedShow){
      return SecretaryStore.selectedShow.secretaryFee > 0
    }else{
      return false
    }
  }

  get secretaryFee(): number{
    if(SecretaryStore.selectedShow){
      return SecretaryStore.selectedShow.secretaryFee
    }else{
      return 0
    }
  }

  includeCatalog : boolean = false
  includeSecretaryFee : boolean = true

  get totalFee():number{
    let out = 0
    for(let showEntry of this.paymentEntries){
      for(let entry of showEntry.entries){
        out += entry.fee
        if(this.includeSecretaryFee && this.hasSecretaryFee){
          out += this.secretaryFee
        }
      }
    }
    if(this.includeCatalog && this.hasCatalog){
      out += this.catalogFee
    }
    return out
  }

  get unpaidOnline(): Array<{ user: User; showEntries: Array<ShowEntry> }> {
    let out: Array<{ user: User; showEntries: Array<ShowEntry> }> = [];
    let unpaidOnlineEntires: Array<ShowEntry> =
      SecretaryStore.showEntries.filter((x) => {
        return x.unpaidEntries.length > 0 && x.user;
      });
    for (let entry of unpaidOnlineEntires) {
      let selectedEntry = out.find((x) => {
        return x.user && entry.user && x.user.id == entry.user.id;
      });
      if (!selectedEntry && entry.user) {
        out.push({ user: entry.user, showEntries: [entry] });
      } else if (selectedEntry && entry.user) {
        selectedEntry.showEntries.push(entry);
      }
    }
    return out;
  }

  test() {
    this.unpaidOnline[0].showEntries[0].showDog.showDog.dog.printName();
  }

  get unpaidManual(): Array<ShowEntry> {
    return SecretaryStore.showEntries.filter((x) => {
      return !x.user;
    });
  }

  reset() {
    this.paymentType = PaymentType.CHECK;
  }
}
