
import { Component, Vue } from "vue-property-decorator";
import Loading from "../../components/Loading.vue";
import StickyFooter from "../../components/StickyFooter.vue";
import {
  DogEvent,
  Entry,
  EventClass,
  SecretaryShowDataRequest,
  SecretaryShowInterfaceRequest,
  Show,
  ShowEntry,
} from "@app/common";
import SecretaryStore from "../../store/modules/secretary";
@Component({ components: { Loading, StickyFooter } })
export default class ShowManagerClassTotals extends Vue {
  dataTableItems(idx: number): Array<{ name: string; total: number }> {
    let out: Array<{ name: string; total: number }> = [];
    if (this.show) {
      let event: DogEvent = this.show.events[idx];
      let eventClasses: Array<EventClass> = SecretaryStore.eventClasses.filter(
        (x) => {
          return x.event.id == event.id;
        }
      );
      let eventTotals: Array<{ eventClass: EventClass; total: number }> =
        eventClasses.map((x) => {
          return { eventClass: x, total: 0 };
        });
      let entries: Array<Entry> = SecretaryStore.entries.filter((x) => {
        return x.event.id == event.id && x.paid && !x.refunded;
      });
      for (let entry of entries) {
        for (let eventClass of entry.classes) {
          let eventTotal = eventTotals.find((x) => {
            return x.eventClass.id == eventClass.id;
          });
          if (eventTotal) {
            eventTotal.total++;
          }
        }
      }
      out = eventTotals.map((x) => {
        return { name: x.eventClass.dogClass.name, total: x.total };
      });
    }
    return out;
  }
  get show(): Show | undefined {
    return SecretaryStore.selectedShow;
  }

  loading: boolean = true;
  abbr: string = "";
  tab: number = 0;

  async mounted() {
    this.loading = true;
    this.abbr = this.$route.params.abbr;
    await SecretaryStore.dogEntryRoutine({ abbr: this.abbr });
    this.loading = false;
  }
}
