
import { Show, ShowType } from "@app/common";
import { Component, Vue } from "vue-property-decorator";
import ShowStore from "../store/modules/show";
import Loading from "../components/Loading.vue";
import TopPage from "../components/TopPage.vue";
import DogShowList from "../components/DogShowList.vue"

@Component({
  components: {
    Loading,
    TopPage,
    DogShowList
  },
})
export default class ShowsView extends Vue {
  get shows(): Array<Show> {
    return ShowStore.shows;
  }
  get showsLoading(): boolean {
    return ShowStore.fetchingShows;
  }

  async mounted() {
    await ShowStore.fetchShows();
  }

  
}
