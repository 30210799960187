
import { DogEvent, DogEventType } from "@app/common";
import { DogClass, DogClassType } from "@app/common/classes";
import { Rule, RuleSet } from "@app/common/class_rules";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import DogRule from "./DogRule.vue";
@Component({ components: { DogRule } })
export default class DogClassEdit extends Vue {
  @Prop({
    default: null,
  })
  readonly dogClass!: DogClass | null;
  @Prop({
    default: () => {
      return new DogEvent(-1, new Date(), new Date(), null, DogEventType.OTHER);
    },
  })
  readonly dogEvent!: DogEvent;
  rulesAndClasses: {
    rules: RuleSet;
    prerequisiteClasses: Array<DogClassType>;
  } = { rules: {}, prerequisiteClasses: [] };
  rule: Rule = new Rule({});
  className: string = "";
  classType: DogClassType | null = null;
  classTypes: Array<DogClassType> = Object.values(DogClassType);

  update(x: { rules: RuleSet; prerequisiteClasses: Array<DogClassType> }) {
    this.rulesAndClasses = x;
    this.updateClass();
  }

  @Watch("dogEvent")
  onDogEventChange(x: DogEvent) {}

  updateClass() {
    if(this.classType){
      let dogClass : DogClass = new DogClass(-1,this.className,new Rule(this.rulesAndClasses.rules),this.rulesAndClasses.prerequisiteClasses,this.classType,true)
      this.$emit("change", dogClass);
    }
  }

  mounted() {
    if(this.dogClass){
      this.classType = this.dogClass.type
      this.className = this.dogClass.name
      this.rulesAndClasses = {rules : this.dogClass.rules.ruleSet, prerequisiteClasses : this.dogClass.prerequisiteClasses}
    } 
  }

  @Watch("dogClass")
  onDogClassChange(x: DogClass | null) {
    if (!x) {
      this.reset()
    } else {
      this.classType = x.type;
      this.className = x.name;
      this.rulesAndClasses = { rules : x.rules.ruleSet, prerequisiteClasses : x.prerequisiteClasses}
      // this.rulesAndClasses.rules = x.rules.ruleSet;
      // this.rulesAndClasses.prerequisiteClasses = x.prerequisiteClasses;
    }
  }

  reset() {
    this.classType = null;
    this.className = "";
    this.rulesAndClasses.rules = {};
    this.rulesAndClasses.prerequisiteClasses = [];
  }

}
