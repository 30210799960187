import {
  ActionResponse,
  AuthenticationResponse,
  ChangePasswordRequest,
  ChangePasswordResponse,
  GeneralErrors,
  LoginRequest,
  LoginResponse,
  LogoutResponse,
  RequestVerificationEmailRequest,
  RequestVerificationEmailResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SignupRequest,
  SignupResponse,
  UserForgotRequest,
  UserForgotResponse,
  VerifyResetTokenRequest,
  VerifyResetTokenResponse,
  VerifyEmailRequest,
  VerifyEmailResponse,
  NewCSRFResponse,
  ActionRequest,
} from "@app/common";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "..";
import { fetchErrorMessage, generalErrorMessage } from "../error";
import { RootState } from "../types";

export enum UserMutations {
  AUTH_CHECK = "auth_check",
}

export enum UserActions {
  AUTH = "auth",
  LOGIN = "login",
  SINGUP = "singup",
}

export interface UserState {
  name: string;
  loggingIn: boolean;
}

@Module({ name: "UserState", dynamic: true, store })
class UserStore extends VuexModule implements UserState {
  private _authenticated = false;
  private _csrfSecurityToken = "";
  private _authChecked = false;

  private _userInfo: { email: string; firstName: string; lastName: string } = {
    email: "",
    firstName: "",
    lastName: "",
  };
  private _name = "";

  private _loggedIn = false;
  private _loginModal = false;
  private _loggingIn = false;
  private _loginError:
    | "EMAIL_NOT_VALIDATED"
    | "FAILED_BRUTE_CHECK"
    | "INVALID_EMAIL_OR_PASSWORD"
    | undefined = undefined;

  private _signupModal = false;
  private _signingUp = false;
  private _signupError:
    | GeneralErrors.AUTHENTICATION_ERROR
    | "INVALID_EMAIL"
    | "EMAIL_IN_USE"
    | undefined = undefined;

  get name() {
    return this._name;
  }

  get authenticated(): boolean {
    return this._authenticated;
  }

  get csrfSecurityToken(): string {
    return this._csrfSecurityToken;
  }

  get authChecked(): boolean {
    return this._authChecked;
  }

  get loggedIn(): boolean {
    return this._loggedIn;
  }

  get loginModal(): boolean {
    return this._loginModal;
  }

  get signupModal(): boolean {
    return this._signupModal;
  }

  get loggingIn(): boolean {
    return this._loggingIn;
  }

  set loggingIn(x: boolean) {
    this._loggingIn = x;
  }

  get signingUp(): boolean {
    return this._signingUp;
  }

  get userInfo(): { email: string; firstName: string; lastName: string } {
    return this._userInfo;
  }

  get loginError():
    | "EMAIL_NOT_VALIDATED"
    | "FAILED_BRUTE_CHECK"
    | "INVALID_EMAIL_OR_PASSWORD"
    | undefined {
    return this._loginError;
  }

  get signupError():
    | GeneralErrors.AUTHENTICATION_ERROR
    | "INVALID_EMAIL"
    | "EMAIL_IN_USE"
    | undefined {
    return this._signupError;
  }

  @Mutation
  authCheck(
    response: Required<{ authenticated: boolean; csrfSecurityToken: string }>
  ) {
    this._authenticated = response.authenticated;
    if (response.csrfSecurityToken.length > 0) {
      this._csrfSecurityToken = response.csrfSecurityToken;
    }
  }

  @Mutation
  setLoginError(
    x:
      | "EMAIL_NOT_VALIDATED"
      | "FAILED_BRUTE_CHECK"
      | "INVALID_EMAIL_OR_PASSWORD"
      | undefined
  ) {
    this._loginError = x;
  }

  @Mutation
  setSignupError(
    x:
      | GeneralErrors.AUTHENTICATION_ERROR
      | "INVALID_EMAIL"
      | "EMAIL_IN_USE"
      | undefined
  ) {
    this._signupError = x;
  }

  @Mutation
  setLoginModal(x: boolean) {
    this._loginModal = x;
  }

  @Mutation
  setLoggedIn(x: boolean) {
    this._loggedIn = x;
  }

  @Mutation
  setSignupModal(x: boolean) {
    this._signupModal = x;
  }

  @Mutation
  setLoggingIn(x: boolean) {
    this._loggingIn = x;
  }

  @Mutation
  setLoggingOut(x: boolean) {
    this._loggingOut = x;
  }

  @Mutation
  setUserInfo(x: { email: string; firstName: string; lastName: string }) {
    this._userInfo = x;
  }

  @Mutation
  setSigningUp(x: boolean) {
    this._signingUp = x;
  }

  @Mutation
  setAuthChecked(x: boolean) {
    this._authChecked = x;
  }

  @Action
  async auth() {
    const response = await fetch(process.env.VUE_APP_DOG_API + "/user/auth", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      credentials: "include",
    });
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: AuthenticationResponse = await response.json();
      this.authCheck(data);
      this.setLoggedIn(data.authenticated);
      this.setAuthChecked(true);
      if (data.userInfo) {
        this.setUserInfo(data.userInfo);
      }
    }
  }

  @Mutation
  setCsrfSecurityToken(x: string) {
    this._csrfSecurityToken = x
  }

  private _newCsrfTokenSuccess: boolean = false

  get newCsrfTokenSuccess(): boolean {
    return this._newCsrfTokenSuccess
  }

  @Mutation
  setNewCsrfTokenSuccess(x: boolean) {
    this._newCsrfTokenSuccess = x
  }


  @Action
  async newCsrfSecurityToken() {
    const response = await fetch(process.env.VUE_APP_DOG_API + "/user/new-csrf", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      credentials: "include",
    });
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
      this.setNewCsrfTokenSuccess(false)
    } else {
      const data: NewCSRFResponse = await response.json();
      this.setNewCsrfTokenSuccess(data.success)
      this.setCsrfSecurityToken(data.csrfSecurityToken)
    }
  }

  @Action
  async updateCsrfToken(payload : ActionRequest){
    await this.newCsrfSecurityToken()
    payload.csrfSecurityToken = this.csrfSecurityToken
  }

  @Action({ rawError: true })
  async login(payload: LoginRequest) {
    await this.updateCsrfToken(payload)
    this.setLoggingIn(true);
    const response = await fetch(process.env.VUE_APP_DOG_API + "/user/login", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(payload),
      credentials: "include",
    });
    this.setLoggingIn(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: LoginResponse = await response.json();
      let authenticated = false;
      if (data.success) {
        authenticated = true;
        this.setLoggedIn(true);
        this.setLoginError(undefined);
        if (data.userInfo) {
          this.setUserInfo(data.userInfo);
        }
        localStorage.setItem("email", payload.email);
      } else if (data.errors[0] === GeneralErrors.GENERAL_ERROR) {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
        this.context.rootState.errorCode = data.errors[0];
      } else {
        this.setLoginError(data.errors[0]);
      }
      this.authCheck({
        authenticated: authenticated,
        csrfSecurityToken: data.csrfSecurityToken,
      });
    }
  }

  @Action
  async signup(payload: SignupRequest) {
    this.setSigningUp(true);
    const response = await fetch(process.env.VUE_APP_DOG_API + "/user/signup", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(payload),
      credentials: "include",
    });
    this.setSigningUp(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: SignupResponse = await response.json();
      console.log(data);
      if (data.success) {
        this.authCheck({
          authenticated: false,
          csrfSecurityToken: data.csrfSecurityToken,
        });
        this.setSignupError(undefined);
        localStorage.setItem("email", payload.email);
      } else if (
        data.errors[0] === GeneralErrors.GENERAL_ERROR ||
        data.errors[0] === "INVALID_EMAIL"
      ) {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
        this.context.rootState.errorCode = data.errors[0];
      } else {
        this.setSignupError(data.errors[0]);
      }
    }
  }

  private _loggingOut = false;
  get loggingOut(): boolean {
    return this.loggingOut;
  }
  @Action
  async logout() {
    this.setLoggingOut(true);
    const response = await fetch(process.env.VUE_APP_DOG_API + "/user/logout", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ csrfSecurityToken: this._csrfSecurityToken }),
      credentials: "include",
    });
    this.setLoggingOut(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: LogoutResponse = await response.json();
      if (data.success) {
        this.reset(data.csrfSecurityToken);
        //Include entire store reset here.
      } else if (data.errors[0] === GeneralErrors.GENERAL_ERROR) {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
        this.context.rootState.errorCode = data.errors[0];
      }
    }
  }

  private _requestingForgotRequest = false;
  private _requestingForgotError: GeneralErrors.GENERAL_ERROR | undefined =
    undefined;
  get requestingForgotRequest(): boolean {
    return this._requestingForgotRequest;
  }
  get requestingForgotError(): GeneralErrors.GENERAL_ERROR | undefined {
    return this._requestingForgotError;
  }

  @Mutation
  setRequestingForgotRequest(x: boolean) {
    this._requestingForgotRequest = x;
  }

  @Mutation
  setRequestingForgotError(x: GeneralErrors.GENERAL_ERROR | undefined) {
    this._requestingForgotError = x;
  }

  @Action
  async requestPasswordReset(payload: UserForgotRequest) {
    this.setRequestingForgotRequest(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/user/request-password-reset",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
      }
    );
    this.setRequestingForgotRequest(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: UserForgotResponse = await response.json();
      if (data.success) {
        this.setRequestingForgotError(undefined);
      } else {
        this.setRequestingForgotError(GeneralErrors.GENERAL_ERROR);
      }
    }
  }

  private _requestingVerifcationEmail: boolean = false
  private _requestVerificationEmailError:
    | GeneralErrors.GENERAL_ERROR
    | "NO_SUCH_EMAIL"
    | "TOO_MANY_REQUESTS"
    | undefined = undefined;

  get requestingVerificationEmail(): boolean {
    return this._requestingVerifcationEmail
  }
  get requestVerificationEmailError():
    | GeneralErrors.GENERAL_ERROR
    | "NO_SUCH_EMAIL"
    | "TOO_MANY_REQUESTS"
    | undefined {
    return this._requestVerificationEmailError;
  }

  @Mutation
  setRequestingVerificationEmail(x: boolean) {
    this._requestingVerifcationEmail = x
  }
  @Mutation
  setRequestVerificationEmailError(
    x:
      | GeneralErrors.GENERAL_ERROR
      | "NO_SUCH_EMAIL"
      | "TOO_MANY_REQUESTS"
      | undefined
  ) {
    this._requestVerificationEmailError = x;
  }

  @Action
  async requestVerifcationEmail(payload: RequestVerificationEmailRequest) {
    this.setRequestingVerificationEmail(true)
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/user/request-verification-email",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
      }
    );
    this.setRequestingVerificationEmail(false)
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: RequestVerificationEmailResponse = await response.json();
      this.setRequestVerificationEmailError(data.errors[0]);
    }
  }

  private _changingPassword = false;
  get changingPassword(): boolean {
    return this._changingPassword;
  }
  @Mutation
  setChangingPassword(x: boolean) {
    this._changingPassword = x;
  }

  private _changePasswordError:
    | GeneralErrors
    | "INCORRECT_PASSWORD"
    | undefined = undefined;
  get changePasswordError(): GeneralErrors | "INCORRECT_PASSWORD" | undefined {
    return this._changePasswordError;
  }
  @Mutation
  setChangePasswordError(x: GeneralErrors | "INCORRECT_PASSWORD" | undefined) {
    this._changePasswordError = x;
  }

  @Action
  async changePassword(payload: ChangePasswordRequest) {
    this.setChangingPassword(true);
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/user/change-password",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      }
    );
    this.setChangingPassword(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: ChangePasswordResponse = await response.json();
      if (data.success) {
        this.setChangePasswordError(undefined);
      } else {
        console.log(data.errors);
        if (data.errors[0] === GeneralErrors.GENERAL_ERROR) {
          this.context.rootState.errorMsg = generalErrorMessage;
          this.context.rootState.errorModal = true;
          this.context.rootState.errorCode = data.errors[0];
        }
        this.setChangePasswordError(data.errors[0]);
      }
      this.authCheck(data);
    }
  }

  @Mutation
  reset(csrfSecurityToken: string) {
    this._authenticated = false;
    this._csrfSecurityToken = csrfSecurityToken;
    this._userInfo = {
      email: "",
      firstName: "",
      lastName: "",
    };
    this._loggedIn = false;
    this._loginModal = false;
    this._loggingIn = false;
    this._loginError = undefined;
    this._signupModal = false;
    this._signingUp = false;
  }


  private _verifyingResetToken: boolean = false
  private _verifyResetTokenErrors: Array<GeneralErrors.GENERAL_ERROR | 'INVALID_OR_EXPIRED_TOKEN' | 'REQUEST_DOES_NOT_EXIST'> | undefined = undefined
  private _resetEmail: string = ""

  get verifyingResetToken(): boolean {
    return this._verifyingResetToken
  }
  @Mutation
  setVerifyingResetToken(x: boolean) {
    this._verifyingResetToken = x
  }
  get verifyResetTokenErrors(): Array<GeneralErrors.GENERAL_ERROR | 'INVALID_OR_EXPIRED_TOKEN' | 'REQUEST_DOES_NOT_EXIST'> | undefined {
    return this._verifyResetTokenErrors
  }
  @Mutation
  setVerifyResetTokenErrors(x: Array<GeneralErrors.GENERAL_ERROR | 'INVALID_OR_EXPIRED_TOKEN' | 'REQUEST_DOES_NOT_EXIST'> | undefined) {
    this._verifyResetTokenErrors = x
  }
  get resetEmail(): string {
    return this._resetEmail
  }
  @Mutation
  setResetEmail(x: string) {
    this._resetEmail = x
  }

  @Action
  async verifyResetToken(payload: VerifyResetTokenRequest) {
    this.setVerifyingResetToken(true)
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/user/verify-reset-token",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      }
    );
    this.setVerifyingResetToken(false)
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: VerifyResetTokenResponse = await response.json();
      if (data.success) {
        this.setVerifyResetTokenErrors(undefined)
        this.setResetEmail(data.email)
      } else {
        this.setVerifyResetTokenErrors(data.errors)
      }
    }
  }


  private _resettingPassword: boolean = false
  private _resetPasswordErrors: Array<GeneralErrors.GENERAL_ERROR | 'INVALID_OR_EXPIRED_TOKEN' | 'REQUEST_DOES_NOT_EXIST'> | undefined = undefined

  get resettingPassword(): boolean {
    return this._resettingPassword
  }
  @Mutation
  setResettingPassword(x: boolean) {
    this._resettingPassword = x
  }
  get resetPasswordErrors(): Array<GeneralErrors.GENERAL_ERROR | 'INVALID_OR_EXPIRED_TOKEN' | 'REQUEST_DOES_NOT_EXIST'> | undefined {
    return this._resetPasswordErrors
  }
  @Mutation
  setResetPasswordErrors(x: Array<GeneralErrors.GENERAL_ERROR | 'INVALID_OR_EXPIRED_TOKEN' | 'REQUEST_DOES_NOT_EXIST'> | undefined) {
    this._resetPasswordErrors = x
  }

  @Action
  async resetPassword(payload: ResetPasswordRequest) {
    this.setResettingPassword(true)
    const response = await fetch(
      process.env.VUE_APP_DOG_API + "/user/reset-password",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      }
    );
    this.setResettingPassword(false)
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: ResetPasswordResponse = await response.json();
      if (data.success) {
        this.setResetPasswordErrors(undefined)
      } else {
        this.setResetPasswordErrors(data.errors)
      }
    }
  }



  private _verifyingEmail: boolean = false
  private _verifyEmailErrors: GeneralErrors | 'VALIDATION_REQUEST_DOES_NOT_EXIST' | undefined = undefined

  get verifyingEmail(): boolean {
    return this._verifyingEmail
  }
  @Mutation
  setVerifyingEmail(x: boolean) {
    this._verifyingEmail = x
  }

  get verifyEmailErrors(): GeneralErrors | 'VALIDATION_REQUEST_DOES_NOT_EXIST' | undefined {
    return this._verifyEmailErrors
  }
  @Mutation
  setVerifyEmailErrors(x: GeneralErrors | 'VALIDATION_REQUEST_DOES_NOT_EXIST' | undefined) {
    this._verifyEmailErrors = x
  }

  @Action
  async verifyEmail(payload: VerifyEmailRequest) {
    this.setVerifyingEmail(true);
    const response = await fetch(process.env.VUE_APP_DOG_API + "/user/verify-email", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(payload),
      credentials: "include",
    });
    this.setVerifyingEmail(false);
    if (!response.ok) {
      this.context.rootState.errorModal = true;
      this.context.rootState.errorMsg = fetchErrorMessage;
      this.context.rootState.errorCode =
        response.status.toString() + " " + response.statusText;
    } else {
      const data: VerifyEmailResponse = await response.json();
      console.log(data);
      if (data.success) {
        this.setVerifyEmailErrors(undefined);
      } else if (
        data.errors[0] === GeneralErrors.GENERAL_ERROR
      ) {
        this.context.rootState.errorMsg = generalErrorMessage;
        this.context.rootState.errorModal = true;
        this.context.rootState.errorCode = data.errors[0];
      } else {
        this.setVerifyEmailErrors(data.errors[0])
      }
    }
  }

}




export default getModule(UserStore);
