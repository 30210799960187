
import { DogEvent, DogEventInterface } from "@app/common";
import { Component, Vue } from "vue-property-decorator";
import NewShowStore from "../../store/modules/newshow";
@Component
export default class EventGroups extends Vue {
  //unusedEvents : Array<DogEvent> = []
  eventGroups: Array<Array<DogEvent>> = [];
  selectedEventGroup: number = -1;
  get unusedEvents(): Array<DogEvent> {
    let out: Array<DogEvent> = [];
    for (let dogEvent of NewShowStore.show.events) {
      if (
        !this.eventGroups.find((x) => {
          return x.find((y) => {
            return y.id === dogEvent.id;
          });
        })
      ) {
        out.push(DogEvent.dogEventFromJSON(dogEvent));
      }
    }
    out.sort((x, y) => {
      return x.id && y.id && x.id > y.id ? 1 : -1;
    });
    return out;
  }
  get dogEvents(): Array<DogEvent> {
    return DogEvent.dogEventArrayFromJSON(NewShowStore.show.events);
  }
  isInEventGroup(x: DogEvent): boolean {
    for (let eventGroup of this.eventGroups) {
      if (
        eventGroup.find((y) => {
          return y.id == x.id;
        })
      ) {
        return true;
      }
    }
    return false;
  }
  addToNewEventGroup(x: DogEvent) {
    let dogEvent: DogEvent | undefined = this.unusedEvents.find((y) => {
      return y.id === x.id;
    });
    if (dogEvent) {
      this.eventGroups.push([dogEvent]);
    }
    this.update();
  }
  addToSelectedEventGroup(x: DogEvent) {
    this.eventGroups[this.selectedEventGroup].push(x);
    this.update();
  }
  selectEventGroup(idx: number) {
    if (this.selectedEventGroup == idx) {
      this.selectedEventGroup = -1;
    } else {
      this.selectedEventGroup = idx;
    }
  }
  remove(x: DogEvent) {
    let removeIdx: number = -1;
    for (let k = 0; k < this.eventGroups.length; k++) {
      let eventGroup = this.eventGroups[k];
      let idx: number = eventGroup.findIndex((y) => {
        return y.id == x.id;
      });
      if (idx > -1) {
        eventGroup.splice(idx, 1);
        removeIdx = k;
      }
    }
    if (removeIdx > -1 && this.eventGroups[removeIdx].length == 0) {
      if (this.selectedEventGroup == removeIdx) {
        this.selectedEventGroup = -1;
      }
      this.eventGroups.splice(removeIdx, 1);
    }
    this.update();
  }

  update() {
    NewShowStore.setEventGroups(
      this.eventGroups.map((x) => {
        return x.map((y) => {
          return y.id as number;
        });
      })
    );
  }
  get selectedStyle(): { "background-color": string; color: string } {
    return {
      "background-color": this.$vuetify.theme.themes.light.primary as string,
      color: "white",
    };
  }

  validate(): Array<string> {
    return [];
  }
}
