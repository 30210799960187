
import { Component, Vue, Prop } from "vue-property-decorator";
import { nanoid } from "nanoid";
import {
  GeneralErrors,
  PaymentErrors,
  PaymentShowRequest,
  ReceiptRequest,
  Show,
  ShowEntry,
  Transaction,
} from "@app/common";
import Loading from "../Loading.vue";
import UserStore from "../../store/modules/user";
import DogStore from "../../store/modules/dog"
import PaymentStore from "../../store/modules/payment";
import CreditCardError from "./CreditCardError.vue";
import { fetchReceipt } from "../../services/receipt";

@Component({
  components: {
    Loading,
    CreditCardError,
  },
})
export default class CreditCardPayment extends Vue {
  @Prop({
    default: () => {
      return [];
    },
  })
  readonly entries!: Array<ShowEntry>;
  @Prop({ default: false }) readonly catalog!: boolean;
  @Prop() readonly show!: Show;
  @Prop() readonly showEntries!: Array<ShowEntry>;
  @Prop({default : false}) readonly disabled!: boolean

  applicationId: string = process.env.VUE_APP_SQUARE_APPLICATION_ID;
  locationId: string = process.env.VUE_APP_SQUARE_LOCATION_ID;
  idempotencyKey: string | null = null;
  card: any = null;
  disablePayment: boolean = false;
  submitting: boolean = false;
  loading: boolean = true;
  state: number = 0;
  //   beforeMount() {
  //     let squareScript = document.createElement("script");
  //     squareScript.setAttribute("src", process.env.VUE_APP_SQAURE_LINK);
  //     document.head.appendChild(squareScript);
  //   }
  async mounted() {
    this.generateIdempotencyKey();
    const payments = (window as any).Square.payments(
      this.applicationId,
      this.locationId
    );
    this.card = await payments.card();
    this.card.addEventListener("errorClassAdded", () => {
      this.disablePayment = true;
    });
    this.card.addEventListener("errorClassRemoved", () => {
      this.disablePayment = false;
    });
    this.card.addEventListener("submit", () => {
      this.pay();
    });
    await this.card.attach("#card-container");
    this.loading = false;
  }

  generateIdempotencyKey() {
    this.idempotencyKey = nanoid();
  }

  get fee(): number {
    if (this.showEntries && this.show) {
      return this.showFee + this.surchargeFee;
    }
    return 0;
  }

  get showFee(): number {
    if (this.showEntries && this.show) {
      return (
        ShowEntry.fee(this.showEntries) +
        (this.catalog ? this.show.catalog.fee : 0)
      );
    }
    return 0;
  }

  get surchargeFee(): number {
    if (this.showEntries && this.show) {
      return Transaction.surchargeFee(this.showFee);
    }
    return 0;
  }

  get paying(): boolean {
    return PaymentStore.makingPayment;
  }

  get paymentErrors(): Array<GeneralErrors | PaymentErrors> {
    if (PaymentStore.paymentErrors) {
      return PaymentStore.paymentErrors;
    } else {
      return [];
    }
  }

  get email(): string {
    return UserStore.userInfo.email;
  }

  async pay() {
    const token = await this.card.tokenize();
    if (this.idempotencyKey !== null && token && token.status === "OK" && this.show.id) {
      let entries: Array<number> = [];
      for (let showEntry of this.showEntries) {
        for (let entry of showEntry.entries) {
          if (entry.id !== null && !entry.paid) {
            entries.push(entry.id);
          }
        }
      }

      let payload: PaymentShowRequest = {
        show : this.show.id,
        entries: entries,
        catalog: this.catalog,
        idempotencyKey: this.idempotencyKey,
        sourceId: token.token,
        csrfSecurityToken: UserStore.csrfSecurityToken,
      };
      await PaymentStore.makePayment(payload);
      console.log("Payment errors");
      console.log(PaymentStore.paymentErrors);
      this.state = 1;
      if(!PaymentStore.paymentErrors){
        this.$emit("success")
        await DogStore.fetchDogData()
      }
    }
  }

  async fetchReceipt() {
    let receiptId = PaymentStore.receipt;
    if (receiptId > -1) {
      let payload: ReceiptRequest = {
        id: receiptId,
        type: "PAYMENT",
      };
      await fetchReceipt(payload);
    }
  }

  tryAgain() {
    this.generateIdempotencyKey();
    this.disablePayment = false;
    this.state = 0;
  }
}
