var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._l((_vm.transactionItems),function(transactionItem,idx){return _c('expansion-list',{key:idx,attrs:{"icon":"attach_money","iconColor":transactionItem.type == 'Payment' ? '#6a6' : '#a66'}},[_c('template',{slot:"panel"},[_c('v-container',{staticClass:"ma-0 px-0 px-sm-4",attrs:{"text-left":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"12","sm":"8"}},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(transactionItem.transaction.time.toLocaleString("en-US", { month: "long", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", }))+" ")]),_c('div',{staticClass:"text-subtitle-2",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(transactionItem.type)+" "+_vm._s(transactionItem.transaction.status)+" ")])]),_c('v-col',{staticClass:"title d-flex flex-row justify-end align-center",staticStyle:{"text-align":"right"},attrs:{"cols":"12","sm":"4"}},[_c('div',[_vm._v(" $"+_vm._s((transactionItem.transaction.total / 100.0).toFixed(2))+" ")])])],1)],1)],1),_c('template',{slot:"content"},[_c('v-container',[_c('div',[_c('v-icon',[_vm._v("person")]),_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(transactionItem.transaction.user.firstName + " " + transactionItem.transaction.user.lastName))]),_c('v-icon',[_vm._v("mail")]),_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(transactionItem.transaction.user.email))]),(transactionItem.transaction.user.phone)?_c('span',[_c('v-icon',[_vm._v("phone")]),_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(transactionItem.transaction.user.phone))])],1):_vm._e(),_c('v-icon',[_vm._v("paid")]),_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(transactionItem.transaction.transactionType))])],1),(
            transactionItem.type == 'Payment' &&
            transactionItem.transaction.type == 'CHECK' &&
            transactionItem.transaction.status == 'PENDING'
          )?_c('div',[_c('br'),_c('v-btn',{attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.completeCheckPayment(transactionItem.transaction)}}},[_vm._v("Complete payment")])],1):_vm._e(),_c('br'),_vm._l((transactionItem.transaction.entries),function(entry,jdx){return _c('div',{key:jdx,staticStyle:{"background":"#f5f5f5"}},_vm._l((entry.entry.classes),function(eventClass,kdx){return _c('div',{key:kdx,style:(entry.refunded && transactionItem.type == 'Payment'
                ? 'color: #999'
                : '')},[_c('v-divider'),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('div',{staticClass:"d-flex flex-column pa-2"},[_c('div',[_c('b',[_vm._v(" "+_vm._s(entry.entry.dog.showDog.dog.printName()))])]),_c('div',[_vm._v(" "+_vm._s(eventClass.dogClass.name + " - " + entry.entry.event.printDateRange())+" ")])])]),_c('v-col',{staticClass:"d-flex flex-row justify-end align-center",staticStyle:{"text-align":"right"},attrs:{"cols":"12","sm":"4"}},[(entry.refunded && transactionItem.type == 'Payment')?_c('div',{staticClass:"pr-4",staticStyle:{"color":"black","font-weight":"bold"}},[_vm._v(" REFUNDED ")]):_vm._e(),_c('div',{staticClass:"font-weight-bold pr-4"},[_vm._v(" $"+_vm._s((eventClass.fee / 100.0).toFixed(2))+" ")])])],1)],1)}),0)}),(!!transactionItem.transaction.catalogs)?_c('div',{staticStyle:{"background":"#f5f5f5"}},[_c('v-divider'),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('div',{staticClass:"d-flex flex-column pa-2"},[_c('div',[_c('b',[_vm._v(" Catalog ")])]),_c('div',[_vm._v(" "+_vm._s(transactionItem.transaction.show.name)+" ")])])]),_c('v-col',{staticClass:"d-flex flex-column justify-center",staticStyle:{"text-align":"right"},attrs:{"cols":"12","sm":"2"}},[_c('div',{staticClass:"font-weight-bold pr-4"},[_vm._v(" $"+_vm._s(( transactionItem.transaction.catalogs.amount / 100.0 ).toFixed(2))+" ")])])],1)],1):_vm._e(),_c('v-divider'),(
            !!transactionItem.transaction.surchargeFee ||
            !!transactionItem.transaction.surchargeRefund
          )?_c('div',{staticClass:"d-flex flex-row justify-end font-weight-bold pa-2"},[_c('div',{staticClass:"pr-4"},[_vm._v("Surchage :")]),_c('div',{staticClass:"pr-2"},[_vm._v(" $"+_vm._s(transactionItem.type == "Payment" ? (transactionItem.transaction.surchargeFee / 100.0).toFixed( 2 ) : ( transactionItem.transaction.surchargeRefund / 100.0 ).toFixed(2))+" ")])]):_vm._e(),_c('br'),(transactionItem.transaction.status !== 'PENDING')?_c('div',[_c('div',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"outlined":"","small":""},on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.fetchReceipt(transactionItem)}]}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("receipt_long")]),_vm._v(" Download Receipt")],1)],1),_c('br')]):_vm._e()],2)],1)],2)}),_c('v-divider'),_c('v-dialog',{model:{value:(_vm.checkModal),callback:function ($$v) {_vm.checkModal=$$v},expression:"checkModal"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Complete check payment ")]),_c('v-card-text',[_vm._v(" Are you certain you wish to complete this payment? Completing this payment means you've received the check and the transaction is complette. This will also produce a receipt that will be available on the exhibitor's account. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){_vm.checkModal = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"depressed":"","color":"primary","loading":_vm.processingCheck},on:{"click":function($event){return _vm.processCheck()}}},[_vm._v("Complete payment")])],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }