
import { Dog, ShowDog, ShowEntry } from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DogInfo from "../DogInfo.vue";
@Component({ components: { DogInfo } })
export default class ShowDogInfo extends Vue {
  @Prop({ default: null }) readonly value!: ShowEntry | null;
  showEntry: ShowEntry | null = null;
  mode: number = 0;
  showModal: boolean = false;
  get type(): string {
    if (this.mode == 0) {
      return "Original";
    } else {
      return "Edit";
    }
  }

  get otherType():string{
    if(this.mode == 0){
        return "Edit"
    }else{
        return "Orignal"
    }
  }

  get edited(): boolean {
    return !!this.showEntry && !!this.showEntry.showDogEdit;
  }

  get showDog(): Dog{
    if(this.showEntry){
        return this.showEntry.showDog.showDog.dog
    }else{
        return new Dog()
    }
  }
  get showDogEdit(): Dog{
    if(this.showEntry && this.showEntry.showDogEdit){
        return this.showEntry.showDogEdit.showDog.showDog.dog
    }else{
        return new Dog()
    }
  }

  beforeMount() {
    this.showEntry = this.value   
  }

  @Watch("value")
  onValueChange(x: ShowEntry | null) {
    this.showEntry = x;
    this.showModal = !!x
  }

  @Watch("showModal")
  onShowModalChange(x: boolean) {
    if (!x) {
      this.showEntry = null;
      this.mode = 0;
    }
  }

  @Watch("showEntry")
  onShowEntryChange(x: ShowEntry | null) {
    this.$emit("input", x);
  }
}
