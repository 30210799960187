
import { Component, Vue } from "vue-property-decorator";
import Loading from "../components/Loading.vue";
import DogFooter from "../components/DogFooter.vue";
import UserStore from "../store/modules/user";
import DogStore from "../store/modules/dog";
import PaymentStore from "../store/modules/payment";
import DogEntries from "../components/DogEntries.vue";
import { Entry, Show } from "@app/common";
@Component({
  components: {
    Loading,
    DogFooter,
    DogEntries,
  },
})
export default class Refund extends Vue {
  refunds : Array<Entry> = []
  get loading(): boolean {
    return DogStore.fetchingDogData || DogStore.dogDataNeedsUpdating;
  }
  get showId(): number | undefined {
    let abbr: string | undefined = this.$route.params.abbr;
    let show: Show | undefined = DogStore.shows.find((x) => {
      return x.abbr === abbr;
    });
    if(show){
        show.id
    }else{
        return undefined
    }
  }
  refundEntries(x : Array<Entry>){
    this.refunds = x
  }
  get amount():number{
    console.log(this.refunds)
    let amount = 0;
    for(let entry of this.refunds){
      amount += entry.fee
    }
    return amount
  }
}
