import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "..";
import UserStore from "./user"
import DogStore from "./dog"
import { fetchErrorMessage, generalErrorMessage } from "../error";
import { DogClass } from "@app/common/classes";
import { Entry, GeneralErrors, PayByCheckRequest, PayByCheckResponse, Payment, PaymentDataResponse, PaymentErrors, PaymentResponse, PaymentShowRequest, QRErrors, QRReceiptRequest, QRReceiptResponse, Refund, RefundErrors, RefundResponse, RefundShowRequest, Show, SquareAuthRequest, SquareAuthResponse } from "@app/common";

@Module({ dynamic: true, store, name: "PaymentStore", namespaced: true })
class PaymentStore extends VuexModule {
    private _payments: Array<Payment> = []
    private _refunds: Array<Refund> = []
    private _fetchingPaymentData: boolean = false
    private _fetchPaymentDataError: GeneralErrors | undefined = undefined
    private _paymentDataNeedsUpdating: boolean = true

    get payments(): Array<Payment> {
        return this._payments
    }
    @Mutation
    setPayments(x: Array<Payment>) {
        this._payments = x
    }

    get refunds(): Array<Refund> {
        return this._refunds
    }
    @Mutation
    setRefunds(x: Array<Refund>) {
        this._refunds = x
    }

    get fetchingPaymentData(): boolean {
        return this._fetchingPaymentData
    }
    @Mutation
    setFetchingPaymentData(x: boolean) {
        this._fetchingPaymentData = x
    }

    get fetchPaymentDataError(): GeneralErrors | undefined {
        return this._fetchPaymentDataError
    }
    @Mutation
    setFetchPaymentDataError(x: GeneralErrors | undefined) {
        this._fetchPaymentDataError = x
    }

    get paymentDataNeedsUpdating(): boolean {
        return this._paymentDataNeedsUpdating
    }
    @Mutation
    setPaymentDataNeedsUpdating(x: boolean) {
        this._paymentDataNeedsUpdating = x
    }

    ///You forgot something in this
    @Action
    async fetchPayments() {
        if (DogStore.dogDataNeedsUpdating) {
            await DogStore.fetchDogData()
        }
        this.setFetchingPaymentData(true)
        const response = await fetch(
            process.env.VUE_APP_DOG_API + "/transactions/fetch-data",
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({}),
                credentials: "include",
            }
        );
        this.setFetchingPaymentData(false)
        if (!response.ok) {
            this.context.rootState.errorModal = true;
            this.context.rootState.errorMsg = fetchErrorMessage;
            this.context.rootState.errorCode =
                response.status.toString() + " " + response.statusText;
        } else {
            const data: PaymentDataResponse = await response.json()
            if (data.success) {
                this.setFetchPaymentDataError(undefined)
                let entries: Array<Entry> = []
                for (let showEntry of DogStore.entries) {
                    entries = entries.concat(showEntry.entries)
                }
                let shows: Array<Show> = DogStore.shows
                let payments: Array<Payment> = Payment.paymentArrayFromJSON(data.payments, entries, shows)
                let refunds: Array<Refund> = Refund.refundArrayFromJSON(data.refunds,entries,shows,payments)
                this.setPayments(payments)
                this.setRefunds(refunds)
            } else {
                this.context.rootState.errorMsg = generalErrorMessage;
                this.context.rootState.errorModal = true;
            }
        }
    }

    private _makingPayment: boolean = false
    private _paymentErrors: Array<GeneralErrors | PaymentErrors> | undefined = undefined
    private _receipt: number = -1

    get makingPayment(): boolean {
        return this._makingPayment
    }
    @Mutation
    setMakingPayment(x: boolean) {
        this._makingPayment = x
    }

    get paymentErrors(): Array<GeneralErrors | PaymentErrors> | undefined {
        return this._paymentErrors
    }
    @Mutation
    setPaymentErrors(x: Array<GeneralErrors | PaymentErrors> | undefined) {
        this._paymentErrors = x
    }

    get receipt(): number {
        return this._receipt
    }
    @Mutation
    setReceipt(x: number) {
        this._receipt = x
    }

    @Action
    async makePayment(payload: PaymentShowRequest) {
        this.setMakingPayment(true)
        const response = await fetch(
            process.env.VUE_APP_DOG_API + "/transactions/payment",
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(payload),
                credentials: "include",
            }
        );
        this.setMakingPayment(false)
        if (!response.ok) {
            this.context.rootState.errorModal = true;
            this.context.rootState.errorMsg = fetchErrorMessage;
            this.context.rootState.errorCode =
                response.status.toString() + " " + response.statusText;
        } else {
            const data: PaymentResponse = await response.json();
            if (data.success) {
                this.setPaymentErrors(undefined);
                this.setReceipt(data.receipt)
                this.setPaymentDataNeedsUpdating(true)
            } else {
                this.setPaymentErrors(data.errors);
            }
            UserStore.authCheck(data);
        }
        
    }


    private _makingRefund: boolean = false
    private _refundErrors: Array<GeneralErrors | RefundErrors> | undefined = undefined
    private _refundReceipt: number = -1

    get makingRefund() : boolean{
        return this._makingRefund
    }
    @Mutation
    setMakingRefund(x : boolean){
        this._makingRefund = x
    }

    get refundErrors() : Array<GeneralErrors | RefundErrors> | undefined {
        return this._refundErrors
    }
    @Mutation
    setRefundErrors(x: Array<GeneralErrors | RefundErrors> | undefined ){
        this._refundErrors = x
    }

    get refundReceipt(): number{
        return this._refundReceipt
    }
    @Mutation
    setRefundReceipt(x: number){
        this._refundReceipt = x
    }

    @Action
    async makeRefund(payload : RefundShowRequest){
        this.setMakingRefund(true)
        const response = await fetch(
            process.env.VUE_APP_DOG_API + "/transactions/refund",
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(payload),
                credentials: "include",
            }
        );
        this.setMakingRefund(false)
        if (!response.ok) {
            this.context.rootState.errorModal = true;
            this.context.rootState.errorMsg = fetchErrorMessage;
            this.context.rootState.errorCode =
                response.status.toString() + " " + response.statusText;
        } else {
            const data: RefundResponse = await response.json();
            if (data.success) {
                this.setRefundErrors(undefined);
                this.setRefundReceipt(data.receipt)
                this.setPaymentDataNeedsUpdating(true)
            } else {
                this.setRefundErrors(data.errors);
            }
            UserStore.authCheck(data);
        }
    }


    private _fetchingSquareAuthorizationRequest : boolean = false
    private _squareAuthorizationError : GeneralErrors | undefined
    private _squareAuthorizationUrl : string = ""

    get fetchingSquareAuthorizationRequest():boolean{
        return this._fetchingSquareAuthorizationRequest
    }
    @Mutation
    setFetchingSquareAuthorizationRequest(x:boolean){
        this._fetchingSquareAuthorizationRequest = x
    }

    get squareAuthorizationError() : GeneralErrors | undefined{
        return this._squareAuthorizationError
    }
    @Mutation
    setSquareAuthorizationError(x: GeneralErrors|undefined){
        this._squareAuthorizationError = x
    }

    get squareAuthorizationUrl(): string{
        return this._squareAuthorizationUrl
    }
    @Mutation
    setSquareAuthorizationUrl(x:string){
        this._squareAuthorizationUrl = x
    }

    @Action
    async fetchSquareAuthorization(payload : SquareAuthRequest){
        this.setFetchingSquareAuthorizationRequest(true)
        const response = await fetch(
            process.env.VUE_APP_DOG_API + "/transactions/square-auth-request",
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(payload),
                credentials: "include",
            }
        );
        this.setFetchingSquareAuthorizationRequest(false)
        if (!response.ok) {
            this.context.rootState.errorModal = true;
            this.context.rootState.errorMsg = fetchErrorMessage;
            this.context.rootState.errorCode =
                response.status.toString() + " " + response.statusText;
        } else {
            const data: SquareAuthResponse = await response.json();
            if (data.success) {
                this.setSquareAuthorizationError(undefined);
                this.setSquareAuthorizationUrl(data.url)
            } else {
                this.context.rootState.errorMsg = generalErrorMessage;
                this.context.rootState.errorModal = true;
                this.context.rootState.errorCode = data.errors[0];
                this.setSquareAuthorizationError(data.errors[0]);
            }
            UserStore.authCheck(data);
        }
    }

    private _fetchingQRReceiptData : boolean = false
    private _QRReceiptErrors : Array<GeneralErrors | QRErrors> | undefined = undefined
    private _QRReceiptData : QRReceiptResponse | undefined = undefined

    get fetchingQRReceiptData():boolean{
        return this._fetchingQRReceiptData
    }
    @Mutation
    setFetchingQRReceiptData(x : boolean){
        this._fetchingQRReceiptData = x
    }
    get QRReceiptErrors(): Array<GeneralErrors | QRErrors> | undefined{
        return this._QRReceiptErrors
    }
    @Mutation
    setQRReceiptErrors(x : Array<GeneralErrors | QRErrors> | undefined){
        this._QRReceiptErrors = x
    }
    get QRReceiptData(): QRReceiptResponse | undefined{
        return this._QRReceiptData
    }
    @Mutation
    setQRReceiptData(x : QRReceiptResponse | undefined){
        this._QRReceiptData = x
    }

    @Action
    async QRFetch(payload: QRReceiptRequest) {
        this.setFetchingQRReceiptData(true)
        const response = await fetch(
            process.env.VUE_APP_DOG_API + "/transactions/qr",
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(payload),
                credentials: "include",
            }
        );
        this.setFetchingQRReceiptData(false)
        if (!response.ok) {
            this.context.rootState.errorModal = true;
            this.context.rootState.errorMsg = fetchErrorMessage;
            this.context.rootState.errorCode =
                response.status.toString() + " " + response.statusText;
        } else {
            const data: QRReceiptResponse = await response.json();
            if (data.success) {
                this.setQRReceiptErrors(undefined);
                this.setQRReceiptData(data)
            } else {
                this.setQRReceiptErrors(data.errors);
            }
        }
        
    }


    private _payingByCheck : boolean = false
    private _payByCheckErrors : Array<GeneralErrors | PaymentErrors> | undefined = undefined

    get payingByCheck():boolean{
        return this._payingByCheck
    }
    @Mutation
    setPayingByCheck(x : boolean){
        this._payingByCheck = x
    }
    get payByCheckErrors():  Array<GeneralErrors | PaymentErrors> | undefined{
        return this._payByCheckErrors
    }
    @Mutation
    setPayByCheckErrors(x :  Array<GeneralErrors | PaymentErrors> | undefined){
        this._payByCheckErrors = x
    }

    @Action
    async makeCheckPayment(payload: PayByCheckRequest) {
        this.setPayingByCheck(true)
        const response = await fetch(
            process.env.VUE_APP_DOG_API + "/transactions/pay-by-check",
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(payload),
                credentials: "include",
            }
        );
        this.setPayingByCheck(false)
        if (!response.ok) {
            this.context.rootState.errorModal = true;
            this.context.rootState.errorMsg = fetchErrorMessage;
            this.context.rootState.errorCode =
                response.status.toString() + " " + response.statusText;
        } else {
            const data: PayByCheckResponse = await response.json();
            if (data.success) {
                this.setPayByCheckErrors(undefined);
            } else {
                this.setPayByCheckErrors(data.errors);
            }
            UserStore.authCheck(data);
        }
        
    }

}

export default getModule(PaymentStore);
