import { Dog } from './dog'
import { Show, DogEvent, ShowType } from './show'
import { DogClass, DogClassType } from './classes'
import { Address, AddressInterface, Judge, Person } from './people'
import { Breed, Color, Group, ShowDog, ShowInterface, User, UserInterface, Variety } from '.'
import { Rule } from './class_rules'

export const OBEDIENCE_JUMP_HEIGHTS = [4,8,10,12,14,16,18,20,22,24,26,28,30,32,34,36]
export const RALLY_JUMP_HEIGHTS = [4,8,12,16]

export class JuniorShowmanship{
    public static juniorShowmanshipFromJSON(x : JuniorShowmanshipInterface, addresses : Array<Address>) : JuniorShowmanship{
        let address : Address | undefined = addresses.find((y) => {return y.id === x.address})
        if(!address){
            throw "JuniorShowmanship: Address not found."
        }
        return new JuniorShowmanship(x.name,x.number,new Date(x.birthDate),address,x.relationship)
    }

    private _name : string
    private _number : string
    private _birthDate : Date
    private _address: Address
    private _relationship? : string

    get name():string{
        return this._name
    }

    get number():string{
        return this._number
    }
    set number(x:string){
        this._number = x
    }
    get birthDate():Date{
        return this._birthDate
    }
    set birthDate(x : Date){
        this._birthDate = x
    }
    get address(): Address{
        return this._address
    }
    set address(x: Address){
        this._address = x
    }
    get relationship(): string | undefined{
        return this._relationship
    }
    set relationship(x : string | undefined){
        this._relationship = x
    }

    constructor(name: string, number : string, birthDate : Date, address: Address, relationship? : string){
        this._name = name
        this._number = number
        this._birthDate = birthDate
        this._address = address
        if(relationship){
            this._relationship = relationship
        }
    }

    public exportNewJuniorShowmanshipInterface() : NewJuniorShowmanshipInterface{
        let out : NewJuniorShowmanshipInterface = {
            name: this._name,
            number: this._number,
            birthDate: this._birthDate.toISOString(),
            address: this._address.exportInterface(),
            relationship :this._relationship
        }
        return out
    }
}

export interface JuniorShowmanshipInterface{
    name: string,
    number : string,
    birthDate: string,
    address: number,
    relationship?: string,
}

export interface NewJuniorShowmanshipInterface{
    name:string,
    number : string,
    birthDate: string,
    address: AddressInterface,
    relationship?: string,
}

export class Entry {
    

    public static entryFromJSON(x: EntryInterface, showDogs: Array<ShowDog>, show: Show, events: Array<DogEvent>, eventClasses: Array<EventClass>, addresses : Array<Address>): Entry {
        let dog: ShowDog | undefined = showDogs.find((u) => { return u.showDog.id == x.dog })
        if (!dog) {
            console.error("Entry: Dog not found.")
            throw "Entry: Dog not found."
        }
        let dogEvent: DogEvent | undefined = events.find((u) => { return x.event == u.id })
        if (!dogEvent) {
            console.error("Entry: Event not found.")
            throw "Entry: Event not found."
        }
        let dogClasses: Array<EventClass> = []
        for(let dogClass of x.classes){
            let eventClass : EventClass | undefined = eventClasses.find((u)=>{return u.id === dogClass})
            if(!eventClass){
                console.error("Entry: One or more classes not found.")
                throw "Entry: One or more classes not found."
            }
            dogClasses.push(eventClass)
        }
        //let dogClasses: Array<EventClass> | undefined = eventClasses.filter((u) => { return x.classes.includes(u.id) })
        if (!dogClasses || dogClasses.length !== x.classes.length) {
            console.error("Entry: One or more classes not found.")
            throw "Entry: One or more classes not found."
        }
        let time: Date = new Date(x.time)

        let junior : JuniorShowmanship | undefined = undefined

        if(x.junior){
            let juniorAddress : Address | undefined = addresses.find((y) => {return y.id == x.junior?.address})
            if(!juniorAddress){
                console.error("Entry: Junior address not found.")
                throw "Entry: Junior address not found."
            }
            junior = new JuniorShowmanship(x.junior.name,x.junior.number,new Date(x.junior.birthDate),juniorAddress, x.junior.relationship)
        }
        
        if (x.edit) {
            let entryEdit: EntryEdit = {
                id: x.edit.id,
                classes: x.edit.classes.map((x) => {
                    let out: EventClass | undefined = eventClasses.find((y) => { return y.id == x })
                    if (!out) {
                        console.error("Entry: One or more classes not found for the edit.")
                        throw "Entry: One or more classes not found for the edit."
                    }
                    return out
                }),
                agent: x.edit.agent,
                fee: 0,
                time : new Date(x.edit.time)
            }
            return new Entry(show, dogEvent, dog, x.id, dogClasses, x.agent, x.paid, x.refunded, x.ownerHandler, x.newExhibitor, x.signature, x.phone, time, x.obedienceJumpHeight, x.rallyJumpHeight, junior, entryEdit)
        } else {
            return new Entry(show, dogEvent, dog, x.id, dogClasses, x.agent, x.paid, x.refunded, x.ownerHandler, x.newExhibitor, x.signature, x.phone, time, x.obedienceJumpHeight, x.rallyJumpHeight, junior)
        }
    }

    public static entryArrayFromJSON(x: Array<EntryInterface>, showDogs: Array<ShowDog>, show: Show, events: Array<DogEvent>, eventClasses: Array<EventClass>, addresses : Array<Address>): Array<Entry> {
        return x.map((y) => { return Entry.entryFromJSON(y, showDogs, show, events, eventClasses, addresses) })
    }

    public static cloneEntryArray(x: Array<Entry>): Array<Entry> {
        return x.map((y) => { return y.clone() })
    }

    public static testDog(dog: Dog, event: DogEvent, agent: string | null, dogClasses: Array<EventClass>, show: Show, entryGroupEnteredTypes : Array<DogClassType>): boolean {
        let id = -1
        if(show.type == ShowType.SPECIALTY){
            if(!show.breed){
                console.error("Entry: Show type is specialty with no breed specifcied.")
                throw "Entry: Show type is specialty with no breed specifcied."
            }
            id = show.breed.id
        }
        if(show.type == ShowType.GROUP){
            if(!show.group){
                console.error("Entry: Show type is group with no group specified.")
                throw "Entry: Show type is group with no group specified."
            }
            id = show.group.id
        }
        return Entry.testDogWithType(dog,event,agent,dogClasses,{type: show.type, id: id },entryGroupEnteredTypes)
    }

    public static testDogWithType(dog: Dog, event: DogEvent, agent: string | null, dogClasses: Array<EventClass>, showType : {type: ShowType, id: number},entryGroupEnteredTypes : Array<DogClassType>): boolean {
        if(showType.type == ShowType.SPECIALTY){
            if(dog.breed.id !== showType.id){
                return false
            }
        }
        if(showType.type == ShowType.GROUP){
            let group : Group | null = Dog.findGroup(dog.breed,dog.variety)
            if(!group || group.id !== showType.id){
                return false
            }
        }
        for (let eventClass of dogClasses) {
            if (!eventClass.dogClass.testDog(dog, event, agent)) {
                return false
            }
            for (let requiredClass of eventClass.dogClass.prerequisiteClasses) {
                if (!entryGroupEnteredTypes.find((x) => { return x === requiredClass })) {
                    return false
                }
            }
        }
        return true
    }

    public static testDogWithTypeReturningProblemEntries(dog: Dog, event: DogEvent, agent: string | null, dogClasses: Array<EventClass>, showType : {type: ShowType, id: number}, entryGroupEnteredTypes : Array<DogClassType>): Array<EventClass> {
        if(showType.type == ShowType.SPECIALTY){
            if(dog.breed.id !== showType.id){
                return dogClasses
            }
        }
        if(showType.type == ShowType.GROUP){
            let group : Group | null = Dog.findGroup(dog.breed,dog.variety)
            if(!group || group.id !== showType.id){
                return dogClasses
            }
        }
        let problemEntries : Array<EventClass> = []
        for (let eventClass of dogClasses) {
            let test = true
            if (!eventClass.dogClass.testDog(dog, event, agent)) {
                problemEntries.push(eventClass)
                test = false
            }
            if(test){
                for (let requiredClass of eventClass.dogClass.prerequisiteClasses) {
                    if (!entryGroupEnteredTypes.find((x) => { return x === requiredClass })) {
                        problemEntries.push(eventClass)
                        break
                    }
                }
            }
            
        }
        return problemEntries
    }

    private _id: number | null
    private _show: Show
    private _event: DogEvent
    private _dog: ShowDog
    private _classes: Array<EventClass>
    private _agent: string | null
    private _paid: boolean
    private _refunded: boolean
    private _fee: number = 0
    private _ownerHandler : boolean
    private _newExhibitor : boolean
    private _signature: string
    private _phone: string | null
    private _time: Date
    private _obedienceJumpHeight? : number
    private _rallyJumpHeight? : number
    private _junior? : JuniorShowmanship
    private _edit?: EntryEdit

    constructor(show: Show, event: DogEvent, dog: ShowDog)
    constructor(show: Show, event: DogEvent, dog: ShowDog, id: number | null, classes: Array<EventClass>, agent: string | null, paid: boolean, refunded: boolean, ownerHandler : boolean, newExhibitor : boolean, signature: string, phone: string | null, time: Date, obedienceJumpHeight?: number, rallyJumpHeight?: number, junior? : JuniorShowmanship, edit?: EntryEdit)
    constructor(show: Show, event: DogEvent, dog: ShowDog, id?: number | null, classes?: Array<EventClass>, agent?: string | null, paid?: boolean, refunded?: boolean, ownerHandler? : boolean, newExhibitor? : boolean, signature?: string, phone?: string | null, time?: Date, obedienceJumpHeight?: number, rallyJumpHeight?: number, junior? : JuniorShowmanship, edit?: EntryEdit) {
        this._id = id || null
        this._show = show
        this._event = event
        this._dog = dog
        this._classes = classes || []
        this._agent = agent || null
        this._paid = paid || false
        this._refunded = refunded || false
        this._ownerHandler = ownerHandler || false
        this._newExhibitor = newExhibitor || false
        this._signature = signature || ''
        this._phone = phone || null
        for (let eventClass of this._classes) {
            if (eventClass.event.id !== this._event.id) {
                console.error("Entry: Event class does not match event.")
                throw "Entry: Event class does not match event."
            }
        }
        if(obedienceJumpHeight){
            if(!OBEDIENCE_JUMP_HEIGHTS.find((x) => {return x ===obedienceJumpHeight})){
                console.error("Entry: Obedience jump height not valid.")
                throw "Entry: Obedience jump height not valid."
            }
            this._obedienceJumpHeight = obedienceJumpHeight
        }
        if(rallyJumpHeight){
            if(!RALLY_JUMP_HEIGHTS.find((x) => {return x ===rallyJumpHeight})){
                console.error("Entry: Rally jump height not valid.")
                throw "Entry: Rallyjump height not valid."
            }
            this._rallyJumpHeight = rallyJumpHeight
        }
        if(junior){
            this._junior = junior
        }
        if (edit) {
            this._edit = edit
        }
        if(time){
            this._time = time
        }else{
            this._time = new Date()
        }
        this.setFee()
        this.setEditFee()
    }

    private setFee() {
        this._fee = this._classes.reduce((s, x) => { return s + x.fee }, 0)
    }

    private setEditFee() {
        if (this._edit) {
            this._edit.fee = this._edit.classes.reduce((s, x) => { return s + x.fee }, 0)
        }
    }


    get id(): number | null {
        return this._id
    }

    get event(): DogEvent {
        return this._event
    }

    get show(): Show {
        return this._show
    }

    get fee(): number {
        return this._fee
    }

    get feeEdit():number{
        if(this._edit){
            return this._edit.fee
        }else{
            console.error("Entry: Referencing fee of edited entry with no edit.")
            throw "Entry: Referencing fee of edited entry with no edit."
        }
    }

    get classes(): Array<EventClass> {
        return this._classes
    }

    set classes(eventClasses: Array<EventClass>) {
        if (eventClasses.length > 0) {
            let event = eventClasses[0].event
            for (let eventClass of eventClasses) {
                if (eventClass.event !== event) {
                    console.error("Event classes must share the same event")
                    throw "Event classes must share the same event"
                }
            }
        }
        this._classes = eventClasses
        this.setFee()
    }

    get agent(): string | null {
        return this._agent
    }

    set agent(value: string | null) {
        if (value && value.trim().length == 0) {
            this._agent = null
        } else {
            this._agent = value
        }
    }

    get paid(): boolean {
        return this._paid
    }

    get refunded(): boolean {
        return this._refunded
    }
    
    get ownerHandler(): boolean{
        return this._ownerHandler
    }
    set ownerHandler(x : boolean){
        this._ownerHandler = x
    }

    get newExhibitor(): boolean{
        return this._newExhibitor
    }
    set newExhibitor(x : boolean){
        this._newExhibitor = x
    }

    get signature(): string {
        return this._signature
    }
    set signature(value: string) {
        this._signature = value
    }
    get phone():string | null{
        return this._phone
    }
    set phone(value: string | null) {
        this._phone = value
    }
    get edit(): EntryEdit | undefined{
        return this._edit
    }

    get time(): Date | undefined{
        return this._time
    }

    get dog(): ShowDog{
        return this._dog
    }

    get obedienceJumpHeight() : number | undefined{
        return this._obedienceJumpHeight
    }
    set obedienceJumpHeight(x : number | undefined){
        this._obedienceJumpHeight = x
    } 

    get rallyJumpHeight() : number | undefined{
        return this._rallyJumpHeight
    }
    set rallyJumpHeight(x : number | undefined){
        this._rallyJumpHeight = x
    }

    get junior() : JuniorShowmanship | undefined{
        return this._junior
    }
    set junior(x : JuniorShowmanship | undefined){
        this._junior = x
    }


    public verifyEntry(entryGroupEnteredTypes : Array<DogClassType>): boolean {
        return Entry.testDog(this._dog.showDog.dog, this._event, this._agent, this._classes,this._show,entryGroupEnteredTypes)
    }

    public problemClasses(): Array<EventClass> {
        let out: Array<EventClass> = []
        for (let eventClass of this._classes) {
            if (!eventClass.dogClass.testDog(this._dog.showDog.dog, this._event, this._agent)) {
                out.push(eventClass)
            }
        }
        return out
    }

    public addClass(eventClass: EventClass) {
        if (this._event !== eventClass.event) {
            console.error("Event classes must share the same event")
            throw "Event classes must share the same event"
        }
    }

    public printFee(): string {
        return "$" + (this._fee / 100).toFixed(2)
    }

    public eligibleClasses(dogClasses: Array<EventClass>, entryGroupEnteredTypes : Array<DogClassType>): Array<EventClass> {
        //return dogClasses.filter((x) => { return x.dogClass.testDog(this._dog.showDog.dog, this._event, this._agent) })
        return dogClasses.filter((x) => {
            let newClasses : Array<EventClass> = [...this._classes,x]
            return Entry.testDog(this._dog.showDog.dog,this._event,this._agent,newClasses,this._show,entryGroupEnteredTypes)
        })
    }

    public clone(): Entry {
        let classes = EventClass.cloneEventClassArray(this._classes)
        // if(this._edit){
        //     return new Entry(this._show, this._event, this._dog, this._id, classes, this._agent, this._paid, this._refunded, this._signature, this._phone,this._time,this._edit)
        // }else{
        //     return new Entry(this._show, this._event, this._dog, this._id, classes, this._agent, this._paid, this._refunded, this._signature, this._phone,this._time)
        // }
        return new Entry(this._show, this._event, this._dog, this._id, classes, this._agent, this._paid, this._refunded, this._ownerHandler, this._newExhibitor, this._signature, this._phone,this._time,this._obedienceJumpHeight,this._rallyJumpHeight,this._junior,this._edit)
    }

    public exportNewEntryInterface(): NewEntryInterface{
        let showId : number | null = this._show.id
        if(showId === null){
            throw("Entry: exportNewEntryInterface no show id.")
        }
        let eventId : number | null = this._event.id
        if(eventId === null){
            throw("Entry: exportNewEntryInterface no event id.")
        }

        let out : NewEntryInterface = {
            event: eventId,
            classes: this._classes.map((x) => {return x.id}),
            agent: this._agent,
            ownerHandler : this._ownerHandler,
            newExhibitor : this._newExhibitor,
            signature: this._signature,
            phone: this._phone || "",
            obedienceJumpHeight: this._obedienceJumpHeight,
            rallyJumpHeight: this._rallyJumpHeight,
            junior: (this._junior ? this._junior.exportNewJuniorShowmanshipInterface() : undefined)
        }
        return out
    }

    public exportEditEntryInterface() : EditEntryInterface{
        let id : number | null = this._id
        if(id === null){
            throw("Entry: exportInterface no id")
        }
        let out : EditEntryInterface = {
            id : id,
            ...this.exportNewEntryInterface()
        }
        return out
    }

    public exportInterface() : EntryInterface{
        let id : number | null = this._id
        if(id === null){
            throw("Entry: exportInterface no id")
        }
        let showId : number | null = this._show.id
        if(showId === null){
            throw("Entry: exportInterface no show id.")
        }
        let eventId : number | null = this._event.id
        if(eventId === null){
            throw("Entry: exportInterface no event id.")
        }
        let out : EntryInterface = {
            id : id,
            show : showId,
            event : eventId,
            dog: this._dog.showDog.id,
            classes: this._classes.map((x) => {return x.id}),
            agent: this._agent,
            paid : false,
            refunded: false,
            ownerHandler : this._ownerHandler,
            newExhibitor : this._newExhibitor,
            signature: this._signature,
            phone: this._phone || "",
            time: this._time.getTime()
        }
        return out
    }
}

export interface EntryEdit {
    id: number | null
    classes: Array<EventClass>
    agent: string | null
    fee: number
    time: Date
    obedienceJumpHeight?: number
    rallyJumpHeight?:number
}

export interface EntryInterface {
    id: number
    show: number
    event: number
    dog: number
    classes: Array<number>
    agent: string | null
    paid: boolean
    refunded: boolean
    ownerHandler : boolean
    newExhibitor : boolean
    signature: string
    phone: string
    time: number
    // edit?: {
    //     id: number,
    //     classes: Array<number>
    //     agent: string | null,
    //     time: number
    // }
    edit?: EntryEditInterface
    obedienceJumpHeight?: number
    rallyJumpHeight?:number
    junior? : JuniorShowmanshipInterface
}

export interface EntryEditInterface {
    id: number
    entry: number
    classes: Array<number>
    agent: string | null
    time: number
    obedienceJumpHeight?: number
    rallyJumpHeight?:number
    junior? : NewJuniorShowmanshipInterface
}

export interface NewEntryInterface {
    event: number,
    classes: Array<number>,
    agent: string | null,
    ownerHandler: boolean,
    newExhibitor: boolean,
    signature: string,
    phone: string,
    obedienceJumpHeight?: number
    rallyJumpHeight?:number
    junior? : NewJuniorShowmanshipInterface
}

export interface EditEntryInterface extends NewEntryInterface{
    id: number
}

export interface EnterEntry {
    events: Array<number>,
    dog: number,
    classes: Array<number>,
}

export class ShowEntry {
    public static showEntryFromJSON(showEntry: ShowEntryInterface, showDogs: Array<ShowDog>, showDogEdits: Array<ShowDog>, shows: Array<Show>, events: Array<DogEvent>, eventClasses: Array<EventClass>, addresses: Array<Address>): ShowEntry {
        let show: Show | undefined = shows.find((x) => { return x.id == showEntry.show })
        if (!show) {
            console.error("ShowEntry: Show not found.")
            throw "ShowEntry: Show not found."
        }
        let dog: ShowDog | undefined = showDogs.find((x) => { return x.showDog.id == showEntry.showDog })
        if (!dog) {
            console.error("ShowEntry: Show dog not found.")
            throw "ShowEntry: Show dog not found."
        }
        let entries = Entry.entryArrayFromJSON(showEntry.entries, [dog], show, events, eventClasses,addresses)
        let showDogEditParam : {showDog : ShowDog, time : Date} | undefined = undefined
        if(showEntry.showDogEdit){
            let showDogEdit : ShowDog | undefined = showDogEdits.find((x) => {return x.showDog.id == showEntry.showDogEdit?.showDog})
            if(!showDogEdit){
                console.error("ShowEntry: Show dog edit not found.")
                throw "ShowEntry: Show dog edit not found."
            }
            showDogEditParam = {showDog: showDogEdit, time : new Date(showEntry.showDogEdit.time)}
        }
        
        return new ShowEntry(dog,entries,show,showEntry.armband,showDogEditParam)
        // if(showEntry.showDogEdit){
        //     let showDogEdit : ShowDog | undefined = showDogEdits.find((x) => {return x.showDog.id == showEntry.showDogEdit?.showDog})
        //     if(!showDogEdit){
        //         console.error("ShowEntry: Show dog edit not found.")
        //         throw "ShowEntry: Show dog edit not found."
        //     }
        //     return new ShowEntry(dog,entries,show,showEntry.armband,{ showDog: showDogEdit, time: new Date(showEntry.showDogEdit.time)})
        // }else{
        //     return new ShowEntry(dog, entries, show, showEntry.armband)
        // }
    }

    public static showEntryArrayFromJSON(showEntries: Array<ShowEntryInterface>, showDogs: Array<ShowDog>, showDogEdits: Array<ShowDog>, shows: Array<Show>, events: Array<DogEvent>, eventClasses: Array<EventClass>, addresses : Array<Address>): Array<ShowEntry>{
        return showEntries.map((x) => {return ShowEntry.showEntryFromJSON(x,showDogs,showDogEdits,shows,events,eventClasses, addresses)})
    }


    public static groupByShows(showEntries: Array<ShowEntry>): Array<{ show: Show, entries: Array<ShowEntry> }> {
        return showEntries.reduce((s: { show: Show, entries: ShowEntry[] }[], x: ShowEntry) => {
            let thisShow = s.find((u) => { return x.show.id == u.show.id })
            if (thisShow) {
                thisShow.entries.push(x)
            } else {
                s.push({ show: x.show, entries: new Array(x) })
            }
            return s
        }, [])
    }

    public static fee(showEntries: Array<ShowEntry>): number{
        let out : number = 0
        for(let showEntry of showEntries){
            for(let entry of showEntry.entries){
                out += (entry.paid ? 0 : entry.fee+showEntry.show.secretaryFee)
            }
        }
        return out
        //return showEntries.reduce((s,x) =>{return s + x.fee},0)
    }

    public static secretaryFee(showEntries: Array<ShowEntry>):number{
        let out : number = 0
        for(let showEntry of showEntries){
            for(let entry of showEntry.entries){
                out += (entry.paid ? 0 : showEntry.show.secretaryFee)
            }
        }
        return out
    }

    public static groupBy<T>(obj: T, key: string) {

    }

    private _showDog: ShowDog
    private _entries: Array<Entry>
    private _show: Show
    private _fee: number = 0
    private _editedFee: number = 0
    private _showDogEdit? : {showDog : ShowDog, time : Date}
    private _armband: string | null

    public get showDog(): ShowDog {
        return this._showDog
    }

    public get entries(): Array<Entry> {
        return this._entries
    }
    public set entries(value: Array<Entry>) {
        this._entries = value
        this.setFee()
    }
    public get show(): Show {
        return this._show
    }
    public set show(value: Show) {
        this._show = value
    }
    public get fee(): number {
        return this._fee
    }
    public get feeRemaining():number{
        let fee = 0
        for(let entry of this._entries){
            fee += (entry.paid ? 0 : 1)*(entry.fee+this._show.secretaryFee)
        }
        return fee
    }
    public get paidEntries():Array<Entry>{
        return this._entries.filter((x) => {return x.paid})
    }
    public get unpaidEntries():Array<Entry>{
        return this._entries.filter((x) => {return !x.paid})
    }
    public get refundedEntries():Array<Entry>{
        return this._entries.filter((x) =>{return x.refunded})
    }
    public get paidUnrefundedEntries():Array<Entry>{
        return this._entries.filter((x) => {return x.paid && !x.refunded})
    }
    public get entriesEdited(): boolean {
        for(let entry of this._entries){
            if(entry.edit){
                return true
            }
        }
        return false
    }

    public get editedEntries(): Array<EntryEdit>{
        let out : Array<EntryEdit> = []
        for(let entry of this._entries){
            if(entry.edit){
                out.push(entry.edit)
            }
        }
        return out
    }

    public get showDogEdit(): {showDog: ShowDog, time: Date} | undefined{
        return this._showDogEdit
    }

    public get armband():string | null{
        return this._armband
    }

    public get user():User | null{
        return this._showDog.user
    }

    constructor(showDog: ShowDog, entries: Array<Entry>, show: Show, armband: string| null, showDogEdit?: {showDog: ShowDog, time: Date}) {
        this._showDog = showDog
        this._entries = entries
        this._show = show
        this._showDogEdit = showDogEdit
        this._armband = armband
        this.setFee()
        this.setEditedFee()
    }

    get entriesNotRefunded(){
        return this._entries.filter((x) => {return !x.refunded})
    }

    get allRefunded(){
        return this.entriesNotRefunded.length == 0
    }

    private setFee() {
        this._fee = this._entries.reduce((s, x) => { return s + x.fee }, 0)
    }
    private setEditedFee(){
        this._editedFee = 0
        for(let entry of this._entries){
            if(entry.edit){
                this._editedFee+=entry.feeEdit
            }else{
                this._editedFee+=entry.fee
            }
        }
    }

    public printFee(): string {
        return "$" + (this._fee / 100).toFixed(2)
    }

    public printEditFee(): string {
        return "$" + (this._editedFee / 100).toFixed(2)
    }

    public clone(): ShowEntry {
        return new ShowEntry(this._showDog,Entry.cloneEntryArray(this._entries), this._show, this._armband,this._showDogEdit)
        // if(this._showDogEdit){
        //     return new ShowEntry(this._showDog,Entry.cloneEntryArray(this._entries), this._show, this._armband)
        // }else{
        //     return new ShowEntry(this._showDog,Entry.cloneEntryArray(this._entries), this._show, this._armband,this._showDogEdit)
        // }
        
    }
}

export interface ShowEntryInterface {
    showDog: number
    entries: Array<EntryInterface>
    show: number
    showDogEdit?: {showDog: number, time: number}
    armband: string | null
}


export class EventClass {

    public static eventClassFromJSON(x: EventClassInterface, events: Array<DogEvent>, dogClasses: Array<DogClass>, judges: Array<Judge>): EventClass {
        let dogEvent: DogEvent | undefined = events.find((y) => { return y.id == x.event })
        if (!dogEvent) {
            console.error("EventClass: Event not found")
            throw "EventClass: Event not found"
        }
        let dogClass: DogClass | undefined = dogClasses.find((y) => { return y.id == x.dogClass })
        if (!dogClass) {
            console.error("EventClass: Class not found")
            throw "EventClass: Class not found"
        }
        let judge : Judge | undefined = judges.find((y) =>{ return y.id == x.judge})
        if(!judge){
            console.error("EventClass: Judge not found")
            throw "EventClass: Judge not found"
        }
        return new EventClass(x.id, dogEvent, dogClass, x.fee, judge)
    }

    public static eventClassArrayFromJSON(x: Array<EventClassInterface>, events: Array<DogEvent>, dogClasses: Array<DogClass>, judges: Array<Judge>): Array<EventClass> {
        return x.map((y) => { return EventClass.eventClassFromJSON(y, events, dogClasses, judges) })
    }

    public static filterClassByType(eventClasses: Array<EventClass>, type: DogClassType): Array<EventClass> {
        return eventClasses.filter((x) => { return x._dogClass.type == type })
    }

    public static cloneEventClassArray(x: Array<EventClass>): Array<EventClass> {
        return x.map((y) => { return y })
    }

    public static getNoneClass(event: DogEvent): EventClass{
        let noneRule = new Rule({})
        let noneClass = new DogClass(-1,"None",noneRule,undefined,DogClassType.NULL,false)
        let noneJudge = new Judge()
        return new EventClass(-1,event,noneClass,0,noneJudge)
    }

    private _id: number
    private _event: DogEvent
    private _dogClass: DogClass
    private _fee: number
    private _judge: Judge

    public get id(): number {
        return this._id
    }
    //Do not set id
    public get event(): DogEvent {
        return this._event
    }
    //Do not set event
    public get dogClass(): DogClass {
        return this._dogClass
    }
    //Do not set dogClass
    public get fee(): number {
        return this._fee
    }
    public set fee(x:number){
        this._fee = x
    }
    
    public get judge(): Judge{
        return this._judge
    }
    public set judge(x: Judge){
        this._judge = x
    }

    constructor(id: number, event: DogEvent, dogClass: DogClass, fee: number, judge: Judge) {
        this._id = id
        this._event = event
        this._dogClass = dogClass
        this._fee = fee
        this._judge = judge
    }

    public splitBySex(addSuffix : boolean) : Array<EventClass>{
        let dogClasses : Array<DogClass> = this._dogClass.splitClassBySex(addSuffix)
        return dogClasses.map((x) => {return new EventClass(-1,this._event,x,this._fee,this._judge)})
    }
    public splitByColors(colors : Array<Color>, addSuffix : boolean): Array<EventClass>{
        let dogClasses : Array<DogClass> = this._dogClass.splitClassByColors(colors,addSuffix)
        return dogClasses.map((x) => {return new EventClass(-1,this._event,x,this._fee,this._judge)})
    }
    public splitByAge(ages : Array<number>, addSuffix : boolean): Array<EventClass>{
        let dogClasses : Array<DogClass> = this._dogClass.splitClassByAges(ages,addSuffix)
        return dogClasses.map((x) => {return new EventClass(-1,this._event,x,this._fee,this._judge)})
    }
    public splitByVariety(varieties : Array<Variety>, addSuffix : boolean): Array<EventClass>{
        let dogClasses : Array<DogClass> = this._dogClass.splitClassByVariety(varieties,addSuffix)
        return dogClasses.map((x) => {return new EventClass(-1,this._event,x,this._fee,this._judge)})
    }

    public addRuleSuffix():EventClass{
        return new EventClass(-1,this._event,this._dogClass.addRuleSuffix(),this._fee,this._judge)
    }

    // public exportInterface():EventClassInterface{
    //     if(this._event === null){
    //         throw "EventClass : exportInterface cannot have null event id."
    //     }
    //     if(this._judge.id === null){
    //         throw "EventClass : exportInterface cannot have null judge id."
    //     }
    //     let out : EventClassInterface = {
    //         id : this._id,
    //         event : this._event.id || -1,
    //         dogClass : this._dogClass.id,
    //         fee : this._fee,
    //         judge : this._judge.id || -1,
    //     }
    //     return out
    // }

    // public clone(): EventClass{
    //     return new EventClass(this._id,this._event,this._dogClass,this._fee,this._judge)
    // }


}

export interface EventClassInterface {
    id: number
    event: number
    dogClass: number
    fee: number
    judge: number
}

export interface NewEventClassInterface{
    event : number
    dogClass : {id : number, newCustomClass : boolean}
    fee : number
    judge : {id : number, newJudge : boolean}
}


// export class Discount{
//     _requisiteClasses: Array<DogClassType>
//     _applicableClasses : Array<DogClassType>
//     _eventGroup : Array<DogEvent>
//     _feeStructure : Array<{number : number, fee : number}>

//     // public static discountFromJSON(x : DiscountInterface):Discount{
//     //     return new Discount()
//     // }

//     get requisiteClasses(): Array<DogClassType>{
//         return this._requisiteClasses
//     }
//     set requisiteClasses(x : Array<DogClassType>){
//         this._requisiteClasses = x
//     }

//     get applicableClasses():Array<DogClassType>{
//         return this._applicableClasses
//     }
//     set applicableClasses(x : Array<DogClassType>){
//         this._applicableClasses = x
//     }

//     get eventGroup():Array<DogEvent>{
//         return this._eventGroup
//     }
//     //Do not set event group

//     get feeStructure(): Array<{number : number, fee : number}>{
//         return this._feeStructure
//     }
//     set feeStructure(x :  Array<{number : number, fee : number}>){
//         this._feeStructure = x
//     }

//     constructor(eventGroup : Array<DogEvent>)
//     constructor(eventGroup : Array<DogEvent>, requisiteClasses : Array<DogClassType>, applicableClasses : Array<DogClassType>, feeStructure :  Array<{number : number, fee : number}>)
//     constructor(eventGroup : Array<DogEvent>, requisiteClasses? : Array<DogClassType>, applicableClasses? : Array<DogClassType>, feeStructure? :  Array<{number : number, fee : number}>){
//         this._eventGroup = eventGroup
//         this._requisiteClasses = (requisiteClasses ? requisiteClasses : [])
//         this._applicableClasses = (applicableClasses ? applicableClasses : [])
//         this._feeStructure = (feeStructure ? feeStructure : [])
//     }

//     discountedFees(x : Array<Entry>) : Array<Fee>{
//         let out : Array<Fee> = []
//         for(let entry of x){
//             if(!this._eventGroup.find((y) => {return y.id == entry.event.id})){
//                 throw "Discount : Entry not in event group."
//             }
//         }
//         let conditionMet : boolean = true
//         for(let classType of this._requisiteClasses){
//             if(!x.find((y)=>{return y.classes.find((z)=>{return z.dogClass.type == classType})})){
//                 conditionMet = false
//             }
//         }
//         let appliedClasses : Array<EventClass> = []
//         for(let entry of x){
//             for(let eventClass of entry.classes){
//                 if(this._applicableClasses.find((y) => {return eventClass.dogClass.type == y})){
//                     appliedClasses.push(eventClass)
//                 }
//             }
//         }
//         appliedClasses = appliedClasses.sort((y,z) => {return z.fee - y.fee })
//         for(let k = 0; k < appliedClasses.length; k++){
//             let eventClass : EventClass = appliedClasses[k]
//             let feeStructure : {number: number, fee : number} | undefined = this._feeStructure.find((y)=>{return y.number < k+1})
//             if(feeStructure){
//                 let fee : Fee = new Fee(feeStructure.fee,eventClass)
//                 out.push(fee)
//             }else{
//                 let fee : Fee = new Fee(eventClass.fee,eventClass)
//                 out.push(fee)
//             }
//         }
//         return out
//     }
// }

// export interface DiscountInterface{
//     requisiteClasses: Array<DogClassType>
//     applicableClasses : Array<DogClassType>
//     eventGroup : number
//     feeStructure : Array<{number : number, fee : number}>
// }

// // export class ShowDiscount{
// //     private _show : Show

// // }

// export class Fee{
//     private _eventClass : EventClass
//     private _amount : number
    
//     get eventClass(): EventClass{
//         return this._eventClass
//     }
//     get amount(): number{
//         return this._amount
//     }

//     constructor(amount : number, eventClass : EventClass){
//         this._amount = amount
//         this._eventClass = eventClass
//     }

//     get originalFee(): number{
//         return this._eventClass.fee
//     }

//     get discounted():boolean{
//         return this._eventClass.fee !== this._amount
//     }
// }

