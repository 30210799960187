
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Address } from "@app/common";
import smartSearch from "../services/smartyStreet";
@Component
export default class SmartAddress extends Vue {
  @Prop({ default: "" }) readonly zipCode!: string;
  @Prop({ default: false }) readonly requireZip!: boolean;

  lookupString: string = "";
  suggestion: { address: Address; entries: number } | null = null;
  suggestions: Array<{ address: Address; entries: number }> = [];
  //timeInterval: number = 1000;
  //lastTime : number = new Date().getTime()
  //timeOut : number = -1
  open: boolean = false;
  idx: number = 0;
  secondarySearch: string = "";
  searchingSecondary: boolean = false;
  sending: boolean = false;

  get disabled(): boolean {
    if (this.requireZip && this.zipCode.length !== 5) {
      return true;
    } else {
      return false;
    }
  }
  get messages(): Array<string> {
    if (this.disabled) {
      return ["You must first enter your zip code."];
    } else {
      return [];
    }
  }

  get errorMessages(): Array<string> {
    if (
      this.lookupString &&
      this.lookupString.length > 1 &&
      this.suggestions.length == 0 &&
      this.suggestion === null
    ) {
      return ["No results. Check your address for mistakes or use US Manual."];
    } else {
      return [];
    }
  }

  setItem(x: number) {
    this.setSuggestion(this.suggestions[x]);
  }

  setSuggestion(x: { address: Address; entries: number }) {
    if (x && x.entries > 1) {
      this.lookupString = x.address.street + ", " + x.address.secondary;
      this.suggestion = x;
      this.lookupWithSelected(this.lookupString);
      this.idx = 0;
      this.searchingSecondary = true;
      this.secondarySearch = this.lookupString;
    } else if(x){
      this.suggestion = x;
      this.lookupString = this.getAddressString(this.suggestion);
      this.suggestions = [];
      this.idx = 0;
    }
    if(this.suggestion){
      this.$emit("input", this.suggestion.address);
    }
    
  }

  handleKey(x: KeyboardEvent) {
    if (x.key === "ArrowDown" && this.idx < this.suggestions.length - 1) {
      this.idx += 1;
    } else if (x.key === "ArrowUp" && this.idx > 0) {
      this.idx -= 1;
    } else if (x.key === "Enter") {
      this.setSuggestion(this.suggestions[this.idx]);
    }
  }

  getAddressString(x: { address: Address; entries: number }): string {
    return (
      x.address.street +
      ", " +
      (x.address.secondary.length > 0
        ? x.address.secondary +
          (x.entries > 1 ? " (" + x.entries + "), " : ", ")
        : "") +
      x.address.city +
      ", " +
      x.address.state +
      ", " +
      x.address.postal
    );
  }

  async lookup(x: string) {
    if (x.length > 0 && !this.sending) {
      this.sending = true
      this.suggestions = await smartSearch.searchWithZIPCodes(x, this.zipCode);
      this.sending = false

      //this.sending = true
    
      // let currentTime : number = new Date().getTime()
      // let timeRemaining : number = this.timeInterval + this.lastTime - currentTime
      // if(timeRemaining <= 0){
      //   this.suggestions = await smartSearch.searchWithZIPCodes(this.lookupString, this.zipCode);
      //   this.lastTime = currentTime
      //   if(this.timeOut > -1){
      //     clearTimeout(this.timeOut)
      //     this.timeOut = -1
      //   }
      //   this.sending = false
      // }else if(this.timeOut == -1){
      //   this.timeOut = setTimeout(async () => {
      //     this.suggestions = await smartSearch.searchWithZIPCodes(this.lookupString, this.zipCode);
      //     this.lastTime = new Date().getTime()
      //     this.timeOut = -1
      //     this.sending = false
      //   },timeRemaining)
      // }
    }
  }

  async lookupWithSelected(x: string) {
    if (x.length > 0) {
      this.suggestions = await smartSearch.searchWithZIPCodes(
        x,
        this.zipCode,
        this.selected()
      );
    }
  }

  selected(): string {
    let out = "";
    if (this.suggestion) {
      out =
        this.suggestion.address.street +
        " " +
        this.suggestion.address.secondary +
        " (" +
        this.suggestion.entries +
        ") " +
        this.suggestion.address.city +
        " " +
        this.suggestion.address.state +
        " " +
        this.suggestion.address.postal;
    }
    return out;
  }

  update() {
    console.log("search update");
    if (this.suggestion) {
      this.$emit("input", this.suggestion.address);
    }
  }

  async searchInput() {
    if (this.lookupString === null) {
      this.lookupString = "";
    }
    this.suggestion = null;
    if (
      !this.searchingSecondary ||
      this.lookupString.trim().length !== this.secondarySearch.length
    ) {
      await this.lookup(this.lookupString);
      if (this.suggestions && this.suggestions.length > 0) {
        this.open = true;
      }
      this.searchingSecondary = false;
    }
  }

  // async searchInput(x: string | null) {
  //   console.log(x);
  //   console.log(this.suggestion);
  //   if (x && x.length > 0 && !this.ignore) {
  //     if (this.suggestion && this.suggestion.entries > 1) {
  //       await this.lookupWithSelected(x);
  //       this.suggestion = null;
  //       this.ignore = true;
  //     } else {
  //       await this.lookup(x);
  //     }
  //   } else if (this.ignore) {
  //     this.ignore = false;
  //   }
  // }
}
