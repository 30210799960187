
import { Component, Vue, Watch } from "vue-property-decorator";
import fuzzySearch from "../services/fuzzySearch";
import TopPage from "../components/TopPage.vue";
import DogList from "../components/DogList.vue";
import DogEntries from "../components/DogEntries.vue";
import DogPayments from "../components/DogPayments.vue";
import DogRefunds from "../components/DogRefunds.vue";
import Loading from "../components/Loading.vue";
import DogToggle from "../components/DogForm/DogToggle.vue";
import DogShowList from "../components/DogShowList.vue";
import DogSquareTokens from "../components/DogSquareTokens.vue";
import DogStore from "../store/modules/dog";
import PaymentStore from "../store/modules/payment";
import NewShowStore from "../store/modules/newshow";
import SecretaryStore from "../store/modules/secretary";
import { Dog, Payment, Show, SquareToken } from "@app/common";
@Component({
  components: {
    TopPage,
    DogList,
    DogEntries,
    DogPayments,
    DogRefunds,
    Loading,
    DogToggle,
    DogShowList,
    DogSquareTokens,
  },
})
export default class SecretaryDashboard extends Vue {
  tab: number = 0;

  get showsLoading(): boolean {
    return SecretaryStore.fetchingShows;
  }

  get shows(): Array<Show> {
    return SecretaryStore.shows;
  }

  test() {
    console.log(this.squareTokens);
  }

  get tokensLoading(): boolean {
    return NewShowStore.fetchingSquareTokens;
  }

  get squareTokens(): Array<SquareToken> {
    return NewShowStore.squareTokens;
  }

  async mounted() {
    await SecretaryStore.fetchShows();
  }

  @Watch("tab")
  onTabChange(x: number) {
    if (x == 1) {
      NewShowStore.fetchSquareTokens();
    }
  }
}
