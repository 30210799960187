
import { DogName } from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DogTitleSelect from "./DogTitleSelect.vue";
@Component({components: {DogTitleSelect}})
export default class DogTitle extends Vue {
  @Prop({default : () => {return new DogName()}}) readonly value! : DogName
  name: DogName = new DogName();
  messages: Array<string> = [];
  errorMessages: Array<string> = [];

  beforeMount() {
    this.name = this.value
  }

  get namePrefixTitles(): string {
    console.log(this.name.prefixTitles);
    return (
      this.name.prefixTitles
        .map((x) => {
          return x.title + (x.order > 1 ? x.order : "");
        })
        .join(" ") + (this.name.prefixTitles.length > 0 ? " " : "")
    );
  }
  get nameSuffixTitles(): string {
    return (
      (this.name.suffixTitles.length > 0 ? " " : "") +
      this.name.suffixTitles
        .map((x) => {
          return x.title + (x.order > 1 ? x.order : "");
        })
        .join(" ")
    );
  }

  checkName() {
    let baseName = this.name.baseName.trim();
    if (
      baseName.toLowerCase() === baseName ||
      baseName.toUpperCase() === baseName
    ) {
      baseName = baseName
        .split(" ")
        .map((x) => {
          return x[0].toUpperCase() + x.slice(1).toLowerCase();
        })
        .join(" ");
      this.name.baseName = baseName;
      this.messages = [
        "Name must contain at least one lower case character and one upper case character.",
      ];
      setTimeout(() => {
        this.messages = [];
      }, 5000);
    }
  }

  @Watch("name")
  onNameChange(){
    this.$emit("input",this.name)
  }

  @Watch("value")
  onValueChange(x : DogName){
    this.name = x
  }

}
