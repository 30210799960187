
import {
  SecretaryCheckAbbrRequest,
  SecretaryGenerateAbbrRequest,
  ShowInterface,
} from "@app/common";
import { Component, Vue, Watch } from "vue-property-decorator";
import Loading from "../Loading.vue";
import NewShowStore from "../../store/modules/newshow";
@Component({ components: { Loading } })
export default class NewShowWrappingUp extends Vue {
  customAbbr: string = "";
  topLine: string = "";
  bottomLine: string = "";
  notice: string = "";
  get show(): ShowInterface {
    return NewShowStore.show;
  }

  get minDate(): string {
    let minTime = new Date(this.show.events[0].startDate).getTime();
    for (let event of this.show.events) {
      minTime = Math.min(new Date(event.startDate).getTime(), minTime);
    }
    let newDate = new Date(minTime);
    let out = newDate.getUTCMonth() + "-" + newDate.getUTCFullYear();
    return out;
  }

  // get abbr(): string {
  //   let abbr = this.show.name
  //     .split(" ")
  //     .map((x) => {
  //       return x[0] ? x[0].toUpperCase() : "";
  //     })
  //     .join("");
  //   abbr += "-"
  //   abbr += this.minDate
  //   return abbr
  // }
  get loading(): boolean {
    return NewShowStore.generatingAbbr;
  }

  abbr: string = "";

  async mounted() {
    let payload: SecretaryGenerateAbbrRequest = {
      name: this.show.name,
      minDate: this.minDate,
    };
    await NewShowStore.generateAbbr(payload);
    if (NewShowStore.abbr) {
      this.abbr = NewShowStore.abbr;
    }
    this.customAbbr = this.abbr;
    this.topLine = this.show.name;
  }

  abbrError: Array<string> = [];

  async checkAbbr() {
    this.abbrError = [];
    let regMatch = this.customAbbr.match(/([a-zA-Z0-9]|-)/g);
    let customAbbr = (regMatch ? regMatch : []).join("");
    this.$nextTick(() => {
      this.customAbbr = customAbbr;
    });
    //this.customAbbr = this.customAbbr.replace(/\W/g, '')
    let payload: SecretaryCheckAbbrRequest = { abbr: customAbbr };
    await NewShowStore.checkAbbr(payload);
    if (!NewShowStore.abbAvailable) {
      this.abbrError = ["Abbreviation not available"];
    } else {
      this.update();
    }
  }

  update() {
    let abbr = this.abbr;
    if (this.customAbbr.trim().length > 0 && this.abbrError.length == 0) {
      abbr = this.customAbbr;
    }
    let topLine = this.topLine.trim();
    let bottomLine = this.bottomLine.trim();
    let notice = this.notice.trim();
    NewShowStore.setFinalDetails({
      abbr: abbr,
      topLine: topLine,
      bottomLine: bottomLine,
      note: notice,
    });
  }

  @Watch("topLine")
  onTopLineChange() {
    this.update();
  }

  @Watch("bottomLine")
  onBottomLineChange() {
    this.update();
  }

  @Watch("notice")
  onNoticeChange() {
    this.update();
  }

  validate(): Array<string> {
    return [];
  }
}
