export default async function sha512(x: string): Promise<string> {
  return Array.from(
    new Uint8Array(
      await crypto.subtle.digest("SHA-512", new TextEncoder().encode(x))
    )
  )
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
}

