
import { DOGALOG_FEE, Entry, Show, Transaction } from "@app/common";
import { Component, Vue } from "vue-property-decorator";
import Loading from "../../components/Loading.vue";
import ShowManagerPaymentsPanel from "../../components/ShowManager/ShowManagerPaymentsPanel.vue";
import SecretaryStore from "../../store/modules/secretary";
@Component({ components: { Loading, ShowManagerPaymentsPanel } })
export default class ShowManagerPaymentsSummary extends Vue {
  get dataTableSummaryItems(): Array<{
    name: string;
    totalFees: string;
    refundedFees: string;
    dogalogFees: string;
    netFees: string;
  }> {
    let out: Array<{
      name: string;
      totalFees: string;
      refundedFees: string;
      dogalogFees: string;
      netFees: string;
    }> = [];
    if (this.show) {
      for (let event of this.show.events) {
        let totalFees: number = 0;
        let netFees: number = 0;
        let entries: Array<Entry> = [];
        for (let payment of SecretaryStore.payments) {
          entries = entries.concat(
            payment.entries
              .filter((x) => {
                return x.entry.event.id == event.id;
              })
              .map((x) => {
                return x.entry;
              })
          );
        }
        totalFees += entries.reduce((s, x) => {
          return s + x.fee;
        }, 0);
        let dogalogFees: number = entries.length * DOGALOG_FEE;
        let refundedFees: number = 0;
        let entriesRefunded: Array<Entry> = [];
        for (let refund of SecretaryStore.refunds) {
          entriesRefunded = entriesRefunded.concat(
            refund.entries
              .filter((x) => {
                return x.entry.event.id == event.id;
              })
              .map((x) => {
                return x.entry;
              })
          );
        }
        refundedFees += entriesRefunded.reduce((s, x) => {
          return s + x.fee;
        }, 0);
        dogalogFees -= entriesRefunded.length * DOGALOG_FEE;
        netFees = totalFees - refundedFees - dogalogFees;
        out.push({
          name: event.eventTypeAndNumber + " " + event.printStartDate(),
          totalFees: "$" + (totalFees / 100).toFixed(2),
          refundedFees: "$" + (refundedFees / 100).toFixed(2),
          dogalogFees: "$" + (dogalogFees / 100).toFixed(2),
          netFees: "$" + (netFees / 100).toFixed(2),
        });
      }
    }
    return out;
  }

  loading: boolean = false;
  abbr: string = "";
  paymentModal: boolean = false;

  async mounted() {
    this.abbr = this.$route.params.abbr;
    this.loading = true;
    await SecretaryStore.paymentRoutine({ abbr: this.abbr });
    this.loading = false;
  }

  get totalFees(): string {
    let total: number = SecretaryStore.payments.reduce((s, x) => {
      return s + x.total;
    }, 0);
    return "$" + (total / 100).toFixed(2);
  }

  get surchrageFees(): string {
    let total: number = SecretaryStore.payments.reduce((s, x) => {
      return s + x.surchargeFee;
    }, 0);
    let refunded: number = SecretaryStore.refunds.reduce((s, x) => {
      return s + (x.surchargeRefund ? x.surchargeRefund : 0);
    }, 0);
    return "$" + ((total - refunded) / 100).toFixed(2);
  }

  // get dogalogFees():string{
  // }

  get show(): Show | undefined {
    return SecretaryStore.selectedShow;
  }
}
