
import { DogEvent, DogEventType } from "@app/common";
import { DogClass, DogClassType } from "@app/common/classes";
import { Rule, RuleSet } from "@app/common/class_rules";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import DogRule from "./DogRule.vue";
@Component({ components: { DogRule } })
export default class DogCustomClass extends Vue {
  @Prop({
    default: null,
  })
  readonly dogClass!: DogClass | null;
  @Prop({
    default: () => {
      return new DogEvent(-1, new Date(), new Date(), null, DogEventType.OTHER);
    },
  })
  readonly dogEvent!: DogEvent;
  rulesAndClasses: {
    rules: RuleSet;
    prerequisiteClasses: Array<DogClassType>;
  } = { rules: {}, prerequisiteClasses: [] };
  rule: Rule = new Rule({});
  ruleName: string = "";
  prefix: string = "";
  suffix: string = "";
  className: string = "";
  autoNameMode: boolean = true;
  classType: DogClassType | null = null;
  classTypes: Array<DogClassType> = Object.values(DogClassType);

  update(x: { rules: RuleSet; prerequisiteClasses: Array<DogClassType> }) {
    this.rulesAndClasses = x;
    this.rule = new Rule(x.rules);
    this.ruleName = this.rule.nameFromRuleSet(this.prefix, this.suffix);
    this.updateClass();
  }

  updateRuleName() {
    this.ruleName = this.rule.nameFromRuleSet(this.prefix, this.suffix);
    this.updateClass();
  }

  @Watch("dogEvent")
  onDogEventChange(x: DogEvent) {}

  updateClass() {
    let name: string = this.className;
    if (this.autoNameMode) {
      name = this.ruleName;
    }
    let out: DogClass | null = null;
    if (this.classType && name.trim().length > 0) {
      out = new DogClass(
        -1,
        name,
        new Rule(this.rulesAndClasses.rules),
        this.rulesAndClasses.prerequisiteClasses,
        this.classType,
        true
      );
    }
    console.log(out)
    this.$emit("change", out);
  }


  @Watch("dogClass")
  onDogClassChange(x: DogClass | null) {
    console.log("dog class change");
    console.log(x);
    if (!x) {
      this.reset()
    } else {
      this.classType = x.type;
      this.className = x.name;
      this.rulesAndClasses.rules = x.rules.ruleSet;
      this.rulesAndClasses.prerequisiteClasses = x.prerequisiteClasses;
      this.autoNameMode = false;
      this.prefix = "";
      this.suffix = "";
      this.ruleName = this.rule.nameFromRuleSet(this.prefix, this.suffix);
    }
  }

  reset() {
    this.classType = null;
    this.className = "";
    this.rulesAndClasses.rules = {};
    this.rulesAndClasses.prerequisiteClasses = [];
    this.autoNameMode = true;
    this.prefix = "";
    this.suffix = "";
    this.ruleName = "";
  }

  // @Watch("rulesAndClasses")
  // onRuleSetChange(x : {rules: RuleSet, prerequisiteClasses: Array<DogClassType>} ){
  //   this.rule = new Rule(x.rules)
  //   this.ruleName = this.rule.nameFromRuleSet()
  // }
}
