
import {
  CSVDataItem,
  CSVDogEntryColumns,
  CSVPaymentColumns,
  CSVPreset,
  CSVRefundColumns,
  SecretaryCSVDataRequest,
  SecretaryDeleteCSVPresetRequest,
  SecretarySaveCSVPresetRequest,
  User,
} from "@app/common";
import { Component, Vue, Watch } from "vue-property-decorator";
import CsvDataSelector from "./CsvDataSelector.vue";
import ExpansionList from "../ExpansionList.vue";
import Loading from "../Loading.vue";
import CSVDataStore from "../../store/modules/csv_data";
import UserStore from "../../store/modules/user";
import SecretaryStore from "../../store/modules/secretary";
import fetchCSVData from "../../services/csv_data";
@Component({ components: { CsvDataSelector, ExpansionList, Loading } })
export default class CsvDataExporter extends Vue {
  dogEntryItems: Array<CSVDataItem<CSVDogEntryColumns>> = Object.values(
    CSVDogEntryColumns
  ).map((x) => {
    return new CSVDataItem(x);
  });
  paymentItems: Array<CSVDataItem<CSVPaymentColumns>> = Object.values(
    CSVPaymentColumns
  ).map((x) => {
    return new CSVDataItem(x);
  });
  refundItems: Array<CSVDataItem<CSVRefundColumns>> = Object.values(
    CSVRefundColumns
  ).map((x) => {
    return new CSVDataItem(x);
  });

  dogEntryColumns: Array<CSVDataItem<CSVDogEntryColumns>> = [];
  paymentColumns: Array<CSVDataItem<CSVPaymentColumns>> = [];
  refundColumns: Array<CSVDataItem<CSVRefundColumns>> = [];

  reduceDogEntry: boolean = false;
  reducePayment: boolean = false;
  reduceRefund: boolean = false;

  includeDogAndEntry: boolean = true;
  includePayment: boolean = true;
  includeRefund: boolean = true;

  saveModal: boolean = false;
  deleteModal: boolean = false;
  savePresetName: string = "";

  presetName: string | null = null;

  restrictTime: boolean = false;
  restrictStartTime: boolean = false;
  restrictEndTime: boolean = false;

  startTime: string = "";
  endTime: string = "";

  updateTimes() {
    this.$nextTick(() => {
      this.startTime = this.startTime.replace(/\D/g, "");
      this.endTime = this.endTime.replace(/\D/g, "");
    });
  }

  get readyToExport(): boolean {
    if (
      !this.includeDogAndEntry &&
      !this.includePayment &&
      !this.includeRefund
    ) {
      return false;
    } else {
      return (
        (this.includeDogAndEntry && this.dogEntryColumns.length > 0) ||
        (this.includePayment && this.paymentColumns.length > 0) ||
        (this.includeRefund && this.refundColumns.length > 0)
      );
    }
  }

  loadPreset(x: CSVPreset) {
    if (x.dogEntryColumns) {
      this.dogEntryColumns =
        CSVDataItem.csvDataItemArrayFromJSON<CSVDogEntryColumns>(
          x.dogEntryColumns
        );
      this.includeDogAndEntry = true;
    } else {
      this.dogEntryColumns = [];
      this.includeDogAndEntry = false;
    }
    if (x.paymentColumns) {
      this.paymentColumns =
        CSVDataItem.csvDataItemArrayFromJSON<CSVPaymentColumns>(
          x.paymentColumns
        );
      this.includePayment = true;
    } else {
      this.paymentColumns = [];
      this.includePayment = false;
    }
    if (x.refundColumns) {
      this.refundColumns =
        CSVDataItem.csvDataItemArrayFromJSON<CSVRefundColumns>(x.refundColumns);
      this.includeRefund = true;
    } else {
      this.refundColumns = [];
      this.includeRefund = false;
    }
    this.reduceDogEntry = x.reduceDogEntry;
    this.reducePayment = x.reducePayment;
    this.reduceRefund = x.reduceRefund;
    this.$nextTick(() => {
      this.presetName = x.name;
    });
  }

  async savePreset() {
    let payload: SecretarySaveCSVPresetRequest = {
      name: this.savePresetName.trim(),
      reduceDogEntry: this.reduceDogEntry,
      reducePayment: this.reducePayment,
      reduceRefund: this.reduceRefund,
      csrfSecurityToken: UserStore.csrfSecurityToken,
    };
    if (this.includeDogAndEntry && this.dogEntryColumns.length > 0) {
      payload.dogEntryColumns = this.dogEntryColumns.map((x) => {
        return x.exportInterface();
      });
    }
    if (this.includePayment && this.paymentColumns.length > 0) {
      payload.paymentColumns = this.paymentColumns.map((x) => {
        return x.exportInterface();
      });
    }
    if (this.includeRefund && this.refundColumns.length > 0) {
      payload.refundColumns = this.refundColumns.map((x) => {
        return x.exportInterface();
      });
    }
    await CSVDataStore.savePreset(payload);
    await CSVDataStore.fetchPresets();
    this.saveModal = false;
  }

  get savingPreset(): boolean {
    return CSVDataStore.savingPreset || CSVDataStore.fetchingPresets;
  }

  async mounted() {
    await CSVDataStore.fetchPresets();
  }

  get loading(): boolean {
    return CSVDataStore.fetchingPresets;
  }

  get presets(): Array<CSVPreset> {
    return CSVDataStore.CSVPresets;
  }

  presetToDelete: CSVPreset | null = null;

  setDeletePreset(x: CSVPreset) {
    this.presetToDelete = x;
    this.deleteModal = true;
  }

  async deletePreset() {
    if (this.presetToDelete) {
      let payload: SecretaryDeleteCSVPresetRequest = {
        id: this.presetToDelete?.id,
        csrfSecurityToken: UserStore.csrfSecurityToken,
      };
      await CSVDataStore.deletePreset(payload);
      await CSVDataStore.fetchPresets();
    }
    this.presetToDelete = null;
    this.deleteModal = false;
  }

  get deletingPreset(): boolean {
    return CSVDataStore.deletingPreset || CSVDataStore.fetchingPresets;
  }

  async fetchData() {
    if (SecretaryStore.selectedShow && SecretaryStore.selectedShow.id) {
      let timeRange : undefined | {minTime? : number, maxTime? : number} = undefined
      let hasStart : boolean = (this.restrictStartTime && this.startTime.length >0)
      let hasEnd : boolean = (this.restrictEndTime && this.endTime.length > 0)
      if(this.restrictTime && (hasStart || hasEnd)){
        timeRange = {}
        if(hasStart){
          timeRange.minTime = parseInt(this.startTime)
        }
        if(hasEnd){
          timeRange.maxTime = parseInt(this.endTime)
        }
      }
      let payload: SecretaryCSVDataRequest = {
        show: SecretaryStore.selectedShow.id,
        dogEntryColumns: this.dogEntryColumns.map((x) => {
          return x.exportInterface();
        }),
        paymentColumns: this.paymentColumns.map((x) => {
          return x.exportInterface();
        }),
        refundColumns: this.refundColumns.map((x) => {
          return x.exportInterface();
        }),
        reduceDogEntry: this.reduceDogEntry,
        reducePayment: this.reducePayment,
        reduceRefund: this.reduceRefund,
        timeRange : timeRange
      };
      await fetchCSVData(payload);
    }
  }

  @Watch("dogEntryColumns")
  onDogEntryColumnsChange() {
    this.presetName = null;
  }
  @Watch("paymentColumns")
  onPaymentColumnsChange() {
    this.presetName = null;
  }
  @Watch("refundColumns")
  onRefundColumns() {
    this.presetName = null;
  }
  @Watch("includeDogEntry")
  onIncludeDogEntryChange() {
    this.presetName = null;
  }
  @Watch("includePayment")
  onIncludePaymentChange() {
    this.presetName = null;
  }
  @Watch("includeRefund")
  onIncludeRefundChange() {
    this.presetName = null;
  }
  @Watch("reduceDogEntry")
  onReduceDogEntryChange() {
    this.presetName = null;
  }
  @Watch("reducePayment")
  onReducePaymentChange() {
    this.presetName = null;
  }
  @Watch("reduceRefund")
  onReduceRefundChange() {
    this.presetName = null;
  }
}
