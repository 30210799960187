
import { Person } from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DogPerson from "./DogPerson.vue";
import DogPersonModal from "./DogPersonModal.vue";
import DogStore from "../../../store/modules/dog";

@Component({
  components: {
    DogPerson,
    DogPersonModal,
  },
})
export default class DogPeopleList extends Vue {
  @Prop({ default: "" }) readonly label!: string;
  @Prop() readonly value : Array<Person> | undefined
  @Prop({default : undefined}) readonly peopleDefault : Array<Person> | undefined
  people: Array<Person> = [new Person()];
  modal = false;
  isError = false;
  editStatus : Array<number> = []
  addPerson() {
    this.people.push(new Person());
    this.editStatus.push(this.people.length-1)
    this.update()
  }

  upArrow(idx: number): boolean {
    return idx > 0 && this.editStatus.length == 0;
  }
  downArrow(idx: number): boolean {
    return idx < this.people.length - 1 && this.editStatus.length == 0;
  }

  move(idx: number, x: number) {
    let jdx = idx + x;
    let p = this.people[idx];
    let q = this.people[jdx];
    this.$set(this.people, idx, q);
    this.$set(this.people, jdx, p);
    this.update()
  }

  deletePerson(idx: number) {
    this.people.splice(idx, 1);
    this.setEditStatus(idx,false);
    this.update()
  }

  modalAdd(x: Array<Person>) {
    if(this.checkError(this.people[this.people.length - 1])){
      this.people.pop()
    }
    this.people = this.people.concat(x);
    this.update()
  }

  setEditStatus(idx: number, x : boolean){
    let k = this.editStatus.indexOf(idx)
    if(x && k == -1){
        this.editStatus.push(idx)
    }else if(k>-1){
        this.editStatus.splice(k,1)
    }
  }

  get lookUpDisabled():boolean{
    for(let person of this.existingPeople){
      if(!this.people.find((x) => {return x.id == person.id})){
        return false
      }
    }
    return true
  }

  get existingPeople(): Array<Person>{
    if(this.peopleDefault){
      console.log("PEOPLEPEOPEO DEFAULT")
      console.log(this.peopleDefault)
      return this.peopleDefault
    }else if(this.label === "Breeder"){
      return DogStore.breeders
    }else if(this.label === "Owner"){
      return DogStore.owners
    }else{
      return []
    }
  }

  get error(): boolean {
    for (let person of this.people) {
      if (this.checkError(person)) {
        return true;
      }
    }
    return false;
  }

  checkError(person: Person): boolean {
    if (
      person.id == null && (
      person.firstName.trim().length == 0 ||
      person.lastName.trim().length == 0 )
    ) {
      return true;
    } else {
      return false;
    }
  }

  update(){
    this.$emit("input",this.people)
  }

  @Watch("people")
  onPeopleChange(people : Array<Person>){
    if(people.length == 0){
      this.$nextTick(() => {this.addPerson()})
    }
  }

  @Watch("value")
  onValueChange(x : Array<Person> | undefined){
    if(x){
      this.people = x
    }
  }

  validate():Array<string>{
    if(this.error){
      return ["Incomplete " + this.label + " information."]
    }
    return []
  }
}
