import { Breed, Color, Dog, DogInterface, Group, RegistrationNumber, RegistrationNumberInterface, Sex, Variety } from './dog'
import { User, UserInterface } from './user'
import { Address, AddressInterface, Person, PersonInterface } from './people'
import { DogName, DogNameInterface } from './dog_titles'
import { DogClassType } from './classes'
import { Entry, EventClass, ShowEntry } from './entries'


export enum ShowType {
    SPECIALTY = 'SPECIALTY',
    GROUP = 'GROUP',
    ALLBREED = 'ALLBREED',
    FSS = 'FSS',
    OTHER = 'OTHER'
}


export class Show {
    public static showFromJSON(x: ShowInterface): Show {

        let breed: Breed | undefined = undefined
        if (x.breed) {
            breed = Dog.findBreed(x.breed)
        }
        let group: Group | undefined = undefined
        if (x.group) {
            group = Dog.groups.find((y) => { return y.id == x.group })
        }
        return new Show(x.id, x.name, x.type, DogEvent.dogEventArrayFromJSON(x.events), Address.addressFromJSON(x.address), x.catalog, new Date(x.openingTime), new Date(x.closingTime), x.title, x.abbr, x.checkPayableTo, Address.addressFromJSON(x.checkAddress), x.checkRecipient, x.secretaryFee, x.surcharge, x.suspended, x.published, x.subtitle, x.webAddress, breed, group, x.note, x.eventGroups)
    }

    public static showArrayFromJSON(x: Array<ShowInterface>): Array<Show> {
        return x.map((u) => { return Show.showFromJSON(u) })
    }

    public static newShowInterface():ShowInterface{
        return {
            id: -1,
            name: "",
            type: ShowType.OTHER,
            events: [],
            address: new Address().exportInterface(),
            secretary: { id: -1, firstName: "", lastName: "", email: "", phone: null },
            openingTime: -1,
            closingTime: -1,
            catalog: { canPurchase: false, fee: 0 },
            title: "",
            subtitle: "",
            abbr: "",
            checkPayableTo: "",
            checkAddress: new Address().exportInterface(),
            checkRecipient: "",
            secretaryFee: 0,
            surcharge: false,
            suspended: false,
            published: false,
          };
    }


    private _id: number | null
    private _name: string
    private _type: ShowType
    private _events: Array<DogEvent>
    private _address: Address
    private _catalog: { canPurchase: boolean, fee: number }
    private _secretary: User = new User()
    private _openingTime: Date
    private _closingTime: Date
    private _title: string
    private _abbr: string
    private _subtitle?: string
    private _webAddress?: string
    private _breed?: Breed
    private _group?: Group
    private _checkPayableTo: string
    private _checkAddress: Address
    private _checkRecipient: string
    private _secretaryFee: number
    private _surcharge: boolean
    private _note?: string
    private _eventGroups: Array<Array<DogEvent>>
    private _suspended: boolean
    private _published: boolean


    get id(): number | null {
        return this._id
    }

    get name(): string {
        return this._name
    }
    set name(x: string) {
        this._name = x
    }
    get type(): ShowType {
        return this._type
    }
    set type(x: ShowType) {
        this._type = x
    }
    get events(): Array<DogEvent> {
        return this._events
    }
    set events(x: Array<DogEvent>) {
        this._events = x
    }
    get address(): Address {
        return this._address
    }
    set address(x: Address) {
        this._address = x
    }

    get catalog(): { canPurchase: boolean, fee: number } {
        return this._catalog
    }

    get secretary(): User {
        return this._secretary
    }

    get closingTime(): Date {
        return this._closingTime
    }

    get openingTime(): Date {
        return this._openingTime
    }

    get title(): string {
        return this._title
    }
    set title(x: string) {
        this._title = x
    }

    get subtitle(): string | undefined {
        return this._subtitle
    }
    set subtitle(x: string | undefined) {
        this._subtitle = x
    }

    get abbr(): string {
        return this._abbr
    }
    set abbr(x: string) {
        this._abbr = x
    }

    get webAddress(): string | undefined {
        return this._webAddress
    }
    set webAddress(x: string | undefined) {
        this._webAddress = x
    }

    get breed(): Breed | undefined {
        return this._breed
    }
    set breed(x: Breed | undefined) {
        if (this._type == ShowType.SPECIALTY) {
            this._breed = x
        }
    }

    get group(): Group | undefined {
        return this._group
    }
    set group(x: Group | undefined) {
        if (this._type == ShowType.GROUP) {
            this._group = x
        }
    }

    get checkPayableTo(): string {
        return this._checkPayableTo
    }
    set checkPayableTo(x: string) {
        this._checkPayableTo = x
    }

    get checkAddress(): Address {
        return this._checkAddress
    }
    set checkAddress(x: Address) {
        this._checkAddress = x
    }

    get checkRecipient(): string {
        return this._checkRecipient
    }
    set checkRecipient(x: string) {
        this._checkRecipient = x
    }

    get secretaryFee(): number {
        return this._secretaryFee
    }
    set secretaryFee(x: number) {
        this._secretaryFee = x
    }

    get surcharge(): boolean {
        return this._surcharge
    }
    set surcharge(x: boolean) {
        this._surcharge = x
    }

    get note(): string | undefined {
        return this._note
    }
    set note(x: string | undefined) {
        this._note = x
    }

    get eventGroups(): Array<Array<DogEvent>> {
        return this._eventGroups
    }
    set eventGroups(x: Array<Array<DogEvent>>) {
        this._eventGroups = x
    }

    get suspended(): boolean {
        return this._suspended
    }
    //Do not set suspended
    get published(): boolean {
        return this._published
    }
    //Do not set published



    constructor(id: number, name: string, type: ShowType, events: Array<DogEvent>, address: Address, catalog: { canPurchase: boolean, fee: number }, openingTime: Date, closingTime: Date, title: string, abbr: string, checkPayableTo: string, checkAddress: Address, checkRecipient: string, secretaryFee: number, surcharge: boolean, suspended: boolean, published: boolean, subtitle?: string, webAddress?: string, breed?: Breed, group?: Group, note?: string, eventGroups?: Array<Array<number>>) {
        this._id = id
        this._name = name
        this._type = type
        this._events = events
        this._address = address
        this._catalog = catalog
        this._openingTime = openingTime
        this._closingTime = closingTime
        this._title = title
        this._abbr = abbr
        this._checkPayableTo = checkPayableTo
        this._checkAddress = checkAddress
        this._checkRecipient = checkRecipient
        this._secretaryFee = secretaryFee
        this._surcharge = surcharge
        this._suspended = suspended
        this._published = published
        if (subtitle) {
            this._subtitle = subtitle
        }
        if (webAddress) {
            this._webAddress = webAddress
        }
        if (breed) {
            this._breed = breed
        }
        if (group) {
            this._group = group
        }
        if (note) {
            this._note = note
        }
        if (eventGroups) {
            let newEventGroups: Array<Array<DogEvent>> = []
            for (let eventGroup of eventGroups) {
                let newEventGroup: Array<DogEvent> = this._events.filter((x) => { return eventGroup.find((y) => { return x.id === y }) })
                newEventGroups.push(newEventGroup)
            }
            this._eventGroups = newEventGroups
        } else {
            this._eventGroups = this._events.map((x) => { return [x] })
        }

        this._eventGroups.sort((x, y) => { return (DogEvent.minTime(x) > DogEvent.minTime(y) ? 1 : -1) })
        this._eventGroups = this._eventGroups.map((x) => { return x.sort((a, b) => { return (a.startDate.getTime() > b.startDate.getTime() ? 1 : -1) }) })
        this._eventGroups = this._eventGroups.map((x) => { return x.sort((a, b) => { return (DogEvent.eventTypeOrder(a.type) > DogEvent.eventTypeOrder(b.type) ? 1 : -1) }) })
    }


    public printOpeningTime(): string {
        return this._openingTime.toLocaleString('en-US', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' })
    }

    public printClosingTime(): string {
        return this._closingTime.toLocaleString('en-US', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' })
    }

    public printClosingTimeUTC(): string {
        return this._closingTime.toLocaleString("en-US", { timeZone: "UTC", day: "numeric", month: "numeric", hour: "numeric", minute: "numeric" })
    }

    public printShowDateRange(): string {
        const options: Intl.DateTimeFormatOptions = { weekday: "long", day: "numeric", month: "long", year: "numeric", timeZone: "UTC" }
        if (this.events.length == 0) {
            throw "ShowDog: No events to print date range."
        } else {
            let minDate = this.events[0].startDate
            let maxDate = this.events[0].endDate
            for (let event of this.events) {
                if (event.startDate < minDate) {
                    minDate = event.startDate
                }
                if (event.endDate > maxDate) {
                    maxDate = event.endDate
                }
            }
            if (minDate < maxDate) {
                return minDate.toLocaleString('en-US', options) + " - " + maxDate.toLocaleString('en-US', options)
            } else {
                return minDate.toLocaleString('en-US', options)
            }
        }
    }

    public getEventGroup(x: DogEvent): Array<DogEvent> {
        let out: Array<DogEvent> | undefined = this._eventGroups.find((y) => { return y.find((z) => { z.id === x.id }) })
        if (!out) {
            throw "Show: event not foudn in any event group."
        }
        return out
    }

    public exportInterface():ShowInterface{
        let out : ShowInterface = {
            id : ( this._id !== null ? this._id : -1),
            name : this._name,
            type : this._type,
            events : this._events.map((x) =>{return x.exportInterface()}),
            address : this._address.exportInterface(),
            catalog : this._catalog,
            secretary : this._secretary,
            openingTime : this._openingTime.getTime(),
            closingTime : this._closingTime.getTime(),
            title : this._title,
            abbr : this._abbr,
            subtitle : this._subtitle,
            webAddress : this._webAddress,
            breed : (this._breed ? this._breed.id : undefined),
            group : (this._group ? this._group.id : undefined),
            checkPayableTo : this._checkPayableTo,
            checkAddress : this._checkAddress.exportInterface(),
            checkRecipient : this._checkRecipient,
            secretaryFee : this._secretaryFee,
            surcharge : this._surcharge,
            eventGroups : this._eventGroups.map((x) =>{return x.map((y)=>{return ( y.id !== null ? y.id : -1)})}),
            suspended : this._suspended,
            published : this._published
        }
        return out
    }

}

export interface ShowInterface {
    id: number
    name: string
    type: ShowType
    events: Array<DogEventInterface>
    address: AddressInterface
    secretary: UserInterface
    catalog: { canPurchase: boolean, fee: number }
    openingTime: number
    closingTime: number
    title: string
    abbr: string
    checkPayableTo: string
    checkAddress: AddressInterface
    checkRecipient: string
    secretaryFee: number
    surcharge: boolean
    suspended: boolean
    published: boolean
    subtitle?: string
    webAddress?: string
    breed?: number
    group?: number
    note?: string
    eventGroups?: Array<Array<number>>
}

export enum DogEventType {
    CONFORMATION = 'CONFORMATION',
    SWEEPSTAKES = 'SWEEPSTAKES',
    OBEDIENCE = 'OBEDIENCE',
    RALLY = 'RALLY',
    FUTURITY_MATURITY = 'FUTURITY_MATURITY',
    BEGINNER_PUPPY = 'BEGINNER_PUPPY',
    OTHER = 'OTHER'
}

export class DogEvent {
    static dogEventFromJSON(x: DogEventInterface): DogEvent {
        return new DogEvent(x.id, new Date(x.startDate), new Date(x.endDate), x.AKCNumber, x.type)
    }

    static dogEventArrayFromJSON(x: Array<DogEventInterface>): Array<DogEvent> {
        return x.map((u) => { return DogEvent.dogEventFromJSON(u) })
    }

    public static eventTypeOrder(x: DogEventType): number {
        if (x === DogEventType.CONFORMATION) {
            return 0
        } else if (x === DogEventType.BEGINNER_PUPPY) {
            return 1
        } else if (x === DogEventType.SWEEPSTAKES) {
            return 2
        } else if (x === DogEventType.FUTURITY_MATURITY) {
            return 3
        } else if (x === DogEventType.OBEDIENCE) {
            return 4
        } else if (x === DogEventType.RALLY) {
            return 5
        } else {
            return 6
        }
    }

    public static printEventGroupDateRange(x: Array<DogEvent>): string {
        let minTime: number = x[0].startDate.getTime()
        let maxTime: number = minTime
        for (let event of x) {
            if (event.startDate.getTime() < minTime) {
                minTime = event.startDate.getTime()
            }
            if (event.endDate.getTime() > maxTime) {
                maxTime = event.endDate.getTime()
            }
        }
        if (minTime < maxTime) {
            return new Date(minTime).toLocaleString('en-US', { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' }) + " - " + new Date(maxTime).toLocaleString('en-US', { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' })
        } else {
            return new Date(minTime).toLocaleString('en-US', { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' })
        }
    }

    public static minTime(x: Array<DogEvent>): number {
        let minTime: number = x[0].startDate.getTime()
        for (let event of x) {
            if (event.startDate.getTime() < minTime) {
                minTime = event.startDate.getTime()
            }
        }
        return minTime
    }

    public static maxTime(x: Array<DogEvent>): number {
        let maxTime: number = x[0].endDate.getTime()
        for (let event of x) {
            if (event.endDate.getTime() < maxTime) {
                maxTime = event.endDate.getTime()
            }
        }
        return maxTime
    }

    public static printEventType(x: DogEventType) {
        let out: string = x
        out = out.split("_").join(" ").toLowerCase()
        out = out[0].toUpperCase() + out.slice(1)
        return out
    }

    private _id: number | null
    private _startDate: Date
    private _endDate: Date
    private _AKCNumber: string | null
    private _type: DogEventType

    public get id(): number | null {
        return this._id
    }

    public get startDate(): Date {
        return this._startDate
    }
    public set startDate(x: Date) {
        this._startDate = x
    }
    public get endDate(): Date {
        return this._endDate
    }
    public set endDate(x: Date) {
        this._endDate = x
    }
    public get AKCNumber(): string | null {
        return this._AKCNumber
    }
    public set AKCNumber(x: string | null) {
        this._AKCNumber = x
    }
    public get type(): DogEventType {
        return this._type
    }
    public set type(x: DogEventType) {
        this._type = x
    }

    constructor(id: number, startDate: Date, endDate: Date, AKCNumber: string | null, type: DogEventType) {
        this._id = id
        this._startDate = startDate
        this._endDate = endDate
        this._AKCNumber = AKCNumber
        this._type = type
    }

    private printDate(x: Date): string {
        return x.toLocaleString('en-US', { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' })
    }

    private printShortDate(x: Date): string {
        return x.toLocaleString('en-US', { timeZone: 'UTC', month: 'numeric', day: 'numeric', year: 'numeric' })
    }

    public printStartDate(): string {
        return this.printDate(this._startDate)
    }

    public printEndDate(): string {
        return this.printDate(this._endDate)
    }

    public printShortStartDate(): string {
        return this.printShortDate(this._startDate)
    }

    public printShortEndDate(): string {
        return this.printShortDate(this._endDate)
    }

    public printDateRange(): string {
        if (this._startDate.getTime() < this._endDate.getTime()) {
            return this.printStartDate() + " - " + this.printEndDate()
        } else {
            return this.printStartDate()
        }
    }

    public getDayShort():string{
        let weekday = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
        return weekday[this._startDate.getUTCDay()]
    }

    public getDay():string{
        let weekday = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
        return weekday[this._startDate.getUTCDay()]
    }

    public printShortDateRange(): string {
        if (this._startDate.getTime() < this._endDate.getTime()) {
            return this.printShortStartDate() + " - " + this.printShortEndDate()
        } else {
            return this.printShortStartDate()
        }
    }

    public get eventTypeAndNumber(): string {
        return this.eventType + (this._AKCNumber ? " (" + this._AKCNumber + ") " : "")
    }

    public get eventType(): string {
        let out: string = this._type
        out = out.split("_").join(" ").toLowerCase()
        out = out[0].toUpperCase() + out.slice(1)
        return out
    }

    public exportInterface(): DogEventInterface {
        let out: DogEventInterface = {
            id: (this._id !== null ? this._id : -1),
            startDate: this._startDate.toISOString().substr(0, 10),
            endDate: this._endDate.toISOString().substr(0, 10),
            AKCNumber: this._AKCNumber,
            type: this._type
        }
        return out
    }
}

export interface DogEventInterface {
    id: number
    startDate: string
    endDate: string
    AKCNumber: string | null
    type: DogEventType
}

export class ShowDog {
    public static dogFromJSON(showDog: ShowDogBaseInterface, user: User | null): Dog {
        let name: DogName = DogName.dogNameFromJSON(showDog.name)
        let sire: DogName = DogName.dogNameFromJSON(showDog.sire)
        let dam: DogName = DogName.dogNameFromJSON(showDog.dam)
        let birthDate: Date = new Date(showDog.birthDate)
        let breed: Breed = Dog.findBreed(showDog.breed)
        let variety: Variety | null = Dog.findVariety(showDog.variety)
        let color: Color = Dog.findColor(showDog.color)
        let sex: Sex = showDog.sex
        let registrationNumber = RegistrationNumber.registrationNumberFromJSON(showDog.regNumber)
        let breeders: Array<Person> = showDog.breeders.map((x) => { return new Person(null, x) })
        let owners: Array<Person> = showDog.owners.map((x) => { return new Person(null, x) })
        let address: Address = Address.addressFromJSON(showDog.address)
        let dog: Dog = new Dog(showDog.dog, user, name, sire, dam, breed, variety, color, sex, birthDate, showDog.birthPlace, registrationNumber, breeders, owners, address)
        return dog
    }
    public static showDogFromJSON(showDogJSON: ShowDogInterface, users: Array<User>, dogs?: Array<Dog>): ShowDog {
        let user: User | undefined = users.find((x) => { return x.id == showDogJSON.user })
        // if (!user) {
        //     console.error("ShowDog: User not found.")
        //     throw "ShowDog: User not found."
        // }
        let dog: Dog | undefined = undefined
        if (dogs) {
            dog = dogs.find((x) => { return x.id == showDogJSON.dog })
        }
        let showDog: Dog = ShowDog.dogFromJSON(showDogJSON.showDog, (user ? user : null))

        if (dog) {
            return new ShowDog(showDogJSON.id, showDog, dog, user)
        } else {
            return new ShowDog(showDogJSON.id, showDog, undefined, (user ? user : null))
        }


    }

    public static showDogArrayFromJSON(showDogJSON: Array<ShowDogInterface>, users: Array<User>, dogs?: Array<Dog>): Array<ShowDog> {
        if (dogs) {
            return showDogJSON.map((x) => { return ShowDog.showDogFromJSON(x, users, dogs) })
        } else {
            return showDogJSON.map((x) => { return ShowDog.showDogFromJSON(x, users) })
        }

    }

    private _dog?: Dog
    private _showDog: { id: number, dog: Dog }
    private _user: User | null

    get dog(): Dog {
        if (!this._dog) {
            throw "ShowDog: Dog is not defined. Dog should only be accessed in exhibitor mode."
        }
        return this._dog
    }
    //do not set dog
    get showDog(): { id: number, dog: Dog } {
        return this._showDog
    }

    get user(): User | null {
        return this._user
    }

    //do not set time
    constructor(id: number, showDog: Dog)
    constructor(id: number, showDog: Dog, dog: Dog | undefined, user?: User | null)
    constructor(id: number, showDog: Dog, dog?: Dog | undefined, user?: User | null,) {
        if (dog) {
            this._dog = dog
        }
        this._showDog = { id: id, dog: showDog }
        this._user = user || null
    }
}

export interface ShowDogInterface {
    user: number | null,
    dog?: number,
    showDog: ShowDogBaseInterface,
    id: number
}

export interface ShowDogBaseInterface {
    dog: number | null,
    name: DogNameInterface,
    sire: DogNameInterface,
    dam: DogNameInterface,
    breed: number,
    variety: number,
    color: number,
    sex: Sex,
    regNumber: RegistrationNumberInterface,
    birthDate: string,
    birthPlace: string,
    owners: Array<string>,
    breeders: Array<string>,
    address: AddressInterface
}


export interface ShowDogEditInterface {
    showDog: number,
    dog: ShowDogBaseInterface,
    time: number
}




export class Discount {
    _requisiteClasses: Array<DogClassType>
    _applicableClasses: Array<DogClassType>
    _eventGroup: Array<DogEvent>
    _feeStructure: Array<{ number: number, fee: number }>

    // public static discountFromJSON(x : DiscountInterface):Discount{
    //     return new Discount()
    // }

    get requisiteClasses(): Array<DogClassType> {
        return this._requisiteClasses
    }
    set requisiteClasses(x: Array<DogClassType>) {
        this._requisiteClasses = x
    }

    get applicableClasses(): Array<DogClassType> {
        return this._applicableClasses
    }
    set applicableClasses(x: Array<DogClassType>) {
        this._applicableClasses = x
    }

    get eventGroup(): Array<DogEvent> {
        return this._eventGroup
    }
    //Do not set event group

    get feeStructure(): Array<{ number: number, fee: number }> {
        return this._feeStructure
    }
    set feeStructure(x: Array<{ number: number, fee: number }>) {
        this._feeStructure = x
    }

    constructor(eventGroup: Array<DogEvent>)
    constructor(eventGroup: Array<DogEvent>, requisiteClasses: Array<DogClassType>, applicableClasses: Array<DogClassType>, feeStructure: Array<{ number: number, fee: number }>)
    constructor(eventGroup: Array<DogEvent>, requisiteClasses?: Array<DogClassType>, applicableClasses?: Array<DogClassType>, feeStructure?: Array<{ number: number, fee: number }>) {
        this._eventGroup = eventGroup
        this._requisiteClasses = (requisiteClasses ? requisiteClasses : [])
        this._applicableClasses = (applicableClasses ? applicableClasses : [])
        this._feeStructure = (feeStructure ? feeStructure : [])
    }

    discountedEntries(x: Array<Entry>): Array<DiscountedEntry> {
        for (let entry of x) {
            if (!this._eventGroup.find((y) => { return y.id == entry.event.id })) {
                throw "Discount : Entry not in event group."
            }
        }
        let conditionMet: boolean = true
        for (let classType of this._requisiteClasses) {
            if (!x.find((y) => { return y.classes.find((z) => { return z.dogClass.type == classType }) })) {
                conditionMet = false
            }
        }
        let appliedClasses: Array<EventClass> = []
        if (conditionMet) {
            for (let entry of x) {
                for (let eventClass of entry.classes) {
                    if (this._applicableClasses.find((y) => { return eventClass.dogClass.type == y })) {
                        appliedClasses.push(eventClass)
                    }
                }
            }
            appliedClasses = appliedClasses.sort((y, z) => { return z.fee - y.fee })
        }

        let fees: Array<Fee> = []
        for (let k = 0; k < appliedClasses.length; k++) {
            let eventClass: EventClass = appliedClasses[k]
            let feeStructure: { number: number, fee: number } | undefined = this._feeStructure.find((y) => { return y.number < k + 1 })
            if (feeStructure) {
                let fee: Fee = new Fee(feeStructure.fee, eventClass)
                fees.push(fee)
            } else {
                let fee: Fee = new Fee(eventClass.fee, eventClass)
                fees.push(fee)
            }
        }

        let out : Array<DiscountedEntry> = []
        for (let entry of x) {
            let discountedFees: Array<Fee> = []
            for (let eventClass of entry.classes) {
                let fee : Fee | undefined = fees.find((y) => {return y.eventClass.id == eventClass.id})
                if(!fee){
                    fee = new Fee(eventClass.fee,eventClass)
                }
                discountedFees.push(fee)
            }
            out.push(new DiscountedEntry(entry,discountedFees))
        }
        return out
    }

    discountedShowEntry(showEntry : ShowEntry): DiscountedShowEntry{
        let discountedEntries : Array<DiscountedEntry> = []
        for(let eventGroup of showEntry.show.eventGroups){
            let entriesInEventGroup : Array<Entry> = showEntry.entries.filter((y)=>{return eventGroup.find((z)=>{return y.event.id == z.id})})
            discountedEntries = discountedEntries.concat(this.discountedEntries(entriesInEventGroup))
        }
        return new DiscountedShowEntry(showEntry,discountedEntries)
    }
}

export interface DiscountInterface {
    requisiteClasses: Array<DogClassType>
    applicableClasses: Array<DogClassType>
    eventGroup: number
    feeStructure: Array<{ number: number, fee: number }>
}

// export class ShowDiscount{
//     private _show : Show

// }

export class DiscountedEntry {
    private _entry: Entry
    private _discountedFees: Array<Fee>

    get entry(): Entry{
        return this._entry
    }
    get discountedFees(): Array<Fee>{
        return this._discountedFees
    }

    constructor(entry: Entry, discountedFees: Array<Fee>) {
        this._entry = entry
        this._discountedFees = discountedFees
    }
}

export class DiscountedShowEntry{
    private _showEntry : ShowEntry
    private _discountedEntries : Array<DiscountedEntry>

    get showEntry(): ShowEntry{
        return this._showEntry
    }
    get discountedEntries(): Array<DiscountedEntry>{
        return this._discountedEntries
    }

    constructor(showEntry : ShowEntry, discountedEntries : Array<DiscountedEntry>){
        this._showEntry = showEntry
        this._discountedEntries = discountedEntries
    }
}

export class Fee {
    private _eventClass: EventClass
    private _amount: number

    get eventClass(): EventClass {
        return this._eventClass
    }
    get amount(): number {
        return this._amount
    }

    constructor(amount: number, eventClass: EventClass) {
        this._amount = amount
        this._eventClass = eventClass
    }

    get originalFee(): number {
        return this._eventClass.fee
    }

    get discounted(): boolean {
        return this._eventClass.fee !== this._amount
    }
}


