var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',[_c('v-select',{attrs:{"items":_vm.showTypes,"label":"Show Type"},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.showType),callback:function ($$v) {_vm.showType=$$v},expression:"showType"}}),_c('v-expand-transition',[_c('fuzzy-autocomplete',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBreeds),expression:"showBreeds"}],attrs:{"label":"Breed","id":"form-breed","items":_vm.breeds,"string-extract":_vm.breedExtract,"min-search-length":3},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.breed),callback:function ($$v) {_vm.breed=$$v},expression:"breed"}})],1),_c('v-expand-transition',[_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.showGroups),expression:"showGroups"}],attrs:{"label":"Group","items":_vm.groups,"item-text":(x) => {
            return x.group;
          }},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}})],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Show name"},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.showName),callback:function ($$v) {_vm.showName=$$v},expression:"showName"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Show website (optinonal)","error-messages":_vm.webAddressError},on:{"change":function($event){return _vm.testWebAddress()}},model:{value:(_vm.showWebsite),callback:function ($$v) {_vm.showWebsite=$$v},expression:"showWebsite"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-menu',{ref:"openingDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.openingDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.openingDate=$event},"update:return-value":function($event){_vm.openingDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date entries open","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.openingDate),callback:function ($$v) {_vm.openingDate=$$v},expression:"openingDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.openingDateMenu),callback:function ($$v) {_vm.openingDateMenu=$$v},expression:"openingDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.$refs.openingDateMenu.save(_vm.openingDate);
              _vm.update();}},model:{value:(_vm.openingDate),callback:function ($$v) {_vm.openingDate=$$v},expression:"openingDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.openingDateMenu = false}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('v-col',[_c('v-menu',{ref:"openingTimeMenu",attrs:{"close-on-content-click":false,"return-value":_vm.openingTime,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.openingTime=$event},"update:return-value":function($event){_vm.openingTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Time entries open","prepend-icon":"schedule","readonly":""},model:{value:(_vm.openingTime),callback:function ($$v) {_vm.openingTime=$$v},expression:"openingTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.openingTimeMenu),callback:function ($$v) {_vm.openingTimeMenu=$$v},expression:"openingTimeMenu"}},[(_vm.openingTimeMenu)?_c('v-time-picker',{attrs:{"allowed-minutes":[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]},on:{"click:minute":function($event){return _vm.$refs.openingTimeMenu.save(_vm.openingTime)},"change":function($event){return _vm.update()}},model:{value:(_vm.openingTime),callback:function ($$v) {_vm.openingTime=$$v},expression:"openingTime"}}):_vm._e()],1)],1),_c('v-col',[_c('v-select',{attrs:{"label":"Time Zone","items":_vm.timeZones,"item-text":(x) => {
              return x.name;
            },"return-object":"","prepend-icon":"public"},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.openTimeZone),callback:function ($$v) {_vm.openTimeZone=$$v},expression:"openTimeZone"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-menu',{ref:"closingDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.closingDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.closingDate=$event},"update:return-value":function($event){_vm.closingDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date entries close","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.closingDate),callback:function ($$v) {_vm.closingDate=$$v},expression:"closingDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.closingDateMenu),callback:function ($$v) {_vm.closingDateMenu=$$v},expression:"closingDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.$refs.closingDateMenu.save(_vm.closingDate);
              _vm.update();}},model:{value:(_vm.closingDate),callback:function ($$v) {_vm.closingDate=$$v},expression:"closingDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.closingDateMenu = false}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('v-col',[_c('v-menu',{ref:"closingTimeMenu",attrs:{"close-on-content-click":false,"return-value":_vm.closingTime,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.closingTime=$event},"update:return-value":function($event){_vm.closingTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Time entries close","prepend-icon":"schedule","readonly":""},model:{value:(_vm.closingTime),callback:function ($$v) {_vm.closingTime=$$v},expression:"closingTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.closingTimeMenu),callback:function ($$v) {_vm.closingTimeMenu=$$v},expression:"closingTimeMenu"}},[(_vm.closingTimeMenu)?_c('v-time-picker',{attrs:{"allowed-minutes":[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]},on:{"click:minute":function($event){return _vm.$refs.closingTimeMenu.save(_vm.closingTime)},"change":function($event){return _vm.update()}},model:{value:(_vm.closingTime),callback:function ($$v) {_vm.closingTime=$$v},expression:"closingTime"}}):_vm._e()],1)],1),_c('v-col',[_c('v-select',{attrs:{"label":"Time Zone","items":_vm.timeZones,"item-text":(x) => {
              return x.name;
            },"return-object":"","prepend-icon":"public"},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.closeTimeZone),callback:function ($$v) {_vm.closeTimeZone=$$v},expression:"closeTimeZone"}})],1)],1),_c('dog-address',{ref:"showAddress",attrs:{"label":"Show Address","international":false,"addresses":_vm.addresses},on:{"change":function($event){return _vm.update()},"input":function($event){return _vm.update()}},model:{value:(_vm.showAddress),callback:function ($$v) {_vm.showAddress=$$v},expression:"showAddress"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }