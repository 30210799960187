
import { Component, Vue } from "vue-property-decorator";
import SecretaryEntryStore from "../store/modules/secretary_entries";
import SecretaryStore from "../store/modules/secretary";
import SecretaryCatalogStore from "../store/modules/secretary_catalog";
import CatalogWinners from "../components/Catalog/CatalogWinners.vue";
import CatalogBestOfBreed from "../components/Catalog/CatalogBestOfBreed.vue";
import CatalogBestInVeterans from "../components/Catalog/CatalogBestInVeterans.vue";
import CatalogBestInJunior from "../components/Catalog/CatalogBestInJunior.vue";
import {
  Address,
  CatalogOrder,
  DogCatalog,
  DogEvent,
  EventClass,
  Judge,
  Person,
  SecretaryShowDataRequest,
  SecretaryShowInterfaceRequest,
  Sex,
  Show,
  ShowDog,
} from "@app/common";
import CatalogOrdering from "@/components/ShowManager/CatalogOrdering.vue";
import { DogClass, DogClassType } from "@app/common/classes";
@Component({
  components: {
    CatalogWinners,
    CatalogBestOfBreed,
    CatalogBestInVeterans,
    CatalogBestInJunior,
  },
})
export default class Catalog extends Vue {
  abbr: string = "";
  catalog: DogCatalog | undefined = undefined;

  // get entryBlocks(): Array<{
  //   events: Array<DogEvent>;
  //   classes: Array<{
  //     dogClass: DogClass;
  //     entries: Array<{ showDog: ShowDog; events: Array<DogEvent> }>;
  //   }>;
  // }> {
  //   if (this.catalog) {
  //     return this.catalog.entryBlocks;
  //   } else {
  //     return [];
  //   }
  // }

  entryBlocks: Array<{
    events: Array<DogEvent>;
    classes: Array<{
      dogClass: DogClass;
      entries: Array<{
        showDog: ShowDog;
        events: Array<DogEvent>;
        agent: string;
        hasAgent: boolean;
      }>;
      events: Array<DogEvent>;
    }>;
  }> = [];

  ownerAddresses: Array<{
    owners: Array<Person>;
    armbands: Array<number>;
    address: Address;
  }> = [];

  get show(): Show | undefined {
    return SecretaryStore.selectedShow;
  }
  async mounted(): Promise<void> {
    this.abbr = this.$route.params.abbr;
    let showPayload: SecretaryShowInterfaceRequest = { abbr: this.abbr };
    await SecretaryStore.fetchShow(showPayload);
    if (this.show && this.show.id && SecretaryCatalogStore.catalogOrder) {
      let payload: SecretaryShowDataRequest = {
        show: this.show.id,
      };
      await SecretaryStore.fetchEntries(payload);
      let ordering: CatalogOrder = new CatalogOrder([
        { events: [], classOrderings: [] },
      ]);
      this.catalog = new DogCatalog(
        SecretaryCatalogStore.catalogOrder,
        SecretaryStore.showDogs,
        SecretaryStore.showEntries,
        SecretaryStore.eventClasses,
        this.show
      );
      console.log("new catalog");
      console.log(this.catalog);
      this.entryBlocks = this.catalog.entryBlocks;
      this.ownerAddresses = this.catalog.ownerAddresses;
      console.log(this.entryBlocks);
      //this.catalog.entryBlocks[0].classes[0];
      this.entryBlocks[0].classes[0].entries[0].showDog.showDog.dog.printBreeders();
    }
  }

  armband(showDog: ShowDog): number {
    if (this.catalog) {
      let armband = this.catalog.armbands.find((x) => {
        return x.dog.showDog.id == showDog.showDog.id;
      });
      if (armband) {
        return armband.armband;
      }
    }
    return -1;
  }

  eventJudge(event: DogEvent, dogClass: DogClass): Judge {
    let eventClass: EventClass | undefined = SecretaryStore.eventClasses.find(
      (x) => {
        return x.event.id == event.id && x.dogClass.id == dogClass.id;
      }
    );
    if (eventClass) {
      return eventClass.judge;
    }
    return new Judge();
  }

  includesEvent(event: DogEvent, events: Array<DogEvent>): boolean {
    let idx = events.findIndex((x) => {
      return x.id == event.id;
    });
    return idx > -1;
  }

  eventClassTotal(
    event: DogEvent,
    entries: Array<{ showDog: ShowDog; events: Array<DogEvent> }>
  ): number {
    let total = 0;
    console.log("eventClassTotal");
    console.log(entries);
    for (let entry of entries) {
      if (
        entry.events.find((x) => {
          return x.id == event.id;
        })
      ) {
        total += 1;
      }
    }
    return total;
  }

  switchSex(
    idx: number,
    entryBlock: {
      events: Array<DogEvent>;
      classes: Array<{
        dogClass: DogClass;
        entries: Array<{ showDog: ShowDog; events: Array<DogEvent> }>;
        events: Array<DogEvent>;
      }>;
    }
  ): boolean {
    return entryBlock.classes[idx].dogClass.name.startsWith("Open");
  }

  currentSex(
    idx: number,
    entryBlock: {
      events: Array<DogEvent>;
      classes: Array<{
        dogClass: DogClass;
        entries: Array<{ showDog: ShowDog; events: Array<DogEvent> }>;
        events: Array<DogEvent>;
      }>;
    }
  ): Sex {
    return entryBlock.classes[idx].dogClass.rules.ruleSet.sex || Sex.MALE;
  }

  isJunior(entryClass: {
    dogClass: DogClass;
    entries: Array<{ showDog: ShowDog; events: Array<DogEvent> }>;
    events: Array<DogEvent>;
  }): boolean {
    console.log("isJunior");
    return entryClass.dogClass.type == DogClassType.CONFORMATION_JUNIOR;
  }

  getJunior(
    dogClass: DogClass,
    showDog: ShowDog
  ): { name: string; number: string } {
    let out: { name: string; number: string } = { name: "", number: "" };
    if (this.catalog) {
      for (let entry of this.catalog.entries) {
        if (entry.junior && entry.dog.showDog.id == showDog.showDog.id) {
          out.name = entry.junior.name;
          out.number = entry.junior.number;
        }
      }
    }
    return out;
  }
}
