
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import FuzzyAutocomplete from "@/components/FuzzyAutocomplete.vue";
import DogTextField from "./DogTextField.vue";
import DogPeopleList from "./DogPeopleList/DogPeopleList.vue";
import DogAddress from "./DogAddress/DogAddress.vue";
import DogBirthDate from "./DogBirthDate.vue";
import DogCountry from "./DogCountry.vue";
import DogNumber from "./DogNumber.vue";
import DogMF from "./DogMF.vue";
import {
  Breed,
  nullBreed,
  Dog,
  Variety,
  nullColor,
  Color,
  Sex,
  Address,
  Person,
} from "@app/common";
import BreedStore from "../../store/modules/breed";
import SecretaryStore from "../../store/modules/secretary"

@Component({
  components: {
    FuzzyAutocomplete,
    DogTextField,
    DogPeopleList,
    DogAddress,
    DogBirthDate,
    DogCountry,
    DogNumber,
    DogMF,
  },
})
export default class DogFormInfo extends Vue {
  @Prop() readonly value: Dog | undefined;
  @Prop({default: false}) readonly newDog!: boolean
  @Prop({default: false}) readonly secretary!:boolean
  name: string = "";
  breed: Breed | null = null;
  variety: Variety | null = null;
  varieties: Array<Variety> = [];
  color: Color | null = null;
  colors: Array<Color> = [];
  birthDate: Date | null = null;
  sex: Sex | null = null;

  dog: Dog = new Dog();

  sireName: string = "";
  damName: string = "";

  get breeds(): Array<Breed> {
    return BreedStore.breeds;
  }

  findVarieties(): Array<Variety> {
    let varieties: Array<Variety> = [];
    if (this.breed) {
      let breed: Breed = this.breed;
      let breedData = BreedStore.breedData.find((x) => {
        return x.breed.id == breed.id;
      });
      if (breedData) {
        for (let group of breedData.groups) {
          for (let variety of group.varieties) {
            if (variety.variety) {
              varieties.push(variety.variety);
            }
          }
        }
      }
    }
    return varieties;
  }

  findColors(): Array<Color> {
    let colors: Array<Color> = [];
    let breedData = BreedStore.breedData.find((x) => {
      return this.breed && x.breed.id == this.breed.id;
    });
    console.log("findColors");
    console.log(breedData);
    if (breedData) {
      console.log(this.varieties.length)
      if (this.varieties.length == 0) {
        //this.variety = null;
        colors = breedData.groups[0].varieties[0].colors;
      } else {
        for (let group of breedData.groups) {
          for (let variety of group.varieties) {
            if (variety.variety == this.variety) {
              colors = variety.colors;
              break;
            }
          }
          if (colors.length > 0) {
            break;
          }
        }
      }
    }
    return colors;
  }

  @Watch("breed")
  onBreedChange(breed: Breed) {
    if (breed) {
      this.dog.breed = breed;
      this.breedErrors = [];
      this.varietyErrors = [];
      this.colorErrors = [];
      this.update();
    }
    //this.dog.breed = breed
    this.variety = null;
    this.color = null;
    let varieties: Array<Variety> = [];
    let breedData = BreedStore.breedData.find((x) => {
      return x.breed.id == breed.id;
    });
    if (breedData) {
      for (let group of breedData.groups) {
        for (let variety of group.varieties) {
          if (variety.variety) {
            varieties.push(variety.variety);
          }
        }
      }
    }
    this.varieties = varieties;
    if (varieties.length == 0) {
      this.variety = null;
      if (breedData) {
        this.colors = breedData.groups[0].varieties[0].colors;
      }
    }
    console.log(this.colors);
  }

  breedExtractValue(x: any): Breed {
    return x;
  }

  @Watch("variety")
  onVarietyChange(x: Variety | null) {
    this.dog.variety = x;
    this.varietyErrors = [];
    this.colorErrors = [];
    if (this.dog.variety) {
      this.update();
    }
    this.color = nullColor;
    let colors: Array<Color> = [];
    let breedData = BreedStore.breedData.find((x) => {
      return this.breed && x.breed.id == this.breed.id;
    });
    if (breedData) {
      for (let group of breedData.groups) {
        for (let variety of group.varieties) {
          if (variety.variety == this.variety) {
            colors = variety.colors;
            break;
          }
        }
        if (colors.length > 0) {
          break;
        }
      }
    }
    this.colors = colors;
  }

  @Watch("color")
  onColorChange(x: Color | null) {
    console.log("color change watch");
    console.log(this.color);
    console.log(this.dog.color);
    console.log(x);
    console.log("color change watch end");
    if (x) {
      this.dog.color = x;
      this.colorErrors = [];
      this.update();
    }
  }
  // get varieties(): Array<Variety>{
  //     let varieties : Array<Variety> = []
  //     let breedData = BreedStore.breedData.find((x) =>{return x.breed.id == this.breed.id})
  //     if(breedData){
  //         for(let group of breedData.groups){
  //             for(let variety of group.varieties){
  //               if(variety.variety){
  //                 varieties.push(variety.variety)
  //               }
  //             }
  //         }
  //         console.log(varieties)
  //         return varieties
  //     }else{
  //         return []
  //     }
  // }
  breedExtract = function (x: Breed): string {
    return x.breed;
  };

  varietyExtract = function (x: Variety): string {
    return x.variety;
  };

  colorExtract = function (x: Color): string {
    return x.color;
  };

  // get colors(): Array<Color> {
  //   let colors: Array<Color> = [];
  //   let breedData = BreedStore.breedData.find((x) => {
  //     return x.breed.id == this.breed.id;
  //   });
  //   if (breedData) {
  //   } else {
  //     return [];
  //   }
  // }

  get colorMessages(): Array<string> {
    let out = [];
    if (!this.breed) {
      out.push("You must first select a breed.");
    }
    if (this.varieties.length > 0 && !this.variety) {
      out.push("You must first select a variety.");
    }
    return out;
  }

  scrollTo(x: string) {
    console.log("scrollTo");
    this.$vuetify.goTo(x);
  }

  birthPlace: string = "";

  update() {
    console.log("dog form update");
    console.log(this.dog);
    this.$emit("input", this.dog);
    console.log(this.color);
    if(this.newDog){
      window.localStorage.setItem("newDog",JSON.stringify(this.dog.exportAddDogInterface()))
    }
  }

  @Watch("dog")
  onDogChange() {
    console.log("Dog Changed!");
  }

  @Watch("sex")
  onSexChange(x : Sex){
    this.dog.sex = x
  }

  validate(): Array<string> {
    console.log("Test");
    let errors: Array<string> = [];
    console.log(this.breed);
    if (!this.breed) {
      this.breedErrors = ["You must select a breed."];
      errors = errors.concat(this.breedErrors);
    } else if (this.varieties.length > 0 && !this.variety) {
      this.varietyErrors = ["You must select a variety."];
      errors = errors.concat(this.varietyErrors);
    } else if (!this.color || this.color.id < 0) {
      this.colorErrors = ["You must select a color."];
      errors = errors.concat(this.colorErrors);
    }

    errors = errors.concat((this.$refs.dogBirthDate as any).validate());
    errors = errors.concat((this.$refs.dogMF as any).validate());
    errors = errors.concat((this.$refs.dogBirthPlace as any).validate());
    errors = errors.concat((this.$refs.dogName as any).validate());
    errors = errors.concat((this.$refs.dogSire as any).validate());
    errors = errors.concat((this.$refs.dogDam as any).validate());
    errors = errors.concat((this.$refs.dogNumber as any).validate());
    errors = errors.concat((this.$refs.dogOwner as any).validate());
    errors = errors.concat((this.$refs.dogBreeder as any).validate());
    errors = errors.concat((this.$refs.dogAddress as any).validate());
    return errors;
  }

  breedErrors: Array<string> = [];
  varietyErrors: Array<string> = [];
  colorErrors: Array<string> = [];
  // sexErrors : Array<string> = []
  // birthDateErrors : Array<string> = []
  // birthCountryErrors : Array<string> = []
  // dogNameErrors : Array<string> = []
  // sireNameErrors : Array<string> = []
  // damNameErrors : Array<string> = []
  // regNumErrors : Array<string> = []
  // breederErrors : Array<string> = []
  // ownerErrors : Array<string> = []

  @Watch("value")
  onValueChange(x: Dog | undefined) {
    if (x) {
      this.dog = x;
      this.color = null
      this.variety = null
      this.breed = this.dog.breed;
      this.varieties = this.findVarieties();
      if (this.dog.variety) {
        this.variety = this.dog.variety;
      }else{
        this.variety = null
      }
      console.log("mmmounted")
      //let colors = this.findColors()
      //this.colors = colors
      this.$nextTick(() => {this.color=this.dog.color})
      this.sex = x.sex
      //this.color = this.dog.color
      // console.log("Mounted");
      // console.log(this.breed);
      // console.log(this.variety);
      // console.log(this.colors);
      // console.log(this.color);
      // setTimeout(() => {
      //   this.color = null;

      //   console.log("color");
      //   console.log(this.colors);
      //   console.log(this.color);
      //   console.log(this.dog.color);
      // }, 3000);

      //this.color = this.dog.color
      //console.log(x.birthPlace);
      //this.dog.birthPlace = "United States";
    }
  }


  get secretaryAddresses():Array<Address> | undefined{
    if(!this.secretary){
      return undefined
    }else if(this.newDog){
      return SecretaryStore.manualAddresses
    }else if(!this.newDog){
      return [this.dog.address]
    }
  }

  get secretaryBreeders(): Array<Person> | undefined{
    if(!this.secretary){
      return undefined
    }else if(this.newDog){
      return SecretaryStore.manualBreeders
    }else if(!this.newDog){
      return this.dog.breeders.map((x,idx) => {return new Person(idx+1,x.name)})
    }
  }

  get secretaryOwners(): Array<Person> | undefined{
    if(!this.secretary){
      return undefined
    }else if(this.newDog){
      return SecretaryStore.manualOwners
    }else if(!this.newDog){
      return this.dog.owners.map((x,idx) => {return new Person(idx+1,x.name)})
    }
  }
}
