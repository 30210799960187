
import MultiPageSectionItem from "./MultiPageSectionItem.vue"
import { Component, Vue } from "vue-property-decorator";
@Component({components: {MultiPageSectionItem}})
export default class MultiPage extends Vue {
    titles : Array<string> = []
    subtitles : Array<Array<string>> = [[]]
    section : number = 0
    page : number = 0

    get title(): string{
        if(this.titles[this.section]){
            return this.titles[this.section]
        }
        return ""
    }
    get subtitle(): string{
        let out = this.subtitles[this.section][this.page]
        if(out){
            return out
        }
        return ""
    }

    mounted() {
        console.log("multi-page")
        console.log(this.$slots)
    }
}
