
import { DogName } from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DogTitle from "../DogTitle.vue";
@Component({components : {DogTitle}})
export default class DogNameConfirm extends Vue {
    @Prop({default : () => {return new DogName}}) readonly value! : DogName
    @Prop({default : ""}) readonly label! : string
    dogName : DogName = new DogName()
    modal : boolean = false

    mounted() {
      this.dogName = this.value   
    }
    @Watch("value")
    onValueChange(x : DogName){
        this.dogName = x
    }

    @Watch("dogName")
    onDogNameChange(x : DogName){
        this.$emit("input", x)
    }
}
