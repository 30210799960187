
import { DogEvent, Sex } from "@app/common";
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class CatalogBestInVeterans extends Vue {
  @Prop({
    default: () => {
      return [];
    },
  })
  events!: Array<DogEvent>;
}
