
import { Show } from "@app/common";
import { Component, Vue, Prop } from "vue-property-decorator";
import DogShow from "../components/DogShow.vue";
import fuzzySearch from "../services/fuzzySearch";

@Component({ components: { DogShow } })
export default class DogShowList extends Vue {
  @Prop({
    default: () => {
      return [];
    },
  })
  readonly shows!: Array<Show>;
  @Prop({ default: false }) readonly secretary!: boolean;
  search: string | null = null;

  showAllUser: boolean = false;

  get searching(): boolean {
    return this.search !== null && this.search.trim().length > 2;
  }

  get showAll(): boolean {
    return this.searching || this.showAllUser;
  }

  get filteredShows(): Array<Show> {
    if (this.searching && this.search !== null) {
      let showSearch = new fuzzySearch((x: Show) => {
        return x.name + (x.breed ? " " + x.breed : "");
      }, []);
      showSearch.input = this.shows;
      showSearch.search = this.search;
      return showSearch.output;
    } else {
      return this.shows;
    }
  }

  get showList(): Array<Show> {
    // if (this.showAll) {
    //   return this.filteredShows;
    // } else {
    //   let currentTime: number = new Date().getTime();
    //   return this.filteredShows.filter((x) => {
    //     return x.closingTime.getTime() + 1000 * 60 * 60 * 24 * 30 > currentTime;
    //   });
    // }
    if (this.searching) {
      return this.filteredShows;
    } else if (this.showAllUser) {
      return this.shows;
    } else {
      let currentTime: number = new Date().getTime();
      return this.shows.filter((x) => {
        return x.closingTime.getTime() + 1000 * 60 * 60 * 24 * 30 > currentTime;
      });
    }
  }

  get moreToShow(): boolean {
    return this.showList.length < this.shows.length;
  }
}
