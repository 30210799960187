
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import signup from "./components/Singup.vue";
import login from "./components/Login/Login.vue";
import UserStore from "./store/modules/user";
import BreedStore from "./store/modules/breed";
import ShowStore from "./store/modules/show";
import { Dog, Show, User, UserInfo } from "@app/common";

@Component({
  components: {
    signup,
    login,
  },
})
export default class App extends Vue {
  showLoginComponent = true;
  showSignupComponent = true;

  @Watch("loginModal")
  onLoginModalChange(val: boolean) {
    if (!val) {
      UserStore.setLoginError(undefined);
      setTimeout(() => {
        this.showLoginComponent = false;
        this.$nextTick(() => {
          this.showLoginComponent = true;
        });
      }, 300);
    }
  }

  @Watch("signupModal")
  onSingupModalChange(val: boolean) {
    if (!val) {
      setTimeout(() => {
        this.showSignupComponent = false;
        this.$nextTick(() => {
          this.showSignupComponent = true;
        });
      }, 300);
    }
  }

  get loginModal(): boolean {
    return UserStore.loginModal;
  }
  set loginModal(x: boolean) {
    UserStore.setLoginModal(x);
  }

  get loggedIn(): boolean {
    return UserStore.loggedIn;
  }

  get signupModal(): boolean {
    return UserStore.signupModal;
  }
  set signupModal(x: boolean) {
    UserStore.setSignupModal(x);
  }

  get errorModal(): boolean {
    return this.$store.state.errorModal;
  }
  set errorModal(x: boolean) {
    this.$store.state.errorModal = x;
  }

  get errorMsg(): string {
    return this.$store.state.errorMsg;
  }
  get errorCode(): string {
    return this.$store.state.errorCode;
  }

  get userInfo(): UserInfo {
    return UserStore.userInfo;
  }

  get selectedShow(): Show | undefined {
    return ShowStore.selectedShow;
  }

  logText = "Login";
  drawer = false;
  styles = [["black--text"], ["white--text"]];
  styleIdx = 1;
  states = [true, false];
  barColor = "transparent";
  barElevation = "0";
  showBarLogo = false;
  logLoading = false;
  loginErrorModal = false;

  onScroll() {
    if (window.scrollY > 0) {
      this.$set(this.states, 0, false);
      this.$set(this.states, 1, true);
      this.styleIdx = 0;
      this.barColor = "rgba(255,255,255,0.4)";
      this.barElevation = "3";
    } else {
      this.$set(this.states, 0, true);
      this.$set(this.states, 1, false);
      this.styleIdx = 1;
      this.barColor = "transparent";
      this.barElevation = "0";
    }
  }

  logoRoute() {
    let path = "/";
    if (this.$store.state.referrer) {
      path = "/show/" + this.$store.state.referrer;
    } else if (this.$store.state.loggedIn) {
      path = "/home";
    } else {
      path = "/";
    }
    this.$router.push(path);
  }

  signup() {
    this.signupModal = true;
  }

  log() {
    if (UserStore.loggedIn) {
      UserStore.logout();
    } else {
      this.loginModal = true;
    }
  }

  get loadingBreedsAndAuth(): boolean {
    return !BreedStore.breedDataFetched || !UserStore.authChecked;
  }

  async fetchBreedData() {
    await BreedStore.fetchBreedData();
    if (!BreedStore.breedDataFetched) {
      setTimeout(() => {
        this.fetchBreedData();
      }, 1000);
    }
  }

  async newCsrfToken() {
    await UserStore.newCsrfSecurityToken();
    if (!UserStore.newCsrfTokenSuccess) {
      setTimeout(() => {
        this.newCsrfToken();
      }, 1000);
    }
  }

  async beforeMount() {
    // while (!BreedStore.breedDataFetched) {
    //   await BreedStore.fetchBreedData();
    // }
    await this.fetchBreedData();
    await this.newCsrfToken();
    await UserStore.auth();
    // static breeds: Array<Breed>
    // static varities: Array<{ breed: Breed, varities: Array<Variety> }>
    // static colors: Array<{ breed: Breed, variety: Variety, colors: Array<Color> }>
    // static groups: Array<{ group: Group, breeds: Array<Breed> }>
  }
}

// export default {
//   name: "App",
//   components: {},
//   data() {
//     return {
//       logText: "Login",
//       drawer: false,
//       styles: [["black--text"], ["white--text"]],
//       styleIdx: 1,
//       states: [true, false],
//       barColor: "transparent",
//       barElevation: "0",
//       loginModal: false,
//       signupModal: false,
//       showBarLogo: false,
//       logLoading: false,
//       loginErrorModal: false,
//     };
//   },
//   methods: {
//     log: function () {
//       // if (this.$store.state.loggedIn) {
//       //   this.logout();
//       // } else {
//       //   this.login();
//       // }
//       console.log("as");
//     },
//     logoRoute: function () {
//       // let path = "/";
//       // if (this.$store.state.referrer) {
//       //   path = "/show/" + this.$store.state.referrer;
//       // } else if (this.$store.state.loggedIn) {
//       //   path = "/home";
//       // } else {
//       //   path = "/";
//       // }
//       // this.$router.push(path);
//       console.log("a");
//     },
//     openLoginErrorModal: function () {
//       this.loginErrorModal = true;
//     },
//     onScroll() {
//       if (window.scrollY > 0) {
//         this.$set(this.states, 0, false);
//         this.$set(this.states, 1, true);
//         this.styleIdx = 0;
//         this.barColor = "rgba(255,255,255,0.9)";
//         this.barElevation = "3";
//       } else {
//         this.$set(this.states, 0, true);
//         this.$set(this.states, 1, false);
//         this.styleIdx = 1;
//         this.barColor = "transparent";
//         this.barElevation = "0";
//       }
//     },
//     signup: function () {
//       this.signupModal = true;
//     },
//     login: function () {
//       this.loginModal = true;
//     },
//     logout: async function () {
//       try {
//         let result = await axios.post(
//           window.apiUrl + "/logout",
//           {},
//           { withCredentials: true }
//         );
//         console.log(result);
//         if (result.data.success) {
//           this.$store.commit("setLoginStatus", false);
//           this.$router.push("/");
//         }
//       } catch (err) {
//         console.log(err);
//       }
//     },
//     gotoProfile() {
//       this.$router.push({ path: "/profile" });
//     },
//     gotoExhibitorDash() {
//       this.$router.push({ path: "/dashboard/exhibitor" });
//     },
//     gotoSecretaryDash() {
//       this.$router.push({ path: "/dashboard/secretary" });
//     },
//   },
//   beforeMount: async function () {
//     if (this.$route.path == "/" || this.$route.path == "/home") {
//       this.showBarLogo = false;
//     } else {
//       this.showBarLogo = true;
//     }
//     //await this.$store.dispatch("authorize");
//     if (
//       this.$route.path.match(/^\/show/) &&
//       !this.$route.path.match(/^\/shows/) &&
//       !this.$store.state.loggedIn
//     ) {
//       this.showBarLogo = false;
//     }
//   },

//   computed: {
//     loggedIn: function () {
//       //return this.$store.state.loggedIn;
//       return false;
//     },

//     // cssProps() {
//     //   var themeColors = {};
//     //   Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
//     //     themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color];
//     //   });
//     //   return themeColors;
//     // },
//   },
//   updated() {
//     let path = this.$router.history.current.path;
//     if (
//       path == "/" ||
//       path == "/home" ||
//       (this.$route.path.match(/^\/show/) &&
//         !this.$route.path.match(/^\/shows/) &&
//         !this.$store.state.loggedIn)
//     ) {
//       this.showBarLogo = false;
//     } else {
//       this.showBarLogo = true;
//     }
//   },
//   watch: {
//     loggedIn: function (val) {
//       console.log(val);
//       if (val) {
//         //this.$router.push({ path: "/profile" });
//         this.logText = "Logout";
//       } else {
//         //this.$router.push({ path: "/splash" });
//         this.logText = "Login";
//       }
//     },
//     loginErrorModal: function (newVal, oldVal) {
//       if (oldVal && !newVal) {
//         // if (this.$store.state.referrer) {
//         //   this.$router.push("/show/" + this.$store.state.referrer);
//         // } else {
//         //   this.$router.push("/");
//         // }
//         console.log("lol");
//       }
//     },
//   },
// };
//
