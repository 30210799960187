
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class DogBirthDate extends Vue {
  @Prop() readonly value!: Date;
  @Prop({ default: false }) readonly dense!: boolean;
  @Prop({ default: false }) readonly hideDetails!: boolean;

  birthDate: string | null = null;
  date: string | null = null;

  dialog = false;
  activePicker = "YEAR";
  set() {
    if (this.date) {
      this.birthDate = new Date(this.date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
      });
      setTimeout(() => {
        this.dialog = false;
      }, 500);
      this.update();
    }
  }

  change() {
    console.log("change");
  }

  update() {
    if (this.date) {
      this.birthDateErrors = []
      this.$emit("input", new Date(this.date));
    }
  }

  @Watch("dialog")
  onDialogChange(val: boolean) {
    if (val) {
      this.$nextTick(() => {
        this.activePicker = "YEAR";
      });
    }
  }

  @Watch("value")
  onValueChange(val: Date){
    console.log("dog birth date update")
    this.birthDate = val.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
      });
    this.date = val.toISOString().substr(0, 10)
    this.birthDateErrors = []
  }

  birthDateErrors : Array<string> = []
  validate(): Array<string>{
    this.birthDateErrors = []
    if(this.birthDate === null){
      this.birthDateErrors = ["You must select a birth date."]
    }
    return this.birthDateErrors
  }
}
