
import { Address, AddressType } from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FuzzyAutocomplete from "../../FuzzyAutocomplete.vue";
import states from "../../../data/states.json";

@Component({
  components: {
    FuzzyAutocomplete,
  },
})
export default class DogAddressManual extends Vue {
  @Prop() readonly value!: Address;
  address: Address = new Address(null,"","","","","","",AddressType.USMANUAL);

  update() {
    this.cleanZip();
    if(this.address.state.length > 0){
      this.stateErrors = []
    }
    this.$emit("input", this.address);
  }

  get states() {
    return states;
  }

  get stateName() {
    return;
  }

  get rules() {
    return {
      required: (x: string) => {
        return !x || x === "" ? "This field is required" : true;
      },
      requireAddress: (x: string) => {
        return !x || x.trim() === "" ? "You must list a street address" : true;
      },
      requireCity: (x: string) => {
        return !x || x.trim() === "" ? "You must provide a city" : true;
      },
      requireState: (x: string) => {
        return !x || x.trim() === "" ? "You must select a state" : true;
      },
      requireZip: (x: string) => {
        return !x || x.trim() === "" ? "You must provide a zip code" : true;
      },
      zipLength: (x: string) => {
        return x && x.length > 0 && x.length < 5
          ? "Zip code requires 5 numbers"
          : true;
      },
    };
  }

  cleanZip(): void {
    this.$nextTick(() => {
      this.address.postal = this.address.postal.replace(/[^0-9]/g, "");
    });
  }

  @Watch("value")
  onValueChange(x: Address) {
    this.address = x;
  }

  stateErrors : Array<string> = []
  validate(): Array<string> {
    //console.log("asdfdsfds")
    if (!(this.$refs.manualAddress as any).validate() || this.address.state.length == 0) {
      if(this.address.state.length == 0){
        this.stateErrors = ["State is required."]
      }
      return ["Your address information is incomplete."];
    }
    return [];
  }
}
