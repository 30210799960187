import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
  } from "vuex-module-decorators";
  import {
    CSVPreset,
    SecretaryDeleteCSVPresetRequest,
    SecretaryDeleteCSVPresetResponse,
    SecretaryFetchCSVPresetsResponse,
    SecretarySaveCSVPresetRequest,
    SecretarySaveCSVPresetResponse,
  } from "@app/common";
  import store from "..";
  import { fetchErrorMessage, generalErrorMessage } from "../error";
  import UserStore from "./user";
  
  @Module({ dynamic: true, store, name: "CSVData", namespaced: true })
  class CSVDataStore extends VuexModule {
    private _CSVPresets : Array<CSVPreset> = []
    private _fetchingPresets : boolean = false

    get CSVPresets(): Array<CSVPreset>{
        return this._CSVPresets
    }
    @Mutation
    setCSVPresets(x : Array<CSVPreset>){
        this._CSVPresets = x
    }
    get fetchingPresets():boolean{
        return this._fetchingPresets
    }
    @Mutation
    setFetchingPresets(x : boolean){
        this._fetchingPresets = x
    }

    @Action
    async fetchPresets() {
      this.setFetchingPresets(true);
      const response = await fetch(
        process.env.VUE_APP_DOG_API + "/secretary/csv-data/fetch-presets",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({}),
          credentials: "include",
        }
      );
      this.setFetchingPresets(false);
      if (!response.ok) {
        this.context.rootState.errorModal = true;
        this.context.rootState.errorMsg = fetchErrorMessage;
        this.context.rootState.errorCode =
          response.status.toString() + " " + response.statusText;
      } else {
        const data: SecretaryFetchCSVPresetsResponse = await response.json();
        if (data.success) {
          this.setCSVPresets(data.presets)
        } else {
          this.context.rootState.errorMsg = generalErrorMessage;
          this.context.rootState.errorModal = true;
        }
      }
    }


    private _savingPreset : boolean = false
    get savingPreset(): boolean{
        return this._savingPreset
    }
    @Mutation
    setSavingPreset(x : boolean){
        this._savingPreset = x
    }

    @Action
    async savePreset(payload: SecretarySaveCSVPresetRequest) {
      this.setSavingPreset(true);
      const response = await fetch(process.env.VUE_APP_DOG_API + "/secretary/csv-data/save-preset", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      });
      this.setSavingPreset(false);
      if (!response.ok) {
        this.context.rootState.errorModal = true;
        this.context.rootState.errorMsg = fetchErrorMessage;
        this.context.rootState.errorCode =
          response.status.toString() + " " + response.statusText;
      } else {
        const data: SecretarySaveCSVPresetResponse = await response.json();
        if(!data.success){
          this.context.rootState.errorMsg = generalErrorMessage;
          this.context.rootState.errorModal = true;
          this.context.rootState.errorCode = data.errors[0];
        }
        UserStore.authCheck(data);
      }
    }

    private _deletingPreset : boolean = false
    get deletingPreset(): boolean{
        return this._deletingPreset
    }
    @Mutation
    setDeletingPreset(x : boolean){
        this._deletingPreset = x
    }


    @Action
    async deletePreset(payload: SecretaryDeleteCSVPresetRequest) {
      this.setDeletingPreset(true);
      const response = await fetch(process.env.VUE_APP_DOG_API + "/secretary/csv-data/delete-preset", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      });
      this.setDeletingPreset(false);
      if (!response.ok) {
        this.context.rootState.errorModal = true;
        this.context.rootState.errorMsg = fetchErrorMessage;
        this.context.rootState.errorCode =
          response.status.toString() + " " + response.statusText;
      } else {
        const data: SecretaryDeleteCSVPresetResponse = await response.json();
        if(!data.success){
          this.context.rootState.errorMsg = generalErrorMessage;
          this.context.rootState.errorModal = true;
          this.context.rootState.errorCode = data.errors[0];
        }
        UserStore.authCheck(data);
      }
    }
  }
  
  export default getModule(CSVDataStore);
  