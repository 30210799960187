
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
@Component
export default class TabBar extends Vue {
  @Prop() readonly items!: Array<string>;
  @Prop() readonly value!: number;
  @Prop({ default: true }) readonly show!: boolean;

  pageIdx: number = 0;
  left: number = 0;
  widthT: number = 0;
  widthB: number = 0;

  mounted() {
    if (this.items && this.items.length > 0) {
      this.widthT = 100 / this.items.length;
      this.widthB = 100 * this.items.length;
    }
  }
  goTo(idx: number) {
    this.pageIdx = idx;
    this.left = idx * this.widthT;
    this.$emit("input", this.items[this.pageIdx]);
  }

  @Watch("value")
  onValueChange(val: string) {
    if (val !== this.items[this.pageIdx]) {
      let idx = this.items.indexOf(val);
      if (idx >= 0) {
        this.pageIdx = idx;
        this.left = idx * this.widthT;
      }
    }
  }
  @Watch("items")
  onItemsChange() {
    if (this.items && this.items.length > 0) {
      this.widthT = 100 / this.items.length;
      this.widthB = 100 * this.items.length;
      this.left = this.pageIdx * this.widthT;
    }
  }
}
