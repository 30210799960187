import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "..";
import { fetchErrorMessage, generalErrorMessage } from "../error";
import { DogClass } from "@app/common/classes";
import UserStore from "./user";
import { Address, Dog, Entry, EventClass, GeneralErrors, Judge, Payment, Refund, SecretaryAcceptCheckPaymentRequest, SecretaryAcceptCheckPaymentResponse, SecretaryEntryDataRequest, SecretaryEntryDataResponse, SecretaryErrors, SecretaryFetchClassesResponse, SecretaryPaymentDataRequest, SecretaryPaymentDataResponse, SecretaryShowDataRequest, SecretaryShowDataResponse, SecretaryShowInterfaceRequest, SecretaryShowInterfaceResponse, SecretaryShowsResponse, Show, ShowDog, ShowEntry, ShowEntryInterface, User } from "@app/common";
import { Ref } from "vue-property-decorator";

@Module({ name: "SecretaryPaymentStore", dynamic: true, store, namespaced: true })
class SecretaryPaymentStore extends VuexModule {
    private _completingCheckPayment: boolean = false
    private _completeCheckPaymentError: GeneralErrors | SecretaryErrors | undefined = undefined
    get completingCheckPayment(): boolean {
        return this._completingCheckPayment
    }
    @Mutation
    setCompletingCheckPayment(x: boolean) {
        this._completingCheckPayment = x
    }
    get completeCheckPaymentError(): GeneralErrors | SecretaryErrors | undefined {
        return this._completeCheckPaymentError
    }
    @Mutation
    setCompleteCheckPaymentError(x: GeneralErrors | SecretaryErrors | undefined) {
        this._completeCheckPaymentError = x
    }

    @Action
    async completeCheckPayment(payload: SecretaryAcceptCheckPaymentRequest) {
        this.setCompletingCheckPayment(true);
        const response = await fetch(process.env.VUE_APP_DOG_API + "/secretary/transactions/complete-check-payment", {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "include",
        });
        this.setCompletingCheckPayment(false);
        if (!response.ok) {
            this.context.rootState.errorModal = true;
            this.context.rootState.errorMsg = fetchErrorMessage;
            this.context.rootState.errorCode =
                response.status.toString() + " " + response.statusText;
        } else {
            const data: SecretaryAcceptCheckPaymentResponse = await response.json();
            if (data.success) {
                this.setCompleteCheckPaymentError(undefined)
            } else {
                this.context.rootState.errorMsg = generalErrorMessage;
                this.context.rootState.errorModal = true;
                this.context.rootState.errorCode = data.errors[0];
                this.setCompleteCheckPaymentError(data.errors[0]);
            }
            UserStore.authCheck(data);
        }
    }

}

export default getModule(SecretaryPaymentStore);
