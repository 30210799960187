
import { Person } from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DogStore from "../../../store/modules/dog";
import fuzzySearch from "../../../services/fuzzySearch";

@Component
export default class DogPerson extends Vue {
  @Prop() readonly idx!: number;
  @Prop({ default: false }) readonly upArrow!: boolean;
  @Prop({ default: false }) readonly downArrow!: boolean;
  @Prop() readonly value!: Person;
  @Prop({
    default: () => {
      return [];
    },
  })
  readonly people!: Array<Person>;
  person: Person = new Person();

  editing: boolean = true;
  editingTransitioning: boolean = false;
  showSearch: boolean = false;
  peopleSearch: Array<Person> = [];

  selectIdx: number = -1;
  //error: boolean = true;

  // firstNameErrorMessages: Array<string> = [];
  // lastNameErrorMessages: Array<string> = [];

  move(x: number) {
    this.$emit("move", x);
  }

  deletePerson() {
    this.showSearch = false;
    this.$emit("delete", this.idx);
  }

  update() {
    //this.$emit("change", this.person);
    this.$emit("input", this.person);
  }

  handleKey(x: KeyboardEvent) {
    if (x.key === "ArrowUp" && this.selectIdx > -1) {
      this.selectIdx -= 1;
    } else if (
      x.key === "ArrowDown" &&
      this.selectIdx < this.peopleSearch.length - 1
    ) {
      this.selectIdx += 1;
    } else if (x.key === "Enter" && this.selectIdx > -1) {
      console.log(this.peopleSearch);
      console.log(this.selectIdx);
      console.log(this.peopleSearch[this.selectIdx]);
      this.selectExistingPerson(this.peopleSearch[this.selectIdx]);
    } else {
      this.selectIdx = -1;
    }
  }

  clean(x: string): string {
    let out: string = x
      .split(" ")
      .filter((x) => {
        return x.length > 0;
      })
      .join(" ");
    if (out.toLowerCase() === out || out.toUpperCase() === out) {
      out = out
        .toLowerCase()
        .split(" ")
        .map((w) => {
          if (w.length > 0) {
            return w[0].toUpperCase() + w.substring(1);
          } else {
            return "";
          }
        })
        .join(" ");
    }
    return out;
  }

  changeFirstName() {
    if (this.person.id === null) {
      this.person.firstName = this.clean(this.person.firstName);
    }
    this.checkIfComplete();
  }

  changeLastName() {
    if (this.person.id === null) {
      this.person.lastName = this.clean(this.person.lastName);
    }
    this.checkIfComplete();
  }

  get error(): boolean {
    if (
      this.person.id == null &&
      (this.person.firstName.trim().length == 0 ||
        this.person.lastName.trim().length == 0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  stringExtract(x: Person): string {
    return x.printFullName();
  }

  searchPeople() {
    if (this.person.printFullName().length > 0) {
      let fs = new fuzzySearch(this.stringExtract, [], 190);
      fs.input = DogStore.people.filter((x) => {
        return !this.people.find((y) => {
          return y.id == x.id;
        });
      });
      fs.search = this.person.printFullName();
      this.peopleSearch = fs.output;
      this.showSearch = this.peopleSearch.length > 0;
    } else {
      this.showSearch = false;
      this.peopleSearch = [];
    }
  }

  checkIfComplete() {
    if (!this.error) {
      this.showSearch = false;
      this.editing = false;
      this.editingTransitioning = true;
    }
  }

  selectExistingPerson(x: Person) {
    this.editing = false;
    this.editingTransitioning = true;
    this.showSearch = false;
    this.person = x;
    this.update();
  }

  mounted() {
    if (this.value.id !== null) {
      this.editing = false;
      this.editingTransitioning = true;
      this.person = this.value;
    }
  }

  @Watch("value")
  onValueCHange(x: Person) {
    this.person = x;
    if (x.id !== null) {
      if (this.editing) {
        this.editingTransitioning = true;
      }
      this.editing = false;
      this.showSearch = false;
    }
  }

  @Watch("editing")
  onEditChange(x: boolean) {
    this.$emit("editing", x);
  }
}
