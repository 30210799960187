
import { Component, Vue, Watch } from "vue-property-decorator";
import Loading from "../Loading.vue";
import DogJudge from "../DogJudge.vue";
import NewShowStore from "../../store/modules/newshow";
import { Judge } from "@app/common";
import fuzzySearch from "../../services/fuzzySearch";
@Component({ components: { Loading, DogJudge } })
export default class NewShowJudges extends Vue {
  judges: Array<Judge> = [new Judge()];
  searchModal: boolean = false;
  judgeSearch: string = "";
  get loading(): boolean {
    return NewShowStore.fetchingJudges;
  }
  get canAdd(): boolean {
    for (let judge of this.judges) {
      if (judge.name.trim().length == 0) {
        return false;
      }
    }
    return true;
  }
  get fetchedJudges(): Array<Judge> {
    return NewShowStore.fetchedJudges;
  }
  async mounted() {
    if(NewShowStore.loadFromLocalStorage){
      this.judges = NewShowStore.judges
    }
    await NewShowStore.fetchJudges();
  }

  addNewJudge() {
    this.judges.push(new Judge());
  }

  addJudge(x: Judge) {
    if (
      !this.judges.find((y) => {
        return y.id == x.id;
      })
    ) {
      if (this.judges[this.judges.length - 1].name.trim().length == 0) {
        this.$set(this.judges, this.judges.length - 1, x);
        //this.judges[this.judges.length - 1] = x;
      } else {
        this.judges.push(x);
      }
    }
    this.update();
  }

  remove(idx: number) {
    this.judges.splice(idx, 1);
  }

  get judgesList(): Array<Judge> {
    if (this.judgeSearch.trim().length > 2) {
      let search = new fuzzySearch((x: Judge) => {
        return x.name + " " + x.number;
      }, []);
      search.input = this.fetchedJudges;
      search.search = this.judgeSearch;
      return search.output;
    } else {
      return [];
    }
  }

  update() {
    NewShowStore.setJudges(this.judges);
  }

  @Watch("judges")
  onJudgesChange(x: Array<Judge>) {
    NewShowStore.setJudges(x);
  }

  validate(): Array<string> {
    let out : Array<string> = []
    for(let judge of this.judges){
      if(judge.name.trim().length == 0){
        out.push("Each judge must have a name.")
        break;
      }
    }
    return out;
  }
}
