const fuzz = require("fuzzball")

type fuzzVector = [any, number, number];

export default class fuzzySearch<K> {
    private _extractStrings: (arg: K) => string;
    private _synonyms: Array<{ word: string; similarTo: string }>;
    private _search: string;
    private _input: Array<K>;
    private _inputStrings: Array<string>;
    private _output: Array<K>;
    private _minScore : number

    get search(): string {
        return this._search;
    }
    set search(x: string) {
        this._search = x;
        this._output = this.filterList();
    }
    get output(): Array<K> {
        this._output = this.filterList()
        return this._output;
    }
    set input(x: Array<K>) {
        this._input = x;
        this._inputStrings = x.map((y) => {
            return this._extractStrings(y);
        });
    }


    get truncatedInputString(): Array<string> {
        return this._inputStrings.map((x) => {
            return x.slice(0, this._search.length);
        });
    }
    constructor(
        extractStrings: (arg: K) => string,
        synonyms: Array<{ word: string; similarTo: string }>,
        minScore?: number,
    ) {
        this._extractStrings = extractStrings;
        this._synonyms = synonyms;
        this._search = "";
        this._output = [];
        this._input = [];
        this._inputStrings = [];
        this._minScore = minScore || 0
    }

    private filterList(): Array<K> {
        let search : string = this._search
        for(let synonym of this._synonyms){
            let regex = new RegExp(synonym.word,"gi")
            search = search.replace(regex,synonym.similarTo)
        }
        if (this._inputStrings.length > 0) {
            const optionsPR = { scorer: fuzz.partial_ratio, unsorted: true };
            const optionsTR = { scorer: fuzz.token_set_ratio, unsorted: true };
            const optionsD = { scorer: fuzz.distance, unsorted: true };
            const resultPR: Array<fuzzVector> = fuzz.extract(
                search,
                this._inputStrings,
                optionsPR
            );
            const resultTR: Array<fuzzVector> = fuzz.extract(
                search,
                this._inputStrings,
                optionsTR
            );
            const resultD: Array<fuzzVector> = fuzz.extract(
                search,
                this._inputStrings,
                optionsD
            );
            const resultDT: Array<fuzzVector> = fuzz.extract(
                search,
                this.truncatedInputString,
                optionsD
            );
            let scores: Array<fuzzVector> = [];
            for (let k = 0; k < this._inputStrings.length; k++) {
                const newScore =
                    resultPR[k][1] +
                    resultTR[k][1] +
                    20.0 / (1.0 + resultD[k][1]) +
                    20.0 / (1.0 + resultDT[k][1]);
                scores.push([this._input[k], newScore, resultPR[k][2]]);
            }
            scores.sort((a, b) => {
                return b[1] - a[1];
            });
            if (scores.length > 0) {
                const maxScore = scores[0][1];
                scores = scores.filter((x) => {
                    return x[1] >= Math.max(0.65 * maxScore,this._minScore);
                });
                const out: Array<K> = scores.map((x) => {
                    return this._input[x[2]];
                });
                return out;
            } else {
                return [];
            }

        } else {
            return []
        }


    }

    private addScores(
        u: Array<fuzzVector>,
        v: Array<fuzzVector>
    ): Array<fuzzVector> {
        if (u.length !== v.length) {
            console.error("fuzzySearch: arrays not the same length.");
        }
        const out: Array<fuzzVector> = [];
        for (let k = 0; k < u.length; k++) {
            const newFuzzVector: fuzzVector = [u[k][0], u[k][1] + v[k][1], u[k][2]];
            out.push(newFuzzVector);
        }
        return out;
    }

    private multiplyScores(x: number, u: Array<fuzzVector>): Array<fuzzVector> {
        const out: Array<fuzzVector> = [];
        for (let k = 0; k < u.length; k++) {
            let newFuzzVector: fuzzVector;
        }
        return out;
    }
}
