
import { Component, Vue } from "vue-property-decorator";
import DogAddress from "../components/DogForm/DogAddress/DogAddress.vue";
import NewShowStore from "../store/modules/newshow";
import ShowManagerCatalogPanel from "../components/ShowManager/ShowManagerCatalogPanel.vue";
import CatalogBuilder from "../components/CatalogBuilder/CatalogBuilder.vue";

@Component({
  components: { DogAddress, ShowManagerCatalogPanel, CatalogBuilder },
})
export default class NewShowSubmitted extends Vue {
  get trusted(): boolean {
    return NewShowStore.trusted;
  }
  get success(): boolean {
    return true;
    //return NewShowStore.submitSuccess;
  }
}
