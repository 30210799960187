
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class DogToggle extends Vue {
  @Prop({ default : null}) readonly value!: number | null;
  @Prop({ default: ()=>{return[]}}) readonly items!: Array<string>
  @Prop({ default: "" }) readonly color!: string;
  select : number | null = 0

  mounted(){
    this.select = this.value
  }

  @Watch("value")
  onValueChange(x: number){
    this.select = x
  }

  get defaultStyle(): { "background-color": string; color: string } {
    return {
      "background-color": "white",
      color: this.color,
    };
  }

  get selectedStyle(): { "background-color": string; color: string } {
    return { "background-color": this.color, color: "white" };
  }

  get groupStyle(): {
    border: string;
    "border-radius": string;
    "background-color": string;
  } {
    return {
      border: "1px solid " + this.color,
      "border-radius": "4px",
      "background-color": this.color,
    };
  }
}
