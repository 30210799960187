
import { Entry, Show } from "@app/common";
import { Component, Vue, Watch } from "vue-property-decorator";
import ShowStore from "../../store/modules/show"
import PhoneNumber from "../PhoneNumber.vue";

@Component({components: {
  PhoneNumber
}})
export default class EntryConfirm extends Vue {
  //@Prop() readonly entries!: Array<Entry>;
  signature: string = "";
  phone: string = "";
  agree: boolean = false;
  ownerHandler : boolean = false
  newExhibitor : boolean = false
  errorDialog: boolean = false;

  get show(): Show | undefined{
    return ShowStore.show
  }

  get rules() {
    return {
      signatureRequired: (x : string) => {
        return !x || x.trim() === "" ? "Your signature is required" : true;
      },
      agreeRequired: (x : string) => {
        return !x ? "You must agree to the conditions above" : true;
      },
    };
  }

  get confirmData(): {ownerHandler : boolean, newExhibitor : boolean, signature : string, phone : string}{
    return {ownerHandler : this.ownerHandler, newExhibitor : this.newExhibitor, signature : this.signature, phone : this.phone}
  }

  @Watch("ownerHandler")
  onOwnerHandlerChange(){
    this.$emit("input",this.confirmData)
  }

  @Watch("newExhibitor")
  onNewExhibitorChange(){
    this.$emit("input",this.confirmData)
  }

  @Watch("signature")
  onSignatureChange(){
    this.$emit("input",this.confirmData)
  }

  @Watch("phone")
  onPhoneChange(){
    this.$emit("input",this.confirmData)
  }

  validate():Array<string>{
    let out : Array<string> = []
    if(!this.agree){
      out.push("You must agree to the conditions.")
    }

    if(this.signature.trim().length == 0){
      out.push("Your signature is required.")
    }
    return out
  }

}
