
import { Component, Vue, Watch } from "vue-property-decorator";
import ClassesTable from "./ClassesTable.vue";
import DogFee from "../DogFee.vue";
import NewShowStore from "../../store/modules/newshow";
import { DogEvent, EventClass } from "@app/common";
@Component({ components: { ClassesTable, DogFee } })
export default class NewShowFees extends Vue {
  selectedEventIdx: number = 0;
  eventClasses: Array<Array<EventClass>> = [[]];
  fee: number | null = null;

  get storeEventClasses(): Array<Array<EventClass>> {
    return NewShowStore.eventClasses;
  }
  get events(): Array<DogEvent> {
    return NewShowStore.show.events.map((x) => {
      return new DogEvent(
        x.id,
        new Date(x.startDate),
        new Date(x.endDate),
        x.AKCNumber,
        x.type
      );
    });
  }

  get showPointer(): boolean {
    return this.fee !== null;
  }
  get showHover(): boolean {
    return this.fee !== null;
  }

  mounted() {
    console.log("New Show fees mounted");
    this.eventClasses = NewShowStore.eventClasses.map((x) => {
      return x.map((y) => {
        return new EventClass(y.id, y.event, y.dogClass, y.fee, y.judge);
      });
    });
  }
  test() {
    console.log(this.eventClasses);
  }

  @Watch("storeEventClasses")
  onStoreEventClassesChange() {
    console.log("store event classes changed");
    this.eventClasses = NewShowStore.eventClasses.map((x) => {
      return x.map((y) => {
        return new EventClass(y.id, y.event, y.dogClass, y.fee, y.judge);
      });
    });
  }

  changeFee() {
    NewShowStore.setEventClasses(this.eventClasses);
  }

  eventChanged(idx: number) {
    this.selectedEventIdx = idx;
  }

  feeString(idx: number): string {
    return (
      "$" +
      (this.eventClasses[this.selectedEventIdx][idx].fee / 100.0).toFixed(2)
    );
  }

  clickedClass(idx: number) {
    if (this.fee !== null) {
      this.eventClasses[this.selectedEventIdx][idx].fee = this.fee;
      NewShowStore.setEventClasses(this.eventClasses);
    }
  }

  applyToAll() {
    if (this.fee) {
      for (let eventClass of this.eventClasses[this.selectedEventIdx]) {
        eventClass.fee = this.fee;
      }
      NewShowStore.setEventClasses(this.eventClasses);
    }
  }

  copyFees(idx: number) {
    for (let eventClassCopyFrom of this.eventClasses[idx]) {
      for (let eventClassCopyTo of this.eventClasses[this.selectedEventIdx]) {
        if (
          eventClassCopyTo.dogClass.classCompare(eventClassCopyFrom.dogClass)
        ) {
          eventClassCopyTo.fee = eventClassCopyFrom.fee;
        }
      }
    }
    NewShowStore.setEventClasses(this.eventClasses);
  }

  validate(): Array<string> {
    let out : Array<string> = []
    for(let eventClasses of this.eventClasses){
      for(let eventClass of eventClasses){
        if(eventClass.fee == 0){
          out.push("The class " + eventClass.dogClass.name + " has a zero dollar fee. Every class must have a non-zero fee.")
        }
      }
    }
    return out;
  }
}
