
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FuzzyAutocomplete from "../FuzzyAutocomplete.vue";
import countries from "../../data/countries.json";

@Component({ components: { FuzzyAutocomplete } })
export default class DogCountry extends Vue {
  @Prop() readonly value: string | undefined;
  @Prop() readonly label!: string;
  //@Prop({default : () => {return []}}) readonly errorMessages!: Array<string>;

  country: string = "";
  countries: Array<string> = countries;
  errorMessages: Array<string> = [];

  synonyms: Array<{ word: string; similarTo: string }> = [
    { word: "US", similarTo: "United States" },
    { word: "U.S.", similarTo: "United States" },
    { word: "U.S", similarTo: "United States" },
    { word: "USA", similarTo: "United States" },
    { word: "U.S.A.", similarTo: "United States" },
    { word: "U.S.A", similarTo: "United States" },
    { word: "UK", similarTo: "United Kingdom" },
    { word: "U.K.", similarTo: "United Kingdom" },
    { word: "U.K", similarTo: "United Kingdom" },
  ];

  @Watch("value")
  onValueChange(x: string | undefined) {
    console.log("dog country value changed");
    if (x) {
      this.country = x;
    }
    this.errorMessages = [];
  }

  @Watch("country")
  onCountryChange(x: string) {
    this.$emit("input", x);
  }

  validate(): Array<string> {
    if (this.country.length == 0) {
      this.errorMessages = ["You must select a country"];
    }
    return this.errorMessages;
  }

  mounted() {
    if (this.value) {
      this.country = this.value;
    }
  }
}
