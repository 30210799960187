
import { Sex } from "@app/common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DogToggle from "./DogToggle.vue";
@Component({ components: { DogToggle } })
export default class DogMF extends Vue {
  @Prop({default : null}) readonly value!: Sex | null
  toggle : number | null = null

  update(){
    if(this.toggle == 0){
      this.$emit("input",Sex.MALE)
    }else if(this.toggle == 1){
      this.$emit("input",Sex.FEMALE)
    }
  }

  @Watch("value")
  onValueChange(x : Sex | null){
    if(x === Sex.MALE){
      this.toggle = 0
    }else if(x === Sex.FEMALE){
      this.toggle = 1
    }else{
      this.toggle = null
    }
  }

  validate():Array<string>{
    if(this.toggle === null){
      return ["You must select a sex."]
    }else{
      return []
    }
  }
}
