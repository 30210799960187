
import { Component, Vue, Prop } from "vue-property-decorator";
import UserStore from "../store/modules/user";
import Loading from "./Loading.vue";
import SecretaryStore from "../store/modules/secretary";
import SecretaryPaymentStore from "../store/modules/secretary_payments";
import ExpansionList from "./ExpansionList.vue";
import fuzzySearch from "../services/fuzzySearch";
import {
  Entry,
  Payment,
  PaymentStatus,
  ReceiptRequest,
  Refund,
  RefundStatus,
  SecretaryAcceptCheckPaymentRequest,
  SecretaryAcceptCheckPaymentResponse,
  SecretaryReceiptRequest,
  Show,
  Transaction,
} from "@app/common";
import { secretaryFetchReceipt } from "../services/receipt";
@Component({
  components: {
    Loading,
    ExpansionList,
  },
})
export default class DogPaymentsSecretary extends Vue {
  @Prop({ default: true }) readonly showPayments!: boolean;
  @Prop({ default: true }) readonly showRefunds!: boolean;
  @Prop({ default: "" }) readonly search!: string;
  @Prop({ default: true }) readonly showOnline!: boolean;
  @Prop({ default: true }) readonly showManual!: boolean;
  @Prop({ default: true }) readonly showCompleted!: boolean;
  @Prop({ default: true }) readonly showPending!: boolean;
  async mounted() {}
  get show(): Show | undefined {
    return SecretaryStore.selectedShow;
  }

  get payments(): Array<Payment> {
    let out: Array<Payment> = SecretaryStore.payments;
    if (!this.showOnline) {
      out = out.filter((x) => {
        return !x.online;
      });
    }
    if (!this.showManual) {
      out = out.filter((x) => {
        return x.online;
      });
    }
    if (!this.showCompleted) {
      out = out.filter((x) => {
        return x.status !== PaymentStatus.COMPLETED;
      });
    }
    if (!this.showPending) {
      out = out.filter((x) => {
        return (
          x.status !== PaymentStatus.PENDING &&
          x.status !== PaymentStatus.APPROVED
        );
      });
    }
    return out;
  }

  get refunds(): Array<Refund> {
    let out: Array<Refund> = SecretaryStore.refunds;
    if (!this.showOnline) {
      out = out.filter((x) => {
        return !x.online;
      });
    }
    if (!this.showManual) {
      out = out.filter((x) => {
        return x.online;
      });
    }
    if (!this.showCompleted) {
      out = out.filter((x) => {
        return x.status !== RefundStatus.COMPLETED;
      });
    }
    if (!this.showPending) {
      out = out.filter((x) => {
        return x.status !== RefundStatus.PENDING;
      });
    }
    return out;
  }

  get transactionItems(): Array<{
    type: "Payment" | "Refund";
    transaction: Payment | Refund;
  }> {
    let out: Array<{
      type: "Payment" | "Refund";
      transaction: Payment | Refund;
    }> = this.showPayments
      ? this.payments.map((x) => {
          return { type: "Payment", transaction: x };
        })
      : [];
    out = this.showRefunds
      ? out.concat(
          this.refunds.map((x) => {
            return { type: "Refund", transaction: x };
          })
        )
      : out;
    out.sort((x, y) => {
      return x.transaction.time.getTime() > y.transaction.time.getTime()
        ? -1
        : 1;
    });
    if (this.search.trim().length > 2) {
      let fuzzy = new fuzzySearch(
        (x: { type: "Payment" | "Refund"; transaction: Payment | Refund }) => {
          return x.transaction.user
            ? x.transaction.user.fullName + " " + x.transaction.user.email
            : "";
        },
        []
      );
      fuzzy.input = out;
      fuzzy.search = this.search;
      out = fuzzy.output;
    }
    return out;
  }

  //   get transactions(): Array<{ payment? : Payment, refund? : Refund}> {
  //     let out : Array<{payment? : Payment, refund? : Refund}> = []
  //     out = SecretaryStore.payments.map((x)=>{return {payment: x}})
  //     out = out.concat(SecretaryStore.refunds.map((x) => {return {refund: x}}))
  //     out.sort((x,y) =>{
  //         let transactionA : Transaction | undefined = (x.payment ? x.payment : x.refund)
  //         let transactionB : Transaction | undefined = (y.payment ? y.payment : y.refund)
  //         if(!transactionA || !transactionB){
  //             return 1
  //         }else{
  //             return (transactionA.time.getTime() > transactionB.time.getTime() ? 1 : -1)
  //         }
  //     })
  //     return out
  //   }

  // get transactions(): Array<Transaction>{
  //     let out : Array<Transaction> = (SecretaryStore.payments as Array<Transaction>).concat(SecretaryStore.refunds)
  //     out.sort((x,y)=>{return (x.time.getTime() > y.time.getTime() ? 1 : -1)})
  //     return out
  // }

  async fetchReceipt(x: {
    type: "Payment" | "Refund";
    transaction: Transaction;
  }) {
    if (this.show && this.show.id && x.transaction.id) {
      let payload: SecretaryReceiptRequest = {
        show: this.show.id,
        id: x.transaction.id,
        type: x.type == "Payment" ? "PAYMENT" : "REFUND",
      };
      await secretaryFetchReceipt(payload);
    }
  }

  checkModal: boolean = false;
  checkTransaction: Transaction | undefined = undefined;
  processingCheck: boolean = false;
  completeCheckPayment(x: Transaction) {
    this.checkTransaction = x;
    this.checkModal = true;
  }

  async processCheck() {
    if (this.show && this.show.id && this.checkTransaction && this.checkTransaction.id) {
      this.processingCheck = true;
      let payload: SecretaryAcceptCheckPaymentRequest = {
        show: this.show.id,
        payment: this.checkTransaction.id,
        csrfSecurityToken: UserStore.csrfSecurityToken
      };
      await SecretaryPaymentStore.completeCheckPayment(payload)
      if(!SecretaryPaymentStore.completeCheckPaymentError){
        await SecretaryStore.fetchPaymentData({show : this.show.id})
      }
      this.processingCheck = false
      this.checkModal = false
    }
  }

  // refundPayment(x: Payment) {
  //   this.$emit("refund-payment", x);
  // }
}
