
export enum AddressType {
    USSMART = 'USSMART',
    USMANUAL = 'USMANUAL',
    INTERNATIONAL = 'INTERNATIONAL',
}


export class Address {
    static addressFromJSON(x : AddressInterface): Address{
        return new Address(x.id,x.street,x.secondary,x.city,x.state,x.postal,x.country,AddressType.USSMART)
    }

    static addressArrayFromJSON(x : Array<AddressInterface>): Array<Address>{
        return x.map((a) => {return Address.addressFromJSON(a)})
    }

    static addressToJSON(x : Address): AddressInterface{
        return {id: x.id, street: x.street, secondary: x.secondary, city: x.city, state: x.state, postal: x.postal, country: x.country, type: x.type}
    }


    _id: number | null = null
    _street: string = ""
    _secondary: string = ""
    _city: string = ""
    _state: string = ""
    _postal: string = ""
    _country: string = "United States"
    _type: AddressType = AddressType.USSMART

    public get id(): number | null{
        return this._id
    }
    //do not set id
    public get street(): string{
        return this._street
    }
    public set street(value : string){
        this._street = value.trim()
    }

    public get secondary(): string{
        return this._secondary
    }
    public set secondary(value: string){
        this._secondary = value.trim()
    }

    public get city(): string{
        return this._city
    }
    public set city(value: string){
        this._city = value.trim()
    }

    public get state():string{
        return this._state
    }
    public set state(value: string){
        this._state = value.trim()
    }

    public get postal():string{
        return this._postal
    }
    public set postal(value: string){
        this._postal = value.trim()
    }

    public get country():string{
        return this._country
    }
    public set country(value: string){
        this._country = value.trim()
    }

    public get type():AddressType{
        return this._type
    }
    public set type(value: AddressType){
        this._type = value
    }

    constructor()
    constructor(id: number | null, street: string, secondary: string, city: string , state: string, postal: string , country: string, type: AddressType) 
    constructor(id?: number | null, street?: string, secondary?: string, city?: string , state?: string, postal?: string , country?: string, type?: AddressType) {
        this._id = id || null
        this.street = street || ''
        this.secondary = secondary || ''
        this.city = city || ''
        this.state = state || ''
        this.postal = postal || ''
        if(typeof country === "string"){
            this.country = country
        }else{
            this.country = "United States"
        }
        //this.country = country || "United States"
        this.type = type || AddressType.USSMART
    }

    printAddress(): string {
        if (this.secondary.length == 0) {
            return this.street + ", " + this.city + ", " + this.state + ", " + this.postal + ", " + this.country
        } else {
            return this.street + " " + this.secondary + ", " + this.city + ", " + this.state + ", " + this.postal + ", " + this.country
        }
    }

    public validateAddress(): Array<string> {
        let out: Array<string> = []
        if (this.street.length == 0) {
            out.push("Address must have a street.")
        }
        if (this.state.length == 0){
            if(this.type !== AddressType.INTERNATIONAL){
                out.push("Address must have a state.")
            }else{
                out.push("Address must have an administrative area.")
            }
        }
        if (this.city.length == 0){
            if(this.type !== AddressType.INTERNATIONAL){
                out.push("Address must have a city.")
            }else{
                out.push("Address must have a locality.")
            }
        }
        if(this.postal.length == 0 && this.type == AddressType.INTERNATIONAL){
            out.push("Address must have a postal code.")
        }else if(!/^\d+$/.test(this._postal) || this._postal.length !== 5){
            out.push("Address does not have a valid zip code.")
        }


        if (this.country.length == 0){
            out.push("Address must have a country")
        }
        return out
    }

    public similarAddress(anotherAddress: Address):boolean{
        if(this.id == anotherAddress.id){
            return true
        }else{
            let test: boolean = true
            test &&= this.street.toLowerCase() == anotherAddress.street.toLowerCase()
            test &&= this.secondary.toLowerCase() == anotherAddress.secondary.toLowerCase()
            test &&= this.city.toLowerCase() == anotherAddress.city.toLowerCase()
            test &&= this.state.toLowerCase() == anotherAddress.state.toLowerCase()
            test &&= this.postal.toLowerCase() == anotherAddress.postal.toLowerCase()
            test &&= this.country.toLowerCase() == anotherAddress.country.toLowerCase()
            return test
        }
    }

    public clone(): Address{
        return new Address(this._id,this._street,this._secondary,this._city,this._state,this._postal,this._country,this._type)
    }

    public exportInterface(): AddressInterface{
        let out : AddressInterface = {
            id: this._id,
            street: this._street,
            secondary: this._secondary,
            city: this._city,
            state: this._state,
            postal: this._postal,
            country: this._country,
            type: this._type
        }
        return out
    }
}




export class Person {
    public static personFromJSON(x : PersonInterface):Person{
        return new Person(x.id,x.name)
    }

    public static personArrayFromJSON(x : Array<PersonInterface>):Array<Person>{
        return x.map((p) => {return Person.personFromJSON(p)})
    }

    

    public static personToJSON(x : Person): PersonInterface{
        return {id: x.id, name: x.name}
    }

    public static personArrayToJSON(x: Array<Person>):Array<PersonInterface>{
        return x.map((p) => {return Person.personToJSON(p)})
    }

    public static clonePersonArray(x: Array<Person>): Array<Person>{
        return x.map((u)=>{return u.clone()})
    }

    private _id: number | null
    private _firstName: string = ''
    private _lastName: string = ''
    private _name: string

    public get id(): number | null {
        return this._id
    }
    //do not set id
    public get firstName(): string {
        return this._firstName
    }
    public set firstName(value: string) {
        this._firstName = value.trim()
        this._name = this._firstName + " " + this._lastName;
    }
    public get lastName(): string {
        return this._lastName
    }
    public set lastName(value: string) {
        this._lastName = value.trim()
        this._name = this._firstName + " " + this._lastName;
    }

    public get name():string{
        return this._name
    }
    public set name(value: string){
        this._name = value;
        this._firstName = ''
        this._lastName = ''
    }

    constructor()
    constructor(id: number | null, firstName: string, lastName: string)
    constructor(id: number | null, name: string)
    constructor(...args : Array<any>){
        if(args.length == 3){
            this._id = args[0]
            this._firstName = args[1]
            this._lastName = args[2]
            this._name = this.firstName + " " + this.lastName
        }else if (args.length == 2){
            this._id = args[0]
            this._name = args[1]
        }else{
            this._id = null
            this._name = ''
        }

    }


    printFullName(): string {
        if(this._name.length > 0){
            return this._name
        }else{
            return this._firstName + " " + this._lastName
        }
    }
    correctName(): void {
        this._firstName = this.correctWord(this._firstName)
        this._lastName = this.correctWord(this._lastName)
    }
    private correctWord(w: string): string {
        let newWord: string = w
        if (!/[a-z]/.test(w) || !/[A-Z]/.test(w)) {
            newWord = newWord.split(" ")
                .map(function (x) {
                    return x.charAt(0).toUpperCase() + x.slice(1).toLowerCase();
                })
                .join(" ");
        }
        return newWord
    }

    public similarPerson(anotherPerson: Person): boolean {
        return this._firstName == anotherPerson._firstName && this._lastName == anotherPerson._lastName && this._id == anotherPerson._id
    }

    public comparePerson(anotherPerson: Person):boolean{
        return (this.name.length > 0 && anotherPerson.name.length >0) && (this.name.trim() == anotherPerson.name.trim())
    }

    public clone():Person{
        return new Person(this._id,this.name)
    }

    public exportInterface(): PersonInterface{
        let out : PersonInterface = {
            id: this._id,
            name: this.name
        }
        return out
    }
}

export class Judge extends Person{
    public static judgeFromJSON(judge : JudgeInterface): Judge{
        return new Judge(judge.id,judge.name,judge.number)
    }
    public static judgeArrayFromJSON(judges : Array<JudgeInterface>) : Array<Judge>{
        return judges.map((x) => {return Judge.judgeFromJSON(x)})
    }
    private _number : string | null

    get number(): string | null{
        return this._number
    }
    set number(x:string | null){
        this._number = x
    }

    constructor()
    constructor(id: number | null, name: string, number: string)
    constructor(id?: number | null, name?: string, number?: string){
        // if(id && name){
        //     super(id, name)
        //     this._number = number || null
        // }else{
        //     super(i)
        //     this._number = null
        // }
        super(id || null, name || '')
        this._number = number || null
        
    }

    public judgeCompare(x : Judge) : boolean{
        return (x.name == this.name && x.number == this._number)
    }

    public exportInterface(): JudgeInterface{
        let out : JudgeInterface = {
            id: this.id,
            name: this.name,
            number: (this.number ? this.number : "")
        }
        return out
    }
}

export interface AddressInterface{
    id: number | null,
    street: string,
    secondary: string,
    city: string,
    state: string,
    postal: string,
    country: string,
    type: string,
}

export interface PersonInterface {
    id: number | null,
    name: string,
}

export interface JudgeInterface extends PersonInterface{
    number: string
}