
import { Component, Vue } from "vue-property-decorator";
import Loading from "../Loading.vue";
import SecretaryStore from "../../store/modules/secretary";
import {
  EventClass,
  NewEventClassInterface,
  SecretaryNewShowRequest,
  SecretarySubmitShowRequest,
  Show,
  ShowDataRequest,
  ShowInterface,
} from "@app/common";
import ShowStore from "../../store/modules/show";
import NewShowStore from "../../store/modules/newshow";
import fuzzySearch from "../../services/fuzzySearch";
@Component({ components: { Loading } })
export default class NewShowGettingStarted extends Vue {
  get authURL(): string {
    return process.env.VUE_APP_DOG_SITE + "/square-authorize";
  }

  validate(): Array<string> {
    console.log("validate");
    return [];
  }

  get existingShow(): boolean {
    return SecretaryStore.shows.length > 0;
  }

  get existingShows(): Array<Show> {
    return SecretaryStore.shows;
  }

  async mounted() {
    await SecretaryStore.fetchShows();
  }

  get loading(): boolean {
    return SecretaryStore.fetchingShows;
  }

  get filteredShows(): Array<Show> {
    if (this.showSearch && this.showSearch.trim().length > 2) {
      let search = new fuzzySearch((x: Show) => {
        return x.name;
      }, []);
      search.input = this.existingShows;
      search.search = this.showSearch;
      return search.output;
    } else {
      return this.existingShows;
    }
  }

  loadModal: boolean = false;
  loadingShow: boolean = false;
  showSearch: string | null = null;

  async loadShow(show: Show) {
    if (show.id) {
      this.loadingShow = true;
      await ShowStore.fetchShowData({ id: show.id });
      let eventClasses: Array<NewEventClassInterface> = [];
      for (let eventClass of ShowStore.eventClasses) {
        let newEventClass: NewEventClassInterface = {
          event: show.events.findIndex((x) => {
            return x.id == eventClass.event.id;
          }),
          fee: eventClass.fee,
          dogClass: { id: eventClass.dogClass.id, newCustomClass: false },
          judge: {
            id: eventClass.judge.id !== null ? eventClass.judge.id : -1,
            newJudge: false,
          },
        };
        eventClasses.push(newEventClass);
      }
      let showInterface: ShowInterface = show.exportInterface();
      for (let k = 0; k < showInterface.events.length; k++) {
        showInterface.events[k].id = k;
      }
      let showToLoad: SecretarySubmitShowRequest = {
        show: showInterface,
        customClasses: [],
        newJudges: [],
        csrfSecurityToken: "",
        squareToken: -1,
        eventClasses: eventClasses,
      };
      await NewShowStore.setShowFromLocalStorage(showToLoad);
      this.loadingShow = false;
      this.loadModal = false;
    }
  }
}
