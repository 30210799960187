
import { DogEvent, DogEventType } from "@app/common";
import { Component, Vue } from "vue-property-decorator";
import NewShowEventsEvent from "./NewShowEventsEvent.vue";
import NewShowStore from "../../store/modules/newshow";
@Component({
  components: {
    NewShowEventsEvent,
  },
})
export default class NewShowEvents extends Vue {
  dogEvents: Array<DogEvent | null> = [];
  beforeMount() {
    this.dogEvents = [
      new DogEvent(-1, new Date(), new Date(), null, DogEventType.CONFORMATION),
    ];
  }

  mounted() {
    if(NewShowStore.loadFromLocalStorage){
      this.dogEvents = DogEvent.dogEventArrayFromJSON(NewShowStore.show.events)
      console.log("events")
      console.log(this.dogEvents)
    } 
  }

  removeEvent(x: number) {
    console.log("remove")
    console.log(this.dogEvents)
    console.log(x)
    this.dogEvents.splice(x, 1);
    console.log(this.dogEvents)
    this.update();
  }
  addEvent() {
    this.dogEvents.push(
      new DogEvent(-1, new Date(), new Date(), null, DogEventType.CONFORMATION)
    );
  }
  update() {
    let ready: boolean = true;
    for (let dogEvent of this.dogEvents) {
      if (
        !dogEvent ||
        dogEvent.startDate === null ||
        dogEvent.endDate === null ||
        dogEvent.type === null
      ) {
        ready = false;
        break;
      }
    }
    // let newEvents : Array<DogEvent> = []
    // for(let k = 0; k<this.dogEvents.length; k++){
    //   let dogEvent : DogEvent = this.dogEvents[k]
    //   newEvents.push(new DogEvent(k+1,dogEvent.startDate,dogEvent.endDate,dogEvent.AKCNumber,dogEvent.type))
    // }
    NewShowStore.setEvents(
      this.dogEvents.map((x) => {
        return ( x ? x.exportInterface() : new DogEvent(-1, new Date(), new Date(), null, DogEventType.CONFORMATION).exportInterface());
      })
    );
    NewShowStore.setShowEventsReady(ready);
  }

  validate(): Array<string> {
    let out : Array<string> = []
    for(let k = 0; k<this.dogEvents.length; k++){
      let errors : Array<string> = (this.$refs['event-'+k] as any)[0].validate()
      if(errors.length > 0){
        out = errors
        break
      }
    }
    return out
  }
}
