import { Show } from './show'
import { Entry, ShowEntry } from './entries'
import { ShowInterface, User } from '.'

export const DOGALOG_FEE = 100
export const SQUARE_BASE_FEE = 30
export const SQUARE_RATE = 0.029

export enum PaymentType {
    CREDIT_CARD = 'CREDIT_CARD',
    APPLE_PAY = 'APPLE_PAY',
    GOOGLE_PAY = 'GOOGLE_PAY',
    MANUAL_CREDIT_CARD = 'MANUAL_CREDIT_CARD',
    CHECK = 'CHECK',
    WAIVER = 'WAIVER',
    OTHER = 'OTHER',
}

export interface EntryPayment {
    id: number | null
    entry: Entry
    refunded: boolean
}

export interface EntryPaymentInterface{
    id: number
    entry: number
    refunded: boolean
}

export interface CatalogPayment {
    id: number | null
    amount: number
    refunded: boolean
}

export interface CatalogPaymentInterface{
    id: number
    amount: number
    refunded: boolean
}

export class Transaction {
    public static surchargeFee(x:number): number {
        if (x > 0) {
            return (Math.floor((x + SQUARE_BASE_FEE - 0.5) / (1.0 - SQUARE_RATE)) + 1 - x)
        } else {
            return 0
        }
    
    }
    public static surchargeFeeWithParameters(x:number, b:number, r:number): number {
        if (x > 0) {
            return (Math.floor((x + b - 0.5) / (1.0 - r)) + 1 - x)
        } else {
            return 0
        }
    
    }
    private _id: number | null
    private _show: Show
    protected _entries: Array<EntryPayment>
    protected _catalogs: CatalogPayment | null
    private _type: PaymentType
    private _time: Date
    private _idempotencyKey: string | null
    private _online : boolean

    public get id(): number | null {
        return this._id
    }
    //do not set id
    public get show(): Show {
        return this._show
    }
    //do not set show
    public get entries(): Array<EntryPayment> {
        return this._entries
    }
    public set entries(value: Array<EntryPayment>) {
        this._entries = value
    }

    public get catalogs(): CatalogPayment | null {
        return this._catalogs
    }
    public set catalogs(value: CatalogPayment | null) {
        if (value && value.amount < 0) {
            throw "Payment: Catalogs must be non-negative."
        }
        this._catalogs = value
    }
    public get amount(): number {
        let total = this._entries.reduce((s, x) => { return s + x.entry.fee }, 0)
        if (this._show.catalog.canPurchase && this._catalogs) {
            total += this._catalogs.amount
        }
        return total
    }
    //do not set amount
    public get type(): PaymentType {
        return this._type
    }
    public set type(value: PaymentType) {
        this._type = value
    }
    public get time(): Date {
        return this._time
    }
    //do not set time
    public get idempotencyKey(): string | null {
        return this._idempotencyKey
    }
    public set idempotencyKey(value: string | null) {
        this._idempotencyKey = value
    }
    public get online():boolean{
        return this._online
    }

    public get transactionType():string{
        return this._type.split("_").map((x)=>{return x[0].toUpperCase() + x.substring(1)}).join(" ")
    }

    constructor(show: Show)
    constructor(show: Show, id: number | null | undefined, entries: Array<EntryPayment> | undefined, catalogs: CatalogPayment | null | undefined, type: PaymentType | undefined, idempotencyKey: string | null | undefined, time: Date | undefined, online:boolean)
    constructor(show: Show, id?: number | null, entries?: Array<EntryPayment>, catalogs?: CatalogPayment | null, type?: PaymentType, idempotencyKey?: string | null, time?: Date, online?: boolean) {
        this._show = show
        this._id = id || null
        this._entries = entries || []
        this._catalogs = catalogs || null
        this._type = type || PaymentType.CREDIT_CARD
        this._idempotencyKey = idempotencyKey || null
        this._time = time || new Date()
        this._online = (typeof online == 'undefined' ? false : online)
    }

}

export interface TransactionInterface {
    id: number
    show: number
    entries: Array<{id: number, entry: number, refunded: boolean}>
    catalogs: CatalogPaymentInterface | null
    type: PaymentType
    time: number
    idempotencyKey: string
    online : boolean
}

export enum PaymentStatus{
    APPROVED = 'APPROVED',
    PENDING = 'PENDING', 
    COMPLETED = 'COMPLETED', 
    CANCELED = 'CANCELED',
    FAILED = 'FAILED'
}

export class Payment extends Transaction {
    public static paymentFromJSON(payment: PaymentInterface, entries: Array<Entry>, shows: Array<Show>, users? : Array<User>){
        let date = new Date(payment.time)
        let show = shows.find((x) => {return payment.show == x.id})
        if(!show){
            console.error("Payment: Show not found.")
            throw "Payment: Show not found."
        }
        let paymentEntries: Array<EntryPayment> = []
        for(let entryPayment of payment.entries){
            let entry = entries.find((x) => { return x.id == entryPayment.entry})
            if(!entry){
                console.error("Payment: Entry not found")
                throw "Payment: Entry not found"
            }
            paymentEntries.push({id: entryPayment.id, entry: entry, refunded: entryPayment.refunded})
        }
        let user : User | undefined = undefined
        if(users){
            user = users.find((x) => {return x.id == payment.user})
        }
        return new Payment(show,paymentEntries,payment.catalogs,payment.id,payment.type,payment.idempotencyKey,date, payment.surchargeFee, payment.status, payment.online,payment.secretaryFee, user)
    }

    public static paymentArrayFromJSON(payments: Array<PaymentInterface>, entries: Array<Entry>, shows: Array<Show>,users? : Array<User>){
        return payments.map((x) => Payment.paymentFromJSON(x,entries,shows,users))
    }

    private _surchargeFee: number | null
    private _secretaryFee : number
    private _user? : User
    private _status : PaymentStatus
    get surchargeFee(): number{
        if(this._surchargeFee == null){
            return Transaction.surchargeFee(this.amount)
        }else{
            return this._surchargeFee
        }
    }
    get secretaryFee():number{
        return this._secretaryFee
    }

    get total(): number{
        return this.surchargeFee + this.amount + this.secretaryFee
    }
    get user(): User | undefined{
        return this._user
    }
    get status(): PaymentStatus{
        return this._status
    }

    constructor(show: Show, entries: Array<EntryPayment>)
    constructor(show: Show, entries: Array<EntryPayment>, catalogs: CatalogPayment | null, id: number | null, type: PaymentType, idempotencyKey: string, time: Date, surchargeFee: number, status : PaymentStatus,  online: boolean, secretaryFee: number, user? : User)
    constructor(show: Show, entries: Array<EntryPayment>, catalogs?: CatalogPayment | null, id?: number | null,  type?: PaymentType, idempotencyKey?: string, time?: Date, surchargeFee?: number, status?: PaymentStatus, online?: boolean, secretaryFee?: number, user? : User) {
        
        super(show,id,entries,catalogs,type,idempotencyKey,time,(typeof online == 'undefined' ? false : online))
        this._surchargeFee = surchargeFee || null
        this._user = user
        this._status = status || PaymentStatus.PENDING
        this._secretaryFee = (secretaryFee ? secretaryFee : 0)
    }

    public refundedItems(): { entries: Array<EntryPayment>, catalogs: CatalogPayment | null } {
        let entries = this._entries.filter((x) => { return x.refunded })
        let catalogs = (this._catalogs && this._catalogs.refunded ? this._catalogs : null)
        return { entries: entries, catalogs: catalogs }
    }
}

export interface PaymentInterface extends TransactionInterface {
    surchargeFee: number
    status : PaymentStatus
    secretaryFee : number
    user? : number
}

export enum RefundStatus{
    FAILED = 'FAILED',
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    REJECTED = 'REJECTED'
}

export class Refund extends Transaction {
    public static refundFromJSON(refund: RefundInterface, entries: Array<Entry>, shows: Array<Show>, payments: Array<Payment>, users? : Array<User>): Refund{
        let date = new Date(refund.time)
        let show = shows.find((x) => {return refund.show == x.id})
        if(!show){
            throw "Refund: Show not found."
        }
        let refundEntries: Array<EntryPayment> = []
        for(let entryPayment of refund.entries){
            let entry = entries.find((x) => { return x.id == entryPayment.entry})
            if(!entry){
                throw "Refund: Entry not found"
            }
            refundEntries.push({id: entryPayment.id, entry: entry, refunded: entryPayment.refunded})
        }
        let payment = payments.find((x) => {return x.id == refund.payment})
        if(!payment){
            throw "Refund: Payment not found."
        }
        let user : User | undefined = undefined
        if(users){
            user = users.find((x) => {return x.id == refund.user})
        }
        return new Refund(show,payment,refundEntries,refund.catalogs,refund.id,refund.type,refund.idempotencyKey,date,refund.status,refund.surchargeRefund,refund.online,refund.secretaryFeeRefund,user)
    }

    public static refundArrayFromJSON(refunds: Array<RefundInterface>, entries: Array<Entry>, shows: Array<Show>, payments: Array<Payment>, users? : Array<User>): Array<Refund>{
        return refunds.map((x) => Refund.refundFromJSON(x,entries,shows,payments,users))
    }

    private _payment: Payment
    private _status: RefundStatus
    private _surchargeRefund : number | null
    private _secretaryFeeRefund : number
    private _user? : User

    public get payment(): Payment {
        return this._payment
    }
    public set payments(value: Payment) {
        if (value.show.id !== this.show.id) {
            throw "Refund: Payments must all belong to same show."
        }

        this._payment = value
    }
    public get entries(): Array<EntryPayment> {
        return this._entries
    }
    public set entries(value: Array<EntryPayment>) {
        for (let entry of value) {
            let paymentEntry: EntryPayment | undefined = this._payment.entries.find((x) => { return x.id == entry.id })
            if (!paymentEntry) {
                throw "Refund: Entry not in payment."
            } else if (paymentEntry.entry.show.id !== this.show.id) {
                throw "Refund: Entry does not belong to show."
            }
        }
        this._entries = value
    }
    public get status():RefundStatus{
        return this._status
    }
    //Do not set status

    public get surchargeRefund():number | null{
        if(this._surchargeRefund === null){
            return Transaction.surchargeFee(this._payment.amount)-Transaction.surchargeFee(this._payment.amount-this.amount)
        }else{
            return this._surchargeRefund
        }
    }

    public get secretaryFeeRefund():number{
        return this._secretaryFeeRefund
    }

    public get user(): User | undefined{
        return this._user
    }

    constructor(show: Show, payment: Payment)
    constructor(show: Show, payment: Payment, entries: Array<EntryPayment>, catalogs: CatalogPayment | null, id: number | null, type: PaymentType, idempotencyKey: string | null, time: Date, status: RefundStatus, surchargeRefund : number | null, online: boolean, secretaryFeeRefund : number, user? : User)
    constructor(show: Show, payment: Payment, entries?: Array<EntryPayment>, catalogs?: CatalogPayment | null, id?: number | null, type?: PaymentType, idempotencyKey?: string | null, time?: Date, status?: RefundStatus, surchargeRefund? : number | null, online?:boolean, secretaryFeeRefund?:number, user? : User) {
        super(show, id, entries, catalogs, type, idempotencyKey, time, (typeof online == 'undefined' ? false : online))
        this._payment = payment
        this._status = status || RefundStatus.PENDING
        this._surchargeRefund = surchargeRefund || null
        this._user = user
        this._secretaryFeeRefund = (secretaryFeeRefund ? secretaryFeeRefund : 0)
    }

    public get total(): number{
        return this.amount +  (this.surchargeRefund ? this.surchargeRefund : 0) + this.secretaryFeeRefund
    }


}



export interface RefundInterface extends TransactionInterface{
    payment: number
    status: RefundStatus
    surchargeRefund : number | null
    secretaryFeeRefund : number
    user? : number
}


export class SquareToken{
    public static squareTokenFromJSON(squareToken : SquareTokenInterface):SquareToken{
        return new SquareToken(squareToken.id,squareToken.merchant,new Date(squareToken.expiration),squareToken.long,squareToken.shows)
    }
    public static squareTokenArrayFromJSON(squareTokens : Array<SquareTokenInterface>):Array<SquareToken>{
        return squareTokens.map((x) =>{return SquareToken.squareTokenFromJSON(x)})
    }

    private _id : number
    private _merchant : string
    private _expiration : Date
    private _long : boolean
    private _shows : Array<{id : number, name: string}>
    
    get id():number{
        return this._id
    }
    get merchant():string{
        return this._merchant
    }
    get expiration():Date{
        return this._expiration
    }
    get long():boolean{
        return this._long
    }
    get shows(): Array<{id : number, name: string}>{
        return this._shows
    }
    constructor(id : number, merchant : string, expiration : Date, long : boolean, shows : Array<{id : number, name: string}>){
        this._id = id
        this._merchant = merchant
        this._expiration = expiration
        this._long = long
        this._shows = shows
    }
}

export interface SquareTokenInterface{
    id : number,
    merchant : string,
    expiration : number,
    long : boolean,
    shows: Array<{id: number, name: string}>
}